import { GlobalStateType as GST } from "./type";
import { createStore, applyMiddleware, Unsubscribe } from "redux";
import _ from "lodash";
import thunk from 'redux-thunk';
import { INIT_STATE, CACHE_KEY, getSelectConfig } from "./config";
// *********************
// Global State
// *********************

function reducer(
    // get prev state, if not exists, we get from cache or init value
    state: GST.State = INIT_STATE[ getSelectConfig()],
    action: GST.Action
) {
    // init
    let nextState = Object.assign({}, state);
    // switch action
    try {
        switch (action.type) {
            case "SET": {
                nextState = Object.assign({}, action.value);
                localStorage.setItem(CACHE_KEY, JSON.stringify(nextState));
                break;
            }
            case "SET_BY_PATH": {
                _.set(nextState, action.path, action.value);
                localStorage.setItem(CACHE_KEY, JSON.stringify(nextState));
                break;
            }
            case "INIT": {
                nextState = Object.assign({}, INIT_STATE[ getSelectConfig()]);
                localStorage.setItem(CACHE_KEY, JSON.stringify(nextState));
                break;
            }
            default:
                break;
        }
    } catch (error) {

    }
    // return
    return nextState;
}

/**
 * a global `store` created by `Redux` module and `Thunk` middleware
 */
export const GlobalState = createStore(reducer, applyMiddleware(thunk));

// *********************
// Default
// *********************

/**
 * tool class for `GlobalState`
 */
export class StateManager {

    private static state = GlobalState;

    /**
     * this is the observer that saved all callback function that
     * subscribed with `Subscribe` function
     */
    public static Observer: { [key: string]: Function } = {};

    /**
     * subscribe a callback function When `GlobalState` changes
     * @param name 
     * @param fn 
     */
    public static subscribe(name: string, fn: Function): Unsubscribe {
        _.set(this.Observer, name, fn);
        return this.state.subscribe(() => {
            try {
                fn();
            } catch (error) {
                // ignore errors, so far...
            }
        });
    }

    /**
     * try to get state with path by `GlobalState.getState()`, if value is undefined or null,
     * return the state in `InitState`
     * @param path 
     */
    public static get<T = any>(path: keyof GST.State | string[] | string): T {
        try {
            return _.get(this.state.getState(), path);
        } catch (error) {
            return _.get(INIT_STATE[ getSelectConfig()], path);
        }
    }

    /**
     * get state from cache first, if not exists, then get
     * the init value of state
     */
    public static getStateFromCacheOrInit(): void {
        // init
        let state = Object.assign({}, INIT_STATE[ getSelectConfig()]);
        // // try to get "global-state" cache exists
        let stateCached: GST.State = JSON.parse("" + localStorage.getItem(CACHE_KEY));
        // if is exists
        // if (stateCached) state = _.defaultsDeep(JSON.parse(stateCached), InitState);
        if (stateCached) {
            Object.keys(state).forEach((key: any) => {
                // $ handling some special values
                // ...

                // $ handling some normal values
                // $ always using cache value first
                if (stateCached.hasOwnProperty(key)) (state as any)[key] = (stateCached as any)[key];
            });
        }
        // if not existts we set init state in cahce
        else localStorage.setItem(CACHE_KEY, JSON.stringify(INIT_STATE[ getSelectConfig()]));
        // set state
        this.state.dispatch({
            type: "SET",
            value: state
        })
    }

    /**
     * get value from cache
     * @param path 
     */
    public static getFromCache<T = any>(path: keyof GST.State | string[] | string): T {
        try {
            let cache: any = localStorage.getItem(CACHE_KEY);
            cache = JSON.parse(cache);
            return _.get(cache, path);
        } catch (error) {
            return _.get(INIT_STATE[ getSelectConfig()], path);
        }
    }

    /**
     * set value from cache
     * @param path 
     * @param value 
     */
    public static setFromCache(path: keyof GST.State | string[] | string, value: any): void {
        try {
            // get and check cahce
            let cache: any = localStorage.getItem(CACHE_KEY);
            if (!cache) cache = INIT_STATE[ getSelectConfig()];
            else cache = JSON.parse(cache);
            // set values
            _.set(cache, path, value);
            localStorage.setItem(CACHE_KEY, JSON.stringify(cache));

            // $ we update state also
            GlobalState.dispatch({
                type: "SET_BY_PATH",
                path: path,
                value: value
            });
        } catch (error) { }
    }

    /**
     * check if already sign in
     */
    public static isSignedIn(): boolean {
        try {
            let account: string = this.getFromCache("account");
            if (!account || account === "") return false;
            else return true;
        } catch (error) {
            return false;
        }
    }

}