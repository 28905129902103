import React from 'react';
import { Button, FormControl, RadioGroup, FormControlLabel, Radio, Breadcrumbs, Switch, Fade, IconButton, Slide } from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import { CardComponent } from '../components/card.component'
import Paging from "../components/paging.component"
import TablesClassifyComponent from "../components/tables-classify.component";
import { CardProcessor, CardBox } from '../modules/cardProcessor/module'
import { StateManager, GlobalState, CACHE_LOGGER, UPDATE_STATUS, CACHE_PRINT_LOGGER } from '../modules/state';
import { GlobalStateType as GST } from "../modules/state";
import { Request } from '../modules/request'
import { createHashHistory } from 'history';
import i18nService from '../i18n';
import "../css/chef.css"
import _ from 'lodash';
import LoadingComponent from "../components/loading.component";
import { MergeProcessor } from "../modules/mergeProcessor/module"
import { MergeRules, MagnetRules, MergeObject, MergeArray, SortRules } from "../modules/mergeProcessor/type";
// import { withSnackbar } from 'notistack';
import { MessengerModule } from "../modules/messenger/module";
import { ERROR_0011, ERROR_0012, ERROR_0016, ERROR_0017, ERROR_003, ERROR_0018, ERROR_0019, ERROR_0020, ERROR_0021, ERROR_0022, ERROR_0023, ERROR_0024, ERROR_0025 } from "../constants/error";
import { dish, PrintData } from '../modules/cardProcessor/type';
import CardDialogComponent from '../components/card-dialog.component';
import DialogComponent from '../components/dialog.component';
import { Unsubscribe } from 'redux';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import LoopIcon from '@material-ui/icons/Loop';
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import MenuIcon from '@material-ui/icons/Menu';
import { getStateDiffs } from "../utils/tools";
import LayersClearIcon from '@material-ui/icons/LayersClear';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RedoIcon from '@material-ui/icons/Redo';
import { SwitchComponent } from '../components/setting-switch.component';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
let moment = require('moment-timezone');
const uuidv4 = require('uuid/v4');

class CommonPage extends React.Component<Props, State> {

    private classes: any = {};
    private employeeName: string = '';
    constructor(props: any) {
        super(props);

        // binding `this`
        this.registerKeyboardEvent = this.registerKeyboardEvent.bind(this);
        this.focusArea = this.focusArea.bind(this);
        this.checkDependency = this.checkDependency.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.refreshDataDifferences = this.refreshDataDifferences.bind(this);
        // this.refreshChefDishesStatistics = this.refreshChefDishesStatistics.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.refreshDishes = this.refreshDishes.bind(this);
        this.refreshSettingInfo = this.refreshSettingInfo.bind(this);
        this.unfoldShrinkChange = this.unfoldShrinkChange.bind(this);
        this.updateGlobalState = this.updateGlobalState.bind(this);
        this.updateLocalState = this.updateLocalState.bind(this);
        this.clickTab = this.clickTab.bind(this);
        this.updateDishesInfo = this.updateDishesInfo.bind(this)
        this.intelligentMerge = this.intelligentMerge.bind(this)
        this.clickUnableToMake = this.clickUnableToMake.bind(this)
        this.clickSuspended = this.clickSuspended.bind(this)
        this.flashingButton = this.flashingButton.bind(this)
        this.intelligentMergeOrderMode = this.intelligentMergeOrderMode.bind(this);
        this.changeModeStatus = this.changeModeStatus.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.findDishNameInSelectSummaryDishNames = this.findDishNameInSelectSummaryDishNames.bind(this);
        this.getShowDishesSidebarStatus = this.getShowDishesSidebarStatus.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);

        // this.classes = useStyles();
        this.classes = {
            root: {
                height: "100vh",
                width: "100%",
                backgroundColor: '#170503',
                display: 'flex',
                flexDirection: "row",
                overflow: "hidden"
            },
            rootLeft: {
                display: "flex",
                flex: 1,
                height: "100vh",
                background: "#38363E",
                flexDirection: "column",
                position: "relative",
            },

            rootRight: {
                flex: 8,
                height: "99vh",
                border: "none",
                backgroundColor: '#170503',
                overflow: 'hidden',
            },
            "rootRight:: -webkit - scrollbar": {
                display: 'none'
            },
            rootRightRight: {
                display: "flex",
                flex: 1,
                height: "100vh",
                flexDirection: "column",
                paddingRight: 20,
                backgroundColor: '#170503',
                border: "none"
            },
            tabs: {
                height: "60vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            },

            backimgSetimg: {
                height: 170,
                display: 'flex',
                justifyContent: 'center',
            },
            backimg: {
                width: 20,
                height: 20,
            },
            setimg: {
                width: 43,
                height: 43,
            },
            fab: {
                // margin: theme.spacing(1),
            },
            //组件样式
            ChefStateComponentroot: {
                height: "76vh",
                width: "100%",
                borderRadius: 10,
                padding: 30,
                marginBottom: 30
            },
            ChefStateComponentrootTabs: {
                height: 52,
                borderRadius: 100,
                fontWeight: "bold",
                fontSize: 20,
                marginLeft: 10,
                borderWidth: 0,
            },
            ChefStateComponentpadding: {
                // padding: theme.spacing(0, 2),
            },
            ChefStateComponentmargin: {
                // margin: theme.spacing(2),
            }
        };

        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        //init sound
        try {
            if (StateManager.get('soundReminder')) {
                let ringtoneFile = StateManager.get('ringtoneFile');
                if (ringtoneFile) this.audio = new Audio(require(`../assets/audio/${ringtoneFile}`))
            }
        } catch{

        }
        let globalMergeStatus = _.get(StateManager.get('globalMergeStatus'),[this.props.role])
        this.state = {
            // refresh key
            refreshTime: new Date(),
            // $ basic parameters
            // default
            serveUrl: StateManager.get("serveUrl"),
            theme: StateManager.get("theme"),
            // settings
            check: 'first',
            // chef post 
            positions: StateManager.get("chefPositionsSelected"),
            waiterPositionsSelected: StateManager.get("waiterPositionsSelected"),
            settingInfo: StateManager.get("settingInfo"),
            // functions
            countStatisticsFunction: StateManager.get("countStatisticsFunction"),
            // $ view data
            // top filtter button
            filterButtonBadgeValue: [0, 0, 0, 0, 0],
            filterValue: 0,
            filterButton: [
                { name: `${i18nService.t('text_not_yet_made')}`, value: 'kitchen', status: 0 },
                { name: `${i18nService.t('text_prepare_dish')}`, value: 'prepareDish', status: 11 },
                { name: `${i18nService.t('text_made')}`, value: 'history', status: 1 },
                { name: `${i18nService.t('text_pause_production')}`, value: 'suspend', status: 3 },
                { name: `${i18nService.t('text_cancel_production')}`, value: 'cancelled', status: 5 },
            ],
            filterButtonSelected: 'kitchen',
            loadingVisible: false,
            // current mode
            mode: [
                { name: "normal_mode", i18nString: `${i18nService.t('text_normal_mode')}` },
                { name: "order_mode", i18nString: `${i18nService.t('text_order_mode')}` },
                { name: "takeaway_mode", i18nString: `${i18nService.t('text_takeaway_mode')}` },
                { name: "update", i18nString: `${i18nService.t('text_update')}` },
                { name: "setting", i18nString: `${i18nService.t('text_settings')}` },
                { name: "come_back", i18nString: `${i18nService.t('text_come_back')}` },
            ],
            modeSelected: "normal_mode",
            intelligentMode: _.get(globalMergeStatus,['kitchen','intelligentMode']),
            // pagination
            page: 1,
            chefCardPerPage: StateManager.get("cardPerPageNum"),
            waiterUnMakePerPageNum: StateManager.get("waiterUnMakePerPageNum"),
            totalPage: 1,
            // card
            displayDishInCombination: StateManager.get("displayDishInCombination"),
            dishesData: new CardProcessor([]),
            dishesdDeskBadgeValue: [0],
            currentDesk: 'All',
            currentEatType: 'All',
            deskList: ['All'],
            cardData: (new CardProcessor([]) as any).dishesData.BoxGroup,
            selectedCard: [],
            // 存储selectedCard的index
            selectedCardIndex: {},
            lastMergeArr: [],
            name1FontSize: StateManager.get("name1FontSize"),
            name2FontSize: StateManager.get("name2FontSize"),
            displayName1: StateManager.get("displayName1"),
            displayName2: StateManager.get("displayName2"),
            // card dialog
            cardDialogVisible: false,
            cardDialogDishes: [],
            // other
            unfoldShrink: true,
            timeoutID: null,
            // 定时器
            turnOnTheTimer: false,
            // 状态更新确认框
            updteCardDialogVisible: false,
            // 催菜确认框
            rushDishDialogVisible: false,
            // 取消制作原因选择框
            reasonDialogVisible: false,
            reasonData: [],
            selectReasonValue: '',
            // refresh button
            refreshButtonLock: false,
            //打印小票
            printASmallTicket: StateManager.get("crossFoodPrint"),
            orderModeDeskList: ["All"],
            dishesEatTypeBadgeValue: [0],
            // 就餐方式
            eatTypeValue: {
                0: `${i18nService.t('text_dinein')}`,
                1: `${i18nService.t('text_hall_for_packaging')}`,
                2: `${i18nService.t('text_outside_order')}`,
                3: `${i18nService.t('text_to_store_comeUndone')}`
            },
            // 菜品请求体
            getDishesRequestBody: null,
            cardTotalShowAreaWidth: 0,
            cardActualWidth: StateManager.get('cardWidth'),
            cardNumber: 0,
            // 外卖订单显示时间范围外的最小时间
            deliveryRangeOutsideMinKdsTime: null,
            // 智能合并是否缩略模式。
            isSimpleMode: _.get(globalMergeStatus,['kitchen','isSimpleMode']),
            // 汇总的菜品名称
            summaryDishNames:[],
            selectSummaryDishNames:[],
            // 外卖单汇总
            summaryTakeawayOrder: [],
            selectSummaryTakeawayOrder:[],
            // 声音通知数据，例如此时在已备菜的页面中，如果需要提示已下单的数据，这里存的就是已下单所有数据。
            soundNoticeData:[],
            // 总单显示模式
            totalOrderMode: StateManager.get('totalOrderMode'),
            // 显示外卖单
            displayTakeawayOrder: StateManager.get('displayTakeawayOrder'),
        };

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("chef-page-update", () => {
            // i18nService.changeLanguage(StateManager.get("language"));

            this.setState({
                ...this.state,
                serveUrl: StateManager.get("serveUrl"),
                theme: StateManager.get("theme"),
                positions: StateManager.get("chefPositionsSelected"),
                waiterPositionsSelected: StateManager.get("waiterPositionsSelected"),
                settingInfo: StateManager.get("settingInfo"),
                countStatisticsFunction: StateManager.get("countStatisticsFunction"),
                chefCardPerPage: StateManager.get("cardPerPageNum"),
                waiterUnMakePerPageNum: StateManager.get("waiterUnMakePerPageNum"),
                displayDishInCombination: StateManager.get("displayDishInCombination"),
                name1FontSize: StateManager.get("name1FontSize"),
                name2FontSize: StateManager.get("name2FontSize"),
                displayName1: StateManager.get("displayName1"),
                displayName2: StateManager.get("displayName2"),
                totalOrderMode: StateManager.get('totalOrderMode'),
                displayTakeawayOrder: StateManager.get('displayTakeawayOrder')
            })
        });
    };

    private messengerModule: MessengerModule;
    // 按钮闪烁效果的timeout id
    private flashingTimeId: any = null;
    // 判断是否需要开启自动更新的 timeout
    private needSetTimeId: boolean = true;
    // 卡片需要更新成的状态按钮索引
    private toUpdateStatusIndex: number = 0;

    private unsubscribe: Unsubscribe;
    // 就餐方式筛选存储对象
    private eayTypeRef: any;
    // 桌台筛选存储对象
    private tableListRef: any;
    // 铃声
    private audio: any = null;
    // 磁链模式
    private magneticLinkMode:boolean = false;
    // 双击后，需要等待更新的timeoutid
    private updateRefreshTimeId: number | undefined; 
    // 5s后自动关闭左侧边栏的timer
    private timer: number | undefined = undefined;

    // *********************
    // Default Function
    // *********************

    /**
     * update local state by object
     * @param val 
     * @param callback  setState更新完之后的回调函数
     */
    public updateLocalState(val: Partial<State>, callback?: () => void) {
        this.setState(val as any, callback);
    };

    /**
     * update global state by action
     * @param val 
     */
    public updateGlobalState(action: GST.Action) {
        GlobalState.dispatch(action);
    };

    /**
     * using this funciton to check local state values
     * @param paths 
     */
    public checkDependency(paths: string[]) {
        // init
        let flag: boolean = true;
        let report: Array<{ path: string, ok: boolean }> = [];
        // check every dependencies
        try {
            paths.forEach(path => {
                try {
                    if (_.get(this.state, path)) report.push({ path: path, ok: true });
                    else throw new Error("target value is falsy value");
                } catch (error) {
                    report.push({ path: path, ok: false });
                    flag = false;
                }
            });
        } catch (error) {
            flag = false;
        }
        // return
        return { flag, report };
    }

    // *********************
    // Life Cycle Function
    // *********************

    /**
     * init function
     */
    async componentDidMount() {
        const isHideLeftSidebarAuto = StateManager.get('isHideLeftSidebarAutomatically');
        isHideLeftSidebarAuto && this.hideLeftSidebar();
         // 全局存储当前选中模式
         this.updateGlobalState({
            type: "SET_BY_PATH",
            path: "modeSelected",
            value: this.state.modeSelected || 'normal_mode'
        });
        try {
            // check if keyboard mode is on
            // if() ...
            this.registerKeyboardEvent();
            // set props in local state
            if (this.props.filterButton && this.props.filterButton.length > 0) {
                this.updateLocalState({
                    filterButton: this.props.filterButton
                })
            }
            let settingInfo = this.state.settingInfo.find((target: any) => target.id === 61);
            if (settingInfo) {
                this.magneticLinkMode = settingInfo.value.enable;
                if (this.magneticLinkMode && this.props.role === 'waiter' && this.props.filterButtonSelected ==='kitchen') {
                    this.changeModeStatus('order_mode')
                }
            }
            if (this.props.filterButtonBadgeValue && this.props.filterButtonBadgeValue.length > 0) this.updateLocalState({
                filterButtonBadgeValue: this.props.filterButtonBadgeValue
            })
            if (this.props.filterButtonSelected) {
                let globalMergeStatus = _.get(StateManager.get('globalMergeStatus'),this.props.role)
                
                this.updateLocalState({
                    filterButtonSelected: this.props.filterButtonSelected,
                    isSimpleMode: _.get(globalMergeStatus,[this.props.filterButtonSelected,'isSimpleMode']),
                    intelligentMode: _.get(globalMergeStatus,[this.props.filterButtonSelected,'intelligentMode'])
                })
            }
            if (this.props.filterValue) this.updateLocalState({
                filterValue: this.props.filterValue
            })

            // 获取取消制作原因
            let result = await Request.getReason(this.state.serveUrl);
            if (result && Array.isArray(result.data) && result.data.length > 0) {
                this.setState({
                    reasonData: result.data,
                    selectReasonValue: result.data[0].reason_name
                })
            }

            window.addEventListener('resize', this.handleResize);
            // 进来的时候获取当前账号姓名
            try {
                let result = await Request.getEmployee(StateManager.get('account'), StateManager.get('serveUrl'));
                if(result && result.data && Array.isArray(result.data) && result.data.length > 0) {
                    this.employeeName = result.data[0].employee_last_name
                }
            } catch (error) {
                
            }
            this.init();
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0016,
                details: [
                    error,
                    "caller: chef.page.tsx => componentDidMount()"
                ]
            }, { horizontal: "right" });
        }
    }

    /**
     * remove subscribe and timer
     */
    componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();

        window.removeEventListener('resize', this.handleResize)

        // 页面销毁前，不需要自动更新了
        this.needSetTimeId = false;
        // clear timer
        window.clearTimeout(this.state.timeoutID);
        window.clearInterval(this.flashingTimeId);
        window.clearTimeout(this.timer);
    }

    componentDidUpdate() {
        this.handleResize();
    }

    shouldComponentUpdate(nextProps: any, nextState: State) {
        try {
            // get diffs report
            let report = getStateDiffs(this.state, nextState);
            // $ do not refresh
            if (
                (report.flag === true && report.diffs.length === 0) ||
                (report.flag === false && report.diffs[0] === "timeoutID")
            ) {
                // console.log(report, "[X]");
                return false;
            }
            // $ do refresh
            else {
                // console.log(report);
                return true;
            }
        } catch (error) {
            return true;
        }
    }

    // *********************
    // Keyboard Mode Function
    // *********************

    public registerKeyboardEvent() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            // console.log(`${event.code}`);
        });
    }

    public focusArea() {

    }

    // *********************
    // Service Function
    // *********************
    handleResize() {
        // 计算卡片显示区域居中所需要的宽度
        let cardTotalShowAreaElement: any = this.refs['cardTotalShowArea'];
        let keys = Object.keys(this.refs)
        // 因为有一个ref是cardTotalShowArea，所以长度需要大于等于2才能保证当前有菜品卡片
        if(!Array.isArray(keys) || keys.length < 2) return ;
        let key = keys.find(target => target !== 'cardTotalShowArea')
        let cardComponent: any = this.refs[key || 'card0'];
        // 先判断卡片是否已经生成
        // if (cardComponent && cardComponent.refs && cardComponent.refs.card) {
        //     let cardElement = cardComponent.refs.card;
        //     // 判断总的卡片显示区域宽度是否有变化
        //     if (cardTotalShowAreaElement && (cardTotalShowAreaElement.clientWidth !== this.state.cardTotalShowAreaWidth || this.state.cardNumber !== this.state.cardData.length)) {
        //         // 计算出一行可以显示多少个卡片,10是每个卡片的右边距
        //         let cardNumberInline = Math.floor(cardTotalShowAreaElement.clientWidth / (cardElement.offsetWidth + 10));
        //         // 当前卡片数量小于一行可显示卡片数，则使用当前卡片数量来计算
        //         if (this.state.cardData.length < cardNumberInline) cardNumberInline = this.state.cardData.length
        //         // console.log('cardTotalShowAreaElement.clientWidth', cardTotalShowAreaElement.clientWidth)
        //         // console.log('cardNumberInline', cardNumberInline)
        //         // console.log('cardElement.offsetWidth', cardElement.offsetWidth)
        //         this.setState({
        //             // 显示卡片的宽度：一行显示卡片的个数*每个卡片的宽度
        //             cardShowAreaWidth: `${(cardNumberInline * (cardElement.offsetWidth + 10))}px`,
        //             cardTotalShowAreaWidth: cardTotalShowAreaElement.clientWidth,
        //             cardNumber: this.state.cardData.length
        //         })
        //     }
        // }
        if (cardComponent && cardComponent.refs && cardComponent.refs.card) {
            let cardElement = cardComponent.refs.card;
            // 判断总的卡片显示区域宽度是否有变化
            if (cardTotalShowAreaElement && (cardTotalShowAreaElement.clientWidth !== this.state.cardTotalShowAreaWidth || this.state.cardNumber !== this.state.cardData.length)) {
                // 计算出一行可以显示多少个卡片,12是卡片的左右边框
                let cardWidth = StateManager.get('cardWidth') ? StateManager.get('cardWidth') + 12 : cardElement.offsetWidth
                let cardNumberInline = Math.floor(cardTotalShowAreaElement.clientWidth / (cardWidth));
                // let cardNumberInline = Math.round(Math.floor(cardTotalShowAreaElement.clientWidth / (cardWidth + 10) * 100)) /100;
                // 当前卡片数量小于一行可显示卡片数，则使用当前卡片数量来计算
                // if (this.state.cardData.length < cardNumberInline) cardNumberInline = this.state.cardData.length
                // console.log('cardWidth', cardWidth)
                // console.log('cardWidth', StateManager.get('cardWidth'))
                // console.log('cardTotalShowAreaElement.clientWidth', cardTotalShowAreaElement.clientWidth)
                // console.log('cardNumberInline', cardNumberInline)
                // console.log('cardActualWidth',( Math.round(Math.floor((cardTotalShowAreaElement.clientWidth / cardNumberInline) * 100)) / 100) - 23)
                this.setState({
                    // 显示卡片的宽度：总的显示区域除以一行可显示的卡片数 23:左右6px的边框，右边距10，还有1是预防误差。
                    cardActualWidth: (Math.round(Math.floor((cardTotalShowAreaElement.clientWidth / cardNumberInline) * 100)) / 100) - 23,
                    cardTotalShowAreaWidth: cardTotalShowAreaElement.clientWidth,
                    cardNumber: this.state.cardData.length
                })
            }
        }
    }
    /**
     * 刷新当前数据
     */
    public async init() {
        try {
            // we check if already has a account
            let flag: boolean = StateManager.isSignedIn();
            if (!flag) {
                createHashHistory().push('./');
                return;
            }
            // check if "settingInfo" exists
            let report = this.checkDependency(["settingInfo"]);
            if (!report.flag) await this.refreshSettingInfo();
            // 获取RBP设置中的轮次权重值
            const turnWeight = await Request.getRoundWeightValue(this.state.serveUrl)
            // 全局存储轮次权重值，方便调用
            this.updateGlobalState({
                type: "SET_BY_PATH",
                path: "turnWeightValue",
                value: turnWeight.data[0]['value'] || 0
            });
            // init all data first
            await this.refreshData(this.state.currentDesk, this.state.currentEatType, this.state.page - 1);

            // start the timer
            await this.refreshDataDifferences();
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0016,
                details: [
                    error,
                    "caller: chef.page.tsx => init()"
                ]
            }, { horizontal: "right" });
        }
    }

    /**
     * 取消所有卡片的选中状态
     */
    public unSelectAllCard() {
        // 把全部卡片的选中状态撤销
        Object.keys(this.refs).forEach((target) => {
            if (/^card\d*$/g.test(target)) {
                (this.refs[target] as CardComponent).setState({ select: false });
            }
        })
    }
    /**
     * 查找菜品名称在已选择的菜品汇总中
     * @param dish 
     */
    public findDishNameInSelectSummaryDishNames(dish: any){
        let dishesSummarySidebar = StateManager.get('dishesSummarySidebar');
        let index:number
        if(dishesSummarySidebar === '1'){
            // 菜品名称1
            index = this.state.selectSummaryDishNames.findIndex((dishName: any) => dish.item_name1 === dishName.item_name1)
        }else {
            // 菜品名称2
            index = this.state.selectSummaryDishNames.findIndex((dishName: any) => dish.item_name2 === dishName.item_name2)
        }
        return index
    }

    /**
     * 查找外卖单在已选择的外卖单汇总中
     * @param dish 
     */
     public findTakeawayInSelectSummaryTakeawayOrder(takeaway: SummaryTakeawayOrder){
        let index:number = this.state.selectSummaryTakeawayOrder.findIndex((selectTakeaway: SummaryTakeawayOrder) => {
            return `${takeaway.check_number}` === `${selectTakeaway.check_number}` &&
                `${takeaway.eat_type}` === `${selectTakeaway.eat_type}` && takeaway.customer_name === selectTakeaway.customer_name && `${takeaway.kds_time}` === `${selectTakeaway.kds_time}`
        });
        return index
    }
    /**
     * 获取是否需要在订单模式中显示右侧的菜品汇总栏位
     */
    public getShowDishesSidebarStatus(){
       return StateManager.get('displayDishesSidebar') &&
        (
            (this.props.role === 'chef' && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode') && this.state.filterButtonSelected === 'kitchen') || 
            (this.props.role === 'waiter' && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode') && this.state.filterButtonSelected === 'kitchen')
        )
    }
    /**
     * refresh data
     * - step 0: clear the timeout
     * - step 1: refresh dishes statistics
     * - step 2: refresh dishes
     * - step 3: rebuild dishes
     */
    public async refreshData(
        deskNum: "All" | number = "All",
        eatType: string | number = "All",
        pageNum: number = 0,
    ) {
        // start loading
        this.updateLocalState({ loadingVisible: true });
        // init
        let updated: any = {};
        let situation: number | null = null;
        try {
            // $ step 0 
            // clearSetTimeout
            clearTimeout(this.state.timeoutID);

            // $ step 1
            // refresh dishes statistics
            // 把获取数量的接口整合到在获取菜品里面。所以不需要单独执行获取数量了
            // if (this.state.countStatisticsFunction) await this.refreshChefDishesStatistics();

            // $ step 2
            // refresh dishes
            let { 
                deskList, 
                totalPage,
                dishesdDeskBadgeValue, 
                cardData, 
                orderModeDeskList,
                dishesEatTypeBadgeValue,
                deliveryRangeOutsideMinKdsTime,
                filterButtonBadgeValue, 
                summaryDishNames,
                summaryTakeawayOrder
            } = await this.refreshDishes(deskNum, eatType, pageNum);
            // append to updated object
            updated.deskList = deskList;
            updated.orderModeDeskList = orderModeDeskList;
            updated.dishesEatTypeBadgeValue = dishesEatTypeBadgeValue;
            updated.totalPage = totalPage;
            updated.dishesdDeskBadgeValue = dishesdDeskBadgeValue;
            updated.deliveryRangeOutsideMinKdsTime = deliveryRangeOutsideMinKdsTime;
            updated.filterButtonBadgeValue = filterButtonBadgeValue;
            updated.summaryDishNames = summaryDishNames;
            updated.summaryTakeawayOrder = summaryTakeawayOrder;
            // $ step 3
            // $ Normal Mode Enabled + Smart Merger Enabled + Smart Merger Auto Start Disabled + Not First Time Refresh
            if (
                (this.state.modeSelected === 'normal_mode') &&
                this.state.intelligentMode &&
                !StateManager.get('smartMergerAutoStart') &&
                this.state.lastMergeArr.length > 0
            ) {
                situation = 0;
                let result = this.intelligentMerge(cardData, false);
                // append to updated object
                updated.dishesData = result.dishesData;
                updated.cardData = result.cardData;
                updated.lastMergeArr = result.lastMergeArr;

            }
            // $ Normal Mode Enabled + Smart Merger Enabled + Smart Merger Auto Start Enabled Or Not + First Time Refresh Or Not
            else if (
                (this.state.modeSelected === 'normal_mode') &&
                this.state.intelligentMode
            ) {
                situation = 1;

                let result = this.intelligentMerge(cardData, true);
                // append to updated object
                updated.dishesData = result.dishesData;
                updated.cardData = result.cardData;
                updated.lastMergeArr = result.lastMergeArr;
            }
            // $ Order Mode Enabled
            else if (this.state.modeSelected === 'order_mode' || this.state.modeSelected === 'takeaway_mode') {
                situation = 2;

                let result = this.intelligentMergeOrderMode(cardData);
                // 如果当前是订单模式，并且选中了外卖订单统计，则进行排序
                let eatTypeValue = Object.keys(this.state.eatTypeValue)
                if(Array.isArray(eatTypeValue) && this.state.modeSelected === 'order_mode'){
                    for (const eatType of eatTypeValue) {
                        // 当前点击的元素非堂吃，则对卡片经行排序
                        if(`${eatType}` !== '0' && this.state.eatTypeValue[eatType] === this.state.currentEatType) {
                            result.cardData.sort((a,b)=> {
                                // 因为kdsTime是有时分，因此需要默认给一个年月日格式，另外餐馆不会存在第二天的菜品，所以可以直接使用同一个日期
                                let aTime = a.cardInfo && a.cardInfo.kdsTime ? new Date(`1990-01-01 ${a.cardInfo.kdsTime}`).getTime() : 0;
                                let bTime = b.cardInfo && b.cardInfo.kdsTime ? new Date(`1990-01-01 ${b.cardInfo.kdsTime}`).getTime() : 0;
                                return aTime - bTime
                            });
                            break;
                        }
                    }
                }
                // append to updated object
                updated.dishesData = result.dishesData;
                updated.cardData = result.cardData;
                updated.lastMergeArr = result.lastMergeArr;
            }
            // $ Does not satisfy any situation
            else {
                situation = 3;
                // $ 在不参与合并的情况下，菜品按照权重值排序(只影响已下单页面)
                // 已备菜页面也按照权重值排序，但不受轮次权重影响
                if((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') || (this.state.filterButtonSelected === 'prepareDish')) {
                    cardData.sort((a: CardBox,b: CardBox) => {
                        let pre = a.cardInfo.boxData[0].dishWeights
                        let nex = b.cardInfo.boxData[0].dishWeights
                        return nex - pre
                    })
                }
                updated.cardData = cardData;
                updated.lastMergeArr = [];
            }
            // update local state
            this.updateLocalState(updated);
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0017,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshData()",
                    modeSelected: this.state.modeSelected,
                    countStatisticsFunction: this.state.countStatisticsFunction,
                    updated: updated,
                    situation: situation
                }
            }, { horizontal: "right" });
        }
        // stop loading
        this.updateLocalState({ loadingVisible: false });
    }

    /**
     * refresh the setting info (resturant settings that stored in mysql)
     */
    public async refreshSettingInfo(): Promise<void> {
        try {
            // call api
            const result: any = await Request.getSettingInfo(this.state.serveUrl);
            if (!result) throw ERROR_0011
            // update global state
            this.updateGlobalState({
                type: "SET_BY_PATH",
                path: "settingInfo",
                value: result.data
            });
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_003,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshSettingInfo()",
                }
            }, { horizontal: "right" });
        }

    }

    /**
     * refresh the number of dishes statistics in chef page
     */
    public async refreshChefDishesStatistics(): Promise<void> {
        try {
            // check
            const report = this.checkDependency([
                "settingInfo[0].value.menu_id",
            ]);
            if (!report.flag) throw ERROR_0011

            // init
            let positionsArray: any[] = this.state.positions.map((p: any) => `${p.print_class_id}`);
            let waiterPositionsArray: any[] = this.state.waiterPositionsSelected.map((p: any) => `${p.print_class_id}`);
            // 获取点餐餐厅模式
            let turnEnable = this.state.settingInfo.find((target: any) => target.id === 17);
            let rule = {
                isPrintDevice: false,
                menu_id: this.state.settingInfo[0].value.menu_id,
                turn_enable: this.props.role === 'chef' ? turnEnable && turnEnable.value ? turnEnable.value.status : false : false, //只有厨师菜需要点餐餐厅模式
                deliveryDisplayRange: StateManager.get('deliveryDisplayRange')
            }
            // call api
            let result: any
            if (this.props.role === 'chef') result = await Request.chefGetAllCount(
                // $ 设置中选中的岗位
                positionsArray,
                // $ 固定值
                StateManager.get('displayDishInCombination') ? 'combo' : 'normal',
                //是否套餐
                StateManager.get('displayDishInCombination'),
                // $ 额外费用ID
                // passing "extraMenuId" to let api ignore the extra cost dish
                rule,
                // $ 需要显示的桌台
                StateManager.get('regionalMenuFunction') ? StateManager.get('tableNameSelected') : undefined,
                // $ 服务器地址
                this.state.serveUrl
            );
            else result = await Request.waiterGetAllCount(
                // $ 设置中选中的岗位
                positionsArray,
                // 服务员未制作选择岗位
                waiterPositionsArray,
                // $ 固定值
                StateManager.get('displayDishInCombination') ? 'combo' : 'normal',
                //是否套餐
                StateManager.get('displayDishInCombination'),
                // $ 额外费用ID
                // passing "extraMenuId" to let api ignore the extra cost dish
                rule,
                // $ 需要显示的桌台
                StateManager.get('regionalMenuFunction') ? StateManager.get('tableNameSelected') : undefined,
                // $ 服务器地址
                this.state.serveUrl
            );
            // update state
            this.updateLocalState({
                filterButtonBadgeValue: result
            });
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0018,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshChefDishesStatistics()",
                }
            }, { horizontal: "right" });
        }
    }

    /**
     * 获取顾客名称
     */
     getCustomerName = (dish: any) => {
        try {
            let deliveryInfo = _.get(dish, 'delivery_info') || ''
            let customerArr = deliveryInfo.split(',')
            if (customerArr.length > 0) {
                // let customerName = customerArr[0].split(' ')
                return _.get(customerArr, ['0'], '') || ''
            } else return ''
        } catch (error) {
            return ''
        }
    }

    /**
     * refresh the dishes
     */
    public async refreshDishes(deskNum: string | number, eatType: string | number, pageNum: number = 0): Promise<{
        deskList: any[];
        totalPage: number;
        dishesdDeskBadgeValue: number[];
        cardData: Array<CardBox>;
        orderModeDeskList: any[];
        dishesEatTypeBadgeValue: number[];
        deliveryRangeOutsideMinKdsTime: Date | null;
        filterButtonBadgeValue: number[];
        summaryDishNames: Array<SummaryDishNames>;
        summaryTakeawayOrder: Array<SummaryTakeawayOrder>;
    }> {
        // inti
        let positionsArray: any[] = [];
        let rule: any = {};
        try {

            // init
            positionsArray = this.state.positions.map((p: any) => p.print_class_id);
            let waiterPositionsArray: any[] = this.state.waiterPositionsSelected.map((p: any) => `${p.print_class_id}`);
            let ruleSort: any = '';
            let isShowTotalOrder = false;
            // 待上菜页面的排序
            if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
                ruleSort = StateManager.get('readyToServeSort') === 'endTimeSort' ? ' order_detail.rush DESC, order_detail.end_time ASC, order_detail.order_detail_id ' : ' order_detail.rush DESC, order_detail.order_time ASC, order_detail.order_detail_id '
                // 是否显示总单模式,需要在服务员->订单模式->待上菜界面，并且开启了显示总单模式的功能。
                isShowTotalOrder = StateManager.get('totalOrderMode') && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode')
            }
            // 已下单页面排序
            if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen')) {
                ruleSort = (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'normal_mode') && StateManager.get('orderedSort') === 'kdsTimeJointOrderTimeSort' ? ' rush DESC, joint_time is null, joint_time, order_detail.order_detail_id ' : StateManager.get('orderedSort') === 'kdsTimeSort' ? ' rush DESC, order_head.kds_time is null,order_head.kds_time, order_detail.order_time, order_detail.order_detail_id ' : undefined;
            }
            // 获取点餐餐厅模式
            let turnEnable = this.state.settingInfo.find((target: any) => target.id === 17);
            rule = {
                isPrintDevice: false,
                menu_id: this.state.settingInfo[0].value.menu_id,
                turn_enable: this.props.role === 'chef' ? turnEnable && turnEnable.value ? turnEnable.value.status : false : false, //只有厨师菜需要点餐餐厅模式
                sort: ruleSort,
                deliveryDisplayRange: StateManager.get('deliveryDisplayRange'),
                isShowTotalOrder: isShowTotalOrder,
                sortDishedInCard: StateManager.get('sortTheDishesInTheCard'),
                mode: this.state.modeSelected
            }
            let requestBody: DishesRequestBody = {
                // $ 岗位
                // position: (this.state.filterButtonSelected === 'chefkitchen' || this.state.filterButtonSelected === 'prepareDish') && this.props.role === 'waiter' ? waiterPositionsArray : positionsArray,
                position:  positionsArray,
                // 服务员已下单岗位
                waiter_order_position: waiterPositionsArray,
                // $ 角色 
                // "chef" | "waiter"
                // 'chef',
                role: this.props.role,
                // $ 菜品的状态 
                // "kitchen" | "history" | "suspend" | "cancelled" | "chefkitchen",
                section: this.state.filterButtonSelected,
                // $ 当前页码
                // 注意, 0才是代表第一页
                page: pageNum,
                // $ 每页数据容量，总单模式显示订单个数，其他则是按照原来显示菜品数
                perPage: isShowTotalOrder ? 
                    this.state.modeSelected === 'takeaway_mode' ? StateManager.get('takeawayModeNumber') : StateManager.get('totalOrderModeNumber')
                    : 
                     (this.state.filterButtonSelected === 'chefkitchen' || this.state.filterButtonSelected === 'prepareDish') && this.props.role === 'waiter' ?
                        this.state.waiterUnMakePerPageNum
                        : this.state.chefCardPerPage,
                // $ 数据升序或降序
                // "positive" | "reverse"
                sort: this.state.filterButtonSelected === 'history' ? 'reverse' : 'positive',
                // $ 是否获取套餐下的菜品
                is_package: this.state.displayDishInCombination,
                // $ 当前桌的菜品总数
                // 用来计算有多少分页
                rowCount: true,
                // $ 是否统计每一桌的菜数量
                tableCount: this.state.countStatisticsFunction,
                // $ 需要显示的桌台
                needShowTables: StateManager.get('regionalMenuFunction') ? StateManager.get('tableNameSelected') : undefined,
                // $ 规则
                rule: rule,
                // $ 服务器地址
                serverUrl: this.state.serveUrl
            }
            // call api
            const result: any = await Request.getDishes(
                // $ 岗位
                requestBody.position,
                // 服务员已下单岗位
                requestBody.waiter_order_position,
                // $ 角色 
                // "chef" | "waiter"
                // 'chef',
                requestBody.role,
                // $ 菜品的状态 
                // "kitchen" | "history" | "suspend" | "cancelled" | "chefkitchen",
                requestBody.section,
                // $ 当前页码
                // 注意, 0才是代表第一页
                requestBody.page,
                // $ 每页数据容量
                requestBody.perPage,
                // $ 数据升序或降序
                // "positive" | "reverse"
                requestBody.sort,
                // $ 是否获取套餐下的菜品
                requestBody.is_package,
                // $ 当前桌的菜品总数
                // 用来计算有多少分页
                requestBody.rowCount,
                // $ 是否统计每一桌的菜数量
                requestBody.tableCount,
                // $ 需要显示的桌台
                requestBody.needShowTables,
                // $ 规则
                requestBody.rule,
                // $ 服务器地址
                requestBody.serverUrl
            );
            // console.log(result, 'original_data')

            // check
            if (!result || !result.data || !result.data.data) throw ERROR_0012

            let soundNoticeSetting = this.state.settingInfo.find((target: any) => target.id === 44);
            let soundNoticeData = [];
            // 开启了全局声音开关，和声音提醒功能，不是已下单，待上菜页面，需要获取需要全局提醒的数据。
            if(StateManager.get('soundReminder') && _.get(soundNoticeSetting,'value.status') && this.state.filterButtonSelected !== 'kitchen'){
                soundNoticeData = await Request.getDishes(
                    // $ 岗位
                    requestBody.position,
                    // 服务员已下单岗位
                    requestBody.waiter_order_position,
                    // $ 角色 
                    // "chef" | "waiter"
                    // 'chef',
                    requestBody.role,
                    // $ 菜品的状态 
                    // "kitchen" | "history" | "suspend" | "cancelled" | "chefkitchen",
                    'kitchen',
                    // $ 当前页码
                    // 注意, 0才是代表第一页
                    0,
                    // $ 每页数据容量
                    0,
                    // $ 数据升序或降序
                    // "positive" | "reverse"
                    requestBody.sort,
                    // $ 是否获取套餐下的菜品
                    requestBody.is_package,
                    // $ 当前桌的菜品总数
                    // 用来计算有多少分页
                    requestBody.rowCount,
                    // $ 是否统计每一桌的菜数量
                    requestBody.tableCount,
                    // $ 需要显示的桌台
                    requestBody.needShowTables,
                    // $ 规则
                    requestBody.rule,
                    // $ 服务器地址
                    requestBody.serverUrl
                );
                // 不是已下单，待上菜页面，并且页面第一次进来，那就需要保存最新的值。
                if(!_.isEqual(this.state.getDishesRequestBody, requestBody)){
                    this.setState({
                        soundNoticeData: _.get(soundNoticeData,'data.data') || []
                    })
                }
            }
            // 判断是否需要声音
            // 在铃声提醒功能开启的前提下，主要用请求体来判断是不是已经切换了状态或者页面，如果发现一样，则证明还在当前页面，那就需要去判断声音是否需要响起
            if (StateManager.get('soundReminder') && this.state.getDishesRequestBody !== null && _.isEqual(this.state.getDishesRequestBody, requestBody)) {
                let oldOriginDishes = this.state.dishesData.getOriginDishes();
                let newOriginDishes = result.data.data;
                // 新菜品数据的数量大于旧菜品的数量，那就是有新的内容
                if (newOriginDishes.length > oldOriginDishes.length) {
                    this.audio && this.audio.play();
                } else {
                    // 其他情况则需要匹对数据
                    for (let i = 0; i < newOriginDishes.length; i++) {
                        let findIndex = oldOriginDishes.findIndex(target => target.order_detail_id === newOriginDishes[i].order_detail_id)
                        // 在旧菜品数据中找不到，证明是新的菜品，可以马上响声音
                        if (findIndex === -1) {
                            this.audio && this.audio.play();
                            break;
                        }
                    }
                }
                
                // 非已下单或者待上菜的页面，开启全局声音，进行全局声音是否需要想起的判断
                if(this.state.filterButtonSelected !== 'kitchen' && _.get(soundNoticeSetting,'value.status')){
                    let oldSoundNoticeData = this.state.soundNoticeData;
                    let newSoundNoticeData = _.get(soundNoticeData,'data.data') || [];
                    // 新菜品数据的数量大于旧菜品的数量，那就是有新的内容
                    if (newSoundNoticeData.length > oldSoundNoticeData.length) {
                        this.audio && this.audio.play();
                        // 声音响起后需要把新的值覆盖过去
                        this.setState({
                            soundNoticeData: newSoundNoticeData
                        });
                    } else {
                        // 其他情况则需要匹对数据
                        for (let i = 0; i < newSoundNoticeData.length; i++) {
                            let findIndex = oldSoundNoticeData.findIndex(target => target.order_detail_id === newSoundNoticeData[i].order_detail_id)
                            // 在旧菜品数据中找不到，证明是新的菜品，可以马上响声音
                            if (findIndex === -1) {
                                this.audio && this.audio.play();
                                // 声音响起后需要把新的值覆盖过去
                                this.setState({
                                    soundNoticeData: newSoundNoticeData
                                });
                                break;
                            }
                        }
                    }
                }

            }
            // 汇总菜品名称
            let summaryDishNames:Array<SummaryDishNames> = [];
            // 外卖单汇总
            let summaryTakeawayOrder: Array<SummaryTakeawayOrder> = [];
            let dishesSummarySidebar = StateManager.get('dishesSummarySidebar');
            let displayTakeawayStatisticsSidebar = StateManager.get('displayTakeawayStatisticsSidebar');
            let displayDishesSidebar = StateManager.get('displayDishesSidebar');
            (result.data.data || []).forEach((dish: any) => {
                let index:number
                // 订单模式下，过滤掉当前就餐方式的菜品名称
                if(displayDishesSidebar && this.state.currentEatType == 'All' || this.state.currentEatType === this.state.eatTypeValue[dish.eat_type]){
                    if(dishesSummarySidebar === '1'){
                        // 菜品名称1
                        index = summaryDishNames.findIndex((dishName: any) => dish.item_name1 === dishName.item_name1)
                    }else {
                        // 菜品名称2
                        index = summaryDishNames.findIndex((dishName: any) => dish.item_name2 === dishName.item_name2)
                    }
                    if(index === -1) summaryDishNames.push({item_name1:dish.item_name1,item_name2:dish.item_name2})
                }
                // 如果显示外卖单汇总侧边栏，并且非堂吃，则统计外卖单
                if(displayTakeawayStatisticsSidebar && dish.eat_type !== 0 && (this.state.currentEatType == 'All' || this.state.currentEatType === this.state.eatTypeValue[dish.eat_type])) {
                    let findIndex = summaryTakeawayOrder.findIndex((takeaway: SummaryTakeawayOrder) => {
                        return `${takeaway.check_number}` === `${dish.check_number}` &&
                            `${takeaway.eat_type}` === `${dish.eat_type}` && takeaway.customer_name === this.getCustomerName(dish) && `${takeaway.kds_time}` === `${dish.kds_time}`
                    })
                    if(findIndex === -1) summaryTakeawayOrder.push({
                        check_number: dish.check_number,
                        eat_type: dish.eat_type,
                        customer_name: this.getCustomerName(dish),
                        kds_time: dish.kds_time
                    })
                }
            });
            // 对外卖单汇总进行排序
            summaryTakeawayOrder = summaryTakeawayOrder.sort((a,b) => {
                let aTime = a.kds_time ?  new Date(a.kds_time).getTime() : 0;
                let bTime = b.kds_time ?  new Date(b.kds_time).getTime() : 0;
                return aTime - bTime;
            });
            // 检查总的菜品汇总，看是否需要移除已选择的菜品
            let selectSummaryDishNames = _.cloneDeep(this.state.selectSummaryDishNames);
            selectSummaryDishNames = selectSummaryDishNames.filter((selectDish: any) => {
                let index:number
                if(dishesSummarySidebar === '1'){
                    // 菜品名称1
                    index = summaryDishNames.findIndex((dishName: any) => selectDish.item_name1 === dishName.item_name1)
                }else {
                    // 菜品名称2
                    index = summaryDishNames.findIndex((dishName: any) => selectDish.item_name2 === dishName.item_name2)
                }
                return index > -1;
            });
            // 检查总的外卖单汇总，看是否需要移除已选择的外卖单
            let selectSummaryTakeawayOrder = _.cloneDeep(this.state.selectSummaryTakeawayOrder);
            selectSummaryTakeawayOrder = selectSummaryTakeawayOrder.filter((selectTakeaway: SummaryTakeawayOrder) => {
                let findIndex = summaryTakeawayOrder.findIndex((takeaway: SummaryTakeawayOrder) => {
                    return `${takeaway.check_number}` === `${selectTakeaway.check_number}` &&
                        `${takeaway.eat_type}` === `${selectTakeaway.eat_type}` && takeaway.customer_name === selectTakeaway.customer_name && `${takeaway.kds_time}` === `${selectTakeaway.kds_time}`
                })
                return findIndex > -1;
            });
            // update dishes data
            this.updateLocalState({
                selectSummaryDishNames,
                selectSummaryTakeawayOrder,
                dishesData: this.state.dishesData.update(this.state.filterButtonSelected, this.props.role, "normal", result.data.data, deskNum, eatType),
                getDishesRequestBody: requestBody
            });

            // analyze table information and quantity information
            let dishesdDeskBadgeTotalNum = 0;
            let deskList = []
            let dishesdDeskBadgeValue = [];
            let orderModeDeskList = []
            let dishesEatTypeBadgeValue = [];
            let dishesEatTypeBadgeTotalNum = 0;
            let tableCountArray = Object.entries(result.data.tableCount);
            // 对分桌进行排序
            tableCountArray.sort((a: any, b: any) => {
                if (!a[1] || !b[1]) return 0

                if (StateManager.get('sortTableFunction') === 'ascending') return new Date(a[1]['order_start_time']).getTime() - new Date(b[1]['order_start_time']).getTime();
                else return new Date(b[1]['order_start_time']).getTime() - new Date(a[1]['order_start_time']).getTime();
            })
            for (let [desk, badge] of tableCountArray) {
                deskList.push(desk)
                let num = (badge as any).number as number
                dishesdDeskBadgeValue.push(num);
                dishesdDeskBadgeTotalNum += num;
            }
            dishesdDeskBadgeValue.unshift(dishesdDeskBadgeTotalNum);
            deskList.unshift('All');

            // set eat type
            for (let [desk, badge] of Object.entries(result.data.eatTypeCount)) {
                // 如果是外卖模式，则不需要显示堂吃的选项
                if(this.state.modeSelected === 'takeaway_mode' && `${desk}` === '0') continue;
                orderModeDeskList.push(this.state.eatTypeValue[desk])
                dishesEatTypeBadgeValue.push(badge as number);
                dishesEatTypeBadgeTotalNum += badge as number;
            }
            dishesEatTypeBadgeValue.unshift(dishesEatTypeBadgeTotalNum);
            orderModeDeskList.unshift('All');
            // 服务员下，如果是已下单和备菜的状态，则用未制作的卡片数量上限，否则就用每页卡片上限
            let perPageNum = this.props.role === 'waiter' && (this.state.filterButtonSelected === 'chefkitchen' || this.state.filterButtonSelected === 'prepareDish') ? StateManager.get("waiterUnMakePerPageNum") : StateManager.get("cardPerPageNum");
            // 如果是总单模式，则获取总单模式的每页显示订单数
            if( isShowTotalOrder ) perPageNum = this.state.modeSelected === 'takeaway_mode' ? StateManager.get('takeawayModeNumber') : StateManager.get('totalOrderModeNumber');
            // pagation
            let totalPage = Math.ceil(result.data.rowCount[0][`COUNT(*)`] / perPageNum);
            let deliveryRangeOutsideMinKdsTime = _.get(result.data, 'minKdsTime') || null
            // count
            // 获取每个制作按钮状态的数量
            let sectionCount = _.get(result, 'data.sectionCount') || {};
            let filterButtonBadgeValue: number[] = [];
            this.state.filterButton.forEach(target => {
                filterButtonBadgeValue.push(_.get(sectionCount, [target.value, '0', 'COUNT(*)'], 0) || 0)
            })
            // return 
            return {
                deskList,
                totalPage,
                dishesdDeskBadgeValue,
                cardData: this.state.dishesData.BoxGroup,
                orderModeDeskList,
                dishesEatTypeBadgeValue,
                deliveryRangeOutsideMinKdsTime,
                filterButtonBadgeValue,
                summaryDishNames,
                summaryTakeawayOrder
            }
        } catch (error) {
            console.log('error',error)
            this.messengerModule.say({
                ...ERROR_0012,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshDishes()",
                    positionsArray: positionsArray,
                    rule: rule,
                    filterButtonSelected: this.state.filterButtonSelected,
                    pageNum: pageNum,
                    chefCardPerPage: this.state.chefCardPerPage,
                    waiterUnMakePerPageNum: this.state.waiterUnMakePerPageNum,
                    displayDishInCombination: this.state.displayDishInCombination,
                    countStatisticsFunction: this.state.countStatisticsFunction,
                    serveUrl: this.state.serveUrl,
                }
            }, { horizontal: "right" });
            return {
                deskList: ["All"],
                totalPage: 1,
                dishesdDeskBadgeValue: [],
                cardData: [],
                orderModeDeskList: ["All"],
                dishesEatTypeBadgeValue: [],
                deliveryRangeOutsideMinKdsTime: null,
                filterButtonBadgeValue: this.props.role === 'chef' ? [0, 0, 0, 0, 0] : [0, 0, 0, 0, 0, 0],
                summaryDishNames:[],
                summaryTakeawayOrder: []
            };
        }
    }

    /**
     * 设置左边栏是否展示与隐藏
     */
    public unfoldShrinkChange() {
        this.updateLocalState({ unfoldShrink: !this.state.unfoldShrink }, () => {
            const isHodeLeftSidebar = StateManager.get('isHideLeftSidebarAutomatically');
            isHodeLeftSidebar && this.state.unfoldShrink && this.hideLeftSidebar();
        })
    }

    /**
     * 5s后自动隐藏左侧边栏
     */
    public hideLeftSidebar = (): void => {
        this.timer && clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            this.updateLocalState({ unfoldShrink: false });
        }, 5000);
    }

    public setLogger(orderDetailId: Array<any>, orderHeadId: Array<any>, endTime: string, status: number, restaurantMode: boolean, activePushTurn: boolean, isSuccess: boolean, turns: string[], dishOrderTime: string[],chefTurns: string[]) {
        if (!StateManager.get('loggingFunction')) return;
        try {
            let LoggerstateCached: any = JSON.parse("" + localStorage.getItem(CACHE_LOGGER))
            LoggerstateCached = LoggerstateCached ? LoggerstateCached : {}
            let logger = {
                updateTime: endTime,
                // 订单id
                orderHeadId: orderHeadId,
                // 菜品详细ID
                orderDetailId: orderDetailId,
                // 更新类型（从什么状态，更新为什么状态）
                updateType: `${this.state.filterButton[this.state.filterValue].status === 1 ? UPDATE_STATUS[this.props.role + '-1'] : UPDATE_STATUS[this.state.filterButton[this.state.filterValue].status]} update to ${status === 1 ? UPDATE_STATUS[this.props.role + '-1'] : UPDATE_STATUS[status]}`,
                // 操作者账户
                user: StateManager.get('account'),
                // 更新菜品数量
                updateDishesNumber: orderDetailId.length,
                // 是否成功
                isSuccess: isSuccess,
                // 状态快照
                stateSnapshot: {
                    // 当前模式
                    currentMode: this.state.modeSelected,
                    // 当前页面
                    currentRole: this.props.role,
                    // 当前选择按钮  已下单，已备菜，待上菜，已上菜，已暂停，取消制作，已制作
                    currentFilterButton: this.state.filterButton[this.state.filterValue].status === 1 ? UPDATE_STATUS[this.props.role + '-1'] : UPDATE_STATUS[this.state.filterButton[this.state.filterValue].status],
                    restaurantMode: restaurantMode,
                    activePushTurn: activePushTurn
                },
                // 菜品轮次
                turns: turns,
                // 菜品下单时间
                dishOrderTime: dishOrderTime,
                // 厨师当前制作轮次
                chefTurns: chefTurns
            }
            if (LoggerstateCached[moment().format("YYYY-MM-DD")]) LoggerstateCached[moment().format("YYYY-MM-DD")].push(logger)
            else LoggerstateCached[moment().format("YYYY-MM-DD")] = [logger]
            localStorage.setItem(CACHE_LOGGER, JSON.stringify(LoggerstateCached));
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0022,
                details: {
                    ...error,
                    caller: "chef.page.tsx => setLogger()",
                }
            }, { horizontal: "right" });
        }

    }

    public setPrintLogger(orderDetailId: Array<any>, orderHeadId: Array<any>, endTime: string, isSuccess: boolean, printAddress: string[]) {
        if (!StateManager.get('loggingFunction')) return;
        try {
            let LoggerstateCached: any = JSON.parse("" + localStorage.getItem(CACHE_PRINT_LOGGER))
            LoggerstateCached = LoggerstateCached ? LoggerstateCached : {}
            let logger = {
                updateTime: endTime,
                // 订单id
                orderHeadId: orderHeadId,
                // 菜品详细ID
                orderDetailId: orderDetailId,
                // 操作者账户
                user: StateManager.get('account'),
                // 是否成功
                isSuccess: isSuccess,
                // 状态快照
                stateSnapshot: {
                    currentRole: this.props.role
                },
                // 打印地址
                printAddress: printAddress
            }
            if (LoggerstateCached[moment().format("YYYY-MM-DD")]) LoggerstateCached[moment().format("YYYY-MM-DD")].push(logger)
            else LoggerstateCached[moment().format("YYYY-MM-DD")] = [logger]
            localStorage.setItem(CACHE_PRINT_LOGGER, JSON.stringify(LoggerstateCached));
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0022,
                details: {
                    ...error,
                    caller: "chef.page.tsx => setPrintLogger()",
                }
            }, { horizontal: "right" });
        }

    }
    /**
     * refreshData when check changed
     */
    public async refreshDataDifferences(forceUpdate?: boolean) {
        // clear old timer
        clearTimeout(this.state.timeoutID);
        try {
            // start loading
            // this.updateLocalState({ loadingVisible: true });
            // 没有选择卡片的时候才需要更新
            if (this.state.selectedCard.length === 0 || forceUpdate) {
                // check if data need to be updated
                let result: any = await Request.getSettingInfo(this.state.serveUrl, 15);
                // 根据外卖单显示范围与最近的kds_time做判断，看看要不要进行强制刷新
                let needForceRefresh = false;
                if (this.state.deliveryRangeOutsideMinKdsTime) {
                    try {
                        let minKdsTime = new Date(this.state.deliveryRangeOutsideMinKdsTime || '').getTime();
                        let nowTime = new Date().getTime();
                        let deliveryRange = (StateManager.get('deliveryDisplayRange') || 0) * 60 * 1000;
                        if ((minKdsTime - nowTime < deliveryRange) || (minKdsTime - nowTime === deliveryRange)) needForceRefresh = true;
                    } catch (error) {

                    }
                }
                // 第一次为first的时候，只需要赋值就行，不需要更新，因为初始化的时候已经获取了数据
                if (this.state.check === 'first') {
                    this.updateLocalState({ check: result.data[0].value });
                }
                // if need to be update
                else if ((result.data[0].value !== this.state.check) || needForceRefresh) {
                    await this.refreshData(this.state.currentDesk || "All", this.state.currentEatType || "All", this.state.page - 1);
                    this.updateLocalState({ check: result.data[0].value });
                }
            }
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0019,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshDataDifferences()",
                    filterButtonSelected: this.state.filterButtonSelected,
                    chefCardPerPage: this.state.chefCardPerPage,
                    waiterUnMakePerPageNum: this.state.waiterUnMakePerPageNum,
                    displayDishInCombination: this.state.displayDishInCombination,
                    countStatisticsFunction: this.state.countStatisticsFunction,
                    serveUrl: this.state.serveUrl,
                }
            }, { horizontal: "right" });
        }
        // always start the new timer
        // 因为恶意快速切换页面会偶尔导致出现timeout清除不到的情况，然后就一直在请求接口，所以添加needSetTimeId属性，只有当页面销毁时变为false，从而使在销毁后的自动更新的timeout不会再启动
        if (this.needSetTimeId) {
            let delay = StateManager.get('dataFreshTime') * 1000
            this.updateLocalState({
                // loadingVisible: false,
                timeoutID:
                    setTimeout(() => {
                        this.refreshDataDifferences();
                    }, delay ? delay : 5000)
            });
        }
    }

    /**
     * 更新菜品状态
     * @param dishArr 菜品数据
     * @param status 需要更新的状态
     * @param clickMode 判断是单击还是双击的时候触发
     */
    public async updateDishesInfo(dishArr: dish[], status: number, clickMode?: 'double' | 'single') {
        let ids = []
        // 备菜ID
        let preparDishIds = [];
        // 订单Id
        let orderHeadId: any = [];
        let turns: string[] = [];
        let chefTurns: string[] = [];
        let dishOrderTime: string[] = [];
        let endTime = moment().format("YYYY-MM-DD kk:mm:ss");
        // 获取主动推进轮次功能
        let turnEnable = this.state.settingInfo.find((target: any) => target.id === 16);
        let restaurantMode = this.state.settingInfo.find((target: any) => target.id === 17);
        // 获取不参加轮次推进的菜单那
        let notJoininRountAdvancemenu = this.state.settingInfo.find((target: any) => target.id === 41);
        try {
            // 清除check检查
            clearTimeout(this.state.timeoutID);
            this.setState({
                loadingVisible: true
            });
            // 更新菜品状态
            // 备菜模式下用到的变量，normalIds存储正常菜的ID，状态需要更新为11备菜，prepareDishIds存储已经是备菜的ID，需要更新为函数的参数status值。
            // let dishIds = {
            //     ids: [] as any,
            //     prepareDishIds: [] as any
            // }

            // 在双击的情况下，需要开启备菜功能并且是未制作的时候才可以更新为备菜的状态
            // if (StateManager.get('prepareDishFunction') && this.state.filterButtonSelected === 'kitchen' && clickMode === 'double') {
            //     // 备菜模式更新
            //     for (let dish of dishArr) {
            //         if (dish.hasOwnProperty('condiments')) {
            //             for (let condiment of dish.condiments) {
            //                 if (dish.is_make === 11) dishIds.prepareDishIds.push(`${condiment.order_detail_id}`)
            //                 else dishIds.ids.push(`${condiment.order_detail_id}`)
            //             }
            //         }
            //         if (dish.is_make === 11) dishIds.prepareDishIds.push(`${dish.order_detail_id}`)
            //         else dishIds.ids.push(`${dish.order_detail_id}`)
            //     }
            //     Object.keys(dishIds).forEach(async (target) => {
            //         if (target === 'ids' && dishIds[target].length > 0) await Request.updateDish(dishIds[target], ['is_make', 'end_time'], [11, endTime], StateManager.get('settingInfo')[0].value.menu_id, StateManager.get('serveUrl'));
            //         else if (target === 'prepareDishIds' && dishIds[target].length > 0) await Request.updateDish(dishIds[target], ['is_make', 'end_time'], [status, endTime], StateManager.get('settingInfo')[0].value.menu_id, StateManager.get('serveUrl'));
            //     })
            // } else {
            // 非备菜模式
            // let prepareDishPost = StateManager.get('prepareDishPostSelected') || [];
            // let prepareDishFunction = StateManager.get('prepareDishFunction');
            for (let dish of dishArr) {
                // 总单模式
                let isShowTotalOrder = false
                if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
                    // 是否显示总单模式,需要在服务员->订单模式->待上菜界面，并且开启了显示总单模式的功能。
                    isShowTotalOrder = StateManager.get('totalOrderMode') && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode')
                }
                // 如果是总单模式下，并且不是待上菜的菜品，则不需要加入更新的队列
                if(isShowTotalOrder && _.get(dish, 'is_make') !== 1) continue;
                // 判断当前菜品要不要备菜
                let hasPreparDish = this.isNeedPrepareDish(dish, clickMode);
                // 在厨师页面的已下单中，双击的情况下，开启了备菜模式，并且选择了对应的岗位。
                // if (this.props.role === 'chef' && prepareDishFunction && this.state.filterButtonSelected === 'kitchen' && Array.isArray(prepareDishPost) && prepareDishPost.length > 0 && clickMode === 'double') {
                //     let index = prepareDishPost.findIndex( target => `${target.print_class_id}` === `${dish.print_class}` && target.checked)
                //     if (index !== -1) hasPreparDish = true;
                // }
                if (dish.hasOwnProperty('condiments')) {
                    for (let condiment of dish.condiments) {
                        if (hasPreparDish) preparDishIds.push(condiment.order_detail_id);
                        else ids.push(condiment.order_detail_id)
                    }
                }
                if (hasPreparDish) {
                    if (dish.item_type === 3) {
                        const originData = this.state.dishesData.getOriginDishes();
                        const belongedPackage = originData.find(item => item.order_detail_id === dish.order_detail_id);
                        const packageDisheIds = (dish.course_groupArray || []).map(item => `${item.order_detail_id}`);
                        // 套餐菜品的调味品id
                        const packageDishesCondimentIds = (dish.course_groupArray || []).reduce<number[]>((pre, cur) => {
                            (cur.condiments || []).forEach(item => {
                                // if (hasPreparDish) preparDishIds.push(item.order_detail_id);
                                pre.push(item.order_detail_id);
                            });
                            return pre;
                        }, []);
                        if (belongedPackage) {
                            if ((belongedPackage.course_groupArray || []).length === (dish.course_groupArray || []).length) {
                                preparDishIds.push(`${belongedPackage.order_detail_id}`, ...packageDisheIds, ...packageDishesCondimentIds);
                            } else {
                                preparDishIds.push(...packageDisheIds, ...packageDishesCondimentIds);
                            }
                        } else {
                            preparDishIds.push(...packageDisheIds, ...packageDishesCondimentIds);
                        }
                    } else {
                        preparDishIds.push(dish.order_detail_id);
                    }
                }
                else {
                    // 如果是套餐,就取套餐选择的菜品id,如果选择的套餐全部菜品就连带套餐ID和选择的菜品ID一起传
                    if (dish.item_type === 3) {
                        const originData = this.state.dishesData.getOriginDishes();
                        const belongedPackage = originData.find(item => item.order_detail_id === dish.order_detail_id);
                        // const packageDisheIds = (dish.course_groupArray || []).map(item => `${item.order_detail_id}`);
                        const packageDisheIds = (dish.course_groupArray || []).reduce((pre, cur) => {
                            if (isShowTotalOrder) {
                                cur.is_make === 1 && pre.push(`${cur.order_detail_id}`);
                            } else {
                                pre.push(`${cur.order_detail_id}`);
                            }
                            return pre;
                        }, [] as string[]);
                        // 套餐菜品的调味品id
                        const packageDishesCondimentIds = (dish.course_groupArray || []).reduce<number[]>((pre, cur) => {
                            if (isShowTotalOrder) {
                                if (cur.is_make === 1) {
                                    (cur.condiments || []).forEach(item => {
                                        pre.push(item.order_detail_id);
                                    });
                                }
                            } else {
                                (cur.condiments || []).forEach(item => {
                                    pre.push(item.order_detail_id);
                                });
                            }
                            return pre;
                        }, []);
                        if (belongedPackage) {

                            
                            if (((belongedPackage.course_groupArray || []).length === (dish.course_groupArray || []).length) || (isShowTotalOrder && belongedPackage.is_make === 2 && status === 1)) {
                                ids.push(`${belongedPackage.order_detail_id}`, ...packageDisheIds, ...packageDishesCondimentIds);
                            } else {
                                ids.push(...packageDisheIds, ...packageDishesCondimentIds);
                            }
                        } else {
                            ids.push(...packageDisheIds, ...packageDishesCondimentIds);
                        }
                    } else {
                        ids.push(`${dish.order_detail_id}`);
                    }
                }

                if (!orderHeadId.includes(dish.order_head_id)) {
                    orderHeadId.push(dish.order_head_id)
                    chefTurns.push(`${dish.order_head_id}-${dish.rule_gnr_chef_turn}`)
                }
                turns.push(`${dish.order_detail_id}-${dish.turn}`)
                dishOrderTime.push(`${dish.order_detail_id}~~${moment(dish.order_time).format("YYYY-MM-DD HH:mm:ss")}`)
            }
            // let endTime = moment().format("YYYY-MM-DD hh:mm:ss");
            // 没有需要更新的id数据，则返回
            if (!(ids.length > 0 || preparDishIds.length > 0)) return
            let magneticLinkDate:{
                [key: string]: string
            } = {};
            // 厨师模式下，开启了磁力链模式，若把菜品更新到已完成中，则把“同订单同桌台同类别合并”的菜品更新为同一个磁力链
            let updateMagneticLink = this.props.role === 'chef' && this.magneticLinkMode && (status === 1 || status === 2);
            if (updateMagneticLink){
                let rules: Array<MergeRules | MagnetRules> = [
                    {
                        type: 'Merge',
                        data: [
                            {
                                key: 'table_name',
                            },
                            {
                                key: 'order_head_id',
                            },
                            {
                                key: 'family_group',
                            }
                        ],
                        capacity: 999999,
                    }
                ];
                let mergeData: MergeObject = MergeProcessor.merge(dishArr, rules);
                Object.values(mergeData).forEach(mergeItem => {
                    if(Array.isArray(mergeItem.data)) {
                        let uuid = uuidv4()
                        mergeItem.data.forEach(target => {
                            // 合并菜品数量大于1，证明可以更新磁力链
                            if(target.dishes.length > 1) {
                                target.dishes.forEach(dish => {
                                    // 菜品类别为null当作不是同一种类别，因此不会更新磁力链
                                    if (dish.family_group == null) return;
                                    magneticLinkDate[dish.order_detail_id] = uuid;
                                })
                            }
                        });
                    }
                })
            }
            let rule = {
                isPrintDevice: false,
                menu_id: this.state.settingInfo[0].value.menu_id,
                turn_enable: turnEnable && turnEnable.value ? turnEnable.value.status : false, //只有厨师菜需要点餐餐厅模式
                not_joinin_round_advance_menu: _.get(notJoininRountAdvancemenu, 'value.menu') || [],
                basis_for_round_advance: StateManager.get('basisForRoundAdvance')
            }
            // update
            // 取消制作选择原因的功能开启，并且是更新到取消制作的时候
            let result;
            if (StateManager.get('addReasonInCancelFunction') && status === 5) {
                result = await Request.updateDish(ids, ['is_make', 'end_time', 'return_reason'], [status, endTime, this.state.selectReasonValue], rule, StateManager.get('serveUrl'));
            } else {
                if (ids.length > 0) {
                    let updateKeys = ['is_make', 'end_time'];
                    let updateValues = [status, endTime];
                    // 提交更新磁力链的数据
                    if (updateMagneticLink){
                        updateKeys.push('magnetic_link');
                        updateValues.push(magneticLinkDate);
                    }
                    result = await Request.updateDish(ids, updateKeys, updateValues, rule, StateManager.get('serveUrl'));
                }
                // 更新备菜
                if (preparDishIds.length > 0) {
                    result = await Request.updateDish(preparDishIds, ['is_make', 'end_time'], [11, endTime], rule, StateManager.get('serveUrl'));
                }
            }
            // 设置日志
            this.setLogger(ids, orderHeadId, endTime, status, restaurantMode && restaurantMode.value ? restaurantMode.value.status : false, turnEnable && turnEnable.value ? turnEnable.value.status : false, !!result,turns,dishOrderTime,chefTurns)
            // }
            // build new processor
            let newDishesData = this.state.dishesData.removeCardFromBoxGroup(dishArr);
            // update view
            this.updateLocalState({
                dishesData: newDishesData,
                cardData: newDishesData.BoxGroup,
                loadingVisible: false
            });
        } catch (error) {
            this.setState({
                loadingVisible: false
            });
            this.messengerModule.say({
                ...ERROR_0022,
                details: {
                    ...error,
                    caller: "chef.page.tsx => updateDishesInfo()",
                }
            }, { horizontal: "right" });
            // 设置日志
            this.setLogger(ids, orderHeadId, endTime, status, restaurantMode && restaurantMode.value ? restaurantMode.value.status : false, turnEnable && turnEnable.value ? turnEnable.value.status : false, false,turns,dishOrderTime,chefTurns)
        }
    }
    // 获取边框颜色
    public getBorderColor() {
        let borderColorDefault = 'rgba(122, 102, 99, 0.8)';
        let selectBorderColorDefault = 'rgb(110, 109, 107)';
        let borderColorChange = 'rgb(185, 48, 114)';
        if (StateManager.get('theme') === 'standard') {
            borderColorDefault = 'rgba(122, 102, 99, 0.8)';
            selectBorderColorDefault = 'rgb(110, 109, 107)';
            borderColorChange = 'rgb(185, 48, 114)';
        } else if (StateManager.get('theme') === 'summer') {
            borderColorDefault = 'rgb(123, 184, 200)';
            selectBorderColorDefault = 'rgb(123, 184, 200)';
            borderColorChange = 'rgb(0, 110, 217)';
        } else if (StateManager.get('theme') === 'atlanti') {
            borderColorDefault = 'rgb(123, 184, 200)';
            selectBorderColorDefault = 'rgb(123, 184, 200)';
            borderColorChange = 'rgb(0, 110, 217)';
        }
        return {
            borderColorDefault,
            selectBorderColorDefault,
            borderColorChange
        }
    }
    //闪烁按钮
    public flashingButton() {
        let borderColor = this.getBorderColor();
        this.flashingTimeId = setInterval(() => {
            if (this.state.selectedCard.length !== 0 || this.state.cardDialogVisible === true) {
                //获取选中的节点
                const SelectedTab = document.getElementsByClassName('SelectedTab')[0] as any
                if (SelectedTab.style.borderColor === borderColor.selectBorderColorDefault) {
                    //变色
                    SelectedTab.style.borderColor = borderColor.borderColorChange
                } else {
                    //恢复
                    SelectedTab.style.borderColor = borderColor.selectBorderColorDefault
                }
                for (let i = 0; i < this.state.filterButton.length - 1; i++) {
                    //获取未选中的节点
                    const tabs = document.getElementsByClassName('Tabs')[i] as any
                    if (tabs.style.borderColor === borderColor.borderColorDefault) {
                        //变色
                        tabs.style.borderColor = borderColor.borderColorChange
                    } else {
                        //恢复
                        tabs.style.borderColor = borderColor.borderColorDefault
                    }
                }
            } else if (this.state.selectedCard.length === 0) {
                //关闭setInterval()
                clearInterval(this.flashingTimeId);
                this.updateLocalState({ turnOnTheTimer: false });
                //获取选中的节点
                const SelectedTab = document.getElementsByClassName('SelectedTab')[0] as any
                //恢复默认样式
                SelectedTab.style.borderColor = borderColor.selectBorderColorDefault
                for (let i = 0; i < this.state.filterButton.length - 1; i++) {
                    //获取未选中的节点
                    const tabs = document.getElementsByClassName('Tabs')[i] as any
                    //恢复默认样式
                    tabs.style.borderColor = borderColor.borderColorDefault
                }
            }
        }, 700);
    }

    public async clickTab(index: number, status: number, refresh?: boolean) {
        // 点击不同页面或者是总单模式的情况下执行。
        if (this.state.filterValue !== index || refresh) {
            // 开启了厨师控菜功能，并且更新的状态是1的时候，把状态换成2
            status = this.props.role === 'chef' && StateManager.get('chefControlFunction') && status === 1 ? 2 : status;
            // $ if card dialog visible
            if (this.state.cardDialogVisible === true) {

                // check if selected multi dishes
                let selectedDish: Array<dish> = [];
                this.state.cardDialogDishes.forEach((c) => {
                    if (c.isCardDialogSelected === true) selectedDish.push(c);
                });

                // update
                await this.updateDishesInfo(selectedDish, status);
                // 打印
                if ((this.state.filterButton[index].status === 1 && this.props.role !== 'waiter') || (this.props.role === 'waiter' && this.state.filterButton[index].status === 2)) {
                    let selectedCard: any = []
                    this.state.cardDialogDishes.forEach(target => {
                        if (target.isCardDialogSelected) selectedCard = selectedCard.concat(target)
                    })
                    this.printTip(selectedCard, true)
                }
                // update view
                this.updateLocalState({ cardDialogVisible: false, cardDialogDishes: [], selectedCard: [] });
            }
            // $ if multi card has been selected
            else if (Array.isArray(this.state.selectedCard) && this.state.selectedCard.length > 0) {
                // init 选择的菜品源数据
                let dishData: Array<dish> = [];
                for (let dishArr of this.state.selectedCard) {
                    dishData = _.concat(dishData, dishArr);
                }

                // 打印
                // 在服务员更新到已上菜的时候才打印
                if ((this.state.filterButton[index].status === 1 && this.props.role !== 'waiter') || (this.props.role === 'waiter' && this.state.filterButton[index].status === 2)) {
                    let selectedCard: any = []
                    this.state.selectedCard.forEach(target => {
                        selectedCard = selectedCard.concat(target)
                    })
                    this.printTip(selectedCard, true)
                }
                // update
                await this.updateDishesInfo(dishData, status);
                // update view
                this.updateLocalState({ selectedCard: [], selectedCardIndex: {} });
            }
            // $ if notthing happend
            else {
                // 清除已选样式
                document.getElementsByClassName('SelectedTab')[0].setAttribute('class', "Tabs");
                // 添加已选样式
                document.getElementsByClassName('Tabs')[index].setAttribute('class', 'SelectedTab');
                // // 清除已选样式
                // if (document.getElementsByClassName('selectedDeskButton')) document.getElementsByClassName('selectedDeskButton')[0].setAttribute('class', "deskButton");
                // // 添加桌台All已选样式
                // document.getElementsByClassName('deskButton')[this.state.deskList.findIndex((ele: any) => ele === 'All')].setAttribute('class', 'selectedDeskButton');
                this.tableListRef.resetSelectIndex()
                this.eayTypeRef.resetSelectIndex()
                let globalMergeStatus = _.get(StateManager.get('globalMergeStatus'),[this.props.role])
                // console.log('globalMergeStatus',globalMergeStatus,_.get(globalMergeStatus,[this.state.filterButton[index].value,'intelligentMode']))
                // update view
                this.updateLocalState({
                    page: 1,
                    filterValue: index,
                    filterButtonSelected: this.state.filterButton[index].value as any,
                    lastMergeArr: [],
                    currentDesk: "All",
                    currentEatType: "All",
                    intelligentMode: _.get(globalMergeStatus,[this.state.filterButton[index].value,'intelligentMode']),
                    isSimpleMode: _.get(globalMergeStatus,[this.state.filterButton[index].value,'isSimpleMode'])
                }, async () => {
                    // get new data
                    await this.refreshData('All', 'All');
                    //重置页码为1
                    GlobalState.dispatch({
                        type: "SET_BY_PATH",
                        path: "pageCurrent",
                        value: 1,
                    });
                });
            }
            // refresh
            this.refreshDataDifferences();
        }
    }

    /**
     * 点击催菜的按钮
     */
    public async clickRushTab() {
        // $ if card dialog visible
        if (this.state.cardDialogVisible === true) {

            // check if selected multi dishes
            let selectedDish: Array<dish> = [];
            this.state.cardDialogDishes.forEach((c) => {
                if (c.isCardDialogSelected === true) selectedDish.push(c);
            });

            // update
            await this.updateDishesRushInfo(selectedDish);
            // update view
            this.updateLocalState({ cardDialogVisible: false, cardDialogDishes: [], selectedCard: [] });
        }
        // $ if multi card has been selected
        else if (Array.isArray(this.state.selectedCard) && this.state.selectedCard.length > 0) {
            // init
            let dishData: Array<dish> = [];
            for (let dishArr of this.state.selectedCard) {
                dishData = _.concat(dishData, dishArr);
            }

            // update
            await this.updateDishesRushInfo(dishData);
            // update view
            this.updateLocalState({ selectedCard: [], selectedCardIndex: {} });
        }
        // refresh
        this.refreshDataDifferences();
    }

    /**
     * 更新菜品的催菜状态
     * @param dishArr 菜品数据
     */
    public async updateDishesRushInfo(dishArr: any) {
        // 菜品id
        let ids = []
        // 订单Id
        let orderHeadId: any = []
        try {
            // 清除check检查
            clearTimeout(this.state.timeoutID);
            // 非备菜模式
            for (let dish of dishArr) {
                if (dish.hasOwnProperty('condiments')) {
                    for (let condiment of dish.condiments) {
                        ids.push(condiment.order_detail_id)
                    }
                }
                if (!orderHeadId.includes(dish.order_head_id)) orderHeadId.push(dish.order_head_id)
                if (dish.item_type === 3) {
                    // 判断套餐内的菜品是否全部选上,是: 取套餐的order_detail_id, 否: 去套餐选择的菜品order_detail_id
                    const originData = this.state.dishesData.getOriginDishes();
                    const packageDishes: dish[] = _.get(dish, 'course_groupArray') || [];
                    const foundPackage = originData.find(item => item.order_detail_id === dish.order_detail_id);
                    if (foundPackage) {
                        if ((foundPackage.course_groupArray || []).length === packageDishes.length) {
                            ids.push(`${dish.order_detail_id}`)
                        } else {
                            const packageDishIds = packageDishes.map(item => `${item.order_detail_id}`);
                            ids.push(...packageDishIds);
                        }
                    }
                } else {
                    ids.push(`${dish.order_detail_id}`)
                }
            }
            // 没有需要更新的id数据，则返回
            if (!(ids.length > 0)) return

            // update
            await Request.rushDish(StateManager.get('serveUrl'), ids);
            // // build new processor
            // let newDishesData = this.state.dishesData.removeCardFromBoxGroup(dishArr);
            // // update view
            // this.updateLocalState({
            //     dishesData: newDishesData,
            //     cardData: newDishesData.BoxGroup
            // });
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0022,
                details: {
                    ...error,
                    caller: "common.page.tsx => updateDishesRushInfo()",
                }
            }, { horizontal: "right" });
        }
    }

    public async clickUnableToMake(boxData: any) {
        await this.updateDishesInfo(boxData, 5)
        // refresh
        this.refreshDataDifferences();
    }
    public async clickSuspended(boxData: any) {
        // 服务员在未制作和已备菜的界面上，不能做更新
        if (this.props.role === 'waiter' && (this.state.filterButtonSelected === 'prepareDish' || this.state.filterButtonSelected === 'chefkitchen')) return
        // 厨师更新为暂停制作，服务员更新为未制作。
        else if (this.props.role === 'chef') await this.updateDishesInfo(boxData, 3)
        else await this.updateDishesInfo(boxData, 0)
        // refresh
        this.refreshDataDifferences();
    }

    /**
     * change mode
     */
    public async changeModeStatus(mode: string) {
        try {
            // 判断总单功能是否使用
            let isShowTotalOrder = false;
            if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
                isShowTotalOrder = StateManager.get('totalOrderMode')
            }
            // 判断是否切换到外卖模式,获取是从外卖模式到其他的模式,若是,则需要重新获取数据
            let needRefreshData = (mode === 'takeaway_mode' || (this.state.modeSelected === 'takeaway_mode' && mode !== 'takeaway_mode')) ? true : false;
            if (isShowTotalOrder || needRefreshData) {
                // 开启了总单模式，需要先设置好对应的参数，然后重新获取数据，再合并。
                // await new Promise((resolve, reject) =>{
                //     this.updateLocalState({
                //         modeSelected: mode
                //     }, async() =>{
                //         try {
                //             await this.clickTab(this.state.filterValue, this.state.filterButton[this.state.filterValue].status)
                //             resolve('ok')
                //         } catch (error) {
                //             reject(error)
                //         }
                //     });
                // })
                this.updateLocalState({
                    modeSelected: mode
                },() =>{
                    // 全局存储当前选中模式
                    this.updateGlobalState({
                        type: "SET_BY_PATH",
                        path: "modeSelected",
                        value: mode
                    });
                    this.clickTab(this.state.filterValue, this.state.filterButton[this.state.filterValue].status, true);
                });
            } else {
                // $ switch to normal mode
                if (mode === 'normal_mode') {
                    // intelligent mode enabled
                    if (this.state.intelligentMode === true) {
                        // 模式的切换，若满足了合并条件，则直接合并
                        let { dishesData, cardData } = this.intelligentMerge(this.state.cardData, StateManager.get("smartMergerAutoStart"), undefined, true);
                        this.updateLocalState({
                            modeSelected: mode, dishesData, cardData,
                            lastMergeArr: [], //模式切换需要重置最后合并数组
                            selectedCard: [], //触发合并时清空已选择卡片
                            selectedCardIndex: {},
                            currentDesk: 'All',
                            currentEatType: 'All',
                        },() => {
                             // 全局存储当前选中模式
                            this.updateGlobalState({
                                type: "SET_BY_PATH",
                                path: "modeSelected",
                                value: mode
                            });
                        });
                    }
                    // intelligent mode disabled
                    else {
                        let dishesData = this.state.dishesData;
                        dishesData.update(this.state.filterButtonSelected, this.props.role, undefined, undefined, 'All');
                        this.updateLocalState({
                            modeSelected: mode, dishesData, 
                            // cardData: dishesData.BoxGroup,
                            cardData: (this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') ||
                            (this.state.filterButtonSelected === "prepareDish") ?
                                dishesData.BoxGroup.sort((a: CardBox, b: CardBox) => {
                                    // $ 在不参与合并的情况下，菜品按照权重值排序(只影响已下单页面)
                                    // 已备菜页面也按照权重值排序，但不受轮次权重影响
                                    let pre = a.cardInfo.boxData[0].dishWeights
                                    let nex = b.cardInfo.boxData[0].dishWeights
                                    return nex - pre
                                }) : dishesData.BoxGroup, 
                            lastMergeArr: [], //模式切换需要重置最后合并数组
                            selectedCard: [], //触发合并时清空已选择卡片
                            selectedCardIndex: {},
                            currentDesk: 'All',
                            currentEatType: 'All',
                        },() => {
                             // 全局存储当前选中模式
                            this.updateGlobalState({
                                type: "SET_BY_PATH",
                                path: "modeSelected",
                                value: mode
                            });
                        });
                    }
                }
                // $ switch to order mode
                else if (mode === 'order_mode'  || mode === 'takeaway_mode') {
                    let { dishesData, cardData } = this.intelligentMergeOrderMode(undefined, undefined, undefined, undefined, mode);
                    this.updateLocalState({
                        modeSelected: mode, 
                        dishesData, 
                        cardData,
                        lastMergeArr: [], //模式切换需要重置最后合并数组
                        selectedCard: [], //触发合并时清空已选择卡片
                        selectedCardIndex: {},
                        currentDesk: 'All',
                        currentEatType: 'All'
                    },() => {
                         // 全局存储当前选中模式
                        this.updateGlobalState({
                            type: "SET_BY_PATH",
                            path: "modeSelected",
                            value: mode
                        });
                    });
                }
            }
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0023,
                details: {
                    ...error,
                    caller: "chef.page.tsx => changeModeStatus()",
                }
            }, { horizontal: "right" });
        }
        this.tableListRef.resetSelectIndex();
        this.eayTypeRef.resetSelectIndex();
    }
    /**
     * 判断当前菜品是否需要备菜
     * @param dish 
     * @returns 
     */
    public isNeedPrepareDish(dish: dish, clickMode: 'double' | 'single' = 'single'){
        let prepareDishPost = StateManager.get('prepareDishPostSelected') || [];
        let prepareDishFunction = StateManager.get('prepareDishFunction');
        // 判断当前菜品要不要备菜
        let hasPreparDish = false;
        // 在厨师页面的已下单中，双击的情况下，开启了备菜模式，并且选择了对应的岗位。
        if (this.props.role === 'chef' && prepareDishFunction && this.state.filterButtonSelected === 'kitchen' && Array.isArray(prepareDishPost) && prepareDishPost.length > 0 && clickMode === 'double') {
            let index = prepareDishPost.findIndex( target => `${target.print_class_id}` === `${dish.print_class}` && target.checked)
            if (index !== -1) hasPreparDish = true;
        }
        return hasPreparDish;
    }
    /**
     * 获取需要打印的菜品数据
     * @param dishes 菜品数组
     * @param printAll 无视所有规则，打印dishes中的所有菜
     */
    public getPrintDishData(dishes: Array<dish>, clickMode: 'double' | 'single' = 'single', printAll?: boolean, employeeName?: string): {
        printData: Array<PrintData>;
        orderHeadId: Array<number>;
        orderDetailId: Array<string>;
    } {
        let printData: Array<PrintData> = [];
        let orderHeadId: Array<number> = [];
        let orderDetailId: Array<string> = [];
        try {
            // 菜名打印方式
            let dishNamePrintMethod = StateManager.get('dishNamePrintMethod');
            let printingMethod = StateManager.get('printingMethod');
            let isPrintEatIn = StateManager.get('isPrintEatIn');
            let isPrintTakeAway = StateManager.get('isPrintTakeAway');
            let printOperatorType = StateManager.get('printOperatorType');
            // 是否是服务员的待上菜页面并且开启了总单模式
            let isShowTotalOrder = false;
            if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
                // 是否显示总单模式,需要在服务员->订单模式->待上菜界面，并且开启了显示总单模式的功能。
                isShowTotalOrder = StateManager.get('totalOrderMode') && ['order_mode', 'takeaway_mode'].includes(this.state.modeSelected);
            }
            // 循坏需要打印的打印机
            StateManager.get('printersSelected').forEach((print: any, index: number) => {
                printData.push({
                    type: "EPSON",
                    theinterface: `tcp://${print.printer_name}:9100`,
                    print_device_id: print.print_device_id,
                    print_device_name: print.print_device_name,
                    data: [],
                    rule: {
                        printingMethod
                    },
                    multi_creater: i18nService.t('text_printing_multi_person'),
                    print_emp_type: printOperatorType === 'CHEF' ? '1' : '0'
                })
                // 循坏菜品组装打印的数据
                dishes.forEach((target: dish) => {
                    // 备菜的菜品不需要打印
                    if(this.isNeedPrepareDish(target, clickMode)) return;
                    let desc = `${target.description ? target.description : ''}${target.condimentsDescription ? target.condimentsDescription : ''}`
                    let itemName1 = target.item_name1 ? target.item_name1 : '(No Item Name)'
                    // 名称打印方式选择了菜名2，如果此时数据没有名称2，则显示(No Item Name)，其他方式，没有菜名2可以不显示。
                    let itemName2 = target.item_name2 ? target.item_name2 : dishNamePrintMethod === '2' ? '(No Item Name)' : undefined;
                    // 套餐名称
                    let setName1 = target.set_name1 ? target.set_name1 : '(No Item Name)';
                    let setName2 = target.set_name2 ? target.set_name2 : dishNamePrintMethod === '2' ? '(No Item Name)' : undefined;
                    // 获取用户名称
                    let customerName = '';
                    try {
                        let deliveryInfo = _.get(target, 'delivery_info') || '';
                        let customerArr = deliveryInfo.split(',');
                        if (customerArr.length > 0) {
                            // let customerName = customerArr[0].split(' ')
                            customerName =  _.get(customerArr, ['0'], '') || '';
                        } else customerName = '';
                    } catch (error) {
                    }
                    // 如果没开启打印堂食订单，不传递堂食的菜品
                    if(!isPrintEatIn && _.get(target, 'eat_type') == 0) return;
                    // 如果没开启打印外卖订单，不传递外卖的菜品
                    if(!isPrintTakeAway && _.get(target, 'eat_type') != 0) return;
                    let printParams = {
                        id: target.order_head_id,
                        table: target.table_name,
                        checkNumber: target.check_number,
                        date: null,
                        dishName: dishNamePrintMethod === '0' || dishNamePrintMethod === '1' ? itemName1 : undefined,
                        dishName2:  dishNamePrintMethod === '0' ||  dishNamePrintMethod === '2' ? itemName2 : undefined,
                        dishNum: target.quantity,
                        description: desc,
                        checkName: target.check_name ? target.check_name : "",
                        order_type: _.get(target, 'eat_type') == 0 ? '0' : '1',
                        deliveryInfo: customerName,
                        deliveryTime: target.kds_time ? moment(target.kds_time).format("kk:mm:ss DD/MM/YYYY") : "",
                        family_group: target.family_group,
                        rush: target.rush,
                        open_employee_name: employeeName || '',
                        create_order_id: `${target.order_employee_id}`,
                        set_name1: dishNamePrintMethod === '0' || dishNamePrintMethod === '1' ? setName1 : undefined,
                        set_name2: dishNamePrintMethod === '0' ||  dishNamePrintMethod === '2' ? setName2 : undefined,
                        set_quantity: target.set_quantity,
                        condiment_belong_item: target.condiment_belong_item,
                        customer_num: target.customer_num || 1
                    }
                    if (isShowTotalOrder) {
                        // 普通菜品已完成或者套餐的某个菜品已完成
                        (target.is_make === 1 || (target.item_type === 3 && (target.course_groupArray || []).some(item => item.is_make === 1))) && printData[index].data.push(printParams);
                    } else {
                        printData[index].data.push(printParams);
                    }
                    if (target.item_type === 3) {
                        (target.course_groupArray || []).forEach(item => {
                            let packageDishPrintParams = {
                                id: item.order_head_id,
                                table: item.table_name,
                                checkNumber: item.check_number,
                                date: null,
                                dishName: dishNamePrintMethod === '0' || dishNamePrintMethod === '1' ? item.item_name1 || '(No Item Name)' : undefined,
                                dishName2:  dishNamePrintMethod === '0' ||  dishNamePrintMethod === '2' ? item.item_name2 || '(No Item Name)' : undefined,
                                dishNum: item.quantity,
                                description: `${item.description ? item.description : ''}${(item.condiments || []).reduce((pre, cur) => {
                                    return `${pre}${cur.item_name1 || ''}`;
                                }, '')}`,
                                checkName: item.check_name ? item.check_name : "",
                                order_type: _.get(item, 'eat_type') == 0 ? '0' : '1',
                                // deliveryInfo: target.delivery_info ? target.delivery_info.replace(/\n\|\n\n/ig, ' ') : "",
                                deliveryInfo: customerName,
                                deliveryTime: item.kds_time ? moment(target.kds_time).format("kk:mm:ss DD/MM/YYYY") : "",
                                family_group: item.family_group,
                                rush: item.rush,
                                open_employee_name: employeeName || '',
                                create_order_id: `${item.order_employee_id}`,
                                set_name1: dishNamePrintMethod === '0' || dishNamePrintMethod === '1' ? setName1 : undefined,
                                set_name2: dishNamePrintMethod === '0' ||  dishNamePrintMethod === '2' ? setName2 : undefined,
                                set_quantity: item.set_quantity,
                                condiment_belong_item: item.condiment_belong_item,
                                customer_num: item.customer_num || 1
                            };
                            if (isShowTotalOrder) { 
                                item.is_make === 1 && printData[index].data.push(packageDishPrintParams);
                            } else {
                                printData[index].data.push(packageDishPrintParams);
                            }
                        })
                    }
                    if (!orderHeadId.includes(target.order_head_id)) {
                        orderHeadId.push(target.order_head_id);
                    }
                    orderDetailId.push(`${target.order_detail_id}—${itemName1}`);
                })
            })
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0024,
                details: {
                    ...error,
                    caller: "chef.page.tsx => getPrintDishData()",
                    dishes: dishes,
                    printAll: printAll,
                }
            }, { horizontal: "right" });
        }
        return {printData, orderHeadId, orderDetailId};
    }

    /**
     * 打印小票
     * @param dishes 菜品
     * @param printAll 所有不按规则全部打印
     */
    public async printTip(dishes: Array<dish>, printAll: boolean, clickMode: 'double' | 'single' = 'single') {
        // 没开打印功能的话，不需要打印
        if (!StateManager.get('crossFoodPrint')) return

        let {printData, orderHeadId, orderDetailId} = this.getPrintDishData(dishes, clickMode, printAll, this.employeeName);
        try {
            printData.forEach((target: PrintData) => {
                Request.printTip(StateManager.get('serveUrl'), target, StateManager.get('receiptRetryTimes')).then((res)=>{
                    let status = res && res.data && res.data.ok ? true : false;
                    this.setPrintLogger(orderDetailId, orderHeadId, moment().format("YYYY-MM-DD kk:mm:ss"), status, [`${target.print_device_name}—${target.theinterface}`]);
                }).catch(()=>{
                    this.setPrintLogger(orderDetailId, orderHeadId, moment().format("YYYY-MM-DD kk:mm:ss"), false, [`${target.print_device_name}—${target.theinterface}`]);
                });
            });
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0025,
                details: {
                    ...error,
                    caller: "chef.page.tsx => printTip()",
                    dishes: dishes,
                    printAll: printAll,
                }
            }, { horizontal: "right" });
        }
    }
    /**
     * 获取选中的菜品名字
     */
    public getSelectDishName(): string {
        let selectDishName = '';
        if (this.state.cardDialogVisible) {
            selectDishName = this.state.cardDialogDishes.reduce((total: any, current: any) => {
                return current.isCardDialogSelected ? `${total}${StateManager.get('confirmWindowShowName') === 2 ? current.item_name2 : current.item_name1}\n` : `${total}`
            }, '')
        } else {
            selectDishName = this.state.selectedCard.reduce((total: any, current: any) => {
                return `${total}${current.reduce((total2: any, current2: any) => `${total2}${StateManager.get('confirmWindowShowName') === 2 ? current2.item_name2 : current2.item_name1}\n`, '')}`
            }, '')
        }
        return selectDishName
    }
    /**
     * 判断是否需要隐藏卡片蒙版
     */
    judgeHideCardMask = (ele:CardBox): boolean => {
        let isHide = false;
        // 如果没有开启高亮同名卡片功能，则可以直接隐藏蒙版
        if(!StateManager.get('isHighlightDishesOfTheSameName')) return true;
        // 如果没有选中卡片，则直接隐藏
        if(this.state.selectedCard && this.state.selectedCard.length === 0) return true;
        for (const item of ele.cardInfo.boxData) {
            for( const cardItem of this.state.selectedCard){
                // 选中的卡片与卡片比较，看是否有名称相同的菜品
                let index = cardItem.findIndex((target: any) => {
                    return target.item_name1 === item.item_name1 && target.item_name2 === item.item_name2
                })
                if (index !== -1) {
                    isHide = true;
                    break;
                }
            }
            // 满足隐藏条件后可以直接跳出循环
            if(isHide) break;
        }
        return isHide;
    }
    /**
     * 更新全局的按钮合并状态
     * @param status 
     */
    public updateGlobalMergeStatus(mergeStatus:{intelligentMode: boolean,isSimpleMode: boolean}){
        let globalMergeStatus = StateManager.get('globalMergeStatus') || {}
        // 开启了状态继承，则所有的状态都更新为同一个状态。
        if(StateManager.get('isInheritMergeState')){
            Object.keys(globalMergeStatus[this.props.role]).forEach(status =>{
                globalMergeStatus[this.props.role][status] = mergeStatus
            })
        } else {
            globalMergeStatus[this.props.role] = {
                ...globalMergeStatus[this.props.role],
                [this.state.filterButtonSelected]: mergeStatus
            }
        }
        this.updateGlobalState({
            type: "SET_BY_PATH",
            path: "globalMergeStatus",
            value: globalMergeStatus
        });
    }
    // *********************
    // Intelligent Merge Function
    // *********************

    /**
     * // rebuild card data for view
     * @param resultArr //合并后的数组
     */
    public rebuildCardData(resultArr: Array<any>): Array<any> {
        let boxGroup = []
        for (let index = 0; index < resultArr.length; index++) {
            let dishArr = resultArr[index];
            let personNum = `${dishArr.customer_num}`
            let orderTimeHours = (new Date(dishArr.order_time)).getHours();
            let orderTimeMinutes = (new Date(dishArr.order_time)).getMinutes()
            let orderTime = `${orderTimeHours < 10 ? '0' + orderTimeHours : orderTimeHours}:${orderTimeMinutes < 10 ? '0' + orderTimeMinutes : orderTimeMinutes}`;
            let endTimeHours = (new Date(dishArr.end_time)).getHours();
            let endTimeMinutes = (new Date(dishArr.end_time)).getMinutes();
            let endTime = `${endTimeHours < 10 ? '0' + endTimeHours : endTimeHours}:${endTimeMinutes < 10 ? '0' + endTimeMinutes : endTimeMinutes}`;
            let kdsTimeHours = (new Date(dishArr.kds_time)).getHours();
            let kdsTimeMinutes = (new Date(dishArr.kds_time)).getMinutes();
            let kdsTime = `${kdsTimeHours < 10 ? '0' + kdsTimeHours : kdsTimeHours}:${kdsTimeMinutes < 10 ? '0' + kdsTimeMinutes : kdsTimeMinutes}`;
            // 已制作或者已上菜使用end_time计算，其他使用order_time
            let time = (this.state.filterButtonSelected === "history") || (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort') ? new Date(dishArr.end_time).getTime() : new Date(dishArr.order_time).getTime()
            let timesGone: number | string = parseInt(((new Date().getTime() - time) / 1000 / 60).toFixed(0))
            if (timesGone > 99) timesGone = `99+min`;
            else timesGone = `${timesGone}min`;
            boxGroup.push(new CardBox(
                dishArr.mergeType,
                dishArr.dishes, 
                dishArr.order_head_id, 
                orderTime, timesGone, 
                endTime, 
                kdsTime, 
                index, 
                dishArr.rush,
                dishArr.eat_type,
                personNum,
                dishArr.table_name
            ));
        }
        return boxGroup;
    }

    /**
     * rebuild all dishes with intelligent merge service
     * @param cardData 
     * @param hasMerge 这是为了强制执行合并，因为桌台切换需要合并而不是匹配更新数据。
     */
    public intelligentMerge(cardData: Array<CardBox>, auto: boolean, cardProcessor?: CardProcessor, hasMerge?: boolean): {
        dishesData: CardProcessor;
        cardData: Array<CardBox>;
        lastMergeArr: Array<any>;
    } {
        try {
            // init
            let dishes: Array<dish> = [];
            let familyGroup: string[] = [];
            let resultArr: Array<any> = [];

            // check
            if (!Array.isArray(cardData)) return {} as any;

            // rebuild data
            dishes = _.flatten(cardData.map((c: CardBox) => c.cardInfo.boxData));
            // 菜品种类id数组
            familyGroup = (StateManager.get('dishMergeTypesSelected') || []).map((t: any) => `${t.family_group_id}`);

            // set merge rules
            let capacity = StateManager.get('cardCapacity');
            let rules: Array<MergeRules | MagnetRules> = [
                {
                    type: 'Merge',
                    data: [
                        {
                            key: 'table_name',
                        },
                        {
                            key: 'order_head_id',
                        },
                        {
                            key: 'family_group',
                            value: familyGroup,
                            logic: 'AND'
                        },
                        {
                            key: "is_make",
                            value: '11',
                        }
                    ],
                    extraField: 'table_name,customer_num,end_time:data:max,order_time:date:min,kds_time:data:min,order_head_id,rush:number:max,isSelect=false',
                    capacity,
                },
                {
                    type: 'Magnet',
                    data: [
                        {
                            key: "item_name1"
                        },
                        {
                            key: "check_name"
                        },
                        {
                            key: "remark"
                        },
                        {
                            key: "description"
                        },
                        {
                            key: "condimentsDescription"
                        }
                    ],
                    extraField: 'table_name,customer_num,end_time:data:max,order_time:date:min,kds_time:data:min,order_head_id,rush:number:max,eat_type,isSelect=false',
                    capacity,
                }
            ];
            // 缩略模式，堂吃与堂吃合并，外卖与外卖合并
            if (this.state.isSimpleMode) {
                rules.forEach(target => {
                    if (target.type === 'Magnet') {
                        target.data.unshift({
                            key: "eat_type",
                            value: '0',
                            logic: 'OR'
                        });
                    }
                })
            }
            // 开启了合并检查菜品id，需要把item_id添加到规则里面
            if (StateManager.get('mergeCheckDishID')) {
                rules.forEach(target => {
                    if (target.type === 'Magnet') {
                        target.data.unshift({
                            key: "item_id"
                        });
                    }
                })
            }
            // 没有开启合并忽略客户信息的情况下，需要把客户信息的合并条件添加到规则中
            if (!StateManager.get('mergeIgnoreCustomerInfo')) {
                rules.forEach(target => {
                    if (target.type === 'Magnet') {
                        target.data.push({
                            key: "delivery_info"
                        });
                        // target.data.push({
                        //     key: "kds_time"
                        // })
                    }
                })
            }
            // 开启了仅合并堂吃菜品功能，则需要添加仅合并堂吃的rule。
            if (StateManager.get('onlyMergeDineIn')) {
                rules.forEach(target => {
                    target.data.push({
                        key: "eat_type",
                        value: '0',
                        logic: 'AND'
                    })
                })
            }
            // do mergeing action
            if (auto || this.state.lastMergeArr.length === 0 || hasMerge) {
                let mergeData: MergeObject = MergeProcessor.merge(dishes, rules);
                // 转换数组的同时，也对合并卡片里的菜进行排序
                // 菜品卡片内的排序
                let sortTheDishesInTheCard = StateManager.get('sortTheDishesInTheCard');
                // 菜品卡片内排序规则
                let sortRules: SortRules[];
                // $ 已下单页面卡片内排序作特殊处理 - 按照菜品权重排
                if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen')) {
                    sortRules = [{
                        type: 'time',
                        key: 'dishWeights',
                        order: 'Reverse',
                        extremum: 'max'
                    }]
                } else {
                    // 其余页面按照基础设置排序
                    sortRules = [{
                        type: sortTheDishesInTheCard === '0' ? 'number' : 'string',
                        key: sortTheDishesInTheCard === '0' ? "menu_item_id" : sortTheDishesInTheCard === '2' ? 'item_name2' : 'item_name1',
                        order: 'Positive',
                        extremum: 'max'
                    }]
                }
                resultArr = MergeProcessor.transfromArray(mergeData, sortRules);
            }
            else {
                // get the merged result
                resultArr = MergeProcessor.pairedSourceData(this.state.lastMergeArr, dishes, rules);
            }
            // 存储服务员的待上菜是否开启了end_time的排序
            let enableEndTimeSort = this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort'
            // 是否需要联合排序
            let isJointTimeSort = false;
            if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') && StateManager.get('orderedSort') === 'kdsTimeJointOrderTimeSort'){
                isJointTimeSort = true;
            }
            // do sort
            resultArr = MergeProcessor.sort(resultArr, [{
                type: 'time',
                key: this.state.filterButtonSelected === "history" || enableEndTimeSort ? "end_time" : isJointTimeSort ? 'joint_time' : "order_time",
                order: this.state.filterButtonSelected === "history" ? 'Reverse' : 'Positive',
                extremum: 'min'
            }]);
            // !!智能排序体系 权重值排序测试（只针对 type=time 的情况）
            // resultArr = MergeProcessor.sort(resultArr, [{
            //     type: 'time',
            //     key: 'dishWeights',  // 菜品权重值字段
            //     order: 'Reverse',    // 逆序，权重值大的放前面
            //     extremum: 'max'
            // }])
            // 已下单页面排序
            if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen')) {
                // do sort
                // resultArr = MergeProcessor.sort(resultArr, [{
                //     type: 'time',
                //     key: "kds_time",
                //     order: 'Positive',
                //     extremum: 'min'
                // }], true);

                // !!智能排序体系 权重值排序测试（只针对 type=time 的情况）
                resultArr = MergeProcessor.sort(resultArr, [{
                    type: 'time',
                    key: 'dishWeights',  // 菜品权重值字段
                    order: 'Reverse',    // 逆序，权重值大的放前面
                    extremum: 'max'
                }])
            }
            // if section is kitchen or chefkitchen, sort by rush
            if (this.state.filterButtonSelected === "kitchen" || this.state.filterButtonSelected === "chefkitchen") {
                resultArr = MergeProcessor.sort(resultArr, [{
                        type: 'number',
                        key: 'rush',
                        order: 'Reverse',
                        extremum: 'max'
                    }])
            };
            // 已备菜页面
            if(this.state.filterButtonSelected === "prepareDish") {
                resultArr = MergeProcessor.sort(resultArr, [
                    {
                        type: 'time',
                        key: 'dishWeights',  // 菜品权重值字段
                        order: 'Reverse',    // 逆序，权重值大的放前面
                        extremum: 'max'
                    }
                ])
            }

            // rebuild card data for view
            let boxGroup = this.rebuildCardData(resultArr)
            let dishesData = cardProcessor || this.state.dishesData;
            dishesData.BoxGroup = boxGroup;

            // update local state
            return {
                dishesData,
                cardData: boxGroup,
                lastMergeArr: resultArr
            };
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0020,
                details: {
                    ...error,
                    caller: "chef.page.tsx => intelligentMerge()",
                }
            }, { horizontal: "right" });
            return {} as any;
        }
    }

    /**
     * rebuild all dishes with intelligent merge service in order mode
     */
    public intelligentMergeOrderMode(cardData?: Array<CardBox>, auto?: boolean, cardProcessor?: CardProcessor, hasMerge?: boolean, modeSelected?: string): {
        dishesData: CardProcessor;
        cardData: Array<CardBox>;
        lastMergeArr: Array<any>;
    } {
        try {
            modeSelected = modeSelected ? modeSelected : this.state.modeSelected;
            // init
            let dishes: Array<dish> = [];
            if (cardData && Array.isArray(cardData)) dishes = _.flatten(cardData.map((c: CardBox) => c.cardInfo.boxData));
            else dishes = (this.state.dishesData as any).dishesData
            // rebuild data
            // dishes = _.flatten((this.state.dishesData as any).dishesData.map((c: dish) => c));

            // 订单模式 | 外卖模式 待上菜页面排序字段
            const toBeServeSortKey = this.state.filterButtonSelected === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort' ? 'end_time:date:min' : 'end_time:date:max';

            // build rules
            let rules: Array<MergeRules | MagnetRules> = [{
                type: 'Magnet',
                data: [{
                    key: "order_head_id"
                },
                {
                    key: "delivery_info"
                },
                {
                    key: "kds_time"
                }],
                capacity: 99999,
                extraField: `table_name,customer_num,${toBeServeSortKey},order_time:date:min,kds_time:date:min,order_head_id,rush:number:max,eat_type,isSelect=false`
            }];

            // do merging action
            let mergeData: MergeObject = MergeProcessor.merge(dishes, rules);
            let isShowTotalOrder = false
            if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
                // 是否显示总单模式,需要在服务员->订单模式->待上菜界面，并且开启了显示总单模式的功能。
                isShowTotalOrder = StateManager.get('totalOrderMode') && (modeSelected == 'order_mode' || modeSelected === 'takeaway_mode')
            }
            // 转换数组的同时，也对合并卡片里的菜进行排序,但如果是总单模式，则不需要再排序
            let sortTheDishesInTheCard = StateManager.get('sortTheDishesInTheCard');
            let sortTheOrderModeDineIncard = StateManager.get('sortTheOrderModeDineIncard');
            let sortRule: SortRules[] = [];
            /**
             * 订单模式卡片内排序
             * 1. 针对服务员带上菜页面卡片内排序根据 菜品制作状态做特殊处理（只针对已制作菜品）
             *    1.1 基础设置待上菜页面开启按照最后状态时间排序
             *    1.2 判断是否为总单模式，未完成的菜品没有权重值字段，按照end_time来排序
             * 2. 其余页面卡片内排序根据 就餐类型做特殊处理（只针对堂食）
             */ 

            // 判断是否为服务员待上菜页面 且 设置为最后状态时间排序
            let waiterEndTimeSort = (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') && StateManager.get('readyToServeSort') === 'endTimeSort'
            if (isShowTotalOrder) {
                // 总单模式开启
                sortRule = [{
                    type: 'number',
                    key: 'is_make', // 制作状态
                    order: 'Positive',
                    value: 1, // 已制作
                    extremum: 'max'
                }, {
                    type: 'number',
                    key: 'is_make',
                    order: 'Positive',
                    value: 11, // 备菜
                    extremum: 'max'
                }, {
                    type: sortTheDishesInTheCard === '0' ? 'number' : 'string',
                    key: sortTheDishesInTheCard === '0' ? "menu_item_id" : sortTheDishesInTheCard === '2' ? 'item_name2' : 'item_name1',
                    order: 'Positive',
                    extremum: 'max',
                    specialRules: modeSelected === 'order_mode' || modeSelected === 'takeaway_mode' ? {
                        key: waiterEndTimeSort ? 'is_make' : 'eat_type', 
                        value: waiterEndTimeSort ? 1 : 0, 
                        rule: {
                            type: sortTheOrderModeDineIncard === '0' ? 'time' : 'string',
                            key: sortTheOrderModeDineIncard === '0' ? "end_time" : 'item_name1',
                            order: 'Positive',
                            extremum: 'max',
                        }
                    } : false
                }]
            } else {
                // 总单模式关闭
                sortRule = [{
                    type: sortTheDishesInTheCard === '0' ? 'number' : 'string',
                    key: sortTheDishesInTheCard === '0' ? "menu_item_id" : sortTheDishesInTheCard === '2' ? 'item_name2' : 'item_name1',
                    order: 'Positive',
                    extremum: 'max',
                    specialRules: modeSelected === 'order_mode' || modeSelected === 'takeaway_mode' ? {
                        key: waiterEndTimeSort ? 'is_make' : 'eat_type', 
                        value: waiterEndTimeSort ? 1 : 0, 
                        rule: {
                            type: sortTheOrderModeDineIncard === '0' ? 'time' : 'string',
                            key: sortTheOrderModeDineIncard === '0' ? "dishWeights" : 'item_name1',
                            order: sortTheOrderModeDineIncard === '0' ? 'Reverse' : 'Positive',
                            extremum: 'max',
                        }
                    } : false
                }]
            }
            let mergeArray: MergeArray = MergeProcessor.transfromArray(mergeData, sortRule,
                this.magneticLinkMode && this.props.role === 'waiter' && this.props.filterButtonSelected ==='kitchen' ? {key: 'magnetic_link'} : undefined
            );
             // 非外卖模式才需要进行排序
             if (modeSelected !== 'takeaway_mode') {
                 // 存储服务员的待上菜是否开启了end_time的排序
                 let enableEndTimeSort = this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort'
                 // 是否需要联合排序
                let isJointTimeSort = false;
                if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') && StateManager.get('orderedSort') === 'kdsTimeJointOrderTimeSort'){
                    isJointTimeSort = true;
                }
                 // do sort
                 mergeArray = MergeProcessor.sort(mergeArray, [{
                     type: 'time',
                     key: this.state.filterButtonSelected === "history" || enableEndTimeSort ? "end_time" : isJointTimeSort ? 'joint_time' : "order_time",
                     order: this.state.filterButtonSelected === "history" ? 'Reverse' : 'Positive',
                     extremum: 'min'
                 }]);

                //  !!智能排序体系 权重值排序测试（只针对 type=time 的情况）
                // mergeArray = MergeProcessor.sort(mergeArray, [{
                //     type: 'time',
                //     key: 'dishWeights',  // 菜品权重值字段
                //     order: 'Reverse',    // 逆序，权重值大的放前面
                //     extremum: 'max'
                // }])
     
                 // 已下单页面排序
                 if ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen')) {
                     // do sort
                    //  mergeArray = MergeProcessor.sort(mergeArray, [{
                    //      type: 'time',
                    //      key: "kds_time",
                    //      order: 'Positive',
                    //      extremum: 'min'
                    //  }], true);

                    // !!智能排序体系 权重值排序测试（只针对 type=time 的情况）
                    mergeArray = MergeProcessor.sort(mergeArray, [{
                        type: 'time',
                        key: 'dishWeights',  // 菜品权重值字段
                        order: 'Reverse',    // 逆序，权重值大的放前面
                        extremum: 'max'
                    }])
                 }
     
                 // if section is kitchen or chefkitchen, sort by rush
                 if (this.state.filterButtonSelected === "kitchen" || this.state.filterButtonSelected === "chefkitchen") {
                    mergeArray = MergeProcessor.sort(mergeArray, [
                        {
                            type: 'number',
                            key: 'rush',
                            order: 'Reverse',
                            extremum: 'max'
                        },
                    ])
                 };
                //  已备菜页面
                if(this.state.filterButtonSelected === "prepareDish") {
                    mergeArray = MergeProcessor.sort(mergeArray, [
                        {
                            type: 'time',
                            key: 'dishWeights',  // 菜品权重值字段
                            order: 'Reverse',    // 逆序，权重值大的放前面
                            extremum: 'max'
                        }
                    ])
                }
             } else {
                //  外卖模式按照kds_time进行排序
                // !!智能排序体系 权重值排序测试（只针对 type=time 的情况）
                mergeArray = MergeProcessor.sort(mergeArray, [{
                    type: 'time',
                    key: 'dishWeights',
                    order: 'Reverse',
                    extremum: 'max'
                }])

             }
            // rebuild dishes for view
            let boxGroup = this.rebuildCardData(mergeArray)
            let dishesData = cardProcessor || this.state.dishesData;
            dishesData.BoxGroup = boxGroup;
            // update local state
            return {
                dishesData,
                cardData: boxGroup,
                lastMergeArr: mergeArray
            };
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0020,
                details: {
                    ...error,
                    caller: "chef.page.tsx => intelligentMergeOrderMode()",
                }
            }, { horizontal: "right" });
            return {} as any;
        }
    }

    /**
     * 判断更新状态得按钮能否点击
     * @param element 更新的按钮元素
     */
    public disablefilterButton(element: FilterButton): boolean {
        // 在服务员的界面时，当前显示的是未制作和已备菜的时候，并且选择了卡片，或者弹出了明细选择，不能对数据进行操作
        return this.props.role === 'waiter' &&
        (this.state.filterButtonSelected === 'chefkitchen' || this.state.filterButtonSelected === 'prepareDish') &&
        (this.state.selectedCard.length > 0 || this.state.cardDialogVisible) &&
        (
            element.value === 'chefkitchen' || element.value === 'prepareDish' ||
            element.value === 'kitchen' || element.value === 'history' || element.value === 'suspend'
        )
    }
    // *********************
    // View
    // *********************

    render() {
        let isShowTotalOrder = false;
        // 待上菜页面的排序
        if (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen') {
            // 是否显示总单模式,需要在服务员->订单模式->待上菜界面，并且开启了显示总单模式的功能。
            isShowTotalOrder = StateManager.get('totalOrderMode') && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode')
        }
        // 当前选择的菜品数量
        let selectDishItems = 0;
        this.state.selectedCard.forEach(target => selectDishItems += target.length);
        return (
            <div
                key={"" + this.state.refreshTime}
                style={{
                    height: "100vh",
                    width: "100%",
                    // backgroundColor: '#170503',
                    display: 'flex',
                    flexDirection: "row",
                    overflow: "hidden"
                }}
                className="commonPage"
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
            >
                {/* Left Side Bar */}
                <div className={this.state.unfoldShrink ? 'show' : "hide"}>
                    {
                        this.state.unfoldShrink ?
                            <div className="closeIcon" style={{ height: 60, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 5, paddingBottom: 70, marginTop: 25 }}>
                                <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit" onClick={() => { this.unfoldShrinkChange() }}>
                                    <CloseIcon style={{ fontSize: 30, fontWeight: 'bold', color: '#fff', borderRadius: 20 }} />
                                </Fab>
                            </div>
                            :
                            null
                    }
                    {/* 左侧按钮区域 */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* 普通模式 */}
                        <div
                            style={{
                                minHeight: 79, border: 'none', width: "100%",
                                visibility: this.props.role === 'waiter' && this.magneticLinkMode && this.state.filterButtonSelected === 'kitchen' ? 'hidden' : 'visible'
                            }}
                            className={this.state.modeSelected === 'normal_mode' ? 'selectedMode' : 'mode'}
                            onClick={async (event) => {
                                if (this.state.modeSelected === 'normal_mode') return;
                                // 磁力链模式下，服务员的待上菜页面不能选择普通模式
                                if(this.props.role === 'waiter' && this.magneticLinkMode && this.state.filterButtonSelected === 'kitchen') return;
                                // if (document.getElementsByClassName('selectedMode')[0]) {
                                //     let className = document.getElementsByClassName("selectedMode")[0].className;
                                //     let newClassName = className.replace('selectedMode', 'mode')
                                //     document.getElementsByClassName("selectedMode")[0].setAttribute('class', newClassName)
                                // }
                                // let className = event.currentTarget.className;
                                // let newClassName = className.replace('mode', 'selectedMode');
                                // event.currentTarget.setAttribute('class', newClassName);
                                this.changeModeStatus("normal_mode");
                            }}>
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<ViewModuleIcon style={{ fontSize: 28, fontWeight: 'bold' }} />}>
                                {this.state.mode[0].i18nString}
                            </Button>
                        </div>
                        {/* 订单模式 */}
                        <div style={{ minHeight: 79, border: 'none', width: "100%" }} className={this.state.modeSelected === 'order_mode' ? 'selectedMode' : 'mode'}
                            onClick={(event) => {
                                if (this.state.modeSelected === 'order_mode') return;
                                // if (document.getElementsByClassName('selectedMode')[0]) {
                                //     let className = document.getElementsByClassName("selectedMode")[0].className;
                                //     let newClassName = className.replace('selectedMode', 'mode')
                                //     document.getElementsByClassName("selectedMode")[0].setAttribute('class', newClassName)
                                // }
                                // let className = event.currentTarget.className;

                                // let newClassName = className.replace('mode', 'selectedMode');
                                // event.currentTarget.setAttribute('class', newClassName);
                                this.changeModeStatus("order_mode");
                            }}
                        >
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<ViewListIcon style={{ fontSize: 28, fontWeight: 'bold' }} />}>
                                {this.state.mode[1].i18nString}
                            </Button>
                        </div>
                        {/* 外卖模式 */}
                        <div style={{ minHeight: 79, border: 'none', width: "100%", }} className={this.state.modeSelected === 'takeaway_mode' ? 'selectedMode' : 'mode'}
                            onClick={async (event) => {
                                if (this.state.modeSelected === 'takeaway_mode') return;
                                // if (document.getElementsByClassName('selectedMode')[0]) {
                                //     let className = document.getElementsByClassName("selectedMode")[0].className;
                                //     let newClassName = className.replace('selectedMode', 'mode')
                                //     document.getElementsByClassName("selectedMode")[0].setAttribute('class', newClassName)
                                // }
                                // let className = event.currentTarget.className;
                                // let newClassName = className.replace('mode', 'selectedMode');
                                // event.currentTarget.setAttribute('class', newClassName);
                                this.changeModeStatus("takeaway_mode");
                            }}>
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<ViewModuleIcon style={{ fontSize: 28, fontWeight: 'bold' }} />}>
                                {this.state.mode[2].i18nString}
                            </Button>
                        </div>
                        {/* 刷新 */}
                        <div style={{ minHeight: 79, border: 'none', width: "100%" }} className="updateClass"
                            onClick={async (event) => {
                                if (this.state.modeSelected === 'update') return;
                                // update
                                this.updateLocalState({ refreshButtonLock: true, loadingVisible: true });
                                // await this.componentDidMount();
                                try {
                                    await this.init();
                                } catch (error) {
                                    // todo
                                }
                                this.updateLocalState({ refreshButtonLock: false, loadingVisible: false });
                                // info
                                // show message
                                this.messengerModule.say({
                                    message: "text_refreshed",
                                    level: "Info",
                                }, { horizontal: "right" });
                            }}>
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<LoopIcon style={{ fontSize: 28, fontWeight: 'bold' }} />} >
                                {this.state.mode[3].i18nString}
                            </Button>
                        </div>
                        {/* 设置 */}
                        <div style={{ minHeight: 79, border: 'none', width: "100%" }} className={this.state.modeSelected === 'setting' ? 'selectedMode' : 'mode'}
                            onClick={(event) => {
                                if (this.state.modeSelected === 'setting') return;
                                // if (document.getElementsByClassName('selectedMode')[0]) {
                                //     let className = document.getElementsByClassName("selectedMode")[0].className;
                                //     let newClassName = className.replace('selectedMode', 'mode')
                                //     document.getElementsByClassName("selectedMode")[0].setAttribute('class', newClassName)
                                // }
                                // let className = event.currentTarget.className;
                                // let newClassName = className.replace('mode', 'selectedMode');
                                // event.currentTarget.setAttribute('class', newClassName);
                                this.updateLocalState({ modeSelected: 'setting' })
                                createHashHistory().push('/SettingVertify')
                            }} >
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<SettingsIcon style={{ fontSize: 28, fontWeight: 'bold' }} />}>
                                {this.state.mode[4].i18nString}
                            </Button>
                        </div>
                        {/* 返回 */}
                        <div style={{ minHeight: 79, border: 'none', width: "100%" }} className={this.state.modeSelected === 'come_back' ? 'selectedMode' : 'mode'}
                            onClick={(event) => {
                                if (this.state.modeSelected === 'come_back') return;
                                // if (document.getElementsByClassName('selectedMode')[0]) {
                                //     let className = document.getElementsByClassName("selectedMode")[0].className;
                                //     let newClassName = className.replace('selectedMode', 'mode')
                                //     document.getElementsByClassName("selectedMode")[0].setAttribute('class', newClassName)
                                // }
                                // let className = event.currentTarget.className;
                                // let newClassName = className.replace('mode', 'selectedMode');
                                // event.currentTarget.setAttribute('class', newClassName);
                                this.updateLocalState({ modeSelected: 'come_back' })
                                createHashHistory().go(-1)
                            }}>
                            <Button variant="outlined"
                                style={{ minHeight: 79, border: 'none', width: "100%", display: "flex", justifyContent: "flex-start" }}
                                startIcon={<KeyboardArrowLeftIcon style={{ fontSize: 28, fontWeight: 'bold' }} />} >
                                {this.state.mode[5].i18nString}
                            </Button>
                        </div>
                    </div>

                </div>
                {/* 点击空白地方取消卡片选中状态 */}
                <div 
                    className={this.state.unfoldShrink ? "showRight" : "hideRight"} 
                    onClick={(e) => {
                        // 把全部卡片的选中状态撤销
                        this.unSelectAllCard();
                        // for (let i of Object.values(this.refs)) {
                        //     (i as CardComponent).setState({ select: false })
                        // }
                        this.updateLocalState({
                            selectedCard: [], //点击卡片外的地方，清空已选择卡片
                            selectedCardIndex: {},
                            selectSummaryDishNames:[],
                            selectSummaryTakeawayOrder: []
                        });
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            paddingTop: 25,
                            height: '92vh',
                            overflow: 'hidden'
                        }}
                    >
                        <div style={{
                            display: "flex",
                            // marginRight: 20,
                            justifyContent: "space-between"
                        }}>

                        {/* 收缩左侧边栏状态下的顶部区域 */}
                            <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                {
                                    !this.state.unfoldShrink ? 
                                    <div style={{ marginRight: 10 }} className={this.state.unfoldShrink ? 'btnhide' : "btnshow"}>
                                        <Fab 
                                            style={{ width: 56, height: 56 }}
                                            color="secondary"
                                            aria-label="edit"
                                            onClick={(e) => { 
                                                e.stopPropagation();
                                                this.unfoldShrinkChange();
                                            }}
                                        >
                                            <MenuIcon style={{ fontSize: 30, fontWeight: 'bold', color: '#fff' }} />
                                        </Fab>
                                    </div> : null
                                }
                                {/* 菜品制作状态按钮 */}
                                {
                                    this.state.filterButton.map((element: FilterButton, index: number) => {
                                        // 判断当前是否为更新菜品状态,不是禁用按钮，或者是自身，则在选择了菜品卡片的时候，为可更新菜品状态
                                        let hasDishChangeMake = (!this.disablefilterButton(element) || this.state.filterValue === index) && this.state.selectedCard.length > 0
                                        return (
                                            <div
                                                style={{
                                                    marginBottom: 10,
                                                    marginRight: 10
                                                }} 
                                                key={'chef-filter-badge-' + index}>
                                                <Badge
                                                    color="secondary"
                                                    badgeContent={this.state.filterButtonBadgeValue[index]}
                                                >
                                                    <button
                                                        id={`${element.value}`}
                                                        style={{
                                                            minWidth: 170,
                                                            height: 52,
                                                            borderRadius: 30,
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                            padding: '0 20px',
                                                            color: this.disablefilterButton(element) ? '#999' : '',
                                                            // backgroundColor: '#170503',
                                                        }}
                                                        disabled={this.state.loadingVisible || this.disablefilterButton(element)}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            // 点击当前自己状态不做处理
                                                            if (this.state.filterValue === index) return

                                                            // 开启了确认框设置，并且有选中卡片
                                                            if (StateManager.get('confirmWindow') && (this.state.selectedCard.length > 0 || this.state.cardDialogVisible)) {
                                                                this.toUpdateStatusIndex = index
                                                                this.updateLocalState({
                                                                    updteCardDialogVisible: true
                                                                });
                                                            } else {
                                                                // 开启了原因选择功能，并且是更新到取消制作的时候
                                                                if (StateManager.get('addReasonInCancelFunction') && this.state.filterButton[index].status === 5 && (this.state.selectedCard.length > 0 || this.state.cardDialogVisible)) {
                                                                    this.toUpdateStatusIndex = index
                                                                    this.updateLocalState({
                                                                        reasonDialogVisible: true
                                                                    });
                                                                } else this.clickTab(index, this.state.filterButton[index].status)
                                                            }
                                                            // 没有选中卡片，就是切换按钮，那么需要把样式更改
                                                            if (this.state.selectedCard.length === 0) {
                                                                //获取选中的节点
                                                                // const SelectedTab = document.getElementsByClassName('SelectedTab')[0] as any
                                                                // //恢复默认样式
                                                                // let borderColor = this.getBorderColor();
                                                                // SelectedTab.style.borderColor = borderColor.selectBorderColorDefault
                                                                // for (let i = 0; i < this.state.filterButton.length - 1; i++) {
                                                                //     //获取未选中的节点
                                                                //     const tabs = document.getElementsByClassName('Tabs')[i] as any
                                                                //     //恢复
                                                                //     tabs.style.borderColor = borderColor.borderColorDefault
                                                                // }
                                                                // 取消制作不需要简略模式
                                                                if(element.value === 'cancelled'){
                                                                    this.updateLocalState({
                                                                        isSimpleMode: false
                                                                    })
                                                                }
                                                                // 如果是服务员，并且开启了磁力链模式，切换到待上菜页面的时候，若当前是普通模式，则自动切换为订单模式
                                                                if(this.props.role === 'waiter' && this.magneticLinkMode && element.value === 'kitchen' && this.state.modeSelected === 'normal_mode') {
                                                                    this.changeModeStatus('order_mode')
                                                                }
                                                            }
                                                            // // 打印
                                                            // if (this.state.filterButton[index].status === 1 && this.props.role !== 'waiter') {
                                                            //     let selectedCard: any = []
                                                            //     this.state.selectedCard.forEach(target => {
                                                            //         selectedCard = selectedCard.concat(target)
                                                            //     })
                                                            //     this.printTip(selectedCard, true)
                                                            // }
                                                            // this.clickTab(index, element.status)
                                                        }}
                                                        className={[
                                                            index === this.state.filterValue ? 'SelectedTab' : 'Tabs',
                                                            hasDishChangeMake ? 'hasDishChangeMake' : ''
                                                        ].join(' ')}
                                                    >
                                                        <span className="filterButtonText">{element.name}</span>
                                                        {/* 非当前选中的按钮，并且有选中卡片，则出现此图片 */}
                                                        {
                                                            index !== this.state.filterValue && hasDishChangeMake ?
                                                            <Fade in={hasDishChangeMake} timeout={400}>
                                                                <RedoIcon className="filterButtonIcon" />
                                                            </Fade>
                                                            : null
                                                        }
                                                    </button>
                                                </Badge>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {/* 打印按钮和总单模式开关 */}
                            <div style={{display:'inline-flex',alignItems:'flex-start'}}>
                                {/* 打印  服务员 -- (订单模式 | 外卖模式)-- 待上菜页面 */}
                                {
                                    this.state.printASmallTicket && 
                                    (
                                        this.props.role === 'waiter' && 
                                        this.state.filterButtonSelected === 'kitchen' &&
                                        (this.state.modeSelected === 'order_mode' || this.state.modeSelected === 'takeaway_mode') 
                                    ) ?
                                    <div style={{ width: 56, height: 56 }}>

                                        <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit"
                                            onClick={_.debounce((e) => {
                                                e.stopPropagation();
                                                let selectedCard: any = [];
                                                this.state.selectedCard.forEach(target => {
                                                    selectedCard = selectedCard.concat(target)
                                                })
                                                this.printTip(selectedCard, true)
                                            }, 500)}
                                            disabled={this.state.selectedCard.length === 0}
                                        >
                                            <PrintIcon />
                                        </Fab>
                                    </div> : null
                                }
                                {
                                    this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen' && (this.state.modeSelected == 'order_mode' || this.state.modeSelected === 'takeaway_mode') ? 
                                    <FormControlLabel
                                        className={'totalOrderModeSwitch'}
                                        checked={this.state.totalOrderMode}
                                        control={<SwitchComponent />}
                                        label={i18nService.t("total_order_mode")}
                                        labelPlacement="top"
                                        onChange={(event: React.ChangeEvent<{}>, checked: boolean)=>{
                                            GlobalState.dispatch({
                                                type: "SET_BY_PATH",
                                                path: 'totalOrderMode',
                                                value: checked
                                            })
                                            this.clickTab(this.state.filterValue, this.state.filterButton[this.state.filterValue].status, true);
                                        }}
                                    /> : null
                                }
                            </div>
                        </div>
                        {/* 桌台或订单类型按钮区域 */}
                        <div style={this.state.modeSelected === 'normal_mode' ? { display: 'block' } : { display: 'none' }}>
                            <TablesClassifyComponent
                                self={(ele: any) => { this.tableListRef = ele }}
                                onClick={(deskNum: any) => {
                                    // $ 0: init
                                    // //清除已选样式
                                    // if (document.getElementsByClassName('selectedDeskButton')) document.getElementsByClassName('selectedDeskButton')[0].setAttribute('class', "deskButton");
                                    // //添加已选样式
                                    // document.getElementsByClassName('deskButton')[this.state.deskList.findIndex((ele: any) => `${ele}` === `${deskNum}`)].setAttribute('class', 'selectedDeskButton');
                                    // $ 1: update
                                    this.updateLocalState({
                                        currentDesk: deskNum,
                                        dishesData: this.state.dishesData.update(this.state.filterButtonSelected, this.props.role, undefined, undefined, deskNum),
                                        // cardData: this.state.dishesData.BoxGroup,
                                        cardData: (this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') || (this.state.filterButtonSelected === "prepareDish") ?
                                            this.state.dishesData.BoxGroup.sort((a: CardBox, b: CardBox) => {
                                                // $ 在不参与合并的情况下，菜品按照权重值排序(只影响已下单页面)
                                                // 已备菜页面也按照权重值排序，但不受轮次权重影响
                                                let pre = a.cardInfo.boxData[0].dishWeights
                                                let nex = b.cardInfo.boxData[0].dishWeights
                                                return nex - pre
                                            }) : this.state.dishesData.BoxGroup, 
                                        selectedCard: [], //切换桌台时清空已选择卡片
                                        selectedCardIndex: {}
                                    });
                                    if (this.state.intelligentMode === true) {
                                        let { dishesData, cardData, lastMergeArr } = this.intelligentMerge(this.state.dishesData.BoxGroup, StateManager.get("smartMergerAutoStart"), this.state.dishesData.update(this.state.filterButtonSelected, this.props.role, undefined, undefined, deskNum), true);
                                        this.updateLocalState({
                                            dishesData, 
                                            cardData,
                                            lastMergeArr,
                                            selectedCard: [], //触发合并时清空已选择卡片
                                            selectedCardIndex: {}
                                        });
                                    }
                                }}
                                TablesBubble={this.state.dishesdDeskBadgeValue || []}
                                TablesList={this.state.deskList || ["All"]}
                            ></TablesClassifyComponent>
                        </div>
                        <div style={(this.state.modeSelected === 'order_mode' || this.state.modeSelected === 'takeaway_mode') ? { display: 'block' } : { display: 'none' }}>
                            <TablesClassifyComponent
                                self={(ele: any) => { this.eayTypeRef = ele }}
                                onClick={(element: any) => {
                                    let cardProcessor: CardProcessor = this.state.dishesData.update(this.state.filterButtonSelected, this.props.role, undefined, undefined, 'All', element)
                                    // console.log('element',element,cardProcessor)
                                    this.updateLocalState({
                                        currentEatType: element,
                                        dishesData: cardProcessor,
                                        cardData: this.state.dishesData.BoxGroup,
                                        selectedCard: [], //切换桌台时清空已选择卡片
                                        selectedCardIndex: {}
                                    });
                                    // 汇总菜品名称
                                    let summaryDishNames:Array<SummaryDishNames> = [];
                                    // 外卖单汇总
                                    let summaryTakeawayOrder:Array<SummaryTakeawayOrder> = [];
                                    let dishesSummarySidebar = StateManager.get('dishesSummarySidebar');
                                    let displayTakeawayStatisticsSidebar = StateManager.get('displayTakeawayStatisticsSidebar');
                                    let displayDishesSidebar = StateManager.get('displayDishesSidebar');
                                    (cardProcessor.BoxGroup || []).forEach(boxDish =>{
                                        (_.get(boxDish,['cardInfo','boxData']) || []).forEach(dish => {
                                            if (displayDishesSidebar) {
                                                let index:number
                                                if(dishesSummarySidebar === '1'){
                                                    // 菜品名称1
                                                    index = summaryDishNames.findIndex((dishName: any) => dish.item_name1 === dishName.item_name1)
                                                }else {
                                                    // 菜品名称2
                                                    index = summaryDishNames.findIndex((dishName: any) => dish.item_name2 === dishName.item_name2)
                                                }
                                                if(index === -1) summaryDishNames.push({item_name1:dish.item_name1,item_name2:dish.item_name2})
                                            }
                                        
                                            // 如果显示外卖单汇总侧边栏，并且非堂吃，则统计外卖单
                                            if(displayTakeawayStatisticsSidebar && dish.eat_type !== 0) {
                                                let findIndex = summaryTakeawayOrder.findIndex((takeaway: SummaryTakeawayOrder) => {
                                                    return `${takeaway.check_number}` === `${dish.check_number}` &&
                                                        `${takeaway.eat_type}` === `${dish.eat_type}` && takeaway.customer_name === this.getCustomerName(dish) && `${takeaway.kds_time}` === `${dish.kds_time}`
                                                })
                                                if(findIndex === -1) summaryTakeawayOrder.push({
                                                    check_number: dish.check_number,
                                                    eat_type: dish.eat_type,
                                                    customer_name: this.getCustomerName(dish),
                                                    kds_time: dish.kds_time
                                                })
                                            }
                                        });
                                    });
                                    // 对外卖单汇总进行排序
                                    summaryTakeawayOrder = summaryTakeawayOrder.sort((a,b) => {
                                        let aTime = a.kds_time ?  new Date(a.kds_time).getTime() : 0;
                                        let bTime = b.kds_time ?  new Date(b.kds_time).getTime() : 0;
                                        return aTime - bTime;
                                    });
                                    let { dishesData, cardData } = this.intelligentMergeOrderMode(this.state.dishesData.BoxGroup, undefined, cardProcessor);
                                    let eatTypeValue = Object.keys(this.state.eatTypeValue)
                                    if(Array.isArray(eatTypeValue)){
                                        for (const eatType of eatTypeValue) {
                                            // 当前点击的元素非堂吃，则对卡片经行排序
                                            if(`${eatType}` !== '0' && this.state.eatTypeValue[eatType] === element) {
                                                cardData.sort((a,b)=> {
                                                    // 因为kdsTime是有时分，因此需要默认给一个年月日格式，另外餐馆不会存在第二天的菜品，所以可以直接使用同一个日期
                                                    let aTime = a.cardInfo && a.cardInfo.kdsTime ? new Date(`1990-01-01 ${a.cardInfo.kdsTime}`).getTime() : 0;
                                                    let bTime = b.cardInfo && b.cardInfo.kdsTime ? new Date(`1990-01-01 ${b.cardInfo.kdsTime}`).getTime() : 0;
                                                    return aTime - bTime
                                                });
                                                break;
                                            }
                                        }
                                    }
                                    this.updateLocalState({
                                        dishesData, cardData,
                                        lastMergeArr: [], //模式切换需要重置最后合并数组
                                        selectedCard: [], //触发合并时清空已选择卡片
                                        selectedCardIndex: {},
                                        summaryDishNames,
                                        summaryTakeawayOrder
                                    });
                                    // if (this.state.intelligentMode === true) {
                                    //     let { dishesData, cardData, lastMergeArr } = this.intelligentMerge(this.state.dishesData.BoxGroup, StateManager.get("smartMergerAutoStart"), this.state.dishesData.update(undefined, undefined, deskNum), true);
                                    //     this.updateLocalState({
                                    //         dishesData, cardData, lastMergeArr,
                                    //         selectedCard: [] //触发合并时清空已选择卡片
                                    //     });
                                    // }
                                }}
                                TablesBubble={this.state.dishesEatTypeBadgeValue || []}
                                TablesList={this.state.orderModeDeskList || ["All"]}
                            ></TablesClassifyComponent>
                        </div>
                        {/* 改变了选择的桌台，选择的菜品状态，合并状态，模式才会让容器刷新，否则不刷新容器，让滚动的位置保留. */}
                        {/* 卡片区域 */}
                        <div 
                            ref="cardTotalShowArea"
                            key={
                                'chef-cardContainer-' + this.state.currentDesk + this.state.currentEatType + this.state.modeSelected +
                                this.state.intelligentMode + this.state.isSimpleMode  + this.state.page + this.state.filterButtonSelected
                            }
                            className='cardContainer'
                        >
                            {
                                this.state.deskList.length > 1 ?
                                    (this.state.cardData || []).map(
                                        (ele: CardBox, index: number) => {
                                            // 出现右侧汇总侧边栏,并且有选择汇总菜品名的时候才需要过滤菜品
                                            let newEle = _.cloneDeep(ele);
                                            // 普通模式下并且不显示外卖订单，或者订单模式显示右侧边栏的情况下
                                            let displayTakeawayStatisticsSidebar = StateManager.get('displayTakeawayStatisticsSidebar');
                                            if (
                                                (!this.state.displayTakeawayOrder && this.state.modeSelected === "normal_mode") ||
                                                ((this.getShowDishesSidebarStatus() || displayTakeawayStatisticsSidebar) && (this.state.selectSummaryDishNames.length > 0 ||
                                                     this.state.selectSummaryTakeawayOrder.length > 0))
                                            ) {
                                                // 菜品汇总侧边栏选择现显示的名称
                                                let dishesSummarySidebar = StateManager.get('dishesSummarySidebar');
                                                // 是否显示菜品汇总侧边栏
                                                let displayDishesSidebar = StateManager.get('displayDishesSidebar');
                                                let cardDoxData = _.get(newEle, 'cardInfo.boxData') || [];
                                                let filterCardDoxData = [];
                                                if (Array.isArray(cardDoxData) && cardDoxData.length > 0) {
                                                    filterCardDoxData = cardDoxData.filter(target => {
                                                        // 如果是普通模式，并且不显示外卖单，则需要过滤外卖单数据
                                                        let displayTakeaway = true;
                                                        // 如果普通模式下不显示外卖单, 返回eat_type === 0的数据
                                                        if(!this.state.displayTakeawayOrder && this.state.modeSelected === "normal_mode"){
                                                            if(target.eat_type !== 0) displayTakeaway = false;
                                                            return displayTakeaway;
                                                        }
                                                        let index: number = -1;
                                                        // 如果显示菜品汇总侧边栏, 返回对应选择的菜品名称的数据
                                                        if(displayDishesSidebar) {
                                                            if(dishesSummarySidebar === '1'){
                                                                // 菜品名称1
                                                                index = this.state.selectSummaryDishNames.findIndex((dishName: any) => target.item_name1 === dishName.item_name1)
                                                            }else {
                                                                // 菜品名称2
                                                                index = this.state.selectSummaryDishNames.findIndex((dishName: any) => target.item_name2 === dishName.item_name2)   
                                                            }
                                                        }
                                                        // 如果开启外卖单汇总模式则返回外卖订单信息的数据
                                                        let takeawayIndex: number = -1;
                                                        if(displayTakeawayStatisticsSidebar) {
                                                            takeawayIndex = this.state.selectSummaryTakeawayOrder.findIndex((takeaway: SummaryTakeawayOrder) => {
                                                                return `${takeaway.check_number}` === `${target.check_number}` &&
                                                                    `${takeaway.eat_type}` === `${target.eat_type}` && takeaway.customer_name === this.getCustomerName(target) && `${takeaway.kds_time}` === `${target.kds_time}`
                                                            })
                                                        }
                                                        // 都找不到则过滤
                                                        if (index === -1 && takeawayIndex === -1) return false;
                                                        else return true;
                                                    });
                                                    // 如何有过滤出来的数据,则使用过滤的数据,否则不需要显示当前卡片,可以直接返回null
                                                    if (Array.isArray(filterCardDoxData) && filterCardDoxData.length > 0) _.set(newEle, 'cardInfo.boxData', filterCardDoxData)
                                                    else return null
                                                }
                                            }
                                            return (
                                                <CardComponent
                                                    key={
                                                        // $ prefix
                                                        "chef-card-" +
                                                        // $ using by settings
                                                        StateManager.get('cardWidth') + "-" +
                                                        this.state.name1FontSize + "-" +
                                                        this.state.name2FontSize + "-" +
                                                        this.state.displayName1 + "-" +
                                                        this.state.displayName2 + "-" +
                                                        // $ diff
                                                        this.state.filterButtonSelected + "-" +
                                                        this.state.currentDesk + "-" +
                                                        // $ using by mapping
                                                        index + "-" +
                                                        newEle.cardInfo.uuid
                                                    }
                                                    isHideCardMask={this.judgeHideCardMask(newEle)}
                                                    isShowTotalOrder={isShowTotalOrder}
                                                    name1FontSize={this.state.name1FontSize}
                                                    name2FontSize={this.state.name2FontSize}
                                                    cardWidth={this.state.cardActualWidth}
                                                    magneticLinkMode={this.props.role === 'waiter' && this.magneticLinkMode && this.state.filterButtonSelected === 'kitchen'}
                                                    modeSelected={this.state.modeSelected}
                                                    section={this.state.filterButtonSelected}
                                                    ref={"card" + index}
                                                    clickUnableToMake={this.clickUnableToMake}
                                                    clickSuspended={this.clickSuspended}
                                                    isSimpleMode={this.state.isSimpleMode}
                                                    selectSummaryDishNames={this.getShowDishesSidebarStatus() ? this.state.selectSummaryDishNames : []}
                                                    doubleClickCard={async (boxData: dish[]) => {
                                                        try {
                                                            // loading状态下，不允许双击操作
                                                            if(this.state.loadingVisible) return;
                                                            window.clearTimeout(this.updateRefreshTimeId);
                                                            // 厨师界面下，不是未制作和已备菜的状态下，不能双击
                                                            if (this.props.role === 'chef' && !(this.state.filterButtonSelected === 'kitchen' || this.state.filterButtonSelected === 'prepareDish')) return
                                                            // 服务员界面下，不是未上菜的状态下，不能双击
                                                            if (this.props.role === 'waiter' && !(this.state.filterButtonSelected === 'kitchen')) return
                                                            let status = 0;
                                                            // 厨师界面下，开启了备菜模式，并且是未制作情况下，双击为备菜
                                                            // if (this.props.role === 'chef' && StateManager.get('prepareDishFunction') && this.state.filterButtonSelected === 'kitchen') status = 11
                                                            // 开启了厨师控菜，或者是服务员界面下的未上菜状态，双击更新为2，其他为1.
                                                            if (StateManager.get('chefControlFunction') || (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen')) status = 2
                                                            else status = 1
                                                            if (this.props.role !== 'waiter' && (status === 1 || status === 2)) this.printTip(newEle.cardInfo.boxData, false, 'double');
                                                            // 服务员更新到已上菜,则打印
                                                            else if (this.props.role === 'waiter' && status === 2) this.printTip(newEle.cardInfo.boxData, false, 'double');
                                                            await this.updateDishesInfo(boxData, status, 'double')
                                                            this.updateRefreshTimeId = window.setTimeout(()=>{
                                                                this.refreshDataDifferences(true)
                                                            }, 300)
                                                        } catch (error) {
                                                            // 如果出错了，则恢复周期性刷新数据功能
                                                            this.refreshDataDifferences()
                                                            this.messengerModule.say({
                                                                ...ERROR_0022,
                                                                details: {
                                                                    ...error,
                                                                    caller: "common.page.tsx => CardComponent => doubleClickCard()",
                                                                }
                                                            }, { horizontal: "right" });
                                                        }
                                                    }}
                                                    cardBox={newEle}
                                                    selectData={this.state.selectedCard && this.state.selectedCardIndex[newEle.cardInfo.uuid] !== undefined && this.state.selectedCard[this.state.selectedCardIndex[newEle.cardInfo.uuid]] ? this.state.selectedCard[this.state.selectedCardIndex[newEle.cardInfo.uuid]] : []}
                                                    clickButton={() => {
                                                        // 隐藏所有卡片的操作按钮
                                                        Object.keys(this.refs).forEach((target) => {
                                                            if (/^card\d*$/g.test(target)) {
                                                                (this.refs[target] as CardComponent).hideCardButton()
                                                            }
                                                        })
                                                    }}
                                                    clickQuantity={(
                                                        event: any, 
                                                        dish: dish,
                                                        packageDish?: {
                                                            belongedPackage: dish;
                                                        }
                                                    ) => {
                                                        let arr = this.state.selectedCard ? _.cloneDeep(this.state.selectedCard) : []
                                                        let selectedCardIndexObj = this.state.selectedCardIndex ? _.cloneDeep(this.state.selectedCardIndex) : {}
                                                        let cardIndex = selectedCardIndexObj[newEle.cardInfo.uuid]
                                                        // 看看是否已经存储好选择卡片的index
                                                        if (cardIndex !== undefined && cardIndex !== null) {
                                                            // 若已存储，则需要判断是否存储当前选择的子菜品
                                                            // 判断是否点的是同一个套餐里的菜品
                                                            if (packageDish) {
                                                                const { belongedPackage } = packageDish;
                                                                const exitSelectedPackage = arr[cardIndex].find(item => item.order_detail_id === belongedPackage.order_detail_id);
                                                                // 同一个套餐, 看是否存在已点的菜品
                                                                if (exitSelectedPackage) {
                                                                    const { course_groupArray = [] } = exitSelectedPackage;
                                                                    const index = course_groupArray.findIndex(ele => ele.order_detail_id === dish.order_detail_id);
                                                                    if (index !== -1) {
                                                                        course_groupArray.splice(index, 1);
                                                                        // 如果套餐内的菜品为空了, 就可以清除这个套餐了
                                                                        if (!course_groupArray.length) {
                                                                            // 该套餐的索引
                                                                            const packageIndex = arr[cardIndex].findIndex(item => item.order_detail_id === exitSelectedPackage.order_detail_id);
                                                                            arr[cardIndex].splice(packageIndex, 1);
                                                                            // 是否需要清空
                                                                            let needClear = true;
                                                                            for (const item of arr) {
                                                                                if (item.length > 0) {
                                                                                    needClear = false;
                                                                                    break;
                                                                                }
                                                                            }
                                                                            // 循环一遍发现全都是空数组,则把全部空数组清掉
                                                                            if (needClear) {
                                                                                arr = [];
                                                                                selectedCardIndexObj = {}
                                                                            }
                                                                        }
                                                                    } else {
                                                                        course_groupArray.push(dish);
                                                                    }
                                                                } else {
                                                                    belongedPackage.course_groupArray = [dish];
                                                                    // 不属于同一个套餐, push该套餐和套餐内的菜品
                                                                    arr[cardIndex].push(belongedPackage);
                                                                }
                                                            } else {
                                                                // 如果点的是整个套餐
                                                                let index = arr[cardIndex].findIndex((item: dish) => {
                                                                    return dish.item_type === 3 ? item.order_detail_id === dish.order_detail_id && (item.course_groupArray || []).length === (dish.course_groupArray || []).length : item.order_detail_id === dish.order_detail_id
                                                                });
                                                                if (index !== -1) {
                                                                    arr[cardIndex].splice(index, 1);
                                                                    // [[{dish}, []]] 是否需要清空arr中没有菜品的空数组, 只有里面有一个长度不是0的都不用全部清空
                                                                    let needClear = true;
                                                                    for (const item of arr) {
                                                                        if (item.length > 0) {
                                                                            needClear = false;
                                                                            break;
                                                                        }
                                                                    }
                                                                    // 循环一遍发现全都是空数组,则把全部空数组清掉
                                                                    if (needClear) {
                                                                        arr = [];
                                                                        selectedCardIndexObj = {}
                                                                    }
                                                                }
                                                                // 如果点的是同一个卡片里不同的菜品就push进对应的位置
                                                                else {
                                                                    if (dish.item_type === 3) {
                                                                        // 点击套餐的索引
                                                                        const packageIndex = arr[cardIndex].findIndex(item => item.order_detail_id === dish.order_detail_id)
                                                                        if (packageIndex !== -1) {
                                                                            arr[cardIndex].splice(packageIndex, 1, dish);
                                                                        } else {
                                                                            // 找不到就push新的套餐
                                                                            arr[cardIndex].push(dish);
                                                                        }
                                                                    } else {
                                                                        arr[cardIndex].push(dish);
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            // 没有存储过，直接push
                                                            const packageDishes: dish[] = [];
                                                            if (packageDish) {
                                                                const { belongedPackage } = packageDish;
                                                                belongedPackage.course_groupArray = [dish];
                                                                arr.push([belongedPackage])
                                                            } else {
                                                                arr.push([dish]);
                                                            }
                                                            // save the boxData index 
                                                            selectedCardIndexObj[newEle.cardInfo.uuid] = arr.length - 1;
                                                        }
                                                        this.updateLocalState({
                                                            selectedCard: arr,
                                                            selectedCardIndex: selectedCardIndexObj
                                                        });
                                                        if (this.state.turnOnTheTimer === false && arr.length !== 0) {
                                                            this.updateLocalState({ turnOnTheTimer: true });
                                                            // this.flashingButton();
                                                        }
                                                    }}
                                                    clickCard={(boxData: any) => {
                                                        let arr = this.state.selectedCard ? _.cloneDeep(this.state.selectedCard) : [];
                                                        let selectedCardIndexObj = this.state.selectedCardIndex ? _.cloneDeep(this.state.selectedCardIndex) : {};
                                                        let cardIndex = selectedCardIndexObj[newEle.cardInfo.uuid];
                                                        // let index = arr.findIndex((ele) => {
                                                        //     return _.isEqual(ele, boxData)
                                                        // })
                                                        // 看看是否已经存储好选择卡片的index
                                                        if (cardIndex !== undefined && cardIndex !== null) {
                                                            // 判断当前是否全选了数据
                                                            let allData = true;
                                                            for (const item of boxData) {
                                                                let index = arr[cardIndex].findIndex((target: any) => {
                                                                    return target.order_detail_id === item.order_detail_id
                                                                })
                                                                if (index === -1) {
                                                                    allData = false;
                                                                    break;
                                                                }
                                                            }
                                                            // 全选了数据
                                                            if (allData) {
                                                                // arr.splice(index, 1);
                                                                // 因为删除之后会把index改变，所以使用空数组替换
                                                                arr[cardIndex] = [];
                                                                // if(cardIndex !== undefined && cardIndex !== null) delete selectedCardIndexObj[ele.cardInfo.uuid]
                                                                let needClear = true;
                                                                for (const item of arr) {
                                                                    if (item.length > 0) {
                                                                        needClear = false;
                                                                        break;
                                                                    }
                                                                }
                                                                // 循环一遍发现全都是空数组,则把全部空数组清掉
                                                                if (needClear) {
                                                                    arr = [];
                                                                    selectedCardIndexObj = {}
                                                                }
                                                            }
                                                            // 此时是只选择了部分菜品，当点击了卡片，则直接使用卡片的全部boxData
                                                            else arr[cardIndex] = boxData;
                                                        } else {
                                                            // 看看是否已经存储好选择卡片的index
                                                            // if (cardIndex !== undefined && cardIndex !== null) {
                                                            // } else {
                                                            arr.push(boxData);
                                                            // save the boxData index 
                                                            selectedCardIndexObj[newEle.cardInfo.uuid] = arr.length - 1;
                                                            // }
                                                        }
                                                        this.updateLocalState({
                                                            selectedCard: arr,
                                                            selectedCardIndex: selectedCardIndexObj
                                                        });
                                                        if (this.state.turnOnTheTimer === false && arr.length !== 0) {
                                                            this.updateLocalState({ turnOnTheTimer: true });
                                                            // this.flashingButton();
                                                        }
                                                    }}
                                                    cardUnmount={() => {
                                                        // 销毁卡片时候，要清空按钮的闪烁动画，并且双击销毁的时候，也需要清空已选择的卡片
                                                        clearInterval(this.flashingTimeId);
                                                        this.updateLocalState({
                                                            turnOnTheTimer: false,
                                                            selectedCard: [],
                                                            selectedCardIndex: {}
                                                        });
                                                        //获取选中的节点
                                                        const SelectedTab = document.getElementsByClassName('SelectedTab')[0] as any
                                                        //恢复默认样式
                                                        let borderColor = this.getBorderColor();
                                                        SelectedTab.style.borderColor = borderColor.selectBorderColorDefault
                                                        for (let i = 0; i < this.state.filterButton.length - 1; i++) {
                                                            //获取未选中的节点
                                                            const tabs = document.getElementsByClassName('Tabs')[i] as any
                                                            //恢复
                                                            tabs.style.borderColor = borderColor.borderColorDefault
                                                        }
                                                    }}
                                                    // $ long touch start event
                                                    longTouchCard={() => {
                                                        // try {
                                                        //     // remove all card selected style
                                                        //     this.unSelectAllCard()
                                                        //     // for (let i of Object.values(this.refs)) {
                                                        //     //     (i as CardComponent).setState({ select: false })
                                                        //     // }
                                                        //     // $ 0: init
                                                        //     // clear all single-click selected cards
                                                        //     this.updateLocalState({
                                                        //         selectedCard: [],
                                                        //         selectedCardIndex: {}
                                                        //     })
                                                        //     // for (const ele of this.state.filterButton) {
                                                        //     //     let button = document.getElementById(ele.value)!;
                                                        //     //     button.style.position = 'absoulte'
                                                        //     //     button.style.zIndex = '99999'
                                                        //     // }
                                                        //     // stop auto fresh dish timer
                                                        //     window.clearTimeout(this.state.timeoutID);
                                                        //     // set the style of filter buttons
                                                        //     for (const ele of this.state.filterButton) {
                                                        //         let button = document.getElementById(ele.value)!;
                                                        //         button.style.position = 'absoulte'
                                                        //         button.style.zIndex = '99999'
                                                        //     }
                                                        //     // $ 1: build and show card dialog
                                                        //     this.updateLocalState({
                                                        //         cardDialogDishes: ele.cardInfo.boxData,
                                                        //         cardDialogVisible: true
                                                        //     })
                                                        //     if (this.state.turnOnTheTimer === false && this.state.cardDialogVisible === true) {
                                                        //         this.updateLocalState({ turnOnTheTimer: true });
                                                        //         this.flashingButton()
                                                        //     }
                                                        // } catch (error) {
                                                        //     this.messengerModule.say({
                                                        //         ...ERROR_0021,
                                                        //         details: {
                                                        //             ...error,
                                                        //             caller: "chef.page.tsx => CardComponent => longTouchCard()",
                                                        //         }
                                                        //     }, { horizontal: "right" });
                                                        // }
                                                    }}
                                                    // $ long touch end event
                                                    longTouchEnd={() => { }}
                                                    footerRightText={this.props.role === 'chef' ? 'text_pause_production' : 'text_not_yet_made'}
                                                    // $ 传入角色参数
                                                    role={this.props.role}
                                                >
                                            </CardComponent>)
                                        }
                                    )
                                    :
                                    <div key={'chef-message-' + this.state.dishesData.BoxGroup ? this.state.dishesData.BoxGroup.length : 0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                        <p className="cardContainerText" >{i18nService.t("text_no_data")}</p>
                                    </div>
                            }
                            {/* </div> */}
                            <CardDialogComponent
                                onClose={() => {
                                    for (const ele of this.state.filterButton) {
                                        let button = document.getElementById(ele.value)!
                                        button.style.position = 'static'
                                        button.style.zIndex = '0'
                                    };
                                    // 这个是为了在dialog隐藏的时候，取消所有选择
                                    this.updateLocalState({
                                        cardDialogDishes: this.state.cardDialogDishes.map(c => {
                                            c.isCardDialogSelected = false;
                                            return c;
                                        })
                                    })
                                    this.updateLocalState({
                                        cardDialogDishes: [],
                                        cardDialogVisible: false
                                    })
                                    this.refreshDataDifferences();
                                }}
                                onSelected={(ele: dish & { isCardDialogSelected?: boolean }) => {
                                    this.updateLocalState({
                                        cardDialogDishes: this.state.cardDialogDishes.map(c => {
                                            if (ele.order_detail_id === c.order_detail_id) {
                                                // c.isCardDialogSelected = !!!ele.isCardDialogSelected;
                                                return Object.assign({}, c, { isCardDialogSelected: !!!ele.isCardDialogSelected });
                                            }
                                            else return c;
                                        })
                                    })
                                }}
                                onSelectedAll={() => this.updateLocalState({
                                    cardDialogDishes: this.state.cardDialogDishes.map(c => {
                                        // c.isCardDialogSelected = true;
                                        return Object.assign({}, c, { isCardDialogSelected: true });
                                    })
                                })}
                                onUnselectedAll={() => this.updateLocalState({
                                    cardDialogDishes: this.state.cardDialogDishes.map(c => {
                                        // c.isCardDialogSelected = false;
                                        return Object.assign({}, c, { isCardDialogSelected: false });
                                    })
                                })}
                                visible={this.state.cardDialogVisible}
                                dataArr={this.state.cardDialogDishes}
                            />
                            <DialogComponent
                                key={"card-update-dialog-" + this.state.updteCardDialogVisible}
                                visible={this.state.updteCardDialogVisible}
                                title={i18nService.t("text_confirmation_box")}
                                content={this.getSelectDishName() + i18nService.t("text_update_ismake_message") + this.state.filterButton[this.toUpdateStatusIndex].name}
                                cancelButtonText={i18nService.t("text_no")}
                                confirmButtonText={i18nService.t("text_yes")}
                                onCancelFn={(e: any) => {
                                    e.stopPropagation();
                                    this.setState({
                                        updteCardDialogVisible: false
                                    });
                                }}
                                checkboxText={i18nService.t("text_do_not_show_again")}
                                checkBoxSettingValue="confirmWindow"
                                onConfirmFn={(e: any) => {
                                    e.stopPropagation();
                                    // 开启了原因选择功能，并且是更新到取消制作的时候
                                    this.setState({ updteCardDialogVisible: false });
                                    if (StateManager.get('addReasonInCancelFunction') && this.state.filterButton[this.toUpdateStatusIndex].status === 5) {
                                        this.setState({ reasonDialogVisible: true });
                                    } else {
                                        this.clickTab(this.toUpdateStatusIndex, this.state.filterButton[this.toUpdateStatusIndex].status)
                                    }
                                }}
                            />
                            <DialogComponent
                                key={"rush-dish-dialog-" + this.state.rushDishDialogVisible}
                                visible={this.state.rushDishDialogVisible}
                                title={i18nService.t("text_confirmation_box")}
                                content={i18nService.t("text_whether_rush_dish")}
                                cancelButtonText={i18nService.t("text_no")}
                                confirmButtonText={i18nService.t("text_yes")}
                                onCancelFn={(e: any) => {
                                    e.stopPropagation();
                                    this.setState({
                                        rushDishDialogVisible: false
                                    });
                                }}
                                checkboxText={i18nService.t("text_do_not_show_again")}
                                checkBoxSettingValue="rushDishesconfirmWindow"
                                onConfirmFn={(e: any) => {
                                    e.stopPropagation();
                                    this.setState({ rushDishDialogVisible: false });
                                    this.clickRushTab();
                                }}
                            />
                            <DialogComponent
                                key={"reason-dialog-" + this.state.reasonDialogVisible}
                                visible={this.state.reasonDialogVisible}
                                title={i18nService.t("text_cancel_production_reason")}
                                content={i18nService.t("text_select_one_cancel_production_reason")}
                                contentFunction={() => {
                                    return (
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="position" name="position" value={this.state.selectReasonValue} onChange={(event) => {
                                                this.setState({
                                                    selectReasonValue: event.target.value
                                                })
                                            }}>
                                                {this.state.reasonData.map(target => {
                                                    return (<FormControlLabel
                                                        key={target.reason_id}
                                                        value={target.reason_name}
                                                        control={<Radio color="primary" />}
                                                        label={target.reason_name}
                                                        labelPlacement="end"
                                                    />)
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    )
                                }}
                                cancelButtonText={i18nService.t("button_cancel")}
                                confirmButtonText={i18nService.t("button_ok")}
                                onCancelFn={(e: any) => {
                                    e.stopPropagation();
                                    this.setState({
                                        reasonDialogVisible: false
                                    });
                                }}
                                onConfirmFn={(e: any) => {
                                    e.stopPropagation();
                                    this.setState({ reasonDialogVisible: false });
                                    this.clickTab(this.toUpdateStatusIndex, this.state.filterButton[this.toUpdateStatusIndex].status)
                                }}
                            />
                        </div>
                        {/* 已选择菜品悬浮窗 */}
                        {/* <Fade in={selectDishItems > 0}  timeout={400}> */}
                        {
                            selectDishItems > 0 ?
                            <div
                                // className={["selectCardItem", selectDishItems > 0 ? 'fade-in': ''].join(' ')}
                                className={"selectCardItem"}
                                onClick={e => e.stopPropagation()}
                            >
                                {i18nService.t('text_you_have_selected_items', { number: selectDishItems })}
                                <IconButton style={{marginLeft: 20}} color="inherit" aria-label="delete" size="small" onClick={()=>{
                                     this.unSelectAllCard();
                                     this.updateLocalState({
                                         selectedCard: [], 
                                         selectedCardIndex: {},
                                         selectSummaryDishNames:[],
                                         selectSummaryTakeawayOrder: []
                                     });
                                }}>
                                    <CloseIcon fontSize="inherit" color="inherit" />
                                </IconButton>
                            </div>
                            : null
                        }
                        {/* </Fade> */}
                    </div>
                    {/* 底部分页 */}
                    <div className="pageWrapper" style={{
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        // backgroundColor: '#170503',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Paging
                            key={'chef-page-' + this.state.totalPage + this.state.page}
                            totalPage={this.state.totalPage}
                            onChange={(value: any) => {
                                if (!this.state.loadingVisible) {
                                    // //清除已选样式
                                    // if (document.getElementsByClassName('selectedDeskButton')) {
                                    //     document.getElementsByClassName('selectedDeskButton')[0].setAttribute('class', "deskButton")
                                    // }
                                    // //添加桌台All已选样式
                                    // document.getElementsByClassName('deskButton')[this.state.deskList.findIndex((ele: any) => ele === 'All')].setAttribute('class', 'selectedDeskButton')
                                    // console.log()
                                    this.tableListRef.resetSelectIndex()
                                    this.eayTypeRef.resetSelectIndex()
                                    // update view
                                    this.updateLocalState({
                                        page: value,
                                        lastMergeArr: [], //切换页码需要清空最后合并数组
                                        currentDesk: "All",
                                        currentEatType: "All"
                                    }, async () => {
                                        await this.refreshData(this.state.currentDesk, this.state.currentEatType, value - 1)
                                        // refresh
                                        this.refreshDataDifferences();
                                    });
                                }
                            }}
                        ></Paging>
                    </div>
                </div>

                {/* right side Bar */}
                {/* 普通模式下的右边栏 */}
                <div className="rightSidebar" style={{
                    // width: "10%",
                    // height: 79,
                    display: (this.state.modeSelected === 'normal_mode') || (this.props.role === 'waiter' && (this.state.modeSelected === 'takeaway_mode') && this.state.filterButtonSelected === 'chefkitchen')
                    // 服务员订单模式下的已上菜页面,目前只有打印一个选择,所以关闭打印的时候,不需要显示右侧边栏
                    || (this.props.role === 'waiter' && (this.state.modeSelected === 'takeaway_mode') && this.state.filterButtonSelected === 'history' && this.state.printASmallTicket) ? "flex" : "none",
                    flexDirection: "column",
                    paddingTop: 25,
                    paddingRight: 15,
                    paddingLeft: 15,
                    // background: '#38363E',
                    zIndex: 1,
                    justifyContent: StateManager.get('rightSidebarAlign'),
                    paddingBottom: StateManager.get('rightSidebarAlign') === 'flex-end' ? 66 : 0
                    // paddingRight: 30
                }}>
                    {/* 智能合并 */}
                    <div style={(this.state.modeSelected === 'normal_mode') ? { width: 56, height: 56, marginBottom: 45 } : { display: 'none' }}>
                        <Fab
                            aria-label="edit"
                            className={StateManager.get('smartMergerAutoStart') && !this.state.isSimpleMode ? "mergeAuto" : ""}
                            style={{
                                width: 56, height: 56,
                                // background: StateManager.get('smartMergerAutoStart') ? "#E4542C" : ""
                            }}
                            // color='default'
                            color="secondary"
                            id="fab"
                            // disabled={StateManager.get('smartMergerAutoStart')}
                            onClick={(event: any) => {
                                if (Array.isArray(this.state.cardData)) {
                                    // if (this.state.intelligentMode === false) {
                                    // 每次点击合并后，按钮不需要常亮，所以注释掉此代码
                                    // document.getElementById("fab")!.style.background = "#E4542C"
                                    this.updateGlobalMergeStatus({ 
                                        intelligentMode: true,
                                        isSimpleMode: false
                                    })
                                    this.updateLocalState({ 
                                        intelligentMode: true,
                                        isSimpleMode: false
                                    }, () => {
                                        // 每次执行智能合并都是需要重新合并，因此hasMerge为true
                                        let { dishesData, cardData, lastMergeArr } = this.intelligentMerge(this.state.cardData, StateManager.get("smartMergerAutoStart"), undefined, true);
                                        this.updateLocalState({ dishesData, cardData, lastMergeArr });
                                    })
                                    // }
                                    // else {
                                    //     document.getElementById("fab")!.style.background = "#660033"
                                    //     let dishesData = this.state.dishesData;
                                    //     dishesData.update()
                                    //     this.updateLocalState({ intelligentMode: false, dishesData, cardData: dishesData.BoxGroup })
                                    // }
                                }
                            }}
                        >
                            <LayersIcon style={{ color: "#fff" }} />
                        </Fab>
                    </div>
                    {/* 智能合并-简略模式 */}
                    <div style={(this.state.modeSelected === 'normal_mode') && this.state.filterButtonSelected !== 'cancelled' ? { width: 56, height: 56, marginBottom: 45 } : { display: 'none' }}>
                        <Fab
                            aria-label="edit"
                            className={StateManager.get('smartMergerAutoStart') && this.state.isSimpleMode ? "mergeAuto" : ""}
                            style={{
                                width: 56, height: 56,
                                // background: StateManager.get('smartMergerAutoStart') ? "#E4542C" : ""
                            }}
                            // color='default'
                            color="secondary"
                            id="fab"
                            // disabled={StateManager.get('smartMergerAutoStart')}
                            onClick={(event: any) => {
                                if (Array.isArray(this.state.cardData)) {
                                    // 每次点击合并后，按钮不需要常亮，所以注释掉此代码
                                    // document.getElementById("fab")!.style.background = "#E4542C"
                                    this.updateGlobalMergeStatus({ 
                                        intelligentMode: true,
                                        isSimpleMode: true
                                    })
                                    this.updateLocalState({ 
                                        intelligentMode: true, 
                                        isSimpleMode: true
                                    }, () => {
                                        // 每次执行智能合并都是需要重新合并，因此hasMerge为true
                                        let { dishesData, cardData, lastMergeArr } = this.intelligentMerge(this.state.cardData, StateManager.get("smartMergerAutoStart"), undefined, true);
                                        this.updateLocalState({ dishesData, cardData, lastMergeArr });
                                    })
                                }
                            }}
                        >
                            <ViewModuleIcon style={{ color: "#fff" }} />
                        </Fab>
                    </div>
                    {/* 解除智能合并 */}
                    <div style={(this.state.modeSelected === 'normal_mode') && !StateManager.get('smartMergerAutoStart') ? { width: 56, height: 56, marginBottom: 45 } : { display: 'none' }}>
                        <Fab
                            aria-label="edit"
                            style={{
                                width: 56, height: 56,
                                // background: "#660033"
                            }}
                            // color='default'
                            color="secondary"
                            id="fab"
                            onClick={(event: any) => {
                                if (Array.isArray(this.state.cardData)) {
                                    //打散卡片
                                    let dishesData = this.state.dishesData;
                                    dishesData.update(this.state.filterButtonSelected, this.props.role);
                                    this.updateGlobalMergeStatus({ 
                                        intelligentMode: false,
                                        isSimpleMode: false
                                    });
                                    this.updateLocalState({ 
                                        intelligentMode: false, 
                                        isSimpleMode: false, 
                                        dishesData, 
                                        // cardData: dishesData.BoxGroup, 
                                        cardData: (this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen') || (this.props.role === 'chef' && this.state.filterButtonSelected === 'kitchen') || (this.state.filterButtonSelected === "prepareDish") ?
                                            dishesData.BoxGroup.sort((a: CardBox, b: CardBox) => {
                                                // $ 在不参与合并的情况下，菜品按照权重值排序(只影响已下单页面)
                                                // 已备菜页面也按照权重值排序，但不受轮次权重影响
                                                let pre = a.cardInfo.boxData[0].dishWeights
                                                let nex = b.cardInfo.boxData[0].dishWeights
                                                return nex - pre
                                            }) : dishesData.BoxGroup, 
                                        lastMergeArr: []
                                    })
                                }
                            }}
                        >
                            <LayersClearIcon style={{ color: "#fff" }} />
                        </Fab>
                    </div>
                    {/* 催菜 */}
                    {this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen' ?
                        <div style={{ width: 56, height: 56, marginBottom: 45 }}>

                            <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // 开启了确认框设置，并且有选中卡片
                                    if (StateManager.get('rushDishesconfirmWindow') && (this.state.selectedCard.length > 0 || this.state.cardDialogVisible)) {
                                        this.updateLocalState({
                                            rushDishDialogVisible: true
                                        });
                                    } else {
                                        this.clickRushTab();
                                    }
                                }}
                                disabled={this.state.selectedCard.length === 0}
                            >
                                <ArrowUpwardIcon />
                            </Fab>
                        </div> : null
                    }
                    {/* 打印 */}
                    {this.state.printASmallTicket && (this.props.role !== 'waiter' || (this.props.role === 'waiter' && this.state.filterButtonSelected === 'kitchen' )|| (this.props.role === 'waiter' && this.state.filterButtonSelected === 'history' )) ?
                        <div style={{ width: 56, height: 56, marginBottom: 45 }}>

                            <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit"
                                onClick={_.debounce((e) => {
                                    let selectedCard: any = [];
                                    this.state.selectedCard.forEach(target => {
                                        selectedCard = selectedCard.concat(target)
                                    })
                                    this.printTip(selectedCard, true);
                                }, 500)}
                                disabled={this.state.selectedCard.length === 0}
                            >
                                <PrintIcon />
                            </Fab>
                        </div> : null
                    }
                    {/* 是否显示外卖单 */}
                    {
                        <div style={(this.state.modeSelected === 'normal_mode') ? { width: 56, height: 56, marginBottom: 45 } : { display: 'none' }}>
                            <Fab
                                aria-label="edit"
                                className={StateManager.get('displayTakeawayOrder') ? "mergeAuto" : ""}
                                style={{
                                    width: 56, height: 56,
                                }}
                                // color='default'
                                color="secondary"
                                id="fab"
                                // disabled={StateManager.get('smartMergerAutoStart')}
                                onClick={(event: any) => {
                                    this.updateGlobalState({
                                        type: "SET_BY_PATH",
                                        path: "displayTakeawayOrder",
                                        value: !StateManager.get('displayTakeawayOrder')
                                    });
                                }}
                            >
                                <CardGiftcardIcon style={{ color: "#fff" }} />
                            </Fab>
                        </div>
                    }
                </div>
                {/* 订单模式的右边栏 */}
                <div className="rightSidebar-orderMode" style={{
                    display: (
                        StateManager.get('displayTakeawayStatisticsSidebar') || this.getShowDishesSidebarStatus()
                        || (this.state.printASmallTicket && (this.props.role === 'waiter' && this.state.filterButtonSelected === 'history' ))
                        || (this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen')
                    ) && this.state.modeSelected === 'order_mode' ? "flex" : "none",
                    flexDirection: "column",
                    // paddingTop: 25,
                    // paddingRight: 15,
                    // paddingLeft: 15,
                    textAlign:'center',
                    // flexWrap:'wrap',
                    overflow:'hidden',
                    zIndex: 1,
                    // justifyContent: StateManager.get('displayTakeawayStatisticsSidebar') || StateManager.get('displayDishesSidebar') ? 'center' : 'flex-start',
                    width: StateManager.get('displayTakeawayStatisticsSidebar') || this.getShowDishesSidebarStatus() ? undefined : 'auto'
                    // justifyContent: StateManager.get('rightSidebarAlign'),
                    // paddingBottom: StateManager.get('rightSidebarAlign') === 'flex-end' ? 66 : 0
                }}>
                    {/* 催菜 */}
                    {this.props.role === 'waiter' && this.state.filterButtonSelected === 'chefkitchen' ?
                        <div style={{ width: 56, height: 56, marginBottom: 45, alignSelf: 'center' }}>

                            <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // 开启了确认框设置，并且有选中卡片
                                    if (StateManager.get('rushDishesconfirmWindow') && (this.state.selectedCard.length > 0 || this.state.cardDialogVisible)) {
                                        this.updateLocalState({
                                            rushDishDialogVisible: true
                                        });
                                    } else {
                                        this.clickRushTab();
                                    }
                                }}
                                disabled={this.state.selectedCard.length === 0}
                            >
                                <ArrowUpwardIcon />
                            </Fab>
                        </div> : null
                    }
                    {/* 打印 */}
                    {this.state.printASmallTicket && ((this.props.role === 'waiter' && this.state.filterButtonSelected === 'history' )) ?
                        <div style={{ width: 56, height: 56, marginBottom: 45, alignSelf: 'center' }}>

                            <Fab style={{ width: 56, height: 56 }} color="secondary" aria-label="edit"
                                onClick={_.debounce((e) => {
                                    let selectedCard: any = [];
                                    this.state.selectedCard.forEach(target => {
                                        selectedCard = selectedCard.concat(target)
                                    })
                                    this.printTip(selectedCard, true)
                                }, 500)}
                                disabled={this.state.selectedCard.length === 0}
                            >
                                <PrintIcon />
                            </Fab>
                        </div> : null
                    }
                    {/* 外卖单汇总 */}
                    <div
                        style={{
                            display: StateManager.get('displayTakeawayStatisticsSidebar') ? 'block' : 'none'
                        }}
                        className="part"
                    >
                        {
                            (this.state.summaryTakeawayOrder || []).map((takeaway: SummaryTakeawayOrder) => {
                                return (
                                    <Button 
                                        key={takeaway.check_number} 
                                        className={["takeawayBtn", this.findTakeawayInSelectSummaryTakeawayOrder(takeaway) > -1 ? 'select' : ''].join(' ')}
                                        onClick={()=>{
                                            let selectSummaryTakeawayOrder = _.cloneDeep(this.state.selectSummaryTakeawayOrder);
                                            let index = this.findTakeawayInSelectSummaryTakeawayOrder(takeaway);
                                            if(index === -1) selectSummaryTakeawayOrder.push(takeaway)
                                            else selectSummaryTakeawayOrder.splice(index,1)
                                            this.setState({
                                                selectSummaryTakeawayOrder
                                            })
                                        }}
                                    >
                                        <div>{takeaway.check_number}</div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {
                                                takeaway.eat_type === 2 ? 
                                                <AirportShuttleIcon fontSize="inherit" style={{marginRight: 2}} />:
                                                <CardTravelIcon fontSize="inherit" style={{marginRight: 2}} />
                                            }
                                            <span style={{lineHeight:0}}>
                                                {moment(takeaway.kds_time).format("kk:mm")}
                                            </span>
                                        </div>
                                        <div>{takeaway.customer_name}</div>
                                    </Button>
                                );
                            })
                        }
                    </div>
                    {/* 分割线 */}
                    <div style={{
                        display: StateManager.get('displayTakeawayStatisticsSidebar') && this.getShowDishesSidebarStatus() ? 'block' : 'none',
                        height:2,
                        margin: '5px -14px',
                        background:'#fff'
                    }}></div>
                    {/* 菜品名称汇总 */}
                    <div
                        style={{
                            display: this.getShowDishesSidebarStatus() ? 'block' : 'none'
                        }}
                        className="part"
                    >
                        {
                            (this.state.summaryDishNames || []).map((dishName, index) =>(
                            <Button 
                                key={index} 
                                className={["dishNameBtn", this.findDishNameInSelectSummaryDishNames(dishName) > -1 ? 'select' : ''].join(' ')}
                                onClick={()=>{
                                    let selectSummaryDishNames = _.cloneDeep(this.state.selectSummaryDishNames);
                                    let index = this.findDishNameInSelectSummaryDishNames(dishName);
                                    if(index === -1) selectSummaryDishNames.push(dishName)
                                    else selectSummaryDishNames.splice(index,1)
                                    this.setState({
                                        selectSummaryDishNames
                                    })
                                }}
                            >{
                                StateManager.get('dishesSummarySidebar') === '1' ? dishName.item_name1 :( _.get(dishName,'item_name2') || '(NO ITEM NAME)')
                            }</Button>
                            ))
                        }
                    </div>
                </div>
                {/* <audio ref="audio" preload={'metadata'} src={require("../assets/audio/sound.mp3")}>
                </audio> */}
                <LoadingComponent key={"loading-component-" + this.state.loadingVisible} style={{ position: "absolute", right: 15, bottom: 20, width: 56, height: 56, zIndex: 2 }} visible={this.state.loadingVisible} />
            </div >
        )
    }
}

// export default withSnackbar(CommonPage as any);
export default CommonPage;

// *********************
// Type
// *********************

type State = {
    refreshTime: Date;
    filterButtonBadgeValue: number[];
    filterValue: number;
    filterButton: Array<FilterButton>;
    filterButtonSelected: FilterButtonBadgeValue;
    loadingVisible: boolean;
    mode: Array<{ name: string, i18nString: string }>;
    modeSelected: string;
    page: number;
    totalPage: number;
    unfoldShrink: boolean;
    intelligentMode: boolean;
    check: string;
    currentDesk: number | 'All';
    currentEatType: string | 'All';
    deskList: Array<string>;
    dishesData: CardProcessor;
    dishesdDeskBadgeValue: number[];
    cardData: Array<CardBox>;
    cardDialogVisible: boolean;
    selectedCard: (dish[])[];
    selectedCardIndex: {
        [key: string]: number;
    };
    cardDialogDishes: Array<dish & { isCardDialogSelected?: boolean }>;
    timeoutID: any;
    lastMergeArr: any[];
    turnOnTheTimer: boolean;
    updteCardDialogVisible: boolean;
    rushDishDialogVisible: boolean;
    reasonData: Array<{
        reason_id: number;
        reason_name: string;
    }>
    cardTotalShowAreaWidth: number;
    cardActualWidth: number;
    cardNumber: number;
    selectReasonValue: string;
    reasonDialogVisible: boolean;
    refreshButtonLock: boolean;
    printASmallTicket: boolean;
    orderModeDeskList: Array<string>;
    dishesEatTypeBadgeValue: number[];
    eatTypeValue: {
        [key: string]: string;
    };
    getDishesRequestBody: DishesRequestBody | null;
    deliveryRangeOutsideMinKdsTime: Date | null;
    isSimpleMode: boolean;
    summaryDishNames:Array<SummaryDishNames>
    selectSummaryDishNames:Array<SummaryDishNames>
    summaryTakeawayOrder: Array<SummaryTakeawayOrder>;
    selectSummaryTakeawayOrder:Array<SummaryTakeawayOrder>;
    soundNoticeData: any[];
    totalOrderMode: boolean;
    displayTakeawayOrder: boolean;
} & Pick<
    GST.State,
    "theme" |
    "positions" |
    "waiterPositionsSelected" |
    "settingInfo" |
    "serveUrl" |
    "countStatisticsFunction" |
    "chefCardPerPage" |
    "waiterUnMakePerPageNum" |
    "displayDishInCombination" |
    "name1FontSize" |
    "name2FontSize" |
    "displayName1" |
    "displayName2"
>;

type Props = {
    role: 'chef' | 'waiter'
    filterButtonSelected: FilterButtonBadgeValue;
    enqueueSnackbar?: any;
    filterButton?: Array<FilterButton>;
    filterButtonBadgeValue?: Array<number>;
    filterValue?: number;
}
export type SummaryDishNames = {item_name1:string,item_name2:string}
type SummaryTakeawayOrder = {
    check_number: number;
    kds_time: string;
    eat_type: number;
    customer_name: string;
}
type FilterButton = { name: string, value: string, status: number };
type FilterButtonBadgeValue = 'kitchen' | 'history' | 'suspend' | 'cancelled' | 'chefkitchen' | 'prepareDish'
type DishesRequestBody = {
    position: Array<any>;
    waiter_order_position: Array<any>;
    role: "waiter" | "chef";
    section: string;
    page: number,
    perPage: number,
    sort: 'positive' | 'reverse',
    is_package: boolean,
    // handleCondiment: boolean,
    rowCount: boolean,
    tableCount: boolean,
    needShowTables: Array<any> | undefined,
    rule: {
        isPrintDevice: boolean,
        menu_id: number | string,
        turn_enable: boolean
        sort: string
    },
    serverUrl: string
}

interface TabPanelComponentProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    unfoldShrink?: boolean;
}

interface ChefComponentTabPanelProps {
    children?: React.ReactNode;
    ChefComponentIndex: any;
    ChefComponentValue: any;
}
