import React, { useState } from 'react';
import './App.css';
import BasicRoute from "./router"
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { StateManager } from "./modules/state"
import { createMuiTheme } from '@material-ui/core';
import { I18nextProvider } from 'react-i18next';
import i18nService from './i18n';
import './i18n';
import { GlobalCss } from './constants/theme'
const SummerGlobalCss = GlobalCss.summer;
const StandarGlobalCss = GlobalCss.standar;
const AtlantiGlobalCss = GlobalCss.atlanti;
const App: React.FC = () => {

    // *********************
    // Init
    // *********************

    // $ init cahche and global state
    StateManager.getStateFromCacheOrInit();

    // const [theme, setTheme] = useState(STANDARD_THEME);
    const [themeName, setThemeName] = useState(StateManager.get("theme"));
    // $ global state => local state
    // $ do one-way binding here
    StateManager.subscribe("log-in-page-update", () => {
        i18nService.changeLanguage(StateManager.get("language"));
        // 触发主题的更改
        if (StateManager.get("theme") !== themeName) {
            //     if (StateManager.get("theme") === 'atlanti') setTheme(ALTANTIS_THEME)
            //     else if (StateManager.get("theme") === 'summer') setTheme(SUMMER_THEME)
            //     else setTheme(STANDARD_THEME)
            setThemeName(StateManager.get("theme"))
        }
    });

    // $ init the theme options
    // $ forced use of dark themes so far
    const theme = createMuiTheme({
        palette: {
            type: 'dark'//StateManager.get("theme")
        }
    })
    // *********************
    // App
    // *********************

    return (
        <ThemeProvider theme={theme}>
            {themeName === 'summer' ? <SummerGlobalCss /> : themeName === 'atlanti'? <AtlantiGlobalCss />: <StandarGlobalCss />}

            <I18nextProvider i18n={i18nService}>
                <SnackbarProvider
                    maxSnack={6}
                >
                    <div className="App">
                        <header className="App-header">
                            <BasicRoute />
                        </header>
                    </div>
                </SnackbarProvider>
            </I18nextProvider>
        </ThemeProvider>
    );

}

export default App;
