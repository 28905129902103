import _ from "lodash";

/**
 * compare two states, return the report of data diffs
 * @param prvState 
 * @param nextState 
 * @param strategy 
 */
export function getStateDiffs(
    prvState: any,
    nextState: any,
    strategy: "ALL" | "FIRST" = "ALL"
): { flag: boolean; diffs: any[] } {
    try {
        // init
        let flag = true;
        let diffs: any[] = [];
        // using lodash
        _.forIn(prvState, function (value, key) {
            let isEquelFlag = _.isEqual(value, nextState[key]);
            if (isEquelFlag === false) {
                flag = false;
                if (strategy === "ALL") diffs.push(key);
                else {
                    diffs.push(key);
                    return;
                }
            }
        });
        // return
        return { flag, diffs };
    } catch (error) {
        return {
            flag: true,
            diffs: []
        };
    }
}