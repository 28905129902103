export default {
    text_language: 'Lingua',
    text_theme: 'Tema',
    text_account: "Utente",
    text_password: "Password",
    text_sign_in: 'Sign In',
    text_server_url: 'Indirizzo server',
    button_save: 'Salva',
    text_order_detail: "Dettagli dell\'ordine",
    text_table_mode: "Modalità Tavolo",
    text_quick_order: "Modalità Full_View",
    text_speed_order: "Modalità Rapida",
    text_rwa: "Smarway RWA",
    text_settings: "Impostazioni",
    text_reset: "Ripristina",
    text_confirmation_title: "Conferma",
    text_confirmation_content: "Conferma?",
    button_reset_cache_settings: "Ripristina cache e impostazioni",
    button_ok: "OK",
    button_cancel: "Annulla",
    button_no: "No",
    button_yes: "Si",
    text_customer_name: "Nome del cliente",
    text_complete: "Completa",
    text_dinein: "Dine in",
    text_hall_for_packaging: "Takeaway",
    text_outside_order: "Consegna",
    text_to_store_comeUndone: "Ritiro",
    text_food_pictures: "Immagini del piatto",
    button_sign_out: "Sign out",
    text_sign_out: "Sign out",
    text_card_limit_per_page: "Quantità comande per schermata",
    text_card_capacity_limit: "Limite quantità per SmartMerge",
    text_card_width: "Larghezza comanda",
    text_card_max_height: "Altezza comanda",
    text_smart_merge_card_height_maximum: "Limite altezza comanda in SmartMerge",
    text_rowing_print: "Stampa comanda",
    text_chef_control: "Consegna diretta",
    text_allowed_to_prepare_vegetables: "Iniz. piatto",
    text_open_quantity_statistics: "Abilita funziona statistiche",
    text_statistics_on_the_number_of_dishes_per_table: "Notifiche del tavolo",
    text_display_order_number: "Mostra ID ordine",
    text_show_dishes_from_the_promotional_package: "Mostra detagli del menù-combo",
    text_card_adaptive_height_function: "Altezza adattiva",
    text_menu_update_confirmation_box: "Conferma spostamento comande",
    text_advanced_settings: "Impostazioni avanzate",
    text_data_refresh_cycle: "Frequenza di aggiornamento dati (sec)",
    text_post: "Postazione dispositivo per Chef",
    text_printer: "Stampante",
    text_food_combination: "SmartMerge per categorie",
    button_reset: "Ripristina",
    button_unlimited: "Illimitato",
    text_server_address: "Indirizzo server",
    button_test: "Test",
    button_test_database_connection: "Connessione DB",
    text_unable_to_make: "Non disponibile",
    text_pause_production: "Sospeso",
    text_not_yet_made: "Ordinato",
    text_made: "Cucinato",
    text_not_serving: "In consegna",
    text_has_served: "Servito",
    text_paused: "In Pausa",
    text_cancelled: "Annullato",
    text_order_mode: "Ordini",
    text_did_not_give_the_waiter_no_interface_to_choose_a_post: "Nessuna postazione selezionata nella sezione 'Ordinato' del cameriere",
    text_please_enter_a_valid_username_and_password: "Inserisci un nome utente o una password valida",
    text_wrong_username_or_password: "Username o password non valida",
    text_name1_font_size: "Dimensione font nome_1",
    text_display_name1: "Visualizza nome_1",
    text_card: "Comanda",
    text_the_waiter_did_not_create_a_maximum_number_of_cards: "Quantità comande nella sezione 'Ordinato'",
    text_order_mode_card_height_max: "Limite altezza comanda in modalità Ordini",
    text_display_name2: "Visualizza nome_2",
    text_display_name2_font_size: "Dimensione font nome_2",
    text_dishes_card_notes: "Nota della comanda",
    text_card_memo_font_size: "Dimensione font note",
    text_retry_printing_the_number_of_small_votes: "Numero tentative di ristampe",
    text_the_waite_did_not_make_a_job_selection: "Postazione della sezione 'Ordinato' del Cameriere",
    text_server_address_test_succeeded: "Test dell'IP del server riuscito",
    text_server_address_test_failed_please_re_enter: "Test dell'IP del server fallito",
    text_reset_server_address_successfully: "IP del server ripristinato",
    text_successful_database_connection: "Connessione DB riuscita",
    text_database_connection_failed: "Connessione DB fallita",
    text_cancel_production: "Annullato",
    text_normal_mode: "Standard",
    text_confirmation_box: "Conferma spostamento",
    text_yes: "Si",
    text_no: "No",
    text_is_the_current_account_cancelled: "Disconnettersi?？",
    text_do_you_clear_and_reset_all_settings_and_cache: "Cancella e ripristina tutte impostazioni e cache?",
    text_advanced_setting: "Impostazioni avanzate",
    text_unable_to_get_job_data: 'Impossibile ottenere dati di postazione',
    text_job_data_does_not_exist: 'Dati di postazione errati o inesistenti',
    text_unable_to_get_printer_data: 'Impossibile ottenere dati di stampa',
    text_printer_data_does_not_exist: 'Dati di stampa errati o inesistenti',
    text_unable_to_get_dish_type_data: 'Impossibile ottenere dati di categorie dei piatti',
    text_the_dish_type_data_does_not_exist: 'Dati di categorie dei piatti errati o inesistenti',
    text_cancelled_account: 'Disconnesso',
    text_all_settings_and_caches_have_been_reset: 'Impostazioni e cache ripristinate',
    text_refreshed: 'Aggiornato',
    text_printer_tested_successfully: 'Test della stampa riuscito',
    text_printer_test_failed: 'Test della stampa fallito',
    text_printer_address: 'Indirizzo della stampante',
    text_please_select_a_job_on_the_settings_page_first: 'Seleziona prima la postazione',
    text_please_select_your_job_position: 'Seleziona la tua postazione',
    text_chef: 'Cuoco',
    text_waiter: 'Cameriere',
    text_smart_merger: 'Modalità SmartMerge',
    text_automatic: 'Automatico',
    text_manual: 'Manuale',
    text_no_data: 'Nessun dato trovato',
    text_function: 'Funzioni',
    text_job_position_info: 'Configurazioni postazione',
    text_unable_to_get_global_setting_info: 'Impossibile ottenere dati globali del KWA',
    text_unable_to_get_dish_data: "Impossibile ottenere dati del piatto",
    text_unable_init_page: "Impossibile inizializzare la pagina",
    text_unable_refresh_data: "Impossibile aggiornare i dati",
    text_unable_get_data_statistics: "Impossibile ottenere dati statistici",
    text_unable_check_data_diff_or_update_data: "Aggiornamento dati non riuscito",
    text_unable_intelligent_merge: "SmartMerge fallito",
    text_unable_do_card_long_touch_action: "Touch-Action fallito",
    text_unable_call_update_like_api_of_dishes: "Aggiornamento piatto non riuscito",
    text_unknown_mistake: "Errore sconosciuto",
    text_update_ismake_message: "Sposta comande selezionate",
    text_update: "Aggiorna",
    text_come_back: "Indietro",
    text_prepare_dish: "Preparato",
    text_unable_switching: "Cambio modalità fallito",
    text_unable_to_get_dish_data_to_print: "Impossibile ottenere i dati dei piatti da stampare",
    text_unable_printing_tips: "Stampa comanda fallita",
    text_card_header_information_batext_r_font_size: "Dimensione font info comanda",
    text_dish_area: "Area e tavoli",
    text_whether_to_open_the_regional_menu_function: "Mostra comande per area",
    text_keyboard_mode: "Modalità tastiera",
    text_order_restaurant_mode: "Modalità standard-Restaurant",
    text_select_all: "Seleziona tutto",
    text_all_inversion: "Deseleziona tutto",
    text_takeaway: "Takeaway",
    text_unableto_get_table_area_data: "Impossibile ottenere i dati delle aree",
    text_meal_screen: "Schermata di Pickup",
    text_meal_screen_font_size: "Dimensione font schermata di Pickup",
    text_whether_to_display_the_customer_name: "Visualizza il nome cliente",
    text_meal_screen_area: "Area della schermata di Pickup",
    text_please_take_a_meal: "Si prega di ritirare",
    text_unable_to_get_meal_data: "Impossibile ottenere i dati di Ritiro",
    text_meal_data_does_not_exist: 'Dati di Ritiro errati o inesistenti',
    text_version:'Versione',
    text_round_propulsion_mode:'Modalità del cambio turno',
    text_chef_automatically_pushes:'Automatica',
    text_waiter_manually_pushes:'Manuale',
    text_prepare_dish_mode:"Sezione 'Preparato'",
    text_logging_function: "Log",
    text_is_enable_logging_function: "Attivazione Log",
    button_clear_all_logging: "Svuota cache Log",
    button_view_logging:"Visualizza log",
    text_sort_table:"Ordina per tavolo",
    text_ascending:"Ascendente",
    text_descending:"Discendente",
    text_whether_to_clear_all_logs: "Cancella log",
    text_all_logs_have_been_emptied: "Cancellazione log riuscita",
    text_update_time: "Tempo di aggiornamento",
    text_order_head_id: "ID ordine",
    text_order_detail_id: "ID piatto",
    text_update_type: "Tipo di aggiornamento",
    text_user: "Utente",
    text_update_dishes_number: "Quantità dei piatti per l\'aggiornamento",
    text_is_success: "Riuscito",
    text_current_mode: "Modalità corrente",
    text_current_role: "Ruolo corrente",
    text_date: "Data",
    button_search: "Ricerca",
    button_display_all: "Mostra tutto",
    text_sound_name_starlight:'Luce stellare',
    text_sound_name_radar:'Radar',
    text_sound_name_bird:'Rondine',
    text_sound_name_bamboo:'Boschetto di bambù',
    text_sound: 'Suoneria',
    text_ringtone_reminder:'Attiva',
    text_ring: 'Seleziona',
    text_only_merge_dine_in:'SmartMerge solo Dine-In',
    text_ready_to_serve_sort: "Ordinamento pagina di 'In consegna'",
    text_end_time: "Orario di uttima modifica",
    text_order_time: "Orario dell'ordine",
    text_update_confirmation_box_show_name: 'Nome piatto visualizzato nella Conferma',
    text_default_config:'Impostazioni preferite',
    text_change_default_config:'Sicuro di tornare alla configurazione predefinita? (Forzerà logout e ripristinerà cache)',
    text_merge_ignore_customer_information:"Ignora dati clienti durante SmartMerge",
    text_smart_merge_check_dish_id:'Controllo ID piatto durante SmartMerge',
    text_do_not_show_again: "Non mostrare di nuovo",
    text_rush_dish_confirmation_box: "Memorizza conferma",
    text_whether_rush_dish: "Priorizza il piatto?",
    text_add_reason_in_cancel_production: "Seleziona motivo dell\'annullamento",
    text_select_one_cancel_production_reason: "Seleziona un motivo",
    text_cancel_production_reason: "Motivi",
    text_theme_standard: 'Standard',
    text_theme_summer: 'Estate',
    text_theme_atlantis: 'Atlantis',
    text_right_sidebar_align: 'Allineamento Right-sidebar',
    text_top_align: 'In alto',
    text_center_align: 'Al centro',
    text_bottom_align: 'In basso',
    text_preparing: 'In preparazione',
    text_take_meal_from_feed_time: 'Frequenza di cambio pagina (secondi)',
    text_base_setting: 'Impostazioni di base',
    text_other_setting: 'Altre impostazioni',
    text_ordered_sort: 'Ordinamento sezione "Preparato"',
    text_dining_time: 'Eat time',
    text_delivery_order_display_range: 'Mostra gli ordini d\'asporto entro xx minuti',
    text_order_info_show_customer_name: "Visualizza nome cliente nell'ordine",
    text_basis_for_round_advance: "Modalità di cambio turno",
    text_made_for_round_advance: "Dopo aver finito i piatti di questo turno, entrerà nel turno successivo",
    text_served_for_round_advance: "Dopo aver servito i piatti di questo turno, entrerà nel turno successivo",
    text_page_inheritance_merge_status: "Mantiene stato di SmartMerge dopo cambio sezione",
    text_highlight_dishes_of_the_same_name: "Evidenzia gli stessi piatti",
    text_round_of_dishes: "Turno del piatto",
    text_dish_order_time: "Orario dell\'ordine",
    text_kitchen_current_round: "Turno corrente del chef",
    text_export_log: "Esporta log",
    text_export_success: "Esportazione riuscita",
    text_export_faile: "Esportazione non riuscita",
    text_log_name: "Nome del file log",
    text_please_input_log_name: "Inserisci il nome del log",
    text_dish_name_print_method: "Seleziona nome piatto della stampa",
    display_a_summary_of_dishes_sidebar: "Visualizza Sidebar",
    dishes_summary_sidebar: "Sidebar",
    prepare_dish_post: "Postazione per preparazione",
    total_order_mode: "Modalità Full-Check",
    total_order_mode_number: "Numero di ordini in modalità Full-Check",
    text_takeaway_mode: 'Take Away',
    text_sort_the_dishes_in_the_card: 'Ordinamento dei piatti',
    total_takeaway_mode_number: "Ordini per pagina in modalità Takeaway",
    text_printing_method: "Metodo di stampa",
    text_split_printing_mode: "Modalità di stampa divisa",
    text_full_order_printing_mode: "Stampa dell'ordine completo",
    text_you_have_selected_items: "Hai selezionato {{number}} articoli",
    text_one_receipt_per_dish_mode: "Separato per piatto",
    text_eat_in_order: "Ordini sala",
    text_take_away_order: "TakeAway",
    text_display_takeaway_statistics_sidebar: "Takeaway Sidebar",
    text_sort_the_order_mode_dinein_in_the_card: "Ordine del piatto di Eat-in",
    text_take_out_dine_in_joint_sorting: "Ordinamento comune",
    text_printing_operator_type: "Tipo di operatore",
    text_printing_order_account: "Conto dell'ordinante",
    text_printing_chef_account: "Chef account",
    text_printing_multi_person: "Da più persone",
    button_view_print_logging:"Visualizza los della stampa",
    text_auto_hide_left_sidebar: 'Nascondi automaticamente Left-Sidebar'
}