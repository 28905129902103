import React from "react";
import { withSnackbar } from "notistack";
import CommonPage from './common.page'
import i18nService from '../i18n';
import { StateManager } from "../modules/state";

class WaiterPage extends React.Component<any> {
    constructor(props: any) {
        super(props);
        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));
        // 服务员的按钮
        this.filterButton = [
            { name: `${i18nService.t('text_not_yet_made')}`, value: 'chefkitchen', status: 0 },
            { name: `${i18nService.t('text_prepare_dish')}`, value: 'prepareDish', status: 11 },
            { name: `${i18nService.t('text_not_serving')}`, value: 'kitchen', status: 1 },
            { name: `${i18nService.t('text_has_served')}`, value: 'history', status: 2 },
            { name: `${i18nService.t('text_pause_production')}`, value: 'suspend', status: 3 },
            { name: `${i18nService.t('text_cancel_production')}`, value: 'cancelled', status: 5 },
        ]
    }

    private filterButton: Array<{name: string; value: string; status: number}>;
    render() {
        return (
            <CommonPage
                enqueueSnackbar={this.props.enqueueSnackbar}
                role={'waiter'}
                filterButton={this.filterButton}
                filterButtonBadgeValue={[0, 0, 0, 0, 0, 0]}
                filterButtonSelected={'kitchen'}
                filterValue={2}
            ></CommonPage>
        )
    }
}

export default withSnackbar(WaiterPage as any);