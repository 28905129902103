export default {
    text_language: 'Lanugage',
    text_theme: 'Theme',
    text_account: 'USER',
    text_password: 'PASSWORD',
    text_sign_in: 'Sign In',
    text_server_url: "Server's URL",
    button_save: 'Save',
    text_order_detail: "Order details",
    text_table_mode: "Table mode",
    text_quick_order: "Full_View order",
    text_speed_order: "Extreme order",
    text_rwa: "Smarway Restaurant _ Waiter order",
    text_settings: "Settings",
    text_reset: "Reset",
    text_confirmation_title: "Please Confirm",
    text_confirmation_content: "Are you sure?",
    button_reset_cache_settings: "Reset Cache & Settings",
    button_ok: "OK",
    button_cancel: "Cancel",
    button_no: "No",
    button_yes: "Yes",
    text_customer_name: "Customer name",
    text_complete: "Complete",
    text_dinein: "Dine in",
    text_hall_for_packaging: "Takeaway",
    text_outside_order: "Deliver",
    text_to_store_comeUndone: "Pickup",
    text_food_pictures: "Food pictures",
    button_sign_out: "Sign Out",
    text_sign_out: "Sign Out",
    text_card_limit_per_page: "Max cards per page",
    text_card_capacity_limit: "Max dishes per MERGING",
    text_card_width: "Card width",
    text_card_max_height: "Card height",
    text_smart_merge_card_height_maximum: "Maximum height of card on SmartMerge mode (px)",
    text_rowing_print: "Forwarding with PRINT",
    text_chef_control: "EXEMPT waiters' confirmation",
    text_allowed_to_prepare_vegetables: "Start preparing Ingred.",
    text_open_quantity_statistics: "Enable statistics feature",
    text_statistics_on_the_number_of_dishes_per_table: "Dishes count of each table",
    text_display_order_number: "Show order ID",
    text_show_dishes_from_the_promotional_package: "Show dishes on promotion menu",
    text_card_adaptive_height_function: "Adaptive height",
    text_menu_update_confirmation_box: "Ingred. update Messagebox",
    text_advanced_settings: "Advanced Settings",
    text_data_refresh_cycle: "Data refresh frequency (sec)",
    text_post: "Device role",
    text_printer: "Printer",
    text_food_combination: "MERGING per category",
    button_reset: "Reset",
    button_unlimited: "Unlimited",
    text_server_address: "Server's URL",
    button_test: " Test",
    button_test_database_connection: "DB Connection",
    text_unable_to_make: "Unavailable",
    text_pause_production: "Suspended",
    text_not_yet_made: "Ordered",
    text_made: "Cooked",
    text_not_serving: "Delivering",
    text_has_served: "Served",
    text_paused: "Paused",
    text_cancelled: "Cancelled",
    text_order_mode: "Orders mode",
    text_did_not_give_the_waiter_no_interface_to_choose_a_post: "No position is selected on the waiter's 'To Cook' page!",
    text_please_enter_a_valid_username_and_password: "Please input valid username and password",
    text_wrong_username_or_password: "Invalid username or password",
    text_name1_font_size: "Font size of Ingred.1",
    text_display_name1: "Display name of Ingred.1",
    text_card: "Card",
    text_the_waiter_did_not_create_a_maximum_number_of_cards: "Cards limit of waiter's 'To Cook' page",
    text_order_mode_card_height_max: "Maximum card height of orders mode",
    text_display_name2: "Display name of Ingred.2",
    text_display_name2_font_size: "Font size of Ingred.2",
    text_dishes_card_notes: "Ingred. card remarks",
    text_card_memo_font_size: "Font size of card remarks",
    text_retry_printing_the_number_of_small_votes: "Print retry times",
    text_the_waite_did_not_make_a_job_selection: "Waiter's To Cook page position selection",
    text_server_address_test_succeeded: "Test server address succeeded",
    text_server_address_test_failed_please_re_enter: "Test server address failed, please try again",
    text_reset_server_address_successfully: "Reset server address succeeded",
    text_successful_database_connection: "Database connection succeeded",
    text_database_connection_failed: "Database connection failed",
    text_cancel_production: "Cancelled",
    text_normal_mode: "Normal mode",
    text_confirmation_box: "Confirmation box",
    text_yes: "Yes",
    text_no: "No",
    text_is_the_current_account_cancelled: "sign out?",
    text_do_you_clear_and_reset_all_settings_and_cache: "Clear and reset all settings and cache?",
    text_advanced_setting: "Advanced Settings",
    text_unable_to_get_job_data: 'Unable to get position data',
    text_job_data_does_not_exist: 'Position data does not exist',
    text_unable_to_get_printer_data: 'Unable to get printer data',
    text_printer_data_does_not_exist: 'Printer data does not exist',
    text_unable_to_get_dish_type_data: 'Unable to get ingred. type data',
    text_the_dish_type_data_does_not_exist: 'Ingred. type data does not exist',
    text_cancelled_account: 'Signed out',
    text_all_settings_and_caches_have_been_reset: 'All settings and cache have been reset',
    text_refreshed: 'Refreshed',
    text_printer_tested_successfully: 'Printer test succeeded',
    text_printer_test_failed: 'Printer test failed',
    text_printer_address: 'Printer url',
    text_please_select_a_job_on_the_settings_page_first: 'Please select position on setting page first',
    text_please_select_your_job_position: 'Please select your position',
    text_chef: 'Chef',
    text_waiter: 'Waiter',
    text_smart_merger: 'SMART MERGE',
    text_automatic: 'Automatic',
    text_manual: 'Manual',
    text_no_data: 'No data found',
    text_function: 'Function',
    text_job_position_info: 'Position information',
    text_unable_to_get_global_setting_info: 'Unable to get global setting parameters',
    text_unable_to_get_dish_data: "Unable to get ingred. data",
    text_unable_init_page: "Unable to initiate page",
    text_unable_refresh_data: "Unable to refresh data",
    text_unable_get_data_statistics: "Unable to get data statistics",
    text_unable_check_data_diff_or_update_data: "Data differences comparison failed or update failed",
    text_unable_intelligent_merge: "SMART MERGE failed",
    text_unable_do_card_long_touch_action: "Long-press event of cards failed",
    text_unable_call_update_like_api_of_dishes: "Ingred.-update type event failed",
    text_unknown_mistake: "Unknown error",
    text_update_ismake_message: "Update the status of selected Ingred. to ",
    text_update: "Refresh",
    text_come_back: "Back",
    text_prepare_dish: "Prepared",
    text_unable_switching: "Switching failed",
    text_unable_to_get_dish_data_to_print: "Unable to get Ingred. data to print",
    text_unable_printing_tips: "Printing tips failed",
    text_card_header_information_batext_r_font_size: "Font size of card Top-Area",
    text_dish_area: "Tables area",
    text_whether_to_open_the_regional_menu_function: "Display dishes by area",
    text_keyboard_mode: "Keyboard mode",
    text_order_restaurant_mode: "Standard-Rest. Mode",
    text_select_all: "Select all",
    text_all_inversion: "Unselect all",
    text_takeaway: "Takeaway",
    text_unableto_get_table_area_data: "Unable to get tables area data",
    text_meal_screen: "Pickup Screen",
    text_meal_screen_font_size: "Font size of Pickup screen",
    text_whether_to_display_the_customer_name: "Display customer's name",
    text_meal_screen_area: "Pickup screen area",
    text_please_take_a_meal: "Please pickup meal ",
    text_unable_to_get_meal_data: "Unable to get Pickup data",
    text_meal_data_does_not_exist: 'Pickup data does not exist ',
    text_version:"Version",
    text_round_propulsion_mode:"Kitchen's round propulsion mode",
    text_chef_automatically_pushes:"Automatic mode",
    text_waiter_manually_pushes:"Manual mode",
    text_prepare_dish_mode:'Preparing Mode',
    text_logging_function: "Log",
    text_is_enable_logging_function: "Enable log feature",
    button_clear_all_logging: "Clear log cache",
    button_view_logging:"View logs",
    text_sort_table:"Sort by table",
    text_ascending:"Ascending",
    text_descending:"Descending",
    text_whether_to_clear_all_logs: "Clear all logs",
    text_all_logs_have_been_emptied: "All log files have been cleared",
    text_update_time: "Update time",
    text_order_head_id: "Order ID",
    text_order_detail_id: "Ingred. ID",
    text_update_type: "Update type",
    text_user: "User",
    text_update_dishes_number: "Update Ingred. quantities",
    text_is_success: "Is updated",
    text_current_mode: "Current mode",
    text_current_role: "Current role",
    text_date: "Date",
    button_search: "Search",
    button_display_all: "Show all",
    text_sound_name_starlight:'Starlight',
    text_sound_name_radar:'Radar',
    text_sound_name_bird:'Swallow',
    text_sound_name_bamboo:'Bamboo grove',
    text_sound: 'Sound',
    text_ringtone_reminder:'Ringtone reminder',
    text_ring: 'Ringtones',
    text_only_merge_dine_in:'SmartMerge Dine-In only',
    text_ready_to_serve_sort: "'Delivering' page sort",
    text_end_time: 'Modification time of last status',
    text_order_time: 'Order time',
    text_update_confirmation_box_show_name: 'Dish name displayed in Confirm box',
    text_default_config:'Default configurations',
    text_change_default_config:'Sure to change the default configuration? (It will force logout and reset cache)',
    text_merge_ignore_customer_information:'Ignore customer information while merging',
    text_smart_merge_check_dish_id:'Smart-Merge check dish ID',
    text_do_not_show_again: "Do not Show again (Reopen it at Settings)",
    text_rush_dish_confirmation_box: "Reminder confirm",
    text_whether_rush_dish: "Send the reminder?",
    text_add_reason_in_cancel_production: "Cancel dishes needs select reason",
    text_select_one_cancel_production_reason: "Select a resin",
    text_cancel_production_reason: "Cancellation Reasons",
    text_theme_standard: 'Standard',
    text_theme_summer: 'Summer',
    text_theme_atlantis: 'Atlantis',
    text_right_sidebar_align: 'Right-sidebar icon alignment',
    text_top_align: 'Top',
    text_center_align: 'Center',
    text_bottom_align: 'Bottom',
    text_preparing: 'Preparing',
    text_take_meal_from_feed_time: 'Interval of page switching (seconds)',
    text_base_setting: 'Basic settings',
    text_other_setting: 'Other settings',
    text_ordered_sort: 'Ordered Page Sorting',
    text_dining_time: 'Eat Time',
    text_delivery_order_display_range: 'Order view time range of takeaway (minute)',
    text_order_info_show_customer_name: 'Display customer name on order information',
    text_basis_for_round_advance: "Mode of dishes round propulsion",
    text_made_for_round_advance: "After the dishes of this round are made, enter into the next round ",
    text_served_for_round_advance: "After the dishes of this round are served, enter into the next round",
    text_page_inheritance_merge_status: "Keep card status when changing pages",
    text_highlight_dishes_of_the_same_name: "Highlight the same dishes",
    text_round_of_dishes: "Round of dishes",
    text_dish_order_time: "Order time",
    text_kitchen_current_round: "Kitchen current round",
    text_export_log: "Export log",
    text_export_success: "Export successful",
    text_export_faile: "Export failed",
    text_log_name: "Log file name",
    text_please_input_log_name: "Please enter the log name",
    text_dish_name_print_method: "Dish name printing method",
    display_a_summary_of_dishes_sidebar: "Display dish sidebar",
    dishes_summary_sidebar: "Dish sidebar",
    prepare_dish_post: "Need to prepare",
    total_order_mode: "Order full-check mode",
    total_order_mode_number: "Number of orders per page in full-check mode",
    text_takeaway_mode: 'Take Away',
    text_sort_the_dishes_in_the_card: 'Dishes sorting',
    total_takeaway_mode_number: "Orders per page in takeaway mode",
    text_printing_method: "Printing method",
    text_split_printing_mode: "Split printing mode",
    text_full_order_printing_mode: "Full order printing mode",
    text_you_have_selected_items: "You have selected {{number}} items",
    text_one_receipt_per_dish_mode: "Separate by dish",
    text_eat_in_order: "Eat-in",
    text_take_away_order: "TakeAway",
    text_display_takeaway_statistics_sidebar: "Takeaway Sidebar",
    text_sort_the_order_mode_dinein_in_the_card: "Dish order of Eat-in",
    text_take_out_dine_in_joint_sorting: "Joint sorting",
    text_printing_operator_type: "Operator type",
    text_printing_order_account: "Orderer's account",
    text_printing_chef_account: "Chef account",
    text_printing_multi_person: "Multi-person",
    button_view_print_logging:"View print logs",
    text_auto_hide_left_sidebar: 'Auto hide Left-Sidebar'
}