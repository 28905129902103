
/**
 * Error: 未知错误
 */
export const ERROR_000 = {
    message: "未知错误",
    level: "Error",
}

/**
 * Warning: 请输入有效的用户名和密码
 */
export const ERROR_001 = {
    message: "text_please_enter_a_valid_username_and_password",
    level: "Warning",
}

/**
 * Warning: 用户名或密码错误
 */
export const ERROR_002 = {
    message: "text_wrong_username_or_password",
    level: "Warning",
}

/**
 * Error: 无法获取数据库设置
 */
export const ERROR_003 = {
    message: "text_unable_to_get_global_setting_info",
    level: "Error",
}

/**
 * Warning: 请在设置页面选择工作岗位
 */
export const ERROR_004 = {
    message: 'text_please_select_a_job_on_the_settings_page_first',
    level: "Warning",
}

/**
 * Error: 无法获取岗位数据
 */
export const ERROR_005 = {
    message: "text_unable_to_get_job_data",
    level: "Error",
}
/**
 * Warning: 岗位数据不存在
 */
export const ERROR_006 = {
    message: "text_job_data_does_not_exist",
    level: "Warning",
}
/**
 * Error: 无法获取打印机数据
 */
export const ERROR_007 = {
    message: "text_unable_to_get_printer_data",
    level: "Error",
}
/**
 * Warning: 打印机数据不存在
 */
export const ERROR_008 = {
    message: "text_printer_data_does_not_exist",
    level: "Warning",
}
/**
 * Error: 无法获取菜品类型数据
 */
export const ERROR_009 = {
    message: "text_unable_to_get_dish_type_data",
    level: "Error",
}
/**
 * Warning: 菜品类型数据不存在
 */
export const ERROR_0010 = {
    message: "text_the_dish_type_data_does_not_exist",
    level: "Warning",
}
/**
 * Error: 无法获取全局设置参数
 */
export const ERROR_0011 = {
    message: "text_unable_to_get_global_setting_info",
    level: "Error",
}

/**
 * Error: 无法获取菜品数据
 */
export const ERROR_0012 = {
    message: "text_unable_to_get_dish_data",
    level: "Error",
}


/**
 * Warning: 打印机测试失败
 */
export const ERROR_0013 = {
    message: "text_printer_test_failed",
    level: "Warning",
}


/**
 * Error:  服务器地址测试失败,请重新输入
 */
export const ERROR_0014 = {
    message: "text_server_address_test_failed_please_re_enter",
    level: "Error",
}

/**
 * Error:  数据库连接失败
 */
export const ERROR_0015 = {
    message: "text_database_connection_failed",
    level: "Error",
}

/**
 * Error: 无法初始化页面
 */
export const ERROR_0016 = {
    message: "text_unable_init_page",
    level: "Error",
}

/**
 * Error: 无法刷新数据
 */
export const ERROR_0017 = {
    message: "text_unable_refresh_data",
    level: "Error",
}

/**
 * Error: 无法获取数据统计
 */
export const ERROR_0018 = {
    message: "text_unable_get_data_statistics",
    level: "Error",
}

/**
 * Error: 比较数据差异性失败或更新失败
 */
export const ERROR_0019 = {
    message: "text_unable_check_data_diff_or_update_data",
    level: "Error",
}

/**
 * Error: 智能合并失败
 */
export const ERROR_0020 = {
    message: "text_unable_intelligent_merge",
    level: "Error",
}

/**
 * Error: 卡片长按事件错误
 */
export const ERROR_0021 = {
    message: "text_unable_do_card_long_touch_action",
    level: "Error",
}

/**
 * Error: 菜品更新类事件失败
 */
export const ERROR_0022 = {
    message: "text_unable_call_update_like_api_of_dishes",
    level: "Error",
}

/**
 * Error: 切换模式失败
 */
export const ERROR_0023 = {
    message: "text_unable_switching",
    level: "Error",
}

/**
 * Error: 无法获取需要打印的菜品
 */
export const ERROR_0024 = {
    message: "text_unable_to_get_dish_data_to_print",
    level: "Error",
}

/**
 * Error: 打印小票失败
 */
export const ERROR_0025 = {
    message: "text_unable_printing_tips",
    level: "Error",
}

/**
 * Error: 未给服务员未制作界面选择岗位
 */
export const ERROR_0026 = {
    message: "text_did_not_give_the_waiter_no_interface_to_choose_a_post",
    level: "Warning",
}
/**
 * Error: 获取桌台区域
 */
export const ERROR_0027 = {
    message: "text_unableto_get_table_area_data",
    level: "Error",
}

/**
 * Error: 无法获取取餐数据
 */
export const ERROR_0028 = {
    message: "text_unable_to_get_meal_data",
    level: "Error",
}
/**
 * Warning: 取餐数据不存在
 */
export const ERROR_0029 = {
    message: "text_meal_data_does_not_exist",
    level: "Warning",
}