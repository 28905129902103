export default {
    text_language: 'Idioma',
    text_theme: 'Tema',
    text_account: "Usuario",
    text_password: "Contraseña",
    text_sign_in: 'Iniciar sesión',
    text_server_url: 'Dirección server',
    button_save: 'Guarda',
    text_order_detail: "Detalles pedido",
    text_table_mode: "Modo Mesa",
    text_quick_order: "Modo Full_View",
    text_speed_order: "Modo Rápido",
    text_rwa: "Smartway RWA",
    text_settings: "Ajustes",
    text_reset: "Reset",
    text_confirmation_title: "Confirma",
    text_confirmation_content: "Confirma?",
    button_reset_cache_settings: "Reset Cache & Settings",
    button_ok: "OK",
    button_cancel: "Cancela",
    button_no: "No",
    button_yes: "Sí",
    text_customer_name: "Nombre del cliente",
    text_complete: "Completa",
    text_dinein: "Dine in",
    text_hall_for_packaging: "Para llevar",
    text_outside_order: "Entrega",
    text_to_store_comeUndone: "Recoger",
    text_food_pictures: "Imágenes del plato",
    button_sign_out: "Cerrar sesión",
    text_sign_out: "Cerrar sesión",
    text_card_limit_per_page: "Cantidad pedidos por pantalla",
    text_card_capacity_limit: "Límite cantidad para SmartMerge",
    text_card_width: "Ancho del pedido",
    text_card_max_height: "Altura del pedido",
    text_smart_merge_card_height_maximum: "Límite altura del pedido en SmartMerge",
    text_rowing_print: "Imprimir pedido",
    text_chef_control: "Entrega directa",
    text_allowed_to_prepare_vegetables: "Inic. plato",
    text_open_quantity_statistics: "Habilitar función de estadísticas",
    text_statistics_on_the_number_of_dishes_per_table: "Notificaciones de la mesa",
    text_display_order_number: "Muestra ID de pedido",
    text_show_dishes_from_the_promotional_package: "Muestra detalles del menú-combo",
    text_card_adaptive_height_function: "Altura adaptable",
    text_menu_update_confirmation_box: "Confirma desplazamiento de pedidos",
    text_advanced_settings: "Ajustes avanzados",
    text_data_refresh_cycle: "Frecuencia de actualización de datos (seg)",
    text_post: "Estación dispositivo para Chef",
    text_printer: "Impresora",
    text_food_combination: "SmartMerge por categorías",
    button_reset: "Reset",
    button_unlimited: "Illimitado",
    text_server_address: "Dirección server",
    button_test: "Prueba",
    button_test_database_connection: "Conexión DB",
    text_unable_to_make: "No disponible",
    text_pause_production: "Suspendido",
    text_not_yet_made: "Pedido",
    text_made: "Cocido",
    text_not_serving: "En entrega",
    text_has_served: "Servido",
    text_paused: "Pausado",
    text_cancelled: "Cancelado",
    text_order_mode: "Pedidos",
    text_did_not_give_the_waiter_no_interface_to_choose_a_post: "Ninguna estación seleccionada en la sección 'Pedido' del mesero",
    text_please_enter_a_valid_username_and_password: "Ingrese un nombre de usuario o contraseña válidos",
    text_wrong_username_or_password: "Usuario o contraseña invalidos",
    text_name1_font_size: "Tamaño de fuente nombre_1",
    text_display_name1: "Ver nombre_1",
    text_card: "Pedido",
    text_the_waiter_did_not_create_a_maximum_number_of_cards: "Cantidad pedidos en la sección 'Pedido'",
    text_order_mode_card_height_max: "Límite altura del pedido en el modo Pedidos",
    text_display_name2: "Ver nombre_2",
    text_display_name2_font_size: "Tamaño de fuente nombre_2",
    text_dishes_card_notes: "Nota de pedido",
    text_card_memo_font_size: "Tamaño fuente notas",
    text_retry_printing_the_number_of_small_votes: "Número de intentos de reimpresión",
    text_the_waite_did_not_make_a_job_selection: "Estación de la sección 'Pedido' del Mesero",
    text_server_address_test_succeeded: "Prueba de IP del servidor exitosa",
    text_server_address_test_failed_please_re_enter: "La prueba de IP del servidor falló",
    text_reset_server_address_successfully: "IP del servidor restaurada",
    text_successful_database_connection: "Conexión DB exitosa",
    text_database_connection_failed: "Error de Conexión DB",
    text_cancel_production: "Cancelado",
    text_normal_mode: "Estándar",
    text_confirmation_box: "Confirmar movimiento",
    text_yes: "Sí",
    text_no: "No",
    text_is_the_current_account_cancelled: "¿Cerrar sesión?",
    text_do_you_clear_and_reset_all_settings_and_cache: "Borrar y restablecer todas las configuraciones y el caché?",
    text_advanced_setting: "Ajustes avanzados",
    text_unable_to_get_job_data: 'No se pueden obtener los datos de la estación',
    text_job_data_does_not_exist: 'Datos de estación incorrectos o inexistentes',
    text_unable_to_get_printer_data: 'No se pueden obtener datos de impresión',
    text_printer_data_does_not_exist: 'Datos de impresión incorrectos o inexistentes',
    text_unable_to_get_dish_type_data: 'No se pudieron obtener los datos de la categoría platos',
    text_the_dish_type_data_does_not_exist: 'Datos de categoría de platos incorrectos o inexistentes',
    text_cancelled_account: 'Desconectado',
    text_all_settings_and_caches_have_been_reset: 'Configuración y caché restaurados',
    text_refreshed: 'Actualizado',
    text_printer_tested_successfully: 'Prueba de impresión exitosa',
    text_printer_test_failed: 'Error en la prueba de impresión',
    text_printer_address: 'Dirección de la impresora',
    text_please_select_a_job_on_the_settings_page_first: 'Selecciona la estación primero',
    text_please_select_your_job_position: 'Selecciona tu estación',
    text_chef: 'Chef',
    text_waiter: 'Mesero',
    text_smart_merger: 'Modo SmartMerge',
    text_automatic: 'Automático',
    text_manual: 'Manual',
    text_no_data: 'Datos no encontrados',
    text_function: 'Funciones',
    text_job_position_info: 'Configuraciones estaciones de trabajo',
    text_unable_to_get_global_setting_info: 'No se pudieron obtener los datos globales de KWA',
    text_unable_to_get_dish_data: "No se pudieron obtener los datos del plato",
    text_unable_init_page: "No se pudo inicializar la página",
    text_unable_refresh_data: "No se pudo actualizar los datos",
    text_unable_get_data_statistics: "No se pudo obtener datos estadísticos",
    text_unable_check_data_diff_or_update_data: "Error al actualizar los datos",
    text_unable_intelligent_merge: "Error en SmartMerge",
    text_unable_do_card_long_touch_action: "Error Touch-Action",
    text_unable_call_update_like_api_of_dishes: "Error al actualizar plato",
    text_unknown_mistake: "Error desconocido",
    text_update_ismake_message: "Mover pedidos seleccionados",
    text_update: "Actualiza",
    text_come_back: "Atrás",
    text_prepare_dish: "Preparado",
    text_unable_switching: "El cambio de modo falló",
    text_unable_to_get_dish_data_to_print: "No se pudo obtener los datos para impresión",
    text_unable_printing_tips: "Error de impresión del pedido",
    text_card_header_information_batext_r_font_size: "Tamaño fuente info pedido",
    text_dish_area: "Área y mesas",
    text_whether_to_open_the_regional_menu_function: "Muestra pedidos por área",
    text_keyboard_mode: "Modo teclado",
    text_order_restaurant_mode: "Modo Standard-Restaurant",
    text_select_all: "Selecciona todo",
    text_all_inversion: "Deselecciona todo",
    text_takeaway: "Para llevar",
    text_unableto_get_table_area_data: "No se pudo obtener los datos del área",
    text_meal_screen: "Pantalla de Pedidos Para Llevar",
    text_meal_screen_font_size: "Tamaño fuente pantalla Para llevar",
    text_whether_to_display_the_customer_name: "Ver nombre cliente",
    text_meal_screen_area: "Área de la pantalla Para Llevar",
    text_please_take_a_meal: "Recoger pedido",
    text_unable_to_get_meal_data: "No se pudo obtener los datos de recogida",
    text_meal_data_does_not_exist: 'Datos de recogida incorrectos o inexistentes',
    text_version:'Versión',
    text_round_propulsion_mode:'Modo cambio-ronda',
    text_chef_automatically_pushes:'Automática',
    text_waiter_manually_pushes:'Manual',
    text_prepare_dish_mode:"Sesión 'Preparado'",
    text_logging_function: "Log",
    text_is_enable_logging_function: "Ativación Log",
    button_clear_all_logging: "Limpiar cache Log",
    button_view_logging:"Ver log",
    text_sort_table:"Pedir por mesa",
    text_ascending:"Ascendente",
    text_descending:"Descendiente",
    text_whether_to_clear_all_logs: "Cancela log",
    text_all_logs_have_been_emptied: "Eliminación log exitosa",
    text_update_time: "Tiempo de actualización",
    text_order_head_id: "ID pedido",
    text_order_detail_id: "ID plato",
    text_update_type: "Tipo de actualización",
    text_user: "Usuario",
    text_update_dishes_number: "Cantidad de platos para actualización",
    text_is_success: "Exitoso",
    text_current_mode: "Modo actual",
    text_current_role: "Rol actual",
    text_date: "Fecha",
    button_search: "Búsqueda",
    button_display_all: "Muestra todo",
    text_sound_name_starlight:'Luz de las estrellas',
    text_sound_name_radar:'Radar',
    text_sound_name_bird:'Golondrinas',
    text_sound_name_bamboo:'Arboleda de bambú',
    text_sound: 'Tonos',
    text_ringtone_reminder:'Activa',
    text_ring: 'Selecciona',
    text_only_merge_dine_in:'SmartMerge solamente Dine-In',
    text_ready_to_serve_sort: "Página de pedidos de 'En entrega'",
    text_end_time: "Hora última modificación",
    text_order_time: "Hora del pedido",
    text_update_confirmation_box_show_name: 'Nombre del plato visto en la Confirmación',
    text_default_config:'Configuración predeterminada',
    text_change_default_config:'¿Está seguro de volver a la configuración predeterminada? (Forzará el cierre de sesión y restablecerá la caché)',
    text_merge_ignore_customer_information:"Ignora los datos del cliente durante SmartMerge",
    text_smart_merge_check_dish_id:'Control de ID plato durante SmartMerge',
    text_do_not_show_again: "No volver a mostrar",
    text_rush_dish_confirmation_box: "Guardar confirmación",
    text_whether_rush_dish: "Prioriza el plato?",
    text_add_reason_in_cancel_production: "Selecciona el motivo de la cancelación",
    text_select_one_cancel_production_reason: "Selecciona un motivo",
    text_cancel_production_reason: "Motivos",
    text_theme_standard: 'Estándar',
    text_theme_summer: 'Verano',
    text_theme_atlantis: 'Atlantis',
    text_right_sidebar_align: 'Alineación Right-sidebar',
    text_top_align: 'Arriba',
    text_center_align: 'Centralizado',
    text_bottom_align: 'Abajo',
    text_preparing: 'En preparación',
    text_take_meal_from_feed_time: 'Frecuencia de cambio de página (segundos)',
    text_base_setting: 'Ajustes básicos',
    text_other_setting: 'Otros ajustes',
    text_ordered_sort: 'Ordenamiento sesión "Preparado"',
    text_dining_time: 'Eat time',
    text_delivery_order_display_range: 'Muestra los pedidos para llevar de los últimos xx minutos',
    text_order_info_show_customer_name: "Ver nombre del cliente en el pedido",
    text_basis_for_round_advance: "Modo cambio-ronda",
    text_made_for_round_advance: "Después de terminar los platos de esta ronda, entrará en la siguiente",
    text_served_for_round_advance: "Después de servir los platos en esta ronda, entrará en la siguiente.",
    text_page_inheritance_merge_status: "Mantiene el estado de SmartMerge después del cambio de sesión",
    text_highlight_dishes_of_the_same_name: "Destacar platos iguales",
    text_round_of_dishes: "Ronda del plato",
    text_dish_order_time: "Hora del pedido",
    text_kitchen_current_round: "Ronda actual del chef",
    text_export_log: "Exporta log",
    text_export_success: "Exportación exitosa",
    text_export_faile: "Error al exportar",
    text_log_name: "Nombre del archivo log",
    text_please_input_log_name: "Ingrese el nombre del log",
    text_dish_name_print_method: "Selecciona nombre plato para impresión",
    display_a_summary_of_dishes_sidebar: "Ver Sidebar",
    dishes_summary_sidebar: "Sidebar",
    prepare_dish_post: "Estación de preparación",
    total_order_mode: "Modo Full-Check",
    total_order_mode_number: "Número de pedidos en modo Full-Check",
    text_takeaway_mode: 'Take Away',
    text_sort_the_dishes_in_the_card: 'Ordenar los platos',
    total_takeaway_mode_number: "Pedidos por página en modo Takeaway",
    text_printing_method: "Método de impresión",
    text_split_printing_mode: "Modo de impresión dividida",
    text_full_order_printing_mode: "Imprime el pedido completo",
    text_you_have_selected_items: "Ha seleccionado {{number}} artículos",
    text_one_receipt_per_dish_mode: "Separar por plato",
    text_eat_in_order: "Eat-in",
    text_take_away_order: "TakeAway",
    text_display_takeaway_statistics_sidebar: "Takeaway Sidebar",
    text_sort_the_order_mode_dinein_in_the_card: "Orden de plato de Eat-in",
    text_take_out_dine_in_joint_sorting: "Orden común",
    text_printing_operator_type: "Tipo de operador",
    text_printing_order_account: "Cuenta del ordenante",
    text_printing_chef_account: "Chef account",
    text_printing_multi_person: "Por varias personas",
    button_view_print_logging:"Ver logs de impresión",
    text_auto_hide_left_sidebar: 'Ocultar automáticamente Left-Sidebar'
}