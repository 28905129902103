import React, { Component, SetStateAction } from 'react';
import Button from '@material-ui/core/Button';
import { createHashHistory } from 'history';
import { Request } from '../modules/request'
import { TextField } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import i18nService from '../i18n';
import { StateManager, GlobalState } from '../modules/state';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import LoadingComponent from "../components/loading.component";
import { MessengerModule } from "../modules/messenger/module";
import { ERROR_001, ERROR_002, ERROR_003 } from "../constants/error";
import { Unsubscribe } from 'redux';

class SettingVertify extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.signInClick = this.signInClick.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);

        // set i18n
        i18nService.changeLanguage(StateManager.get("language"));

        // State
        this.state = {
            account: "",
            password: "",
            serveUrl: StateManager.get("serveUrl"),
            loadingVisible: false,
        }
        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("log-in-page-update", () => {
            // set i18n
            i18nService.changeLanguage(StateManager.get("language"));
            // update local state
            this.setState({
                serveUrl: StateManager.get("serveUrl")
            } as SetStateAction<State>);
        });

    };

    private messengerModule: MessengerModule;

    private unsubscribe: Unsubscribe;

    // *********************
    // Default Function
    // *********************

    componentDidMount() {
        // we check if already has a account

    }

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    /**
     * update local state by object
     * @param val 
     */
    public updateLocalState(val: Partial<State>) {
        this.setState(val as any);
    };

    /**
     * sign in action
     */
    async signInClick() {
        try {
            // start loading
            this.setState({
                loadingVisible: true
            });

            // do sigin in action
            if (this.state.account && this.state.password) {
                // $ always get and set the newest setting data before sigin in action
                // let settings: any = await Request.getSettingInfo(this.state.serveUrl);
                // if (!settings) throw ERROR_003;
                // udpate seeting info first
                // GlobalState.dispatch({
                //     type: "SET_BY_PATH",
                //     path: "settingInfo",
                //     value: settings.data,
                // });
                // call api
                let result = await Request.login(this.state.account, this.state.password, this.state.serveUrl);
                // if account and password correct
                if (result.status === 200 && result.data) {
                    // GlobalState.dispatch({
                    //     type: "SET_BY_PATH",
                    //     path: "account",
                    //     value: result.data.employee_id,
                    // });
                    createHashHistory().push('/Setting');
                }
                // if not
                else throw ERROR_002;
            }

            // if input value is not provided
            else throw ERROR_001;
        } catch (error) {
            this.messengerModule.say(error);
        } finally {
            // end loading
            this.setState({
                loadingVisible: false
            });
        }
    }

    // *********************
    // View
    // *********************

    render() {
        return (
            <div
                style={{
                    height: "100vh",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                className="loginPage"
            >
                <div style={{
                    width: "100%",
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 23
                }}>
                    <img
                        src={require("../assets/kichenLogo.png")} 
                        alt="" 
                        style={{
                            width: 150,
                            height: 150,
                            borderRadius: 12
                        }} 
                    />
                    <p 
                        style={{
                            fontSize: 25,
                            fontWeight: 500,
                        }} 
                        className="loginTitle">
                        KWA
                    </p>
                    <TextField
                        style={{
                            height: 50,
                            width: 250,
                            margin: 10
                        }}
                        className="loginInput"
                        label={i18nService.t('text_account')}
                        value={this.state.account}
                        onChange={(event) => this.setState({ account: event.target.value })}
                        variant="outlined"
                        type="text"
                        onKeyUp={(event) => { if (event.keyCode === 13) { this.signInClick() } }}
                    />
                    <TextField
                        style={{
                            height: 50,
                            width: 250,
                            margin: 10
                        }}
                        className="loginInput"
                        label={i18nService.t('text_password')}
                        value={this.state.password}
                        onChange={(event) => this.setState({ password: event.target.value })}
                        variant="outlined"
                        // $ Add this type will cause a known bug in PC Chrome: 
                        // $ The background color of the input is forced to white and the style is invalid. 
                        // $ This should be related to the Chrome automatic record password feature.
                        // $ If you put the password input above the account input, you may avoid this problem.
                        type="password"
                        onKeyUp={(event) => { if (event.keyCode === 13) { this.signInClick() } }}
                    />
                    <Button
                        style={{
                            height: 50,
                            width: 250,
                            margin: 10,
                            borderRadius: 16,
                            marginBottom: 40
                        }}
                        variant="contained"
                        // color="primary"
                        color="secondary"
                        onClick={this.signInClick}
                    >
                        {i18nService.t('button_ok')}
                    </Button>
                </div >
                <div className="loginText" style={{ fontSize: 16, flex: 1 }}>2020 MAIN TANK SRL. ALL RIGHTS RESERVED</div>
                {/* Loading */}
                <LoadingComponent key={"" + this.state.loadingVisible} style={{ position: "absolute", right: 20, top: 20, width: 60, height: 60 }} visible={this.state.loadingVisible} />

            </div >
        );
    }

}

export default withSnackbar(SettingVertify as any);

// *********************
// Types
// *********************

type State = {
    account: string;
    password: string;
    serveUrl: string;
    loadingVisible: boolean;
}

type Props = {
} & WithSnackbarProps;
