import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/login.page';
import JobSelection from './pages/job-selection.page';
import Chef from './pages/chef.page';
import Waiter from './pages/waiter.page';
import Setting from "./pages/setting.page";
import MealDisplay from "./pages/meal-display.page"
import SettingVertify from './pages/setting-vertify.page';

const BasicRoute = () => (
    <HashRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/JobSelection" component={JobSelection} />
            {/* <Route exact path="/Waiter" component={Waiter} /> */}
            <Route exact path="/Chef" component={Chef} />
            <Route exact path="/MealDisplay" component={MealDisplay} />
            <Route exact path="/Waiter" component={Waiter} />
            <Route exact path="/Setting" component={Setting} />
            <Route exact path="/SettingVertify" component={SettingVertify} />
        </Switch>
    </HashRouter>
);


export default BasicRoute;