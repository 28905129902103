import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { StateManager } from '../modules/state';

export default class DialogComponent extends Component<DialogComponentProps, DialogComponentState> {

    constructor(props: DialogComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
            visible: this.props.visible || false,
            title: this.props.title || "",
            content: this.props.content || "",
            cancelButtonText: this.props.cancelButtonText || "",
            confirmButtonText: this.props.confirmButtonText || "",
            checkBoxValue: this.props.checkBoxSettingValue ? StateManager.get(this.props.checkBoxSettingValue) : false
        }
    };

    // *********************
    // View
    // *********************

    render(this: any) {
        return (
            <Dialog
                open={this.state.visible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClick={(e)=>{e.stopPropagation();}}
                className="cardDialog"
            >
                <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{whiteSpace:'pre',color:'inherit'}} id="alert-dialog-description">{this.state.content}</DialogContentText>
                    {typeof this.props.contentFunction === 'function' ? this.props.contentFunction() : null}
                    <FormControlLabel
                        style={this.props.checkboxText ? {opacity: 0.65,marginTop: 20}: {display:'none'}}
                        control={
                        <Checkbox
                            checked={!this.state.checkBoxValue}
                            onChange={()=>{
                                this.setState({
                                    checkBoxValue: !this.state.checkBoxValue
                                },()=>{
                                    if(this.props.checkBoxSettingValue) StateManager.setFromCache(this.props.checkBoxSettingValue, this.state.checkBoxValue);
                                })
                            }}
                            value="checkBoxValue"
                            color="primary"
                        />
                        }
                        label={this.props.checkboxText}
                    />
                </DialogContent>
                <DialogActions style={{
                    justifyContent:'flex-start',
                    paddingLeft: 24,
                    marginBottom: 10
                }}>
                    <Button
                        onClick={(e) => {
                            if (this.props.onCancelFn) this.props.onCancelFn(e);
                        }}
                        size="large"
                        variant="contained"
                        color="secondary"
                        className="dialogButton"
                        autoFocus
                        style={{
                            borderRadius: 8
                        }}
                    >
                        {this.state.cancelButtonText}
                    </Button>
                    <Button
                        style={{marginLeft: 24,borderRadius: 8}}
                        onClick={(e) => {
                            if (this.props.onConfirmFn) this.props.onConfirmFn(e);
                        }}
                        size="large"
                        className="dialogButton"
                        variant="contained"
                        color="secondary"
                    >
                        {this.state.confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

// *********************
// States
// *********************

type DialogComponentProps = {
    visible: boolean;
    title: string;
    content: string;
    cancelButtonText: string;
    confirmButtonText: string;
    checkboxText?: string;
    checkBoxSettingValue?: string;
    contentFunction?: ()=> React.ReactNode;
    onCancelFn?: (e:any) => void;
    onConfirmFn?: (e:any) => void;
}

type DialogComponentState = {
    visible: boolean;
    title: string;
    content: string;
    cancelButtonText: string;
    confirmButtonText: string;
    checkBoxValue: boolean;
}
