
export class BlockerModule {

    public static setButtonState(
        buttonNames: string[]
    ): any {
        let buttonState: any = {};
        buttonNames.forEach(
            (b: string) => {
                // is string
                if (typeof b === "string")
                    buttonState[`${b}_lock`] = false;
            }
        );
        return buttonState;
    }

}