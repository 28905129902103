import React, { Component } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { TextField, Fab, FormControl, Select, MenuItem } from "@material-ui/core";
import { GlobalStateType as GST } from "../modules/state";
import { StateManager, GlobalState } from '../modules/state';
import i18nService from '../i18n';
import { Request } from "../modules/request"
import { MessengerModule } from "../modules/messenger/module";
import { ERROR_0013 } from "../constants/error";
import { Unsubscribe } from 'redux';

class PrinterComponent extends Component<PrinterComponentProps, PrinterComponentState> {

    constructor(props: PrinterComponentProps) {
        super(props);

        // binding `this`
        this.updateGlobalState = this.updateGlobalState.bind(this);
        this.testPrinterUrl = this.testPrinterUrl.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);
        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        // state
        this.state = {
            printerUrl: StateManager.get("printerUrl"),
            printerBrandType: StateManager.get("printerBrandType"),
            printerConnectionType: StateManager.get("printerConnectionType"),
            serveUrl: StateManager.get("serveUrl"),
            disabledBtn: false,
        }

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("setting-printer-update", () => {
            // update i18n
            i18nService.changeLanguage(StateManager.get("language"));
            // update local state
            this.setState({
                printerUrl: StateManager.get("printerUrl"),
                serveUrl: StateManager.get("serveUrl"),
            } as any);
        });

    }

    private messengerModule: MessengerModule;

    private unsubscribe: Unsubscribe;

    // *********************
    //  Life Cycle Function
    // *********************

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    // *********************
    // Functions
    // *********************

    /**
     * update global state by action
     * @param val 
     */
    public updateGlobalState(action: GST.Action) {
        GlobalState.dispatch(action);
    };

    /**
     * 测试函数
     */
    private async testPrinterUrl() {
        try {
            // init
            this.setState({ disabledBtn: true });
            let res = await Request.testPrinter(
                this.state.printerBrandType,
                this.state.printerUrl,
                this.state.serveUrl,
            );
            // check
            if (!res) throw ERROR_0013
            this.setState({ disabledBtn: false });
            this.messengerModule.say({
                message: "text_printer_tested_successfully",
                level: "Success",
            });
        } catch (error) {
            this.messengerModule.say(error);
            this.setState({ disabledBtn: false });
            throw ERROR_0013
        }
    };

    // *********************
    // View
    // *********************

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", marginBottom: 20 }}>
                <span style={{ height: 50, fontSize: 25, fontWeight: "bold", display: "flex", textAlign: "center" }}> {i18nService.t('text_printer_address')}</span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        style={{ height: 50 }}
                        value={this.state.printerUrl}
                        onChange={(event: any) => this.updateGlobalState({
                            type: "SET_BY_PATH",
                            path: 'printerUrl',
                            value: event.target.value
                        })}
                        variant="outlined"
                        type="text"
                    />
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", paddingTop: 20 }}>
                        <FormControl variant="outlined" style={{ flex: 1, marginRight: 20 }} >
                            <Select
                                value={this.state.printerBrandType}
                                onChange={(event: any) => this.updateGlobalState({
                                    type: "SET_BY_PATH",
                                    path: `printerBrandType`,
                                    value: event.target.value
                                })}
                            >
                                <MenuItem value={"EPSON"}>EPSON</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ flex: 1 }} >
                            <Select
                                value={this.state.printerConnectionType}
                                onChange={(event: any) => this.updateGlobalState({
                                    type: "SET_BY_PATH",
                                    path: `printerConnectionType`,
                                    value: event.target.value
                                })}
                            >
                                <MenuItem value={"tcp"}>tcp</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", paddingBottom: 20, paddingTop: 16 }}>
                        <Fab color="secondary" variant="extended" aria-label="delete" style={{fontSize: 10, display: "flex", width: 80 }} disabled={this.state.disabledBtn} onClick={this.testPrinterUrl} >
                            {/* @ts-ignore */}
                            {`${i18nService.t("button_test")}`}
                        </Fab>
                    </div>
                </div>
            </div>
        )
    }

}

export default withSnackbar(PrinterComponent as any);

// *********************
// Type
// *********************

type PrinterComponentProps = {
} & WithSnackbarProps;

type PrinterComponentState = {
    printerUrl: any;
    printerBrandType: any;
    printerConnectionType: any;
    serveUrl: string;
    disabledBtn: boolean;
}
