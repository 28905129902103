import axios from "axios";
import { PrintData } from "./cardProcessor/type";
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(
    err
) {
    let res = err.response;
    var config = err.config;
    // If response not exist or the response status not equal 500, reject
    if (!res || !(res.status === 500)) return Promise.reject(err);
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.params || !config.params.retry) return Promise.reject(err);
    // Set the variable for keeping track of the retry count
    config.params.__retryCount = config.params.__retryCount || 0;
    // Check if we've maxed out the total number of retries
    if (config.params.__retryCount >= config.params.retry) {
        // Reject with the error
        return Promise.reject(err);
    }

    // Increase the retry count
    config.params.__retryCount += 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve: (value?: unknown) => void) {
        setTimeout(function () {
            resolve();
        }, config.params.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
        return axios(config);
    });
});
export class Request {
    /**
     * 获取系统设置
     * @param serverUrl 服务器地址
     * @param part 只获取对应的部分设置
     */
    public static async getSettingInfo(
        serverUrl: string,
        part?: number
    ): Promise<any[]> {
        try {
            if (part) return await axios.get(`${serverUrl}/api/v2/settings/${part}`);
            else return await axios.get(`${serverUrl}/api/v2/settings`);
        } catch (error) {
            return false as any;
        }
    }

    /**
     * 登录接口
     * @param id 账户名
     * @param password 账户密码
     * @param serverUrl 服务器地址
     */
    public static async login(
        id: number | string,
        password: number | string,
        serverUrl: string,
    ): Promise<any> {
        try {
            return await axios.post(`${serverUrl}/api/v1/kds/employee/login`, {
                id, password
            });
        } catch (error) {
            return false;
        }
    }

    /**
     * 登录接口
     * @param id 账户名
     * @param serverUrl 服务器地址
     */
     public static async getEmployee(
        id: number | string,
        serverUrl: string,
    ): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v2/employee_name/${id}`);
        } catch (error) {
            return false;
        }
    }

    /**
     *  厨师端获取数量统计
     * @param position 设置中选中的岗位
     * @param comboMode 设置中的是否显示促销套餐
     * @param is_package 是否套餐
     * @param rule 规则
     * @param needShowTables 需要显示的桌台
     * @param serverUrl 服务器地址
     */
    public static async chefGetAllCount(
        position: Array<any>,
        comboMode: string,
        is_package: boolean,
        rule: {
            isPrintDevice: boolean,
            menu_id: number | string,
            turn_enable: boolean
        },
        needShowTables: Array<any> | undefined,
        serverUrl: string
    ): Promise<number[]> {
        try {
            let requestArr = [] as any;
            for (const item of ["kitchen", "prepareDish", "history", "suspend", "cancelled"]) {
                let body = {
                    printer: position,
                    role: "chef",
                    section: item,
                    comboMode: comboMode,
                    is_package,
                    needShowTables,
                    rule
                };
                let request = () => {
                    return axios.post(
                        `${serverUrl}/api/v2-1/orders/dishes/count`,
                        body
                    );
                };
                requestArr.push(request());
            }
            return (
                await axios.all(requestArr).then(
                    axios.spread(
                        (noMake: any, prepare: any, made: any, paused: any, cancelled: any) => {
                            return [
                                noMake.data["COUNT(*)"],
                                prepare.data["COUNT(*)"],
                                made.data["COUNT(*)"],
                                paused.data["COUNT(*)"],
                                cancelled.data["COUNT(*)"]
                            ];
                        }
                    )
                )
            )
        } catch (error) {
            return false as any;
        }
    }

    /**
     *  服务员端获取数量统计
     * @param position 设置中选中的岗位(公共)
     * @param waiterPosition 设置中选中的服务员岗位 
     * @param comboMode 设置中的是否显示促销套餐
     * @param is_package 是否套餐
     * @param rule 规则
     * @param needShowTables 需要显示的桌台
     * @param serverUrl 服务器地址
     */
    public static async waiterGetAllCount(
        position: Array<any>,
        waiterPosition: Array<any>,
        comboMode: string,
        // turnEnable: boolean,
        is_package: boolean,
        rule: {
            isPrintDevice: boolean,
            menu_id: number | string,
            turn_enable: boolean
        },
        needShowTables: Array<any> | undefined,
        serverUrl: string
    ): Promise<any> {
        let requestArr = [] as any;
        for (const item of ['chefkitchen', "prepareDish", "kitchen", "history", "suspend", "cancelled"]) {
            let body = {
                printer: item === "chefkitchen" ? waiterPosition : position,
                role: "waiter",
                section: item,
                comboMode: comboMode,
                is_package,
                needShowTables,
                rule
            };
            let request = () => {
                return axios.post(
                    `${serverUrl}/api/v2-1/orders/dishes/count`,
                    body
                );
            };
            requestArr.push(request());
        }
        return (
            await axios.all(requestArr).then(
                axios.spread(
                    (chefNoMake: any, prepare: any, noMake: any, made: any, paused: any, cancelled: any) => {
                        return [
                            chefNoMake.data["COUNT(*)"],
                            prepare.data["COUNT(*)"],
                            noMake.data["COUNT(*)"],
                            made.data["COUNT(*)"],
                            paused.data["COUNT(*)"],
                            cancelled.data["COUNT(*)"]
                        ];
                    }
                )
            )
        )
    }

    /**
     *  用户数量校验
     * @param id 用户登录账号
     * @param serverUrl 服务器地址
     */
    public static async certificate(
        id: number | string,
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.patch(`${serverUrl}/api/v1/certificate`, {
                id
            });
        } catch (error) {
            return false;
        }
    }


    /**
     *  测试打印机
     * @param type 设置中的选择的打印机
     * @param theinterface 由设置中的测试时选中的打印机型号,打印机名称,打印机端口拼接成的
     * @param serverUrl 服务器地址
     */
    public static async testPrinter(
        type: any,
        theinterface: string,
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.post(`${serverUrl}/api/v1/printer/test/ip`, {
                type,
                theinterface
            });
        } catch (error) {
            return false;
        }
    }

    /**
     * 测试服务器
     * @param serverUrl 服务器地址
     */
    public static async testServer(
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v1/ping`);
        } catch (error) {
            return false;
        }
    }

    /**
     * 测试数据库
     * @param serverUrl 服务器地址
     */
    public static async testDataBase(
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v1/kds/test`);
        } catch (error) {
            return false;
        }
    }

    /**
     * 打印小票
     * @param data 打印数据
     * @param serverUrl 服务器地址
     */
    public static async printReceipt(
        data: any,
        serverUrl: string,
    ): Promise<any> {
        try {
            return await axios.post(`${serverUrl}/api/v1/printer/dishTips/ip`, data);
        } catch (error) {
            return false;
        }
    }

    /**
     * 更新菜品数据
     * @param ids order_detail_id 的数组
     * @param key 要更新的字段
     * @param value 要更新字段对应的值
     * @param rule 规则
     */
    public static async updateDish(
        ids: Array<any>,
        key: Array<string>,
        value: Array<any>,
        rule: {
            isPrintDevice: boolean,
            menu_id: number | string,
            turn_enable: boolean
        },
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.patch(`${serverUrl}/api/v2-1/orders/dishes`, {
                ids,
                key,
                value,
                rule
            });
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取菜品数据
     * @param position 岗位
     * @param role 角色
     * @param section 菜品的状态 "kitchen" | "history" | "suspend" | "cancelled" | "chefkitchen" | "prepareDish",
     * @param page 当前页码,注意,0才是代表第一页
     * @param perPage 每页数据容量
     * @param sort 数据升序或降序
     * @param is_package 是否获取套餐下的菜品
     * @param rowCount 当前桌的菜品总数,用来计算有多少分页
     * @param tableCount 是否统计每一桌的菜数量
     * @param needShowTables 需要显示的桌台
     * @param rule 规则
     * @param serverUrl 服务器地址
     */
    public static async getDishes(
        position: Array<any>,
        waiter_order_position: Array<any>,
        role: "waiter" | "chef",
        section: string,
        page: number,
        perPage: number,
        sort: 'positive' | 'reverse',
        is_package: boolean,
        // handleCondiment: boolean,
        rowCount: boolean,
        tableCount: boolean,
        needShowTables: Array<any> | undefined,
        rule: {
            isPrintDevice: boolean,
            menu_id: number | string,
            turn_enable: boolean,
            sort: string
        },
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.post(`${serverUrl}/api/v2-1/orders/dishes`, {
                printer: position,
                waiter_order_position,
                role,
                section,
                page,
                perPage,
                sort,
                is_package,
                handleCondiment: true,
                rowCount,
                tableCount,
                needShowTables,
                rule
            });
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取岗位数据
     * @param serverUrl 服务器地址
     */
    public static async getPositions(
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v1/kds/printers`);
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取打印机数据
     * @param serverUrl 服务器地址
     */
    public static async getPrinters(
        serverUrl: string
    ): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v1/kds/printers/location`);
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取菜品合并种类
     * @param serverUrl 服务器地址
     */
    public static async getDishMergeType(serverUrl: string): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v1/settings/mergetypes`);
        } catch (error) {
            return false;
        }
    }

    /**
     * 打印小票
     * @param serverUrl 服务器地址
     */
    public static async printTip(serverUrl: string, printData: PrintData, retry?: number, retryDelay?: number): Promise<any> {
        try {
            // const client = axios.create({ baseURL: `${serverUrl}` });
            let obj: any = {
                retry: retry ? retry : 5,
                retryDelay: retryDelay ? retryDelay : 1000
            }
            // axiosRetry(axios, obj);
            // printData = Object.assign({},printData,{
            //     retry: 5,
            //     retryDelay: 100
            // })
            if (printData.data.length > 0) return await axios.post(`${serverUrl}/api/v1/printer/dishTips/ip`, printData, { params: obj })
            else return false
        } catch (error) {
            return false;
        }
    }
    /**
     * 获取桌台区域
     * @param serverUrl 服务器地址
     */
    public static async getTablesInfo(serverUrl: string, ): Promise<any> {
        try {
            return await axios
                .get(`${serverUrl}/api/v2/tables`, {
                    params: {
                        with_section: true,
                        with_order: false,
                    }
                })
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取所有订单
     */
    public static async getTakedishes(serverUrl: string, isMake: Array<number | null>, rule: object, needShowTables: Array<any> | undefined, sort?: 'positive' | 'reverse', sortType?: 'orderTime' | 'endTime'): Promise<any> {
        try {
            return await axios
                .post(`${serverUrl}/api/v2-1/orders/takedishes`, {
                    isMake: isMake,
                    sort: sort ? sort : "positive",
                    sortType: sortType ? sortType : 'orderTime',
                    needShowTables: needShowTables,
                    rule: rule
                })
        } catch (error) {
            return false;
        }
    }

    /**
     * 催菜
     * @param serverUrl 服务器地址
     * @param ids 更新的order_detail_id数组
     * @param addNumber 催菜增加的数量
     */
    public static async rushDish(serverUrl: string, ids: Array<number>, addNumber?: Number): Promise<any> {
        try {
            return await axios
                .patch(`${serverUrl}/api/v2/orders/dishes/rush/add`, {
                    ids: ids,
                    addNumber: addNumber ? addNumber : 1
                })
        } catch (error) {
            return false;
        }
    }

    /**
     * 获取退菜，取消制作原因
     */
    public static async getReason(serverUrl: string): Promise<any> {
        try {
            return await axios
                .get(`${serverUrl}/api/v2/dishes/returnReason`)
        } catch (error) {
            return false;
        }
    }

    /**
     * 导出日志
     */
    public static async exportLog(serverUrl:string,params: {log: any,name:string}): Promise<any> {
        try {
            return await axios.post(`${serverUrl}/api/v2.5/kwalog`,params)
        } catch (error) {
            throw error;
        }
    }

    /**
     * 获取RBP设置中的轮次权重值
     */
     public static async getRoundWeightValue(serverUrl:string): Promise<any> {
        try {
            return await axios.get(`${serverUrl}/api/v2/shark/round_weight_value`)
        } catch (error) {
            throw error;
        }
    }

}
