import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import Fab from '@material-ui/core/Fab';
import { StateManager, INIT_STATE, getSelectConfig } from '../modules/state';
import _ from 'lodash';
import i18nService from '../i18n';
export default class PrettoSlidersComponent extends Component<PrettoSlidersComponentProps, PrettoSlidersComponentState> {

    constructor(props: PrettoSlidersComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        // state
        this.state = {
            value: StateManager.getFromCache(this.props.settingValue),
            progressDisabled: false,
            resetBtnDisabled: false,
        }
    };

    // *********************
    // View
    // *********************

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", }} >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", marginBottom: 20, }}>
                    <div style={{ display: "flex",flexDirection:'column', flex: 7,fontWeight: "bold"}}>
                        <span style={{ fontSize: 16 }}>{this.props.settingText}</span>
                        <span style={{ color:'#999',paddingTop:5,fontSize: 12, display: this.props.settingSubText ? 'inline':'none' }}>{this.props.settingSubText}</span>
                    </div>
                    <Fab
                        variant="extended"
                        aria-label="delete"
                        disabled={this.state.resetBtnDisabled}
                        color="secondary"
                        style={{ fontSize: 10, display: "flex", minWidth: 80, marginTop: 10 }}
                        onClick={async () => {
                            if (this.state.progressDisabled === false) {
                                this.setState({ resetBtnDisabled: true });
                                let initValue = _.get(INIT_STATE[getSelectConfig()], this.props.settingValue);
                                this.setState({ value: initValue });
                                StateManager.setFromCache(this.props.settingValue, initValue);
                                this.setState({ resetBtnDisabled: false });
                            } else {
                                this.setState({ resetBtnDisabled: false });
                            }
                        }}
                    >
                        {i18nService.t('button_reset')}
                    </Fab>
                </div>
                <Slider
                    style={{ marginBottom: 10 }}
                    valueLabelDisplay="auto"
                    min={this.props.min || 0}
                    max={this.props.max || 100}
                    value={this.state.value}
                    onChange={
                        async (event: any, newValue: any) => {
                            if (this.state.progressDisabled === false) {
                                this.setState({ progressDisabled: true });
                                this.setState({ value: newValue });
                                this.setState({ progressDisabled: false });
                            } else {
                                this.setState({ progressDisabled: false });
                            }

                        }
                    }
                    onChangeCommitted={
                        (event: React.ChangeEvent<{}>, value: number | number[]) => StateManager.setFromCache(this.props.settingValue, value)
                    }
                />

            </div >
        );
    }
}

// *********************
// States
// *********************

type PrettoSlidersComponentProps = {
    settingValue: string;
    settingText: string;
    settingSubText?: string;
    max?: number;
    min?: number;
}

type PrettoSlidersComponentState = {
    value: any;
    progressDisabled?: boolean;
    resetBtnDisabled: boolean;
}


