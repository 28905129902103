import React, { Component } from 'react';
import { Request } from "../modules/request"
import { StateManager, GlobalState } from '../modules/state';
import i18nService from '../i18n';
import { TextField, Fab } from '@material-ui/core';
import { GlobalStateType as GST } from "../modules/state";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { MessengerModule } from "../modules/messenger/module";
import { ERROR_0014, ERROR_0015 } from "../constants/error";
import { Unsubscribe } from 'redux';

class ServesComponent extends Component<ServesComponentProps, ServesComponentState> {
    constructor(props: ServesComponentProps) {
        super(props);

        // binding `this`
        this.updateGlobalState = this.updateGlobalState.bind(this);
        this.testServerUrl = this.testServerUrl.bind(this);
        this.resetServerUrl = this.resetServerUrl.bind(this);
        this.testDatabaseServerUrl = this.testDatabaseServerUrl.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);

        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        // state
        this.state = {
            inputsvalue: StateManager.get("serveUrl"),
            testServerUrlDisabledBtn: false,
            testDatabaseServerUrlDisabledBtn: false,
            resetServerUrlDisabledBtn: false,
        }

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("setting-serve-update", () => {
            // update i18n
            i18nService.changeLanguage(StateManager.get("language"));
            // update local state
            this.setState({
                inputsvalue: StateManager.get("serveUrl")
            } as any);
        });

    };

    private messengerModule: MessengerModule;

    private unsubscribe: Unsubscribe;

    // *********************
    // Life Cycle Function
    // *********************

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    // *********************
    // Functions
    // *********************

    /**
     * update global state by action
     * @param val 
     */
    public updateGlobalState(action: GST.Action) {
        GlobalState.dispatch(action);
    };

    /**
     * 测试函数
     */
    private async testServerUrl() {
        try {
            if (this.state.testServerUrlDisabledBtn === false) {
                // init
                this.setState({ testServerUrlDisabledBtn: true });
                let res = await Request.testServer(this.state.inputsvalue);
                // check
                if (!res) throw ERROR_0014
                this.setState({ testServerUrlDisabledBtn: false });
                this.messengerModule.say({
                    message: "text_server_address_test_succeeded",
                    level: "Success",
                });
            }
        } catch (error) {
            this.messengerModule.say(error);
            this.setState({ testServerUrlDisabledBtn: false });

        }
    }

    /**
     * 重置服务器地址
     */
    private async resetServerUrl() {
        try {
            if (this.state.resetServerUrlDisabledBtn === false) {
                this.setState({ resetServerUrlDisabledBtn: true });
                this.updateGlobalState({
                    type: "SET_BY_PATH",
                    path: 'serveUrl',
                    value: "http://localhost:3000"
                });
                this.setState({ resetServerUrlDisabledBtn: false })
                this.messengerModule.say({
                    message: "text_reset_server_address_successfully",
                    level: "Success",
                });
            }
        } catch (error) {
            this.messengerModule.say(error);
        }
    }

    /**
     * 测试数据库连接
     */
    private async testDatabaseServerUrl() {
        try {
            if (this.state.testDatabaseServerUrlDisabledBtn === false) {
                // init
                this.setState({ testDatabaseServerUrlDisabledBtn: true });
                let res = await Request.testDataBase(this.state.inputsvalue);
                // check
                if (!res) throw ERROR_0015;
                // success
                this.setState({ testDatabaseServerUrlDisabledBtn: false });
                this.messengerModule.say({
                    message: "text_successful_database_connection",
                    level: "Success",
                });
            }
        } catch (error) {
            this.messengerModule.say(error);
            this.setState({ testDatabaseServerUrlDisabledBtn: false });
        }
    }

    // *********************
    // View
    // *********************

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", marginBottom: 20 }}>
                <span style={{ height: 50, fontSize: 25, fontWeight: "bold", display: "flex", textAlign: "center"}}> {i18nService.t('text_server_address')}</span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        style={{ height: 50 }}
                        value={this.state.inputsvalue}
                        onChange={(event: any) => this.updateGlobalState({
                            type: "SET_BY_PATH",
                            path: 'serveUrl',
                            value: event.target.value
                        })}
                        variant="outlined"
                        type="text"
                    />
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", paddingTop: 20, paddingBottom: 20 }}>
                        <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, marginRight: 10, display: "flex", width: 80 }} disabled={this.state.testServerUrlDisabledBtn} onClick={this.testServerUrl} >
                            {i18nService.t("button_test")}
                        </Fab>
                        <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, marginRight: 10, display: "flex", width: 80 }} disabled={this.state.resetServerUrlDisabledBtn} onClick={this.resetServerUrl}>
                            {i18nService.t('button_reset')}
                        </Fab>
                        <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, minHeight: 50, width: 120 }} disabled={this.state.testDatabaseServerUrlDisabledBtn} onClick={this.testDatabaseServerUrl}>
                            {i18nService.t('button_test_database_connection')}
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(ServesComponent as any);

// *********************
// Type
// *********************

type ServesComponentProps = {
} & WithSnackbarProps;

type ServesComponentState = {
    inputsvalue?: any;
    testServerUrlDisabledBtn: boolean;
    testDatabaseServerUrlDisabledBtn: boolean;
    resetServerUrlDisabledBtn: boolean;
}
