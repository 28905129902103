import { DataSoruce, MergeRules, MergeObject, MagnetRules, SortRules, MergeArray } from "./type";
import { dish } from "../cardProcessor/type";
import { CARD_NORMAL_STATUS } from "./const";
const uuidv4 = require('uuid/v4');
export class MergeProcessor {

	/**
	 * 根据rule生成对应的rule key
	 * @param dish 要生成rule key的菜品
	 * @param rule 合并规则
	 */
    private static genMergeRuleKey(dish: dish, rule: MergeRules | MagnetRules): string {
        let mergeRuleKey = '';
        for (let ruleIndex = 0; ruleIndex < rule.data.length; ruleIndex++) {
            let currentRule = rule.data[ruleIndex];
            if (dish.hasOwnProperty(currentRule.key)) {
                if (currentRule.hasOwnProperty('value')) {
                    if (Array.isArray(currentRule['value'])) {
                        let key = '';
                        // 这是判断产生的key值是否需要唯一
                        if(currentRule.logic === 'AND'){
                            key = uuidv4();
                        } else {
                            key = 'false'
                        }
                        // 如果value是一个数组，则需要判断数组中每一个值是否与当前字段的值相同，相同则把value存到mergeRuleKey，否则就存放uuid到mergeRuleKey
                        for (let i = 0; i < currentRule['value'].length; i++) {
                            if (`${currentRule['value'][i]}` === `${dish[currentRule.key]}`) {
                                key = currentRule['value'][i];
                                break;
                            }
                        }
                        mergeRuleKey = `${mergeRuleKey}${key}#`
                    } else {
                        let key = '';
                        // 这是判断产生的key值是否需要唯一
                        if(currentRule.logic === 'AND'){
                            key = uuidv4();
                        } else {
                            key = `X${currentRule.value}`;
                        }
                        mergeRuleKey = `${mergeRuleKey}${`${currentRule.value}` === `${dish[currentRule.key]}` ? currentRule.value : key}#`
                    }
                } else {
                    // 没有value的情况下直接拿当前字段的值作为mergeRuleKey
                    let keyValue = dish[currentRule.key] === '' || dish[currentRule.key] === null || dish[currentRule.key] === undefined ? null : dish[currentRule.key]
                    mergeRuleKey = `${mergeRuleKey}${keyValue}#`
                }
            }
        }
        // 去掉最后一个#号
        return mergeRuleKey = mergeRuleKey.substring(0, mergeRuleKey.length - 1)
    }

	/**
	 * 获取额外栏位并根据设置赋值。
	 * @param dish 菜品
	 * @param rule 规则
	 * @param compareObject 比较的对象 
	 */
    private static getExtraFileds(dish: dish, rule: MergeRules | MagnetRules , compareObject: any): Object {
        // 新增额外的栏位
        if (rule && rule.hasOwnProperty('extraField')) {
            let extraFiled = rule['extraField']!.split(',');
            extraFiled.forEach((target: any) => {
                if (target.indexOf(':') !== -1) {
                    // 根据规则的设置，获取最大或者最小值
                    let value = target.split(':')
                    // 此比较规则至少需要3个值，分别是字段名，类型，需要比较大小max，min
                    if (value.length < 3) return

                    if (compareObject.hasOwnProperty(value[0]) && dish.hasOwnProperty(value[0])) {
                        switch (value[1]) {
                            case 'date':
                                if (value[2] === 'max') {
                                    compareObject[value[0]] = new Date(compareObject[value[0]]).getTime() < new Date(dish[value[0]]).getTime() ? dish[value[0]] : compareObject[value[0]]
                                } else {
                                    compareObject[value[0]] = new Date(compareObject[value[0]]).getTime() > new Date(dish[value[0]]).getTime() ? dish[value[0]] : compareObject[value[0]]
                                }
                                break;
                            case 'number':
                                if (value[2] === 'max') {
                                    compareObject[value[0]] = parseFloat(compareObject[value[0]]) < parseFloat(dish[value[0]]) ? dish[value[0]] : compareObject[value[0]]
                                } else {
                                    compareObject[value[0]] = parseFloat(compareObject[value[0]]) > parseFloat(dish[value[0]]) ? dish[value[0]] : compareObject[value[0]]
                                }
                                break;
                            default:
                                break;
                        }
                    } else compareObject[value[0]] = dish[value[0]]
                } else if (target.indexOf('=') !== -1) {
                    // 把规则设置的值赋值过去
                    let value = target.split('=')
                    if (value[1] === 'false') value[1] = false;
                    if (value[1] === 'true') value[1] = true;
                    // 如果原来就有数据，则用原来的数据
                    compareObject[value[0]] = compareObject[value[0]] ? compareObject[value[0]] : value[1]
                } else {
                    // 直接把菜品的数据赋值过去
                    compareObject[target] = dish[target]
                }

            })
        }
        return compareObject;
    }
	/**
	 * 把菜品存放到mergeObject适合位置中
	 * @param dish 菜品
	 * @param mergeRuleKey 根据合并规则产生的 rule key
	 * @param rule 合并规则
	 * @param mergeObject 存放合并后的数据对象
	 */
    private static storeDish(dish: dish, mergeRuleKey: string, rule: MergeRules | MagnetRules, mergeObject: MergeObject) {
        // 根据key把对应的菜品存放到对应的位置上
        if (mergeObject.hasOwnProperty(mergeRuleKey)) {
            // 判断当前存的数据长度是否已经到了rules中设置的最大容量
            let mergeObjectRuleDataLen = mergeObject[mergeRuleKey].data.length;
            if (mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen - 1].dishes.length < rule.capacity) {
                // 合并额外的字段
                mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen - 1] = Object.assign(mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen - 1], MergeProcessor.getExtraFileds(dish, rule, mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen - 1]))
                // 添加新的菜品
                mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen - 1].dishes.push(dish)
            }
            else mergeObject[mergeRuleKey].data[mergeObjectRuleDataLen] = Object.assign(
                {
                    dishes: [dish]
                }, 
                MergeProcessor.getExtraFileds(dish, rule, {})
            )
            // 保存当前合并的类型
            mergeObject[mergeRuleKey].mergeType = rule.type
        } else {
            // obj ={ 
            //     aa: {data: [{
            //         dishes: [{} as dish],
            //     }]
            // }   
            // }
            mergeObject[mergeRuleKey] = {
                data: [
                    Object.assign(
                        {
                         dishes: [dish]
                        }, 
                        MergeProcessor.getExtraFileds(dish, rule, {})
                    )
                ],
                mergeType: CARD_NORMAL_STATUS
            }
        }
    }

	/**
	 * 在菜品源数据基础上进行合并
	 * @param dataSource 菜品数据源
	 * @param rules 合并规则
	 */
    private static mergeDishByArray(dataSource: DataSoruce, rule: MergeRules | MagnetRules): MergeObject {
        // 合并后的对象
        let mergeObject: MergeObject = {};
        // 遍历每笔菜品
        for (let index = 0; index < dataSource.length; index++) {
            let target = dataSource[index];
            // 根据合并规则产生对应key
            let mergeRuleKey = MergeProcessor.genMergeRuleKey(target, rule);
            // 把当前菜根据规则存在mergeObject中
            MergeProcessor.storeDish(target, mergeRuleKey, rule, mergeObject);
        }
        return mergeObject
    }

	/**
	 * 在合并后的对象基础上再次进行合并
	 * @param dataSource 菜品数据源
	 * @param rule 合并规则
	 */
    private static mergeDishByObject(dataSource: MergeObject, rule: MergeRules | MagnetRules): MergeObject {
        // 合并后的对象
        let mergeObject: MergeObject = {};
        let sourceKeys = Object.keys(dataSource);
        // 遍历每笔菜品
        for (let index = 0; index < sourceKeys.length; index++) {
            // 如何不是普通状态的菜品，则直接跳过，不需要再次合并
            if (dataSource[sourceKeys[index]].mergeType !== CARD_NORMAL_STATUS) {
                // 多拼接uuid，是为了防止以后的新合并规则可能会产生一样的key值。保证已合并过的数据不会再参与合并
                mergeObject[uuidv4() + sourceKeys[index]] = dataSource[sourceKeys[index]];
                continue;
            };
            let target = dataSource[sourceKeys[index]].data[0].dishes[0];
            // 根据合并规则产生对应key
            let mergeRuleKey = MergeProcessor.genMergeRuleKey(target, rule);

            // 把当前菜根据规则存在mergeObject中
            MergeProcessor.storeDish(target, mergeRuleKey, rule, mergeObject);
        }
        return mergeObject
    }

	/**
	 * 对源数据进行合并
	 * @param dataSource 菜品源数据,传入数据最好符合DataSoruce的格式，any是为了方便单元测试
	 * @param rules 一个存放着所有规则的数组
	 */
    public static merge(dataSource: DataSoruce | any, rules: Array<MergeRules | MagnetRules>): MergeObject {
        let mergeObj: MergeObject = {}
        rules.forEach((rule, index) => {
            if (index === 0) {
                mergeObj = MergeProcessor.mergeDishByArray(dataSource, rule);
            } else {
                mergeObj = MergeProcessor.mergeDishByObject(mergeObj, rule);
            }
        })
        return mergeObj;
    }

	/**
	 * 把合并后的对象转换成数组
	 * @param mergeObject 合并后的对象
	 * @param sortRules 排序规则，对合并在一起的菜品进行排序
	 * @param sortBySame 在排序后的结果，把相同的内容再次排序在一起
	 */
    public static transfromArray(mergeObject: MergeObject, sortRules?: Array<SortRules>, sortBySame?: {
        key: string;
    }): MergeArray {
        let mergeArray: MergeArray = [];
        let keys = Object.values(mergeObject);
        keys.forEach((target) => {
            target.data.forEach(element => {
                // 对当前合并的菜品进行排序
                sortRules && MergeProcessor.sort(element.dishes, sortRules)
                if (sortBySame && sortBySame.key) element.dishes = MergeProcessor.transSameData(element.dishes, sortBySame.key);
                mergeArray.push(Object.assign({}, element, { mergeType: target.mergeType }))
            });
        })
        return mergeArray
    }

    /**
     * 根据key值，把相同的内容排序在一起
     * @param data 需要排序的数据
     * @param key 需要判断的字段
     */
    public static transSameData (data: dish[], key: string) {
        let cache: {
            [key: string]: number
        } = {} // cache存储的键是key对应的值，值是这个key对应的值在indices数组中的下标
        let indices: number[][] = [] // 数组中的每一个值是一个数组，数组中的每一个元素是原数组中相同key对应的值的下标
        data.forEach((item: dish, i: number) => {
            let itemKey = item[key]
            // 没有内容的值不会排序在一起,所以设置一个唯一的值上去   
            if (!itemKey) itemKey = uuidv4();
            let index = cache[itemKey]
            if (index !== undefined) {
                indices[index].push(i)
            } else {
                cache[itemKey] = indices.length
                indices.push([i])
            }
        })
        let result: dish[] = []
        indices.forEach((item: number[]) => {
          item.forEach((index: number) => {
            result.push(data[index]) // 依次把index对应的元素data[index]添加进去即可
          })
        })
        
        return result
      }

	/**
	 * 对合并后的数组进行排序
	 * @param mergeArray 合并后的数组
	 * @param sortRules 排序规则
	 * @param setNullBottom 排序时是否把null放到最底部
	 */
    public static sort(mergeArray: Array<any>, sortRules: Array<SortRules>,setNullBottom?:boolean) {
        mergeArray.sort((a: any, b: any) => {
            let sortValue = 0;
            for(let element of sortRules) {
                if(element.specialRules && a[element.specialRules.key] === element.specialRules.value) {
                    element = element.specialRules.rule
                }
                if (element.type === 'number' || element.type === 'time') {
                    // 默认以最大值来进行排序
                    let aValue = MergeProcessor.getExtremeValue(a, element);
                    let bValue = MergeProcessor.getExtremeValue(b, element);
                    // 按照固定值排序
                    if(element.type === 'number' && element.value !== null && element.value !== undefined) {
                        // 当前的数据的值等于需要排序的值，则根据order的排序方向经行重新赋值排序
                        if (aValue === element.value && aValue === bValue) {
                            // 如果a和b都符合排序规则的值，则需要再次进行下组的排序
                            continue;
                        }
                        else if (aValue === element.value) {
                            // 如果只有a符合排序规则的值，则a根据order的排序进行升序或者降序
                            aValue = element.order === 'Positive' ? 0 : 1;
                            bValue = element.order === 'Positive' ? 1 : 0;
                            sortValue = aValue - bValue;
                            break;
                        } else if (bValue === element.value) {
                             // 如果只有b符合排序规则的值，则b根据order的排序进行升序或者降序
                            aValue = element.order === 'Positive' ? 1 : 0;
                            bValue = element.order === 'Positive' ? 0 : 1;
                            sortValue = aValue - bValue;
                            break;
                        }
                    }
                    // 时间排序的情况下，如果有一个为null，把数据放到最后
                    if(element.type === 'time' && setNullBottom && aValue != bValue) {
                        if (element.order === 'Positive'){
                            // 在null放到后面的模式下，如果a值为0，那就是为null，返回整数b排列到a之前，B为0，返回负数，a排到b之前。
                            if( aValue === 0) {
                                sortValue = 1;
                                continue;
                            }
                            else if(bValue === 0) {
                                sortValue = -1;
                                continue;
                            }
                            else {
                                sortValue = 0
                                continue;
                            }
                        }
                    }
                    if (element.order === 'Positive'){
                        sortValue = aValue - bValue;
                        continue;
                    }
                    else {
                        sortValue = bValue - aValue;
                        continue;
                    }
                } else if (element.type === 'string') {
                    // 目前字符串只会判断第一层结构的数据
                    let avalue = '';
                    let bvalue = '';
                    if (a.hasOwnProperty(element.key)) avalue = a[element.key] ? a[element.key] : '';
                    if (b.hasOwnProperty(element.key)) bvalue = b[element.key] ? b[element.key] : '';
                    if (element.order === 'Positive') {
                        sortValue = avalue.toLowerCase() < bvalue.toLowerCase() ? -1 : 1;
                        continue;
                    }
                    else {
                        sortValue = bvalue.toLowerCase() < avalue.toLowerCase() ? -1 : 1;
                        continue;
                    }
                }
                else {
                    sortValue = 0;
                    continue;
                }
            }
            // sortRules.forEach((element: SortRules) => {
            // });
            return sortValue
        })
        return mergeArray;
    }

	/**
	 * 获取排序中比较数的极值
	 * @param source 需要获取极值的参数
	 * @param sortRule 排序规则
	 */
    private static getExtremeValue(source: any, sortRule: SortRules): any {
        let sourceValue = '0';
        // 默认以最大值来进行排序
        let extremum = sortRule.extremum ? sortRule.extremum : 'max';
        if (source.hasOwnProperty(sortRule.key)) {
            let value = source[sortRule.key] ? source[sortRule.key] : '0';
            sourceValue = sortRule.type === 'number' ? value : new Date(source[sortRule.key]).getTime();
        }
        else {
            if (source.dishes.length > 0) {
                // 先给默认值
                let sourceDefValue: any;
                if (sortRule.type === 'number') sourceDefValue = source.dishes[0][sortRule.key] ? source.dishes[0][sortRule.key] : '0';
                else sourceDefValue = new Date(source.dishes[0][sortRule.key] ? source.dishes[0][sortRule.key] : '0').getTime();
                source.dishes.forEach((target: any) => {
                    let tarValue;
                    if (sortRule.type === 'number') tarValue = target[sortRule.key] ? target[sortRule.key] : '0'
                    else tarValue = new Date(target[sortRule.key] ? target[sortRule.key] : '0').getTime();

                    sourceDefValue = extremum === 'max' ? (sourceDefValue > tarValue ? sourceDefValue : tarValue) : (sourceDefValue < tarValue ? sourceDefValue : tarValue)
                })
                sourceValue = sourceDefValue
            }
        }
        return sourceValue;
    }

	/**
	 * 用已经合并的数据与源数据进行匹配，并对已经合并的数据进行增删改。
	 * @param mergeArray 合并的数据
	 * @param dataSource 源数据 any是为了单元测试，正式数据用dish
	 * @param mergeRules 合并规则
	 * @param notUpdateColumn 在改动卡片数据时，卡片的单独属性不需要更新的栏位。例如当前卡片的选中状态。
	 * @param uniqueKey 数据的唯一值键值
	 */
    public static pairedSourceData(mergeArray: MergeArray, dataSource: Array<dish | any>, mergeRules: Array<MergeRules | MagnetRules>, notUpdateColumn?: Array<string>, uniqueKey?: string) {
        if (!uniqueKey) uniqueKey = 'order_detail_id'
        let newMergeArray = [];
        // 判断是否跳出最外层循环
        newMergeArray = mergeArray.filter((target, indxe: number) => {
            let newDishes: any[] = [];
            // let newTarget = {};
            if (target.hasOwnProperty('dishes')) {
                // 先把菜品和合并类型外的数据删除，方便以后的栏位比较
                Object.keys(target).forEach((key) => {
                    if (!(key === 'dishes' || key === 'mergeType' || (notUpdateColumn && notUpdateColumn.indexOf(key) !== -1))) {
                        delete target[key]
                    }
                })
                target.dishes = target.dishes.filter((dish: dish, dishIndex: number) => {
                    let findIdex = dataSource.findIndex((findTarget: dish) => {
                        return findTarget[uniqueKey!] === dish[uniqueKey!]
                    })
                    if (findIdex !== -1) {
                        // 把源数据的菜品放到新菜品数组中
                        newDishes.push(dataSource[findIdex]);
                        if (target.mergeType) {
                            let rule = mergeRules.find((rule: MergeRules | MagnetRules) => {
                                return rule.type === target.mergeType;
                            })
                            // newTarget = MergeProcessor.getExtraFileds(dataSource[findIdex], rule!, target)
                            // 若找不到匹配的合并类型，则直接取最后一个规则来获取额外栏位
                            if(!rule) rule = mergeRules[mergeRules.length - 1]
                            MergeProcessor.getExtraFileds(dataSource[findIdex], rule!, target)
                        }
                        // 赋值到合并菜品后，源数据中的菜品可以删除了
                        dataSource.splice(findIdex, 1);
                        return true;
                    } else {
                        // 在源数据中没找到合并的菜品，把此菜品删除
                        return false;
                    }
                })
                // dishes里面的菜品已经全部删除，那整个卡片就不需要了
                if (target.dishes.length === 0) return false;
                else {
                    target.dishes = newDishes;
                    return true;
                }
            } else if (target.hasOwnProperty(uniqueKey!)) {
                let findIdex = dataSource.findIndex((findTarget: dish) => {
                    return findTarget[uniqueKey!] === target[uniqueKey!]
                })
                if (findIdex !== -1) {
                    let keys = Object.keys(dataSource[findIdex]);
                    // filter直接把对象赋值没有效果，只能一个个属性去赋值。
                    keys.forEach((key) => {
                        if (dataSource[findIdex].hasOwnProperty(key)) target[key] = dataSource[findIdex][key];
                    })
                    // 赋值到合并菜品后，源数据中的菜品可以删除了
                    dataSource.splice(findIdex, 1);
                    return true;
                } else {
                    // 在源数据中没找到合并的菜品，把此菜品删除
                    return false;
                }
            }
            return true;
        })
        if (dataSource.length > 0) newMergeArray = newMergeArray.concat(dataSource.map((target) => {
            // 这是新的菜品，合并规则是normal,取额外栏位就去规则的最后一个
            let data = {dishes: [target], mergeType: 'normal'};
            MergeProcessor.getExtraFileds(target, mergeRules[mergeRules.length - 1], data)
            return data
        }))
        return newMergeArray;
    }


}