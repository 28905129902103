export default {
    text_language: '语言',
    text_theme: '主题',
    text_account: '账号',
    text_password: '密码',
    text_sign_in: '登入',
    text_server_url: '服务器地址',
    button_save: '保存',
    text_order_detail: "订单详情",
    text_table_mode: "桌台模式",
    text_quick_order: "全视图点餐",
    text_speed_order: "极速点餐",
    text_rwa: "Smartway智能餐厅 _ 服务员点餐",
    text_settings: "设置",
    text_reset: "重置",
    text_confirmation_title: "请确认",
    text_confirmation_content: "您确定吗?",
    button_reset_cache_settings: "重置缓存和设置",
    button_ok: "确认",
    button_cancel: "取消",
    button_no: "否",
    button_yes: "是",
    text_customer_name: "客户名字",
    text_complete: "全部",
    text_dinein: "堂吃",
    text_hall_for_packaging: "堂吃打包",
    text_outside_order: "外送",
    text_to_store_comeUndone: "来店自取",
    text_food_pictures: "菜品图片",
    button_sign_out: "Sign Out",
    text_sign_out: "Sign Out",
    text_card_limit_per_page: "每页卡片上限",
    text_card_capacity_limit: "卡片容量上限",
    text_card_width: "卡片宽度",
    text_card_max_height: "卡片高度最大值",
    text_smart_merge_card_height_maximum: "智能合并卡片高度最大值",
    text_rowing_print: "划菜打印",
    text_chef_control: "厨师控菜",
    text_allowed_to_prepare_vegetables: "允许备菜",
    text_open_quantity_statistics: "开启数量统计",
    text_statistics_on_the_number_of_dishes_per_table: "每桌菜品数量统计",
    text_display_order_number: "显示订单号",
    text_show_dishes_from_the_promotional_package: "显示促销套餐中的菜品",
    text_card_adaptive_height_function: "卡片自适应高度功能",
    text_menu_update_confirmation_box: "菜品更新确认框",
    text_advanced_settings: "高级设置",
    text_data_refresh_cycle: "数据刷新周期",
    text_post: "岗位",
    text_printer: "打印机",
    text_food_combination: "菜品合并种类",
    button_reset: "重置",
    button_unlimited: "无限",
    text_server_address: "服务器地址",
    button_test: " 测试",
    button_test_database_connection: "测试数据库连接",
    text_unable_to_make: "无法制作",
    text_pause_production: "已暂停",
    text_not_yet_made: "已下单",
    text_made: "已制作",
    text_not_serving: "待上菜",
    text_has_served: "已上菜",
    text_paused: "已暂停",
    text_cancelled: "已取消",
    text_order_mode: "订单模式",
    text_did_not_give_the_waiter_no_interface_to_choose_a_post: "未给服务员未制作界面选择岗位",
    text_please_enter_a_valid_username_and_password: "请输入有效的用户名和密码 ",
    text_wrong_username_or_password: "用户名或密码错误",
    text_name1_font_size: "菜品名称1字体大小",
    text_display_name1: "菜品名称1",
    text_card: "卡片",
    text_the_waiter_did_not_create_a_maximum_number_of_cards: "服务员未制作卡片数量上限",
    text_order_mode_card_height_max: "订单模式卡片高度最大值",
    text_display_name2: "菜品名称2",
    text_display_name2_font_size: "菜品名称2字体大小",
    text_dishes_card_notes: "菜品卡片备注",
    text_card_memo_font_size: "卡片备注字体大小",
    text_retry_printing_the_number_of_small_votes: "重试打印小票次数",
    text_the_waite_did_not_make_a_job_selection: "服务员未制作岗位选择",
    text_server_address_test_succeeded: "服务器地址测试成功",
    text_server_address_test_failed_please_re_enter: "服务器地址测试失败,请重新输入",
    text_reset_server_address_successfully: "重置服务器地址成功",
    text_successful_database_connection: "数据库连接成功",
    text_database_connection_failed: "数据库连接失败",
    text_cancel_production: "取消制作",
    text_normal_mode: "普通模式",
    text_confirmation_box: "确认框",
    text_yes: "是",
    text_no: "否",
    text_is_the_current_account_cancelled: "是否注销当前账户？",
    text_do_you_clear_and_reset_all_settings_and_cache: "是否清空并重置所有设置与缓存？",
    text_advanced_setting: "高级设置",
    text_unable_to_get_job_data: '无法获取岗位数据',
    text_job_data_does_not_exist: '岗位数据不存在',
    text_unable_to_get_printer_data: '无法获取打印机数据',
    text_printer_data_does_not_exist: '打印机数据不存在',
    text_unable_to_get_dish_type_data: '无法获取菜品类型数据',
    text_the_dish_type_data_does_not_exist: '菜品类型数据不存在',
    text_cancelled_account: '已注销账户',
    text_all_settings_and_caches_have_been_reset: '已重置所有设置与缓存',
    text_refreshed: '刷新完毕',
    text_printer_tested_successfully: '打印机测试成功',
    text_printer_test_failed: '打印机测试失败',
    text_printer_address: '打印机地址',
    text_please_select_a_job_on_the_settings_page_first: '请先在设置页面选择工作岗位',
    text_please_select_your_job_position: '请选择你的工作岗位',
    text_chef: '厨师',
    text_waiter: '服务员',
    text_smart_merger: '智能合并',
    text_automatic: '自动',
    text_manual: '手动',
    text_no_data: '暂无数据',
    text_function: '功能',
    text_job_position_info: '岗位信息',
    text_unable_to_get_global_setting_info: '无法获取全局设置参数',
    text_unable_to_get_dish_data: "无法获取菜品数据",
    text_unable_init_page: "无法初始化页面",
    text_unable_refresh_data: "无法刷新数据",
    text_unable_get_data_statistics: "无法获取数据统计",
    text_unable_check_data_diff_or_update_data: "获取数据更新失败",
    text_unable_intelligent_merge: "智能合并失败",
    text_unable_do_card_long_touch_action: "卡片长按事件错误",
    text_unable_call_update_like_api_of_dishes: "菜品更新类事件失败",
    text_unknown_mistake: "未知错误",
    text_update_ismake_message: "是否把选择菜品的状态更新为",
    text_update: "刷新",
    text_come_back: "返回",
    text_prepare_dish: "已备菜",
    text_unable_switching: "切换模式失败",
    text_unable_to_get_dish_data_to_print: "无法获取需要打印的菜品",
    text_unable_printing_tips: "打印小票失败",
    text_card_header_information_batext_r_font_size: "卡片头部信息栏字体大小",
    text_dish_area: "桌台区域",
    text_whether_to_open_the_regional_menu_function: "分区域显示菜品功能",
    text_keyboard_mode: "键盘模式",
    text_order_restaurant_mode: "普通点餐餐厅模式",
    text_select_all: "全选",
    text_all_inversion: "全反选",
    text_takeaway: "外卖",
    text_unableto_get_table_area_data: "获取桌台区域数据失败",
    text_meal_screen: "取餐屏幕",
    text_meal_screen_font_size: "取餐屏幕字体大小",
    text_whether_to_display_the_customer_name: "是否显示顾客名",
    text_meal_screen_area: "取餐屏幕区域",
    text_please_take_a_meal: "请取餐",
    text_unable_to_get_meal_data: "无法获取取餐数据",
    text_meal_data_does_not_exist: '取餐数据不存在',
    text_version: '版本号',
    text_round_propulsion_mode: '厨房轮次推进模式',
    text_chef_automatically_pushes: '自动模式',
    text_waiter_manually_pushes: '手动模式',
    text_prepare_dish_mode: '备菜模式',
    text_logging_function: "日志",
    text_is_enable_logging_function: "是否开启日志",
    button_clear_all_logging: "清空所有日志",
    button_view_logging:"查看日志",
    text_sort_table:"分桌排序",
    text_ascending:"升序",
    text_descending:"降序",
    text_whether_to_clear_all_logs: "是否清空所有日志",
    text_all_logs_have_been_emptied: "已清空所有日志",
    text_update_time: "更新时间",
    text_order_head_id: "订单号",
    text_order_detail_id: "菜品号",
    text_update_type: "更新类型",
    text_user: "用户",
    text_update_dishes_number: "更新菜品数量",
    text_is_success: "是否成功",
    text_current_mode: "当前模式",
    text_current_role: "当前角色",
    text_date: "日期",
    button_search: "搜索",
    button_display_all: "显示全部",
    text_sound_name_starlight:'星光',
    text_sound_name_radar:'雷达',
    text_sound_name_bird:'飞燕',
    text_sound_name_bamboo:'竹林',
    text_sound: '声音',
    text_ringtone_reminder:'铃声提醒',
    text_ring: '铃声',
    text_only_merge_dine_in:'仅合并堂吃菜品',
    text_ready_to_serve_sort: '待上菜页面排序',
    text_end_time: '最后状态更改时间',
    text_order_time: '下单时间',
    text_update_confirmation_box_show_name: '更新确认框内显示的菜品名',
    text_default_config:'默认配置',
    text_change_default_config:'是否更换默认配置（将会强制退出登录并重置和清空缓存）',
    text_merge_ignore_customer_information:'合并时忽略客户信息',
    text_smart_merge_check_dish_id:'智能合并检查菜品ID',
    text_do_not_show_again: "不再显示确认框（关闭后在后台开启）",
    text_rush_dish_confirmation_box: "催菜确认框",
    text_whether_rush_dish: "是否催菜？",
    text_add_reason_in_cancel_production: "取消制作时选择原因",
    text_select_one_cancel_production_reason: "请选择一个取消制作的原因",
    text_cancel_production_reason: "取消制作原因",
    text_theme_standard: '标准',
    text_theme_summer: '初夏',
    text_theme_atlantis: '蔚蓝',
    text_right_sidebar_align: '右侧边栏图标对齐',
    text_top_align: '靠上',
    text_center_align: '居中',
    text_bottom_align: '靠下',
    text_preparing: '准备中',
    text_take_meal_from_feed_time: '取餐屏幕每页切换间隔时间（秒）',
    text_base_setting: '基本设置',
    text_other_setting: '其他设置',
    text_ordered_sort: '已下单页面排序',
    text_dining_time: '就餐时间',
    text_delivery_order_display_range: '外卖单显示范围(分钟)',
    text_order_info_show_customer_name: "订单信息显示顾客名",
    text_basis_for_round_advance: "菜品显示轮次化设置",
    text_made_for_round_advance: "订单本轮次菜品制作完毕后，自动显示下一轮次的菜品",
    text_served_for_round_advance: "订单本轮次菜品上菜完毕后，自动显示下一轮次的菜品",
    text_page_inheritance_merge_status: "切页继承合并状态",
    text_highlight_dishes_of_the_same_name: "高亮同名菜品",
    text_round_of_dishes: "菜品所在轮次",
    text_dish_order_time: "菜品下单时间",
    text_kitchen_current_round: "厨师当前制作轮次",
    text_export_log: "导出日志",
    text_export_success: "导出成功",
    text_export_faile: "导出失败",
    text_log_name: "日志名称",
    text_please_input_log_name: "请输入日志名称",
    text_dish_name_print_method: "菜名打印方式",
    display_a_summary_of_dishes_sidebar: "显示菜品汇总侧边栏",
    dishes_summary_sidebar: "菜品汇总侧边栏",
    prepare_dish_post: "备菜岗位",
    total_order_mode: "总单显示模式",
    total_order_mode_number: "总单模式每页订单数",
    text_takeaway_mode: '外卖模式',
    text_sort_the_dishes_in_the_card: '卡片内菜品排序方式',
    total_takeaway_mode_number: "外卖模式每页订单数",
    text_printing_method: "划菜打印方式",
    text_split_printing_mode: "分单打印模式",
    text_full_order_printing_mode: "整单打印模式",
    text_you_have_selected_items: "您已选择 {{number}} 项",
    text_one_receipt_per_dish_mode: "一菜一单",
    text_eat_in_order: "堂食订单",
    text_take_away_order: "外卖订单",
    text_display_takeaway_statistics_sidebar: "外卖单统计侧边栏",
    text_sort_the_order_mode_dinein_in_the_card: "订单模式堂食卡片菜品排序方式",
    text_take_out_dine_in_joint_sorting: "外卖堂食联合排序",
    text_printing_operator_type: "操作者类型",
    text_printing_order_account: "下单者账号",
    text_printing_chef_account: "厨师账号",
    text_printing_multi_person: "批量下单",
    button_view_print_logging:"查看打印日志",
    text_auto_hide_left_sidebar: '自动隐藏左侧边栏'
}