import React, { Component } from 'react';
import { Fade, CircularProgress } from '@material-ui/core';

export default class LoadingComponent extends Component<LoadingComponentProps, LoadingComponentState> {

    constructor(props: LoadingComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);

        // state
        this.state = {
            visible: this.props.visible || false
        }
    };

    // *********************
    // View
    // *********************

    render(this: any) {
        return (
            <Fade
                in={this.state.visible}
                style={{
                    ...(this.props.style || {})
                }}
                unmountOnExit
            >
                <CircularProgress />
            </Fade>
        )
    }
}

// *********************
// States
// *********************

type LoadingComponentProps = {
    visible: boolean;
    style?: any;
}

type LoadingComponentState = {
    visible: boolean;
}
