import { GlobalStateType } from "./type";

export const CACHE_KEY = "KWA2_CACHE";
export const CACHE_LOGGER = "KWA2_LOGGER";
export const CACHE_PRINT_LOGGER = "KWA2_PRINT_LOGGER";
export const CACHE_SELECT_CONFIG = "CACHE_SELECT_CONFIG";
export const UPDATE_STATUS: any = {
    '0': 'Ordered',
    'null': 'Ordered',
    '11': 'Prepared',
    'waiter-1': 'Delivering',
    'chef-1': 'Cooked',
    '2': 'Served',
    '3': 'Paused',
    '5': 'Cancelled'
}

export const INIT_STATE: GlobalStateType.NewState = {
    selectConfig: 'default',
    default: {
        // $ 菜品卡片相关
        // 字体大小
        name1FontSize: 18,
        name2FontSize: 18,
        //卡片头部信息栏字体大小
        cardheaderInformationBarFontASize: 18,
        // 是否显示菜品名
        displayName1: false,
        displayName2: true,
        // 厨师/服务员 页面卡片数量
        chefCardPerPage: 50,
        waiterCardPerPage: 50,

        // 每页卡片数量
        cardPerPageNum: 50,
        // 服务员未制作每页卡片数量（不与每页卡片数量冲突）
        waiterUnMakePerPageNum: 50,
        // 卡片容量上限
        cardCapacity: 5,
        // 卡片最大高度
        cardMaxHeight: 300,
        // 订单模式下卡片最大高度（不与卡片最大高度冲突）
        orderModeCardMaxHeight: 750,
        // 卡片宽度
        cardWidth: 265,
        // 智能合并后卡片最大高度
        smartMergeCardMaxHeight: 500,
        // 卡片菜品备注字体大小
        cardRemarksFontSize: 15,
        // 卡片自适应高度
        cardAutoHeight: true,

        // $ 功能相关
        // 智能合并
        smartMergerAutoStart: false,
        // 全局的合并状态
        globalMergeStatus:{
            'chef':{
                kitchen:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                prepareDish:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                history:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                suspend:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                cancelled:{
                    intelligentMode:true,
                    isSimpleMode: false
                }
            },
            'waiter':{
                chefkitchen:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                prepareDish:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                kitchen:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                history:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                suspend:{
                    intelligentMode:true,
                    isSimpleMode: false
                },
                cancelled:{
                    intelligentMode:true,
                    isSimpleMode: false
                }
            }
        },
        // 划菜打印
        crossFoodPrint: false,
        // 厨师控菜功能
        chefControlFunction: false,
        // 厨师备菜功能
        prepareDishFunction: false,
        // 数量统计功能
        countStatisticsFunction: true,
        //每桌菜品数量统计
        everyTableFoodQuantityCount: false,
        // 显示订单号
        displayOrderId: true,
        // 显示套餐中的菜品
        displayDishInCombination: true,
        // 操作确认框
        confirmWindow: false,
        // 操作确认框显示的菜品名称 1- name1,2-name2
        confirmWindowShowName: 2,
        // 催菜确认框
        rushDishesconfirmWindow: false,
        // 键盘模式
        keyboardModeFunction: false,
        // 日志
        loggingFunction: false,
        // 分桌排序
        sortTableFunction: 'ascending',
        // 铃声提醒
        soundReminder: true,
        // 铃声文件 
        ringtoneFile: 'radar.mp3',
        // 待上菜排序
        readyToServeSort: 'endTimeSort',
        // 取消制作时选择原因
        addReasonInCancelFunction: true,
        // 已下单页面排序
        orderedSort: 'kdsTimeJointOrderTimeSort',
        // 外卖单显示范围(分钟)
        deliveryDisplayRange: 180,
        // 订单信息显示顾客名
        orderInfoShowCustomerName: true,
        // 轮次推进依据
        basisForRoundAdvance: 'served',
        // 切页继承合并状态
        isInheritMergeState: false,
        // 菜名打印方式 0——菜名1+菜名2，1——菜名1，2——菜名2
        dishNamePrintMethod: '2',
        // 显示菜品汇总侧边栏
        displayDishesSidebar: true,
        // 菜品汇总侧边栏 1——菜名1，2——菜名2
        dishesSummarySidebar: '2',
        // 卡片内菜品排序方式 0-菜品id ,1-菜品名1,2-菜品名2
        sortTheDishesInTheCard: '1',
        // 订单模式堂食卡片菜品品排序方式 0-下单时间 1-菜品名称1
        sortTheOrderModeDineIncard: '0',
        // 是否打印堂食订单
        isPrintEatIn: true,
        // 是否打印外卖订单
        isPrintTakeAway: true,
        // 显示外卖单统计栏
        displayTakeawayStatisticsSidebar: true,
        // 显示外卖单
        displayTakeawayOrder: true,    

        // $ 基础参数
        // 主题
        theme: "atlanti",
        // 语言
        language: "it",
        // 服务器地址
        serveUrl: "http://192.168.111.100:3000",
        // 打印机地址
        printerUrl: "tcp://192.168.111.91:9100",
        printerBrandType: "EPSON",
        printerConnectionType: "tcp",
        // 数据刷新周期
        dataFreshTime: 20,
        // 重试打印小票次数
        receiptRetryTimes: 3,
        // 岗位
        positions: [],
        // 厨师岗位
        chefPositionsSelected: [],
        // 当前登录账户
        account: "",
        // 服务员未制作界面岗位选择
        waiterPositionsSelected: [],
        // 打印机
        printersSelected: [],
        //菜品种类
        dishMergeTypesSelected: [],
        // 数据库设置参数
        settingInfo: [{ value: { menu_id: '' } }],
        //分页当前页码
        pageCurrent: 1,
        //是否开启区域菜品功能
        regionalMenuFunction: false,
        //选中的桌台
        tableNameSelected: [],
        //是否开启点餐餐厅模式
        orderRestaurantMode: false,
        //是否显示顾客名
        mealScreenCustomerName: false,
        //取餐屏幕字体大小
        mealScreenFontSize: 90,
        // 仅合并堂吃菜品
        onlyMergeDineIn: false,
        // 合并忽略客户信息
        mergeIgnoreCustomerInfo: true,
        // 智能合并检查菜品ID
        mergeCheckDishID: false,
        // 右侧边栏图标对齐
        rightSidebarAlign: 'flex-start',
        // 请取餐号码最初等待时间
        takeMealWaitTime: 5,
        // 取餐切换页时间
        takeMealFormfeedTime: 10,
        // 高亮同名菜品
        isHighlightDishesOfTheSameName: false,
        // 备菜岗位
        prepareDishPostSelected: [],
        // 总订单显示模式
        totalOrderMode: false,
        //总单显示模式显示的订单数量
        totalOrderModeNumber: 10,
        //外卖模式每页订单数
        takeawayModeNumber: 10,
        // 划菜打印方式
        printingMethod: 'SPLIT_MODE',
        // 划菜打印操作者方式
        printOperatorType: 'ORDER',
        // 自动隐藏左侧边栏
        isHideLeftSidebarAutomatically: true,
        // 当前选中模式
        modeSelected: 'normal_mode',
        // 轮次权重值
        turnWeightValue: 0
    }

}

export function getSelectConfig() {
    try {
        return localStorage.getItem(CACHE_SELECT_CONFIG) ? localStorage.getItem(CACHE_SELECT_CONFIG) as string : INIT_STATE.selectConfig;
    } catch (error) {
        return INIT_STATE.selectConfig
    }
}
