import React, { Component, SetStateAction } from 'react';
import i18nService from '../i18n';
import { StateManager } from '../modules/state';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { MessengerModule } from "../modules/messenger/module";
import { Unsubscribe } from 'redux';
import { Request } from "../modules/request"
import { ERROR_0028, ERROR_0029, ERROR_0019 } from "../constants/error";
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { createHashHistory } from 'history';
import { TakeDishesComponent } from '../components/take-dishes.component'
// import Swiper from 'react-id-swiper';
// import 'react-id-swiper/lib/styles/css/swiper.css';
// import 'swiper/css/swiper.css';
import { getStateDiffs } from '../utils/tools';
// import "../css/swiper.css";
// import 'swiper/swiper.scss'
class MealDisplayPage extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);
        this.refreshDataDifferences = this.refreshDataDifferences.bind(this);
        this.getPreparingDishes = this.getPreparingDishes.bind(this);
        this.dealTakeMeal = this.dealTakeMeal.bind(this);
        this.waitMealtimeoutID = null;
        // State
        this.state = {
            serveUrl: StateManager.get("serveUrl"),
            loadingVisible: false,
            settingInfo: StateManager.get("settingInfo"),
            check: StateManager.get("settingInfo[9].value"),
            mealDisplayArray: [],
            prepareMealDisplayArray: [],
            waitForMealArray: [],
            timeoutID: null,
            params: {
                slidesPerView: 6,
                spaceBetween: 0,
                direction: 'vertical',
                centeredSlides: true,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false
                },
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true
                // },
                // navigation: {
                //   nextEl: '.swiper-button-next',
                //   prevEl: '.swiper-button-prev'
                // }
            }
        }


        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        //init sound
        try {
            if (StateManager.get('soundReminder')) {
                let ringtoneFile = StateManager.get('ringtoneFile');
                if (ringtoneFile) this.audio = new Audio(require(`../assets/audio/${ringtoneFile}`));
            }
        } catch (error) {

        }

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("log-in-page-update", () => {
            // update local state
            this.setState({
                serveUrl: StateManager.get("serveUrl"),
                settingInfo: StateManager.get("settingInfo"),
                check: StateManager.get("settingInfo[9].value"),
            } as SetStateAction<State>);
        });



    };
    private unsubscribe: Unsubscribe;
    private messengerModule: MessengerModule;
    // 转移到待取餐的timeout id
    private waitMealtimeoutID: any;
    // 铃声
    private audio: any = null;

    // *********************
    // Default Function
    // *********************

    async componentDidMount() {
        // we check if already has a account
        this.getTakedishes()
        this.getPreparingDishes()
        await this.refreshDataDifferences();
        // this.transferMeal();
    }
    /**
       * remove subscribe and timer
       */
    componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
        // clear timer
        window.clearTimeout(this.state.timeoutID);
        window.clearTimeout(this.waitMealtimeoutID)
    }

    shouldComponentUpdate(nextProps: any, nextState: State) {
        try {
            // get diffs report
            let report = getStateDiffs(this.state, nextState);
            // $ do not refresh
            if (
                (report.flag === true && report.diffs.length === 0) ||
                (report.flag === false && report.diffs[0] === "timeoutID")
            ) {
                // console.log(report, "[X]");
                return false;
            }
            // $ do refresh
            else {
                // console.log(report);
                return true;
            }
        } catch (error) {
            return true;
        }
    }

    /**
     * update local state by object
     * @param val 
     */
    public updateLocalState(val: Partial<State>, callback?: Function) {
        this.setState(val as any, () => {
            if (callback instanceof Function) callback()
        });
    };


    /**
      * refreshData when check changed
      */
    public async refreshDataDifferences() {
        // clear old timer
        clearTimeout(this.state.timeoutID);
        try {

            // check if data need to be updated
            let result: any = await Request.getSettingInfo(this.state.serveUrl, 15);
            // if need to be update
            if (result.data[0].value !== this.state.check) {
                // (this.refs.audio as any).play();
                if (StateManager.get('soundReminder')) this.audio && this.audio.play();
                this.getTakedishes()
                this.getPreparingDishes()
                this.updateLocalState({
                    check: result.data[0].value
                });
            }
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0019,
                details: {
                    ...error,
                    caller: "chef.page.tsx => refreshDataDifferences()",
                    serveUrl: this.state.serveUrl,
                }
            }, { horizontal: "right" });
        }
        // always start the new timer
        let delay = StateManager.get('dataFreshTime') * 1000

        // 使用原生的 DOM API 获取焦点

        // console.log("2", delay)
        this.updateLocalState({
            timeoutID:
                setTimeout(() => {
                    this.refreshDataDifferences();
                }, delay ? delay : 5000)
        });
    }

    // 判断要不要转移待取餐
    public transferMeal() {
        try {
            // clear old timer
            clearTimeout(this.waitMealtimeoutID);
            if (this.state.mealDisplayArray.length > 0) this.dealTakeMeal(this.state.mealDisplayArray)
            // console.log("2", delay)
            this.waitMealtimeoutID = setTimeout(() => {
                this.transferMeal();
            }, 10000)
        } catch (error) {
            this.messengerModule.say({
                ...ERROR_0019,
                details: {
                    ...error,
                    caller: "meal-display.page.tsx => transferMeal()",
                }
            }, { horizontal: "right" });
        }
    }

    //获取请取餐数据
    async getTakedishes() {
        try {
            // call api
            let rule = {
                menu_id: this.state.settingInfo[0].value.menu_id,
            }
            let res = await Request.getTakedishes(
                StateManager.get("serveUrl"),
                [1],
                rule,
                StateManager.get('regionalMenuFunction') ? StateManager.get('tableNameSelected') : undefined,
                'reverse',
                'endTime'
            );
            // check
            if (!res) throw ERROR_0028;
            if (res.length === 0) throw ERROR_0029;
            // update local state
            this.updateLocalState({
                mealDisplayArray: res.data
            });

        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "meal-display.page.tsx => getTakedishes()",
                }
            });
        }
    }

    //获取准备中数据
    async getPreparingDishes() {
        try {
            // call api
            let rule = {
                menu_id: this.state.settingInfo[0].value.menu_id,
            }
            let res = await Request.getTakedishes(
                StateManager.get("serveUrl"),
                [0, null, 11],
                rule,
                StateManager.get('regionalMenuFunction') ? StateManager.get('tableNameSelected') : undefined,
            );
            // check
            if (!res) throw ERROR_0028;
            if (res.length === 0) throw ERROR_0029;
            // update local state
            this.updateLocalState({
                prepareMealDisplayArray: res.data
            });

        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "meal-display.page.tsx => getPreparingDishes()",
                }
            });
        }
    }
    // 处理请取餐数据，根据设置的时间，把满足条件的菜品移到待取餐中
    async dealTakeMeal(mealData: any[]) {
        try {
            let nowDate = new Date().getTime();
            let takeMeal: any[] = [];
            let waitForMeal = mealData.filter(target => {
                // 先判断 待取餐中是否已存在此订单。
                let index = this.state.waitForMealArray.findIndex(findTarget => {
                    return findTarget.order_head_id === target.order_head_id
                })
                // 已经存在，不做任何处理
                if (index !== -1) return false
                if (target.end_time) {
                    let endTime = new Date(target.end_time).getTime();
                    let ms = nowDate - endTime;
                    // 条件未满足，不需要移到待取餐中
                    if (ms < 0) {
                        takeMeal.push(target);
                        return false;
                    } else {
                        let mins = StateManager.get('takeMealWaitTime');
                        // 满足转移的条件
                        // eslint-disable-next-line
                        if (Math.floor(ms / 1000 / 60) > mins || Math.floor(ms / 1000 / 60) == mins) {
                            // return true

                            // 是新的订单号，转移
                            if (index === -1) return true;
                            // 已存在的订单号，不需要做任何处理
                            else return false;
                        } else {
                            takeMeal.push(target);
                            return false;
                        }
                    };
                }
                return false;
            })

            // update local state
            this.updateLocalState({
                mealDisplayArray: takeMeal,
                waitForMealArray: this.state.waitForMealArray.concat(waitForMeal)
            });

        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "meal-display.page.tsx => dealTakeMeal()",
                }
            });
        }
    }

    // *********************
    // View
    // *********************

    render() {
        // let src = "../audio/Short Marimba Notification Ding.mp3"
        // const params = {
        //     direction: 'vertical',
        //     loop: true,
        //     effect: 'fade',
        //     // spaceBetween: 30,
        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true
        //     },
        //     // autoplay: {
        //     //     delay: 1000,
        //     //     disableOnInteraction: false
        //     // }
        // }
        return (
            <div
                className="take-dishes"
                style={{
                    // bottom: 0,
                    // left: 0,
                    // right: 0,
                    // top: 0,
                    // backgroundColor: '#170503',
                    // overflow: "hidden",
                    display: "flex",
                    // // flexDirection: "column",
                    // height: "100vh",
                    // width: "100%",
                    color: '#fff'
                }}
            >
                <div style={{ flex: 4 }}>
                    <TakeDishesComponent
                        mealData={this.state.mealDisplayArray}
                        styles={{ paddingLeft: 70, paddingTop: 20 }}
                        title={i18nService.t("text_please_take_a_meal")}
                        format={(target: any) => {
                            let customerName = target.customer_name;
                            if(target.customer_name){
                                let customerArray = target.customer_name.split(' ');
                                if(Array.isArray(customerArray) && customerArray.length > 0) customerName = customerArray[0]
                            }
                            return `${target.check_number}${StateManager.get("mealScreenCustomerName") ? customerName ? ' ' + customerName : '' : ''}`
                        }}
                        takeMealClass="takeMealClass"
                        swiperParams={{
                            slidesPerColumnFill: 'row',
                            autoHeight: true,
                            // watchSlidesProgress:true,
                            // watchSlidesVisibility: true,
                            // slidesPerColumn: 6,
                            // slidesPerView:6,
                            observer: true
                            // slidesPerGroupSkip: 1
                        }}
                    ></TakeDishesComponent>
                </div>
                <div style={{ flex: 1,position:'relative' }}>
                    <div style={{position: 'absolute',top:20,bottom:20,width:1,background:'#999'}}></div>
                    <TakeDishesComponent swiperParams={{
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true
                        },
                        autoplay: {
                            delay: StateManager.get('takeMealFormfeedTime') * 1000,
                            // delay: 1000,
                            disableOnInteraction: false
                        }
                    }} mealData={this.state.prepareMealDisplayArray} styles={{ paddingTop: 20 }} align='center' title={i18nService.t("text_preparing")}></TakeDishesComponent>
                </div>
                {/* <div style={{ flex: 1 }}>
                    <TakeDishesComponent mealData={this.state.waitForMealArray} styles={{ paddingTop: 20 }} align='center' title={i18nService.t("text_waiting_for_meal")}></TakeDishesComponent>
                </div> */}
                {/* <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    // overflow: 'hidden'
                    // maxHeight: "100vh"
                }}>
                    <div style={{ height: '10vh',paddingTop:20,paddingLeft:40 }}>
                        <div className="take-dishes-title" style={{ display: 'inline-block', height: '70px', lineHeight: '70px', textAlign: 'center', minWidth: 200, borderRadius: 8, fontSize: 30 }}>
                            {i18nService.t("text_please_take_a_meal")}
                        </div>
                    </div>
                    <div style={{ height: '90vh',paddingLeft:40 }}>
                        <Swiper
                            shouldSwiperUpdate={true}
                            {...params}
                        >
                            <div key="1">Slide #1</div>
                            <div key="2">Slide #2</div>
                            <div key="31">Slide #3</div>
                            <div key="4">Slide #4</div>
                            <div key="5">Slide #5</div>
                        </Swiper>
                    </div>
                </div> */}
                {/* <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "flex-start",
            color: "#EEE",
            fontSize: 48,
            alignItems: "center"
          }}>
          <span
            style={{
              display: "inline-block",
              height: 100,
              lineHeight: "100px",
              background: "#339900",
              paddingLeft: 10,
              paddingRight: 10
            }}> {i18nService.t("text_please_take_a_meal")}</span>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", }}>
          {this.state.mealDisplayArray.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div
                  style={{
                    minWidth: 200,
                    marginRight: 20,
                    color: "#EEE",
                    fontSize: StateManager.get('mealScreenFontSize') + 'px',
                  }}>
                  <span style={{ marginRight: 50 }}>{item.order_head_id}</span>
                  {StateManager.get("mealScreenCustomerName") ? <span>
                    {item.customer_name}
                  </span> : null}
                </div>
              </div>
            )
          })}
        </div> */}

                {/* <Swiper {...this.state.params} rebuildOnUpdate={true} > </Swiper> */}
                <div
                    style={{ position: "fixed", zIndex: 100, bottom: 20, left: 10, display: "flex", flexDirection: "row" }}>
                    <Fab
                        color="secondary"
                        style={{ width: 56, height: 56 }}
                        onClick={() => { createHashHistory().go(-1) }}
                    >
                        <ChevronLeftIcon style={{ fontSize: 50 }} />
                    </Fab>
                    {/* <audio ref="audio" preload={'metadata'} src={require("../assets/audio/sound.mp3")}>
          </audio> */}
                </div>
            </div >
        );
    }

}

export default withSnackbar(MealDisplayPage as any);

// *********************
// Types
// *********************

type State = {
    serveUrl: string;
    loadingVisible: boolean;
    mealDisplayArray: Array<any>,
    prepareMealDisplayArray: Array<any>,
    waitForMealArray: Array<any>,
    settingInfo: any,
    timeoutID: any,
    check: string,
    params: object,
}

type Props = {
} & WithSnackbarProps;

