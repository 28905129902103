import { withStyles } from '@material-ui/core';

/**
 * 以下的主题不包括按钮的闪烁边框，如需修改按钮的闪速边框，请到common.page.tsx中找到对应的代码进行修改,搜索getBorderColor和cardUnmount和chef-filter-badge关键字。
 */

// 基本颜色，卡片的默认，按钮的默认
let standardBaseBackgroundColor = {
    'standar': '#512038',
    'summer': 'linear-gradient(#5BA4BB, #5992B2)',
    'atlanti': 'linear-gradient(#5BA4BB, #5992B2)'
};
// 卡片页脚背景颜色
let standardFooterBaseBackgroundColor = {
    'standar': '#512038',
    'summer': '#5992B2',
    'atlanti': '#5992B2'
};
// 卡片登陆页面输入框背景颜色
let inputBackgroundColor = {
    'standar': '#282F31',
    'summer': '#fff',
    'atlanti': '#27323E'
}
// 卡片中的菜品催菜背景颜色
let cardDetailRushBackgroundColor = {
    'standar': 'linear-gradient(145deg, #C43F3F 12%,#1A5A95 90%)',
    'summer': 'linear-gradient(145deg, #E83131 12%, #3399FF 90%)',
    'atlanti': 'linear-gradient(145deg,#E83131  12%, #3399FF 90%)'
};
// 卡片数量按钮字体颜色
let cardQuantityColor = {
    other: {
        'standar': '#fff',
        'summer': '#fff',
        'atlanti': '#fff'
    },
    takeout: {
        'standar': '#fff',
        'summer': '#fff',
        'atlanti': '#fff'
    },
    base: {
        'standar': '#fff',
        'summer': '#fff',
        'atlanti': '#fff'
    }
}
// 卡片数量按钮背景颜色
let cardQuantityBackgroundColor = {
    other: {
        'standar': 'linear-gradient(#881B14, #C34B76)',
        'summer': 'linear-gradient(#2E4F74, #379FD9)',
        'atlanti': 'linear-gradient(#2E4F74, #379FD9)'
    },
    takeout: {
        'standar': 'linear-gradient(#881B14, #C34B76)',
        'summer': 'linear-gradient(#2E4F74, #379FD9)',
        'atlanti': 'linear-gradient(#2E4F74, #379FD9)'
    },
    base: {
        'standar': 'linear-gradient(#881B14, #C34B76)',
        'summer': 'linear-gradient(#2E4F74, #379FD9)',
        'atlanti': 'linear-gradient(#2E4F74, #379FD9)'
    }
}
// 总单模式下外卖未制作的菜品卡片背景颜色
let cardQuantityNotMakeBackgroundColor = {
    other: {
        'standar': 'linear-gradient(#55110C, #77314A)',
        'summer': 'linear-gradient(#508294, #55ABC9)',
        'atlanti': 'linear-gradient(#334151, #256D95)'
    },
    takeout: {
        'standar': 'linear-gradient(#55110C, #77314A)',
        'summer': 'linear-gradient(#508294, #55ABC9)',
        'atlanti': 'linear-gradient(#334151, #256D95)'
    },
    base: {
        'standar': 'linear-gradient(#55110C, #77314A)',
        'summer': 'linear-gradient(#508294, #55ABC9)',
        'atlanti': 'linear-gradient(#334151, #256D95)'
    }
}
// 卡片下划线颜色
let cardUnderlineColor = {
    'standar': '#D5D5D5',
    'summer': '#B4CED3',
    'atlanti': '#B4CED3'
}
// 卡片头部背景颜色
let cardHeaderBackgroundColor = {
    'standar': '#D5D5D5',
    'summer': 'linear-gradient(#3D6799, #2A486A)',
    'atlanti': 'linear-gradient(#C5D6D9, #A2C4CC)'
};
// 卡片头部字体颜色
let cardHeaderColor = {
    table: {
        'standar': '#2A486A',
        'summer': '#fff',
        'atlanti': '#fff'
    },
    order: {
        'standar': '#2A486A',
        'summer': 'rgba(255,255,255,0.35)',
        'atlanti': '#2A486A'
    },
    overTime: {
        'standar': '#ff8873',
        'summer': '#3CC6A5',
        'atlanti': '#079976'
    },
    orderTime: {
        'standar': '#2A486A',
        'summer': 'rgba(255,255,255,0.35)',
        'atlanti': '#2A486A'
    }
}
// 磁力链头部背景颜色
let magneticLinkBackgroundColor = {
    other: {
        'standar': '#6C2F4C',
        'summer': '#6EA9CB',
        'atlanti': '#3382A4'
    },
    takeout: {
        'standar': '#BD6A3B',
        'summer': '#55BD97',
        'atlanti': '#5CC49F'
    },
    base: {
        'standar': '#6C2F4C',
        'summer': '#6EA9CB',
        'atlanti': '#3382A4'
    }
}
let cardOrderRemarkBackgroundColor= {
    'standar': 'rgb(62,49,59)',
    'summer': '#27323E',
    'atlanti': '#27323E'
}

// 套餐的背景色
let packageBackgroundColor = {
    eatIn: {
        'standar': 'linear-gradient(#4F102E, #591134)',
        'summer': 'linear-gradient(#3180A2, #3E97BD)',
        'atlanti':'linear-gradient(#3180A2, #3E97BD)',
    },
    takeaway: {
        'standar': 'linear-gradient(#BB5217, #B5531C)',
        'summer': 'linear-gradient(#2F9F70, #2EA674)',
        'atlanti':'linear-gradient(#2F9F70, #2EA674)',
    },
    rushedBg: {
        'standar': 'linear-gradient(145deg, #C43F3F 12%, #1A5A95 90%)',
        'summer': 'linear-gradient(145deg, #C43F3F 12%, #5d81d0 90%)',
        'atlanti':'linear-gradient(145deg, #C43F3F 12%, #5d81d0 90%)',
    },
    eatInNotMake: {
        'standar': 'linear-gradient(#1E1E19, #2F2D24)',
        'summer': 'linear-gradient(#86B6D2, #7AADCA)',
        'atlanti':'linear-gradient(#305362, #376071)',
    },
    takeawayNotMake: {
        'standar': 'linear-gradient(#1E1E19, #2F2D24)',
        'summer': 'linear-gradient(#64C6B5, #71C3AA)',
        'atlanti':'linear-gradient(#52736F, #36625D)',
    },
    // 堂吃已完成且位于已完成区域
    eatInFinished: {
        'standar': 'linear-gradient(#420E0B, #310414)',
        'summer': 'linear-gradient(#6097B5, #62A0C3)',
        'atlanti':'linear-gradient(#44839F, #4088A6)',
    },
    // 外卖已完成且位于已完成区域
    takeawayFinished: {
        'standar': 'linear-gradient(#A65527, #9F5126)',
        'summer': 'linear-gradient(#3BA283, #2E9F7D)',
        'atlanti':'linear-gradient(#207654, #277D59)',
    }
}

// 套餐头部公共的样式
let commonPackageHeadBgStyle = {
    borderTopLeftRadius: 9,
    borderTopRightRadius: 9,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
}

// 套餐的border样式
let packageBorderStyle = {
    eatIn: {
        'standar': '#9B7272',
        'summer': '#2A486A',
        'atlanti':'#2A486A',
    },
    takeaway: {
        'standar': '#88482D',
        'summer': '#2A486A',
        'atlanti':'#2A486A'
    },
    rushedBg: {
        'standar': '#9B7272',
        'summer': '#2a486a',
        'atlanti': '#2A486A',
    },
    eatInNotMake: {
        'standar': '#6C6663',
        'summer': '#567293',
        'atlanti':'#2A486A',
    },
    takeawayNotMake: {
        'standar': '#6C6663',
        'summer': '#3A957A',
        'atlanti':'#2A486A',
    },
    eatInFinished: {
        'standar': '#7F5C5C',
        'summer': '#2A486A',
        'atlanti':'#2A486A',  
    },
    takeawaFinished: {
        'standar': '#531F09',
        'summer': '#007B5B',
        'atlanti':'#2A486A',  
    }
}

// 套餐内菜品的背景色
let packageDishesBackgroundColor = {
    eatIn: {
        'standar': '#662444',
        'summer': '#59A0BF',
        'atlanti':'#59A0BF',
    },
    takeaway: {
        'standar': '#ca6a34',
        'summer': '#2BBB7F',
        'atlanti':'#2BBB7F',
    },
    rushed: {
        'standar': 'linear-gradient(145deg, #CB4252 12%, #5d81d0 90%)',
        'summer': 'linear-gradient(145deg, #CB4252 12%, #5d81d0 90%)',
        'atlanti': 'linear-gradient(145deg, #CB4252 12%, #5d81d0 90%)'
    },
    eatInNotMake: {
        'standar': '#353430',
        'summer': '#8DB5CC',
        'atlanti': '#3C687B'
    },
    takeawayNotMake: {
        'standar': '#353430',
        'summer': '#76C9AD',
        'atlanti': '#51736F'
    },
    eatInFinished: {
        'standar': '#3B1628',
        'summer': '#6FABCC',
        'atlanti': '#538DA6'
    },
    takeawayFinished: {
        'standar': '#914921',
        'summer': '#47AE8F',
        'atlanti': '#24835D'
    }
}
// 套餐内菜品的下边框
let packageDishesBorderBottomColor = {
    eatInNormal: {
        'standar': '#7B4455',
        'summer': '#587D8E',
        'atlanti': '#587D8E'
    },
    takeawayNormal: {
        'standar': '#88482D',
        'summer': '#86D7C9',
        'atlanti': '#86D7C9'
    },
    eatInFinished: {
        'standar': '#6E4D57',
        'summer': '#587D8E',
        'atlanti': '#2E5E73'
    },
    takeawayFinished: {
        'standar': '#663520',
        'summer': '#309785',
        'atlanti': '#309785'
    },
    eatInNotMake: {
        'standar': '#594C46',
        'summer': '#6DA2B9',
        'atlanti': '#264E5E'
    },
    takeawayNotMake: {
        'standar': '#594C46',
        'summer': '#6A9F86',
        'atlanti': '#165737'
    },
    rush: {
        'standar': '#9D77A4',
        'summer': '#9D77A4',
        'atlanti': '#9D77A4'
    }
}
// 套餐菜品未制作字体颜色
let packageDishesNotMakeFontSizeColor = {
    eatIn: {
        'standar': '#8e8d8b',
        'summer': '#e8f0f5',
        'atlanti': '#92aab5'
    },
    takeaway:{
        'standar': '#8e8d8b',
        'summer': '#D0EDE3',
        'atlanti': '#9DB0AE'
    },
    eatInFinished: {
        'standar': '#D8D0D4',
        'summer': '#E2EEF5',
        'atlanti': '#DDE8ED'
    },
    takeawayFinished: {
        'standar': '#E9DBD3',
        'summer': '#DAEFE9',
        'atlanti': '#D3E6DF'
    }
}

// 卡片调味品字体颜色
let cardOrderCondimentkBackgroundColor= {
    other: {
        'standar': 'rgba(93,24,57,0.4)',
        'summer': 'rgba(93,24,57,0.4)',
        'atlanti': 'rgba(93,24,57,0.4)'
    },
    takeout: {
        'standar': '#6F3819',
        'summer': '#309785',
        'atlanti': '#1D6C54'
    },
    base: {
        'standar': '#4F2F3E',
        'summer': '#3F83B5',
        'atlanti': '#45758A'
    },
    package: {
        'standar': '#571032',
        'summer': '#3B83A2',
        'atlanti': '#3B83A2'
    },
    takeawayPackage: {
        'standar': '#A85424',
        'summer': '#2C976A',
        'atlanti': '#2C976A'
    },
    rushPackage: {
        'standar': '#5D5C91',
        'summer': '#5D5C91',
        'atlanti': '#5D5C91'
    }
}
// 卡片调味品内容color
let cardOrderCondimentsFontSizeColor= {
    package: {
        'standar': '#A17985',
        'summer': '#9EC6D7',
        'atlanti': '#9EC6D7'
    },
    takeawayPackage: {
        'standar': '#CEB5A7',
        'summer': '#87D4B3',
        'atlanti': '#87D4B3'
    },
    rushPackage: {
        'standar': '#A9A9C5',
        'summer': '#A9A9C5',
        'atlanti': '#A9A9C5'
    },
    eatInNotMake: {
        'standar': '#827F7F',
        'summer': '#ABCBD9',
        'atlanti': '#618FA2'
    },
    takeawayNotMake: {
        'standar': '#827F7F',
        'summer': '#BDDBD0',
        'atlanti': '#90A19F'
    },
    eatInFinished: {
        'standar': '#8C636F',
        'summer': '#ACD4E5',
        'atlanti': '#9EC6D7'
    },
    takwawayFinished: {
        'standar': '#CEB5A7',
        'summer': '#98D7BE',
        'atlanti': '#74B27F'
    }
}

// 卡片未制作调味品字体颜色
let cardOrderCondimentkNotMakeBackgroundColor= {
    other: {
        'standar': '#4c4A44',
        'summer': '#6B99BB',
        'atlanti': '#385C6C'
    },
    takeout: {
        'standar': '#4c4A44',
        'summer': '#4CAE8B',
        'atlanti': '#224440'
    },
    base: {
        'standar': '#4c4A44',
        'summer': '#6B99BB',
        'atlanti': '#385C6C'
    },
    eatInNotMake: {
        'standar': '#28251C',
        'summer': '#619EBF',
        'atlanti': '#385C6C'
    },
    takeawayNotMake: {
        'standar': '#28251C',
        'summer': '#60B39A',
        'atlanti': '#335954'
    },
    rush: {
        'standar': '#5D5C91',
        'summer': '#5D5C91',
        'atlanti': '#5D5C91'
    },
    eatInFinished: {
        'standar': '#280918',
        'summer': '#478FAE',
        'atlanti': '#357895'
    },
    takeawayFinished: {
        'standar': '#713C1E',
        'summer': '#289D7A',
        'atlanti': '#33754F'
    }
}
// 背景颜色
let standardBackgroundColor = {
    'standar': '#201F1B',
    'summer': 'linear-gradient(#C5D6D9, #A2C4CC)',
    'atlanti': 'linear-gradient(#3D6799, #2A486A)'
};
// 侧边栏颜色
let sidebarBackgroundColor = {
    'standar': '#282F31',
    'summer': 'linear-gradient(#D8F4F7, #C5D6D9)',
    'atlanti': '#27323E'
};
// 侧边栏的按钮点击下去颜色
let sidebarButtonBackgroundColor = {
    'standar': '#170503',
    'summer': '#A2C4CC',
    'atlanti': '#2A486A'
};
// 订单模式右侧菜品汇总侧边栏背景色
let dishesSummarySidebarBackgroundColor = {
    'standar': 'rgba(255,255,255,0.09)',
    'summer': 'rgba(163,197,204,0.4)',
    'atlanti': '#4E729C'
};
// 订单模式右侧菜品汇总侧边栏选中背景色
let dishesSummarySidebarSelectBackgroundColor = {
    'standar': 'rgba(255,255,255,0.35)',
    'summer': 'rgba(90,150,181,0.69)',
    'atlanti': '#7BB8C8'
};
// 没有选中的字体颜色
let unSelectColor = {
    'standar': 'rgba(255,255,255,0.75)',
    'summer': '#2A486A',
    'atlanti': '#fff'
};
// 选中的字体颜色
let selectColor = {
    'standar': '#fff',
    'summer': '#2A486A',
    'atlanti': '#fff'
};
// 外卖颜色
let takeoutBackgroundColor = {
    'standar': '#BB5E2C',
    'summer': 'linear-gradient(#31A98D, #2FB579)',
    'atlanti': 'linear-gradient(#31A98D, #2FB579)'
};
// 外卖页脚的背景颜色
let takeoutFooterBackgroundColor = {
    'standar': '#BB5E2C',
    'summer': '#2FB579',
    'atlanti': '#2FB579'
};
// 总单模式下外卖未制作的菜品卡片背景颜色
let cardDetailNotMakeTakeoutBackgroundColor = {
    'standar': '#7F4829',
    'summer': '#76C9AD',
    'atlanti': '#2E5E59'
};
// 总单模式下堂吃或外卖套餐未制作的菜品卡片背景色
let cardPackageNotMakeBg = {
    eatInNotMake: {
        'standar': '#353430',
        'summer': '#8EBCD5',
        'atlanti': '#467488'    
    },
    takeawayNotMake: {
        'standar': '#353430',
        'summer': '#76C9AD',
        'atlanti': '#2E5E59'    
    },
    eatInFinished: {
        'standar': '#2f1321',
        'summer': '#6fabcc',
        'atlanti': '#558ea6'    
    },
    takeawayFinished: {
        'standar': '#864622',
        'summer': '#51b99a',
        'atlanti': '#248469'    
    }
};
// 总单模式下堂吃未制作的菜品卡片背景颜色
let cardDetailNotMakeEatInBackgroundColor = {
    'standar': '#2F1321',
    'summer': 'rgb(142,188,213)',
    'atlanti': '#467488'
};
// 进度条背景
let progressBackgroudColor = {
    'standar': '#771D4E',
    'summer': '#408FBB',
    'atlanti': '#56A1C1'
}
// 进度条选中背景
let progressBarBackgroudColor = {
    'standar': '#B9497F',
    'summer': '#006E9D',
    'atlanti': '#57C4E6'
}
// 菜品状态切换的选中颜色
let changeIsmakeBackgroundColor = {
    'standar': 'rgba(255,255,255,0.35)',
    'summer': '#7BB8C8',
    'atlanti': '#7BB8C8'
};
// 菜品状态悬浮的颜色
let changeIsmakeHoverBackgroundColor = {
    'standar': 'rgba(104, 92, 91, 0.4)',
    'summer': 'rgb(136, 193, 196, 0.5)',
    'atlanti': 'rgb(136, 193, 196, 0.5)'
};
// 选中菜品状态切换的边框颜色
let selectChangeIsmakeBorderColor = {
    'standar': 'rgb(110, 109, 107)',
    'summer': '#7BB8C8',
    'atlanti': '#7BB8C8'
};
// 未选中菜品状态切换的边框颜色
let unselectChangeIsmakeBorderColor = {
    'standar': 'rgba(122, 102, 99, 0.8)',
    'summer': '#7BB8C8',
    'atlanti': '#7BB8C8'
};
// 桌台切换选中颜色
let selectBackgroundColor = {
    'standar': 'rgba(122, 102, 99, 0.8)',
    'summer': '#7BB8C8',
    'atlanti': '#7BB8C8'
};
// 桌台没有选中颜色
let unselectBackgroundColor = {
    'standar': 'rgba(255,255,255,0.1)',
    'summer': 'rgba(255,255,255,0.24)',
    'atlanti': 'rgba(255,255,255,0.24)'
};
// 最上方的菜品状态选择的数量badge,卡片的催菜提示
let ismakeBadgeBackgroundColor = {
    'standar': '#B93072',
    'summer': '#006ED9',
    'atlanti': '#006ED9'
};
// 选中菜品后显示的悬浮框背景颜色
let floatSelectItemBackgroundColor = {
    'standar': '#514849',
    'summer': '#C6D8D8',
    'atlanti': '#5A8DAD'
}
// 选中菜品后显示的悬浮框字体颜色
let floatSelectItemColor = {
    'standar': '#FFFFFF',
    'summer': '#2A486A',
    'atlanti': '#FFFFFF'
}
// 更新菜品状态的按钮背景颜色
let hasDishChangeMakeBackgroundColor = {
    'standar': '#514849',
    'summer': '#9BC4CF',
    'atlanti': '#5A8DAD'
}
// 页码背景颜色
let pageBackgroundColor = {
    'standar': '#201F1B',
    'summer': '#A2C4CC',
    'atlanti': '#2A486A'
}
// 页码选中颜色
let pageSelectColor = {
    'standar': '#fff',
    'summer': '#2A486A',
    'atlanti': '#fff'
}
// 页码没有选中颜色
let pageUnSelectColor = {
    'standar': '#555',
    'summer': '#2A486A',
    'atlanti': '#999'
}
// 设置页面的按钮，滑动条标准颜色
let settingBaseColor = {
    'standar': '#660033',
    'summer': 'rgb(91,155,183)',
    'atlanti': 'rgb(91,157,183)'
}
// 滑动条选择颜色
let settingSecondBaseColor = {
    'standar': '#DF7C7C',
    'summer': 'rgb(200,217,220)',
    'atlanti': 'rgb(137,176,192)'
}
// 设置页面的面版颜色
let settingPanelBackgroundColor = {
    'standar': '#38363E',
    'summer': 'rgb(200,217,220)',
    'atlanti': '#27323E'
}
// 设置页面输入框背景颜色
let settingInputBackground = {
    'standar': '#666',
    'summer': '#BACEDB',
    'atlanti': 'rgb(58,68,79)'
}
// 取餐的订单号码背景色
let takeDishNumberBackgroundColor = {
    'standar': '#58586A',
    'summer': 'linear-gradient(#63CACC, #5992B2)',
    'atlanti': 'linear-gradient(#63CACC, #5992B2)'
}
// 岗位选择的阴影颜色
let boxShadowColor = {
    'standar': '#DF7C7C',
    'summer': '#3E6D99',
    'atlanti': '#391E4D'
}
// 最上方的桌台状态选择的数量badge
// let tableBadgeBackgroundColor = {
//     'standar': '#E4542C',
//     'summer': '#006ED9'
// };
// 主题类型
const themes: any = ['standar', 'summer', 'atlanti']


let globalCss: any = {};
themes.forEach((target: 'standar' | 'summer' | 'atlanti') => {
    globalCss[target] = withStyles({
        // @global is handled by jss-plugin-global.
        '@global': {
            '.MuiFab-secondary': {
                background: standardBaseBackgroundColor[target]
            },
            '.MuiFab-secondary:hover': {
                background: standardBaseBackgroundColor[target]
            },
            '.MuiButton-containedSecondary': {
                background: standardBaseBackgroundColor[target]
            },
            '.MuiButton-containedSecondary:hover': {
                background: standardBaseBackgroundColor[target]
            },
            '.MuiIconButton-colorSecondary': {
                color: selectColor[target]
            },
            '.MuiCheckbox-colorSecondary.Mui-checked': {
                color: selectColor[target]
            },
            // 滑动条
            '.MuiSlider-rail': {
                height: 8,
                borderRadius: 4,
                color: settingBaseColor[target],
                opacity: 1
            },
            '.MuiSlider-track': {
                height: 8,
                borderRadius: 4,
                color: settingSecondBaseColor[target],
                opacity: 0.45
            },
            '.MuiSlider-thumb': {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                marginTop: -8,
                marginLeft: -12,
            },
            // 切换按钮
            '.MuiSwitch-root': {
                '& .MuiSwitch-track': {
                    background: standardBaseBackgroundColor[target]
                },
                '& .Mui-checked + .MuiSwitch-track': {
                    background: takeoutBackgroundColor[target]
                }
            },
            '.MuiDialog-container .MuiPaper-root, .MuiPaper-root.MuiMenu-paper': {
                background: standardBackgroundColor[target],
                color: selectColor[target],
            },
            // 弹出框的背景
            '.cardDialog': {
                '& .MuiPaper-root': {
                    // background: sidebarBackgroundColor[target],
                    // color: selectColor[target],
                    '& .dialogLine': {
                        borderBottomColor: selectColor[target]
                    },
                    '& .tableName': {
                        background: standardBaseBackgroundColor[target]
                    }
                },
                '& .MuiPaper-rounded': {
                    borderRadius: 22
                },
                '& .MuiIconButton-root': {
                    color: 'inherit'
                },
                '& .dialogButton.MuiButton-root': {
                    color: selectColor[target]
                }
            },
            '.MuiButton-root': {
                color: '#fff'
            },
            // 最上方的菜品状态选择的数量badge
            '.MuiBadge-colorSecondary': {
                color: '#fff !important',
                background: `${ismakeBadgeBackgroundColor[target]} !important`
            },
            // 桌台选择的数量badge
            '.MuiBadge-colorPrimary': {
                color: '#fff !important',
                background: `${takeoutBackgroundColor[target]} !important`
            },
            '.MuiOutlinedInput-notchedOutline': {
                borderRadius: 16,
            },
            '.MuiOutlinedInput-input': {
                borderRadius: 16,
            },
            '.MuiSelect-select:focus': {
                borderRadius: 16,
            },
            '.App': {
                background: standardBackgroundColor[target],
                // 登录页
                '& .loginPage': {
                    '& .loginTitle, & .loginText': {
                        color: selectColor[target]
                    },
                    '& .loginInput': {
                        '& .MuiInputLabel-outlined': {
                            color: selectColor[target]
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: 16,
                            borderColor: inputBackgroundColor[target],
                        },
                        '& .MuiOutlinedInput-input': {
                            borderRadius: 16,
                            background: inputBackgroundColor[target],
                            color: selectColor[target]
                        }
                    }
                },
                // 岗位选择页面
                '& .jobSelectPage': {
                    color: selectColor[target],
                    '& .jobButton': {
                        background: standardBaseBackgroundColor[target],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiButton-root': {
                            background: standardBackgroundColor[target],
                            '& .MuiButton-label': {
                                display: 'flex',
                                flexDirection: 'column',
                                '& .MuiButton-startIcon': {
                                    paddingBottom: 35,
                                    marginLeft: 0,
                                    marginRight: 0
                                }
                            },
                            '& .MuiTouchRipple-root': {
                                opacity: '0.45',
                                boxShadow: `0px 27px 42px ${boxShadowColor[target]}`
                            }
                        }
                    }
                },
                // 设置页面
                '& .settingPage': {
                    '& .panelWrapper': {
                        background: settingPanelBackgroundColor[target],
                        color: selectColor[target],
                        // 输入框，选择框
                        '& .MuiInputLabel-outlined': {
                            color: selectColor[target]
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            // borderColor: settingInputBackground[target],
                            // opacity: 0.22,
                            border: 'none'
                        },
                        '& .MuiSelect-icon': {
                            color: selectColor[target]
                        },
                        '& .MuiOutlinedInput-input': {
                            // opacity: 0.22,
                            background: settingInputBackground[target],
                            color: selectColor[target]
                        },
                        // 
                        '& .MuiInputLabel-formControl': {
                            color: selectColor[target],
                        },
                        '& .MuiInput-formControl': {
                            color: selectColor[target],
                            '&:before': {
                                // borderColor: selectColor[target],
                                borderBottom: `1px solid ${selectColor[target]}`
                            }
                        },
                        // 桌台区域
                        '&.tableArea': {
                            '& .selectArea': {
                                background: takeoutBackgroundColor[target]
                            },
                            '& .MuiFormControlLabel-root': {
                                color: selectColor[target]
                            }
                        }
                    },
                    '& .setting-type-title':{
                        color: unSelectColor[target],
                        opacity: 0.3,
                    },
                    '& .setting-type':{
                        '& button':{
                            color: unSelectColor[target],
                            opacity: 0.3,
                            '&.select':{
                                color: selectColor[target],
                                opacity: 1
                            }
                        }
                    }
                },
                // 厨师，服务员页面
                '& .commonPage': {
                    background: standardBackgroundColor[target],
                    // 左侧边栏
                    '& .show, & .hide': {
                        background: sidebarBackgroundColor[target],
                        '& .closeIcon button': {
                            opacity: '1'
                        },
                        '& button': {
                            color: unSelectColor[target],
                            opacity: '0.64'
                        },
                        // 左侧边栏选择s
                        '& .selectedMode': {
                            background: `${standardBackgroundColor[target]} !important`,
                            '& button': {
                                color: `${selectColor[target]} !important`,
                                opacity: '1'
                            }
                        },
                        // 左侧栏的按钮点击下去时候的颜色
                        '& .selectedMode:active,& .mode:active,& .updateClass:active': {
                            background: `${sidebarButtonBackgroundColor[target]} !important`,
                        }
                    },
                    // 中间部分
                    '& .showRight, & .hideRight': {
                        background: standardBackgroundColor[target],
                        // 最上方的菜品状态选择
                        '& button.SelectedTab': {
                            backgroundColor: `${changeIsmakeBackgroundColor[target]} !important`,
                            border: `3px solid ${selectChangeIsmakeBorderColor[target]}`,
                            color: `${selectColor[target]} !important`,
                            '&.hasDishChangeMake': {
                                backgroundColor: 'transparent !important',
                                border: 'none'
                            }
                        },
                        '& button.Tabs': {
                            border: '3px solid #eee',
                            borderColor: unselectChangeIsmakeBorderColor[target],
                            background: 'transparent',
                            color: unSelectColor[target],
                            '&.hasDishChangeMake': {
                                backgroundColor: hasDishChangeMakeBackgroundColor[target]
                            }
                            // opacity: '0.62'
                        },
                        '& button.Tabs:hover': {
                            color: '#999999 !important',
                            background: `${changeIsmakeHoverBackgroundColor[target]} none repeat scroll !important`
                        },
                        // 桌台选择
                        '& .root': {
                            '& .rootdiv': {
                                '& button.selectedDeskButton': {
                                    backgroundColor: selectBackgroundColor[target],
                                    color: selectColor[target]
                                },
                                '& button.deskButton': {
                                    backgroundColor: unselectBackgroundColor[target],
                                    color: unSelectColor[target],
                                    opacity: '0.66'
                                }
                            }
                        },
                        '& .selectCardItem': {
                            background: floatSelectItemBackgroundColor[target],
                            color: floatSelectItemColor[target]
                        },
                        '& .totalOrderModeSwitch':{
                            color:selectColor[target],
                        },
                        // 卡片
                        '& .cardContainer': {
                            '& .cardContainerText': {
                                color: selectColor[target]
                            },
                            // 进度条
                            '& .MuiLinearProgress-root': {
                                height: 20
                            },
                            '& .MuiLinearProgress-colorPrimary': {
                                background: progressBackgroudColor[target]
                            },
                            '& .MuiLinearProgress-barColorPrimary': {
                                background: progressBarBackgroudColor[target]
                            },
                            '& .normal, & .Merge, & .Magnet': {
                                borderColor: 'transparent',
                                '&.cardSelect': {
                                    borderColor: ismakeBadgeBackgroundColor[target]
                                },
                                // 催菜提示
                                '& .rushTips': {
                                    backgroundColor: ismakeBadgeBackgroundColor[target],
                                    color: '#fff'
                                },
                                // 卡片整单备注弹窗
                                '& .order-remark-dialog':{
                                    '& .order-remark-detail':{
                                        background: cardOrderRemarkBackgroundColor[target]
                                    }
                                },
                                // 卡片头部
                                '& .Header': {
                                    background: cardHeaderBackgroundColor[target],
                                    '& .Numbers': {
                                        '& .Nums': {
                                            '& .DeskNum, & .PeopleNum, & .roundNum': {
                                                color: cardHeaderColor.table[target]
                                            }
                                        },
                                        '& .OrderNum': {
                                            color: cardHeaderColor.order[target],
                                            opacity: '0.7'
                                        }
                                    },
                                    '& .Time': {
                                        '& .OverTime': {
                                            color: cardHeaderColor.overTime[target]
                                        },
                                        '& .OrderTime': {
                                            color: cardHeaderColor.orderTime[target],
                                            opacity: '0.7'
                                        }
                                    }
                                },
                                // 卡片内容
                                '& .Container': {
                                    background: standardBaseBackgroundColor[target],
                                    color: 'white',
                                    // 整个卡片的选中框
                                    '& .cardDetailBorder': {
                                        borderColor: 'transparent',
                                        '&.selectCardDetailBorder': {
                                            borderColor: ismakeBadgeBackgroundColor[target]
                                        }
                                    },
                                    // 卡片中每笔菜品的背景颜色
                                    '& .cardDetailRush': {
                                        background: cardDetailRushBackgroundColor[target]
                                    },
                                    '& .cardDetailTakeout': {
                                        background: takeoutBackgroundColor[target]
                                    },
                                    '& .cardDetailNotMakeTakeout': {
                                        background: cardDetailNotMakeTakeoutBackgroundColor[target],
                                        color: target === 'standar' ? '#fff' : 'rgba(255,255,255,0.63)',
                                        '& .Row .Quantity': {
                                            '& .takeoutCardQuantity': {
                                                background: cardQuantityNotMakeBackgroundColor.takeout[target]
                                            },
                                            '& .otherCardQuantity': {
                                                background: cardQuantityNotMakeBackgroundColor.other[target]
                                            },
                                        }
                                    },
                                    '& .cardDetailNotMakeEatIn': {
                                        background: cardDetailNotMakeEatInBackgroundColor[target],
                                        color: target === 'standar' ? '#fff' : 'rgba(255,255,255,0.63)',
                                        '& .Row .Quantity': {
                                            '& .otherCardQuantity': {
                                                background: cardQuantityNotMakeBackgroundColor.other[target]
                                            },
                                            '& .baseCardQuantity': {
                                                background: cardQuantityNotMakeBackgroundColor.base[target]
                                            },
                                        }
                                    },
                                    // 总单模式下堂吃未制作的套餐
                                    '& .cardPackageNotMakeEatIn': {
                                        background: cardPackageNotMakeBg.eatInNotMake[target],
                                    },
                                    // 总单模式下堂吃已制作的菜品
                                    '& .cardDetailFinishedEatIn': {
                                        background: cardPackageNotMakeBg.eatInFinished[target],
                                    },
                                    // 总单模式下外卖已制作的菜品
                                    '& .cardDetailFinishedTakeaway': {
                                        background: cardPackageNotMakeBg.takeawayFinished[target],
                                    },
                                    // 总单模式下外卖未制作的套餐
                                    '& .cardPackageNotMakeTakeaway': {
                                        background: cardPackageNotMakeBg.takeawayNotMake[target],
                                    },
                                    '& .cardDetailBase': {
                                        // background: standardBaseBackgroundColor[target]
                                        background: 'transparent'
                                    },
                                    '& .cardUnderline': {
                                        position:'absolute',
                                        left:10,
                                        right:10,
                                        bottom:0,
                                        borderBottomColor: cardUnderlineColor[target]
                                    },
                                    // 磁力链颜色
                                    '& .magneticLinkHeaderShowBorder': {
                                        borderColor: ismakeBadgeBackgroundColor[target]
                                    },
                                    // 堂吃套餐已完成的磁力链头部背景色
                                    '& .packageLinkHeadEatInBg': {
                                        background: cardPackageNotMakeBg.eatInFinished[target]
                                    },
                                    // 外卖套餐已完成的磁力链头部背景色
                                    '& .packageLinkHeadTakeawayBg': {
                                        background: cardPackageNotMakeBg.takeawayFinished[target]
                                    },
                                    '& .magneticLinkHeader': {
                                        background: magneticLinkBackgroundColor.base[target]
                                    },
                                    '& .magneticLinkBorder': {
                                        borderColor: magneticLinkBackgroundColor.base[target]
                                    },
                                    // 卡片中的数量按钮颜色
                                    '& .Row,& .simple-row': {
                                        '& .Quantity': {
                                            '& .otherCardQuantity': {
                                                background: cardQuantityBackgroundColor.other[target],
                                                color: cardQuantityColor.other[target],
                                                '&.quantityRush': {
                                                    background: cardDetailRushBackgroundColor[target]
                                                }
                                            },
                                            '& .takeoutCardQuantity': {
                                                background: cardQuantityBackgroundColor.takeout[target],
                                                color: cardQuantityColor.takeout[target],
                                                '&.quantityRush': {
                                                    background: cardDetailRushBackgroundColor[target]
                                                }
                                            },
                                            '& .baseCardQuantity': {
                                                background: cardQuantityBackgroundColor.base[target],
                                                color: cardQuantityColor.base[target],
                                                '&.quantityRush': {
                                                    background: cardDetailRushBackgroundColor[target]
                                                }
                                            },
                                            // 整个卡片的选中框
                                            '&.quantityButtonBorder': {
                                                borderColor: 'transparent',
                                                '&.selectQuantityButtonBorder': {
                                                    borderColor: ismakeBadgeBackgroundColor[target]
                                                }
                                            }
                                        }
                                    },
                                    // 卡片的调味品
                                    '& .card-condiment': {
                                        padding: '5px 15px',
                                        borderRadius: 65,
                                        marginRight: 7,
                                        marginBottom: 10,
                                        '&.other': {
                                            background: cardOrderCondimentkBackgroundColor.other[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.other[target]
                                            }
                                        },
                                        '&.takeout': {
                                            background: cardOrderCondimentkBackgroundColor.takeout[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.takeout[target]
                                            }
                                        },
                                        '&.base': {
                                            background: cardOrderCondimentkBackgroundColor.base[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.base[target]
                                            }
                                        },
                                        '&.package': {
                                            background: cardOrderCondimentkBackgroundColor.package[target],
                                            color: cardOrderCondimentsFontSizeColor.package[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.eatInNotMake[target],
                                                color: cardOrderCondimentsFontSizeColor.eatInNotMake[target]
                                            },
                                            '&.eatInFinished': {
                                                background: cardOrderCondimentkNotMakeBackgroundColor.eatInFinished[target],
                                                color: cardOrderCondimentsFontSizeColor.eatInFinished[target]
                                            }
                                        },
                                        '&.takeawayPackage': {
                                            background: cardOrderCondimentkBackgroundColor.takeawayPackage[target],
                                            color: cardOrderCondimentsFontSizeColor.takeawayPackage[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.takeawayNotMake[target],
                                                color: cardOrderCondimentsFontSizeColor.takeawayNotMake[target]
                                            },
                                            '&.takeawayFinished': {
                                                background: cardOrderCondimentkNotMakeBackgroundColor.takeawayFinished[target],
                                                color: cardOrderCondimentsFontSizeColor.takwawayFinished[target]
                                            },
                                        },
                                        '&.rushPackage': {
                                            background: cardOrderCondimentkBackgroundColor.rushPackage[target],
                                            color: cardOrderCondimentsFontSizeColor.rushPackage[target],
                                            '&.notmake':{
                                                background: cardOrderCondimentkNotMakeBackgroundColor.rush[target]
                                            }
                                        },
                                    },
                                    // 非总单模式下堂吃套餐的背景色
                                    '& .packageBg': {
                                        background: packageBackgroundColor.eatIn[target],
                                        border: `1px solid ${packageBorderStyle.eatIn[target]}`,
                                        // 非总单模式下堂吃套餐菜品的背景色
                                        '& .packageDishesBg': {
                                            background: packageDishesBackgroundColor.eatIn[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInNormal[target]}`
                                        },
                                        // 非总单模式下堂吃套餐单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        // 非总单模式下堂吃套餐头底部边框的颜色
                                        '& .packageEatInBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.eatIn[target]}`,
                                            background: packageBackgroundColor.eatIn[target],
                                            ...commonPackageHeadBgStyle
                                        },
                                        // packageEatInDishesNotMakeBg
                                        '& .packageEatInDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.eatInNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.eatIn[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInNotMake[target]}`
                                        },
                                    },
                                    // 非总单模式下外卖套餐的背景色
                                    '& .takeawayPackageBg': {
                                        background: packageBackgroundColor.takeaway[target],
                                        border: `1px solid ${packageBorderStyle.takeaway[target]}`,
                                        '& .takeawayPackageDishesBg': {
                                            background: packageDishesBackgroundColor.takeaway[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayNormal[target]}`
                                        },
                                        // 非总单模式下外卖套餐单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageTakeAwayBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.takeaway[target]}`,
                                            background: packageBackgroundColor.takeaway[target],
                                            ...commonPackageHeadBgStyle
                                        },
                                        // packageTakeawayDishesNotMakeBg
                                        '& .packageTakeawayDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.takeawayNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.takeaway[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayNotMake[target]}`
                                        },
                                    },
                                    // 整个套餐的催菜背景色
                                    '& .rushedBg': {
                                        background: packageBackgroundColor.rushedBg[target],
                                        border: `1px solid ${packageBorderStyle.rushedBg[target]}`,
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageRushBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.rushedBg[target]}`
                                        },
                                    },
                                    // 总单模式下套餐的堂吃未制作背景色
                                    '& .packageEatInNotMakeBg': {
                                        background: packageBackgroundColor.eatInNotMake[target],
                                        border: `1px solid ${packageBorderStyle.eatInNotMake[target]}`,
                                        '& .packageEatInDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.eatInNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.eatIn[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInNotMake[target]}`
                                        },
                                        '& .packageFinishedEatInDishesBg': {
                                            background: packageDishesBackgroundColor.eatInFinished[target],
                                            color: packageDishesNotMakeFontSizeColor.eatInFinished[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInFinished[target]}`
                                        },
                                        // 总单模式下堂吃套餐单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageEatInNotMakeBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.eatInNotMake[target]}`,
                                            background: packageBackgroundColor.eatInNotMake[target],
                                            ...commonPackageHeadBgStyle
                                        }
                                    },
                                    // 总单模式下套餐的外卖未制作背景色
                                    '& .packageTakeawayNotMakeBg': {
                                        background: packageBackgroundColor.takeawayNotMake[target],
                                        border: `1px solid ${packageBorderStyle.takeawayNotMake[target]}`,
                                        '& .packageTakeawayDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.takeawayNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.takeaway[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayNotMake[target]}`
                                        },
                                        // 套餐中存在已完成的菜品
                                        '& .packageFinishedTakeawayDishesBg': {
                                            background: packageDishesBackgroundColor.takeawayFinished[target],
                                            color: packageDishesNotMakeFontSizeColor.takeawayFinished[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayFinished[target]}`
                                        },
                                        // 总单模式下外卖套餐单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageTakeawayNotMakeBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.takeawayNotMake[target]}`,
                                            background: packageBackgroundColor.takeawayNotMake[target],
                                            ...commonPackageHeadBgStyle
                                        }
                                    },
                                    // 总单模式下外卖已完成且存在已完成区域
                                    '& .packageTakeawayFinishedBg': {
                                        background: packageBackgroundColor.takeawayFinished[target],
                                        border: `1px solid ${packageBorderStyle.takeawaFinished[target]}`,
                                        '& .packageFinishedTakeawayDishesBg': {
                                            background: packageDishesBackgroundColor.takeawayFinished[target],
                                            color: packageDishesNotMakeFontSizeColor.takeawayFinished[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayFinished[target]}`
                                        },
                                        // 总单模式下外卖已完成且存在已完成区域单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageTakeawayFinishedBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.takeawaFinished[target]}`,
                                            background: packageBackgroundColor.takeawayFinished[target],
                                            ...commonPackageHeadBgStyle
                                        },
                                        // packageTakeawayDishesNotMakeBg
                                        '& .packageTakeawayDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.takeawayNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.takeaway[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.takeawayNotMake[target]}`
                                        },
                                    },
                                    // 总单模式下堂吃已完成且存在已完成区域
                                    '& .packageEatInFinishedBg': {
                                        background: packageBackgroundColor.eatInFinished[target],
                                        border: `1px solid ${packageBorderStyle.eatInFinished[target]}`,
                                        '& .packageFinishedEatInDishesBg': {
                                            background: packageDishesBackgroundColor.eatInFinished[target],
                                            color: packageDishesNotMakeFontSizeColor.eatInFinished[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInFinished[target]}`
                                        },
                                        // 总单模式下堂吃已完成且存在已完成区域单个菜品的催菜背景色
                                        '& .packageDishesRushedBg': {
                                            background: packageDishesBackgroundColor.rushed[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.rush[target]}`
                                        },
                                        '& .packageEatInFinishedBorderBottom': {
                                            borderBottom: `1px solid ${packageBorderStyle.eatInFinished[target]}`,
                                            background: packageBackgroundColor.eatInFinished[target],
                                            ...commonPackageHeadBgStyle
                                        },
                                        // packageEatInDishesNotMakeBg
                                        '& .packageEatInDishesNotMakeBg': {
                                            background: packageDishesBackgroundColor.eatInNotMake[target],
                                            color: packageDishesNotMakeFontSizeColor.eatIn[target],
                                            borderBottom: `1px solid ${packageDishesBorderBottomColor.eatInNotMake[target]}`
                                        },
                                    },
                                    // 卡片整单备注
                                    '& .order-remark':{
                                        background: cardOrderRemarkBackgroundColor[target]
                                    }
                                },
                                // 外卖的卡片
                                '&.takeout .Container': {
                                    background: takeoutBackgroundColor[target],
                                    '& .cardDetailTakeout': {
                                        // background: takeoutBackgroundColor[target]
                                        background: 'transparent'
                                    },
                                    '& .cardDetailBase': {
                                        background: standardBaseBackgroundColor[target]
                                    },
                                    '& .magneticLinkHeader': {
                                        background: magneticLinkBackgroundColor.takeout[target]
                                    },
                                    '& .magneticLinkBorder': {
                                        borderColor: magneticLinkBackgroundColor.takeout[target]
                                    }
                                },
                                // 卡片底
                                '& .Footer': {
                                    background: standardFooterBaseBackgroundColor[target],
                                    '& .CardButton': {
                                        background: 'rgba(255,255,255,0.2)',
                                        color: 'white'
                                    }
                                },
                                '&.takeout .Footer': {
                                    background: takeoutFooterBackgroundColor[target]
                                }
                            },
                        },
                        '& .pageWrapper': {
                            background: pageBackgroundColor[target],
                            '& .page': {
                                '& li': {
                                    color: pageUnSelectColor[target],
                                    opacity: 0.62,
                                    '&.active, &.nomore': {
                                        color: `${pageSelectColor[target]} !important`,
                                        opacity: 1
                                    }
                                }
                            }
                        }
                    },
                    // 右侧边栏
                    '& .rightSidebar': {
                        background: sidebarBackgroundColor[target],
                        '& .mergeAuto': {
                            background: takeoutBackgroundColor[target],
                        }
                    },
                    // 右侧边栏
                    '& .rightSidebar-orderMode': {
                        width:198,
                        padding: 14,
                        background: sidebarBackgroundColor[target],
                        '& .dishNameBtn, & .takeawayBtn': {
                            width:170,
                            height: 76,
                            marginBottom: 15,
                            borderRadius: 13,
                            boxSizing:'content-box',
                            color:selectColor[target],
                            textTransform:'none',
                            fontSize: 18,
                            lineHeight: 1.3,
                            background: dishesSummarySidebarBackgroundColor[target],
                            '&.select':{
                                background: dishesSummarySidebarSelectBackgroundColor[target],
                            }
                        },
                        '& .takeawayBtn': {
                            background: takeoutBackgroundColor[target]
                        }
                    }
                },
                // 取餐屏幕:
                '& .take-dishes': {
                    background: standardBackgroundColor[target],
                    '& .takeMealClass': {
                        '& .take-dishes-title': {
                            // marginLeft: 30
                        },
                        '& .swiper-wrapper': {
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignContent: 'flex-start',
                            height: '100%',
                            // paddingLeft: 30,
                            boxSizing: 'border-box',
                            '& .swiper-slide': {
                                marginRight: '1.38%',
                                // display: 'inline-block',
                                width: 'auto',
                                height: 'auto'
                            }
                        },
                        '& .swiper-pagination-bullets': {
                            left: 10,
                            right: 'auto'
                        }
                    },
                    '& .take-dishes-title': {
                        background: 'linear-gradient(#31A98D, #2FB579)'
                    },
                    '& .takeDishNumber': {
                        background: takeDishNumberBackgroundColor[target]
                    },
                    '& .swiper-container': {
                        height: '100%',
                        '& .swiper-slide': {
                            // background: standardBackgroundColor[target],
                            marginTop: 15,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            // height: '20%',
                            height: 'auto',
                            // width: '100%',
                            // marginBottom: 10
                            '&.flex-end': {
                                justifyContent: 'flex-end',
                            },
                            '&.center': {
                                justifyContent: 'center',
                            }
                        },
                        '& .swiper-pagination-bullet': {
                            background: '#eee'
                        },
                        '& .swiper-pagination-bullet-active': {
                            background: '#fff'
                        }
                    },
                }
            }
        },
    })(() => null);
})

export const GlobalCss = globalCss;

// export const SummerGlobalCss = withStyles({
//     // @global is handled by jss-plugin-global.
//     '@global': {
//         // You should target [class*="MuiButton-root"] instead if you nest themes.
//         '.normal': {
//             '& .Container': {
//                 backgroundColor: 'black'
//             }
//         }
//     },
// })(() => null);