import React, { SetStateAction } from 'react';
import { createHashHistory } from 'history';
import SwitchsComponent from "../components/setting-switch.component";
import PrettoSlidersComponent from "../components/setting-reset.component";
import DialogComponent from "../components/dialog.component";
import LoadingComponent from "../components/loading.component";
import ServesComponent from "../components/setting-serve.component";
import PrinterComponent from "../components/setting-printer.component";
import { Request } from "../modules/request"
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { StateManager, GlobalState, INIT_STATE, CACHE_LOGGER, getSelectConfig, CACHE_SELECT_CONFIG, CACHE_PRINT_LOGGER } from '../modules/state';
import i18nService from '../i18n';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RestoreIcon from '@material-ui/icons/Restore';
import { Fab, FormControl, Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';
import { GlobalStateType as GST } from "../modules/state";
import _ from 'lodash';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { BlockerModule } from "../modules/blocker/module";
import { MessengerModule } from "../modules/messenger/module";
import {
    ERROR_005, ERROR_006, ERROR_007, ERROR_008, ERROR_009,
    ERROR_0010, ERROR_0011, ERROR_0026
} from "../constants/error";
import { Unsubscribe } from 'redux';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import '../css/setting.scss';
import { Button } from '@material-ui/core';

let packageJson = require("../../package.json");
let moment = require('moment-timezone');

class SetPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // binding `this`
        this.onCheckerChange = this.onCheckerChange.bind(this);
        this.refreshChefAndWaiterPositions = this.refreshChefAndWaiterPositions.bind(this);
        this.refreshPrinter = this.refreshPrinter.bind(this);
        this.refreshDishMergeTypes = this.refreshDishMergeTypes.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.refreshSettingInfo = this.refreshSettingInfo.bind(this);
        this.render = this.render.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);
        this.getTablesInfo = this.getTablesInfo.bind(this);
        this.getTablesInformation = this.getTablesInformation.bind(this)
        this.clearAllLogging = this.clearAllLogging.bind(this);
        this.readLogs = this.readLogs.bind(this);
        this.readPrintLogs = this.readPrintLogs.bind(this);
        this.renderLogsContent = this.renderLogsContent.bind(this);
        this.audition = this.audition.bind(this);
        // this.getTables = this.getTables.bind(this)

        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        //init sound
        try {
            let ringtoneFile = StateManager.get('ringtoneFile');
            if (ringtoneFile) this.audio = new Audio(require(`../assets/audio/${ringtoneFile}`));
        } catch (error) {

        }
        this.willChangeConfig = getSelectConfig();

        // state
        this.state = {
            // refresh key
            refreshTime: new Date(),
            // language
            language: StateManager.get("language"),
            // positions
            chefPositions: [],
            chefPositionsSelected: StateManager.get("chefPositionsSelected"),
            waiterPositions: [],
            waiterPositionsSelected: StateManager.get("waiterPositionsSelected"),
            // 备菜岗位
            prepareDishPost: [],
            prepareDishPostSelected: StateManager.get("prepareDishPostSelected"),
            // printers
            printers: [],
            printersSelected: StateManager.get("printersSelected"),
            // dish merge types
            dishMergeTypes: [],
            dishMergeTypesSelected: StateManager.get("dishMergeTypesSelected"),
            // smart merger
            smartMergerAutoStart: StateManager.get("smartMergerAutoStart"),
            // server url
            serveUrl: StateManager.get("serveUrl"),
            // dialog
            exitDialogVisible: false,
            resetDialogVisible: false,
            // loading
            loadingVisible: false,
            // refresh button lock
            refreshButtonLock: false,

            // set button locker here
            ...BlockerModule.setButtonState([
                "refresh_button",
                "reset_button",
                "exit_button",
            ]),
            getTablesArray: [],
            tableNameArray: [],
            selectedTableAreaName: `${i18nService.t('text_takeaway')}`,

            negateSelectedArrayValue: "",
            selectedTableNumberArray: [],
            settingInfo: StateManager.get("settingInfo"),
            // 分桌排序
            sortTableFunction: StateManager.get('sortTableFunction'),
            // 右侧边栏图标对齐
            rightSidebarAlign: StateManager.get('rightSidebarAlign'),
            // 日志
            loggingFunction: StateManager.get('loggingFunction'),
            // 清空日志确认框
            clearLogDialogVisible: false,
            // 查看日志
            viewLogDialogVisible: false,
            // 查看打印日志
            viewPrintLogDialogVisible: false,
            // 日志每页显示笔数的选项
            rowsPerPageOptions: [10, 25, 100],
            // 日志每页显示的笔数
            rowsPerPage: 10,
            // 日志当前页
            page: 0,
            // 当前日志筛选时间,默认显示今天
            logFilterDate: moment().format("YYYY-MM-DD"),
            // 当前日志筛选是否成功
            logFilterIsSuccess: '',
            // 当前日志筛选订单
            logFilterOrderHeadId: '',
            // 铃声文件
            ringtoneFile: StateManager.get('ringtoneFile'),
            // 待上菜排序
            readyToServeSort: StateManager.get('readyToServeSort'),
            // 已下单页面排序
            orderedSort: StateManager.get('orderedSort'),
            // 卡片内菜品排序方式 0-菜品id ,1-菜品名1,2-菜品名2
            sortTheDishesInTheCard: StateManager.get('sortTheDishesInTheCard'),
            // 订单模式堂食卡片菜品品排序方式 0-下单时间 1-菜品名称1
            sortTheOrderModeDineIncard: StateManager.get('sortTheOrderModeDineIncard'),
            // 菜品更新确认框显示的菜品名称
            confirmWindowShowName: StateManager.get('confirmWindowShowName'),
            // 选择的配置文件
            selectConfig: getSelectConfig(),
            // 更换配置的提示框
            resetChangeConfigDialogVisible: false,
            // 主题
            theme: StateManager.get('theme'),
            rightSelectItem: [{
                name: 'text_base_setting',
                target: 'base-setting',
                index: 0
            }, {
                name: 'text_other_setting',
                target: 'other-setting',
                index: 1
            }, {
                name: 'text_advanced_settings',
                target: 'advanced-setting',
                index: 2
            }],
            rightSelectIndex: 0,
            // 外卖单显示范围(分钟)
            deliveryDisplayRange: StateManager.get('deliveryDisplayRange'),
            basisForRoundAdvance: StateManager.get('basisForRoundAdvance'),
            logFileName: '',
            // 菜名打印方式
            dishNamePrintMethod: StateManager.get('dishNamePrintMethod'),
            // 划菜打印方式
            printingMethod: StateManager.get('printingMethod'),
            // 划菜打印操作者方式
            printOperatorType: StateManager.get('printOperatorType'),
            // 菜品汇总侧边栏
            dishesSummarySidebar: StateManager.get('dishesSummarySidebar')
        }

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("set-page-update", () => {
            // update i18n
            i18nService.changeLanguage(StateManager.get("language"));
            // update local state
            this.setState({
                ...this.state,
                // language
                language: StateManager.get("language"),
                // positions
                chefPositionsSelected: StateManager.get("chefPositionsSelected"),
                waiterPositionsSelected: StateManager.get("waiterPositionsSelected"),
                prepareDishPostSelected: StateManager.get("prepareDishPostSelected"),
                // printers
                printersSelected: StateManager.get("printersSelected"),
                // dish merge types
                dishMergeTypesSelected: StateManager.get("dishMergeTypesSelected"),
                // server url
                serveUrl: StateManager.get("serveUrl"),
                // smart merger
                smartMergerAutoStart: StateManager.get("smartMergerAutoStart"),
                // 日志
                loggingFunction: StateManager.get('loggingFunction'),
                // 分桌排序
                sortTableFunction: StateManager.get('sortTableFunction'),
                // 右侧边栏图标对齐
                rightSidebarAlign: StateManager.get('rightSidebarAlign'),
                // 铃声文件
                ringtoneFile: StateManager.get('ringtoneFile'),
                // 待上菜排序
                readyToServeSort: StateManager.get('readyToServeSort'),
                // 已下单排序
                orderedSort: StateManager.get('orderedSort'),
                // 卡片内菜品排序方式 0-菜品id ,1-菜品名1,2-菜品名2
                sortTheDishesInTheCard: StateManager.get('sortTheDishesInTheCard'),
                // 订单模式堂食卡片菜品品排序方式 0-下单时间 1-菜品名称1
                sortTheOrderModeDineIncard: StateManager.get('sortTheOrderModeDineIncard'),
                // 菜品更新确认框显示的菜品名称
                confirmWindowShowName: StateManager.get('confirmWindowShowName'),
                // 选择的配置文件
                selectConfig: getSelectConfig(),
                // 主题
                theme: StateManager.get('theme'),
                // 外卖单显示范围(分钟)
                deliveryDisplayRange: StateManager.get('deliveryDisplayRange'),
                // 轮次推进依据
                basisForRoundAdvance: StateManager.get('basisForRoundAdvance'),
                // 菜名打印方式
                dishNamePrintMethod: StateManager.get('dishNamePrintMethod'),
                // 划菜打印方式
                printingMethod: StateManager.get('printingMethod'),
                // 划菜打印操作者方式
                printOperatorType: StateManager.get('printOperatorType'),
                // 菜品汇总侧边栏
                dishesSummarySidebar: StateManager.get('dishesSummarySidebar')
            } as SetStateAction<State>);
        });

    };

    private messengerModule: MessengerModule;

    private unsubscribe: Unsubscribe;
    // 铃声
    private audio: any = null;
    // 将要更换的配置名
    private willChangeConfig: string = ''
    // *********************
    // Default Function
    // *********************

    /**
     * update local state by object
     * @param val 
     */
    public updateLocalState(val: Partial<State>) {
        this.setState(val as any);
    };

    /**
     * update global state by action
     * @param val 
     */
    public updateGlobalState(action: GST.Action) {
        GlobalState.dispatch(action);
    };

    /**
     * update the check status in local and the target value in global state
     * @param name 
     * @param key 
     * @param element 
     * @param selected 
     */
    async onCheckerChange(name: string, key: string, element: any, selected: boolean) {
        // init
        element.checked = selected;
        let selectedValue: any[] = StateManager.get(`${name}Selected`) || [];
        // do selected / unselected action
        if (selected) {
            selectedValue = _.uniqBy([...selectedValue, element], key);
            this.getTablesInfo()
        } else {
            _.pullAllBy(selectedValue, [element], key);
            this.getTablesInfo()
        }

        // update global state
        this.updateGlobalState({
            type: "SET_BY_PATH",
            path: `${name}Selected`,
            value: selectedValue
        })
    }

    // *********************
    // Life Cycle Function
    // *********************

    /**
     * $ DO NOT use `GlobalState.dispatch` in this function!
     */
    async componentDidMount() {
        // start loading
        this.setState({ loadingVisible: true });
        // init data from server
        await this.init();
        // end loading
        this.setState({ loadingVisible: false });
        this.initTablesInformation()

    }



    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    // *********************
    // Service Function
    // *********************

    /**
     * init function
     * - step 0: init positions
     * - step 1: init printers
     * - step 2: init dish merge types
     */
    async init() {
        // init positions
        await this.refreshChefAndWaiterPositions();
        // init printers
        await this.refreshPrinter();
        // init dish merge types
        await this.refreshDishMergeTypes();
        //获取桌台区域
        await this.getTablesInfo()
    }

    /**
     * refresh the positions data of chef & waiter
     */
    async refreshChefAndWaiterPositions() {
        // init
        let chefPositions: any[] = [];
        let waiterPositions: any[] = [];
        let prepareDishPost: any[] = [];
        try {
            // call api
            let res = await Request.getPositions(StateManager.get("serveUrl"));
            // check
            if (!res) throw ERROR_005;
            if (res.length === 0) throw ERROR_006;

            res = res.data || [];
            // init
            chefPositions = _.map(res, _.clone);
            waiterPositions = _.map(res, _.clone);
            prepareDishPost = _.map(res, _.clone)
            // build chef & waiter position data
            res.forEach((target: any, index: number) => {
                chefPositions[index]["checked"] =
                    StateManager.get("chefPositionsSelected").find((v: any) => v.print_class_id === target.print_class_id) || false;
                waiterPositions[index]["checked"] =
                    StateManager.get("waiterPositionsSelected").find((v: any) => v.print_class_id === target.print_class_id) || false;
                prepareDishPost[index]["checked"] =
                    StateManager.get("prepareDishPostSelected").find((v: any) => v.print_class_id === target.print_class_id) || false;
            });
            // update local state
            this.updateLocalState({
                chefPositions, waiterPositions, prepareDishPost
            });
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => refreshChefAndWaiterPositions()",
                    chefPositions, waiterPositions, prepareDishPost
                }
            });
        }
    }

    /**
     * refresh the printer
     */
    async refreshPrinter() {
        try {
            // call api
            let res = await Request.getPrinters(StateManager.get("serveUrl"));
            // check
            if (!res) throw ERROR_007;
            if (res.length === 0) throw ERROR_008;

            // build printer data
            const printersSelected = StateManager.get("printersSelected") || [];
            // 新的打印机数据，用户防止聚客那边更新后，本地缓存没变化的问题。
            const newPrintersSelected = [];
            for (let i in res.data) {
                let findIndex = printersSelected.findIndex((v: any) => v.print_device_id === res.data[i].print_device_id);
                res.data[i]["checked"] = findIndex !== -1 ? true : false;
                // 当缓存中存在此打印机数据，则更新为接口新返回的数据
                if(findIndex !== -1) newPrintersSelected.push(res.data[i])
            }
            // update global state
            this.updateGlobalState({
                type: "SET_BY_PATH",
                path: 'printersSelected',
                value: newPrintersSelected
            })
            // update local state
            this.updateLocalState({
                printers: res.data
            });
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => refreshPrinter()",
                    printersSelected: StateManager.get("printersSelected")
                }
            });
        }
    }

    /**
     * refresh dish merge types
     */
    async refreshDishMergeTypes() {
        try {
            // call api
            let res = await Request.getDishMergeType(StateManager.get("serveUrl"));
            // check
            if (!res) throw ERROR_009;
            if (res.length === 0) throw ERROR_0010;

            // build dish merge types data
            for (let i in res.data) {
                res.data[i]["checked"] =
                    StateManager.get("dishMergeTypesSelected").find((v: any) => v.family_group_name === res.data[i].family_group_name) || false;
            }
            // update local state
            this.updateLocalState({
                dishMergeTypes: res.data
            });
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => refreshDishMergeTypes()",
                    dishMergeTypesSelected: StateManager.get("dishMergeTypesSelected")
                }
            });
        }
    }

    /**
     * refresh setting info
     * $ do not add this function to `init()`, we only add
     * $ this fucntion to refresh button.
     */
    async refreshSettingInfo() {
        try {
            // call api
            let settings: any = await Request.getSettingInfo(this.state.serveUrl);
            if (!settings) throw ERROR_0011;
            this.updateLocalState({
                settingInfo: settings.data
            })
            // update global state
            this.updateGlobalState({
                type: "SET_BY_PATH",
                path: "settingInfo",
                value: settings.data
            });
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => refreshSettingInfo()",
                }
            });
        }
    }

    /**
   * 获取桌台区域数据
   */
    async getTablesInfo() {
        try {
            // call api
            let getTables: any = await Request.getTablesInfo(this.state.serveUrl);
            if (!getTables) throw ERROR_0026;
            // 在原数据前加一个rvc_center_id为-1的外卖数据
            let obj: any = {
                rvc_center_id: -1,
                rvc_center_name: `${i18nService.t('text_takeaway')}`,
                tables: [{
                    table_id: -1,
                    table_name: `${i18nService.t('text_takeaway')}`,
                    checked: false
                }],
            }
            getTables.data.unshift(obj)
            let res: any = []

            for (let i in getTables.data) {

                //判断缓存中的菜品数组和当前区域下的菜品是否有相同，相同的话push到newArr数组中
                var newArr: any = [];
                var arr1 = getTables.data[i].tables;
                var arr2 = StateManager.get("tableNameSelected");
                for (let i = 0; i < arr2.length; i++) {
                    for (let j = 0; j < arr1.length; j++) {
                        if (arr1[j].table_id === arr2[i].table_id) {
                            newArr.push(arr1[j]);
                        }
                    }
                }
                //在每个桌台区域数据里添加气泡字段，字段值是newArr数组的长度
                getTables.data[i]["selectedQuantity"] = newArr.length
                //将处理过的数据push到一个数组中，供遍历使用
                res.push(getTables.data[i])
            }
            this.updateLocalState({ getTablesArray: res })
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => getTablesInfo()",
                }
            });
        } finally {
            // end loading
            this.updateLocalState({
                loadingVisible: false
            });
        }
    }
    /**
   * 获取桌台信息
   */
    getTablesInformation(item: any) {
        try {
            this.updateLocalState({
                tableNameArray: item.tables,
                selectedTableAreaName: item.rvc_center_name,
                selectedTableNumberArray: item
            })
            for (let i in item.tables) {
                item.tables[i]["checked"] =
                    StateManager.get("tableNameSelected").find((v: any) => v.table_id === item.tables[i].table_id) || false;
            }

        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => getTablesInformation()",
                }
            });
        }
    }

    initTablesInformation() {
        let obj = {
            rvc_center_id: -1,
            rvc_center_name: `${i18nService.t('text_takeaway')}`,
            tables: [{
                table_id: -1,
                table_name: `${i18nService.t('text_takeaway')}`,
                checked: StateManager.get("tableNameSelected").find((v: any) => v.table_id === -1) || false
            }],
        }

        this.getTablesInformation(obj)
    }
    //全选或者取消
    changeNegate(name: any, element: any,) {
        console.log("name", name)
        console.log("国际化name", `${i18nService.t('text_select_all')}`)
        try {
            //获取缓存的tableNameSelected
            let selectedValue: any[] = StateManager.get("tableNameSelected") || [];
            //如果传入的值为全选 -->`${i18nService.t('text_select_all')}`
            if (name === `${i18nService.t('text_select_all')}`) {
                //全选时，去重数据，再重新存进缓存中
                for (let i in element.tables) {
                    let findIndex = selectedValue.findIndex((target) => {
                        return target.table_id === element.tables[i].table_id
                    })
                    element.tables[i]["checked"] = true;
                    if (findIndex === -1) selectedValue.push(element.tables[i])
                }
                this.updateGlobalState({
                    type: "SET_BY_PATH",
                    path: `tableNameSelected`,
                    value: selectedValue
                })
                //全选后，更新气泡值
                this.getTablesInfo()
            }
            //如果传入的值为取消 -->`${i18nService.t('text_all_inversion')}`
            else if (name === `${i18nService.t('text_all_inversion')}`) {
                //取消时，判断target.table_id与element.tables[i].table_id是否相同，如果返回-1，则删除findIndex下标的值，再更新缓存
                for (let i in element.tables) {
                    let findIndex = selectedValue.findIndex((target) => {
                        return target.table_id === element.tables[i].table_id
                    })
                    element.tables[i]["checked"] = false;
                    if (findIndex !== -1) selectedValue.splice(findIndex, 1)
                }
                this.updateGlobalState({
                    type: "SET_BY_PATH",
                    path: `tableNameSelected`,
                    value: selectedValue
                })
                //取消后，更新气泡值
                this.getTablesInfo()
            }
        } catch (error) {
            this.messengerModule.say({
                ...error,
                details: {
                    caller: "setting.page.tsx => changeNegate()",
                }
            });
        }


    }
    /**
     * 清空所有日志
     */
    clearAllLogging() {
        this.setState({
            clearLogDialogVisible: true
        });
    }

    /**
     * 查看日志
     */
    readLogs() {
        this.setState({
            viewLogDialogVisible: true
        });
    }

    /**
     * 查看打印日志
     */
    readPrintLogs(){
        this.setState({
            viewPrintLogDialogVisible: true
        })
    }

    /**
     * 创建在表格显示的日志数据
     * @param logData 日志数据
     */
    createlogTableData(logData: Array<logData>) {
        logData = logData.filter(target => {
            // if(this.state.logFilterIsSuccess === '' && (this.state.logFilterOrderHeadId === '' || (this.state.logFilterIsSuccess !== '' && (target.isSuccess ? 'yes':'no') === this.state.logFilterIsSuccess))) return true
            // else if (this.state.logFilterOrderHeadId === '' && (this.state.logFilterIsSuccess === '' || (target.orderHeadId.join('**').indexOf(this.state.logFilterOrderHeadId) !== -1 && this.state.logFilterOrderHeadId !== ''))) return true
            // else if((this.state.logFilterOrderHeadId === '' || (this.state.logFilterIsSuccess !== '' && (target.isSuccess ? 'yes':'no') === this.state.logFilterIsSuccess) && (target.orderHeadId.join('**').indexOf(this.state.logFilterOrderHeadId) !== -1 && this.state.logFilterOrderHeadId !== ''))) return true
            // else if (this.state.logFilterOrderHeadId === '' || this.state.logFilterIsSuccess === '') return true
            // else return false
            let isSucess = this.state.logFilterIsSuccess !== '' && ((target.isSuccess ? 'yes' : 'no') !== this.state.logFilterIsSuccess)
            let orderHeadId = this.state.logFilterOrderHeadId !== '' && (target.orderHeadId.join('**').indexOf(this.state.logFilterOrderHeadId)) === -1
            if (isSucess || orderHeadId) return false
            else return true
        })
        return logData.map((target: logData) => {
            return {
                updateTime: target.updateTime,
                orderHeadId: target.orderHeadId,
                orderDetailId: target.orderDetailId,
                updateType: target.updateType,
                user: target.user,
                updateDishesNumber: target.updateDishesNumber,
                isSuccess: target.isSuccess,
                currentMode: target['stateSnapshot'] ? target['stateSnapshot']['currentMode'] : '',
                currentRole: target['stateSnapshot'] ? target['stateSnapshot']['currentRole'] : '',
                activePushTurn: target['stateSnapshot'] ? target['stateSnapshot']['activePushTurn'] : false,
                restaurantMode: target['stateSnapshot'] ? target['stateSnapshot']['restaurantMode'] : false,
                turns: target.turns,
                dishOrderTime: target.dishOrderTime,
                chefTurns: target.chefTurns,
                printAddress: target.printAddress ? target.printAddress : []
            }
        })
        // return { name, code, population, size, density };
    }
    /**
     * 试听铃声
     */
    audition() {
        this.audio && this.audio.play();
    }

    /**
     * 渲染日志信息
     */
    renderLogsContent() {
        // 如果没有开启日志功能，就不需要渲染
        if (!this.state.loggingFunction) return
        // 没有点击查看日志，不需要渲染
        if (!this.state.viewLogDialogVisible && !this.state.viewPrintLogDialogVisible) return
        // 表格显示的栏位
        let columns: Column[] = [];
        if(this.state.viewLogDialogVisible) {
            columns = [
                { id: 'updateTime', label: i18nService.t('text_update_time'), minWidth: 150 },
                { id: 'orderHeadId', label: i18nService.t('text_order_head_id'), minWidth: 100, format: (value: Array<any>) => value.join(',') },
                { id: 'orderDetailId', label: i18nService.t('text_order_detail_id'), minWidth: 100, format: (value: Array<any>) => value.join(',') },
                { id: 'updateType', label: i18nService.t('text_update_type'), minWidth: 170 },
                {
                    id: 'turns', label: i18nService.t('text_round_of_dishes'), minWidth: 170, format: (value: Array<any>) => {
                        if (Array.isArray(value)) return value.map((target: string, index: number) => (
                            <div key={index}>{target}</div>
                        ))
                        else return ''
                    }
                },
                {
                    id: 'dishOrderTime', label: i18nService.t('text_dish_order_time'), minWidth: 200, format: (value: Array<any>) => {
                        if (Array.isArray(value)) return value.map((target: string, index: number) => (
                            <div key={index}>{target}</div>
                        ))
                        else return ''
                    }
                },
                {
                    id: 'chefTurns', label: i18nService.t('text_kitchen_current_round'), minWidth: 170, format: (value: Array<any>) => {
                        if (Array.isArray(value)) return value.map((target: string, index: number) => (
                            <div key={index}>{target}</div>
                        ))
                        else return ''
                    }
                },
                { id: 'user', label: i18nService.t('text_user'), minWidth: 100 },
                { id: 'updateDishesNumber', label: i18nService.t('text_update_dishes_number'), minWidth: 100 },
                { id: 'isSuccess', label: i18nService.t('text_is_success'), minWidth: 100, format: (value: boolean) => value ? i18nService.t('text_yes') : i18nService.t('text_no') },
                { id: 'currentMode', label: i18nService.t('text_current_mode'), minWidth: 100, format: (value: string) => value === 'normal_mode' ? i18nService.t('text_normal_mode') : i18nService.t('text_order_mode') },
                { id: 'currentRole', label: i18nService.t('text_current_role'), minWidth: 100 },
                { id: 'activePushTurn', label: i18nService.t('text_round_propulsion_mode'), minWidth: 100, format: (value: boolean) => value ? i18nService.t('text_chef_automatically_pushes') : i18nService.t('text_waiter_manually_pushes') },
                { id: 'restaurantMode', label: i18nService.t('text_order_restaurant_mode'), minWidth: 100, format: (value: boolean) => value ? i18nService.t('text_yes') : i18nService.t('text_no') },
            ];
        } else if (this.state.viewPrintLogDialogVisible){
            columns = [
                { id: 'updateTime', label: i18nService.t('text_update_time'), minWidth: 150 },
                { id: 'orderHeadId', label: i18nService.t('text_order_head_id'), minWidth: 100, format: (value: Array<any>) => value.join(',') },
                { id: 'orderDetailId', label: i18nService.t('text_order_detail_id'), minWidth: 100, format: (value: Array<any>) => {
                    if (Array.isArray(value)) return value.map((target: string, index: number) => (
                        <div key={index}>{target}</div>
                    ))
                    else return ''
                } },
                { id: 'user', label: i18nService.t('text_user'), minWidth: 100 },
                { id: 'isSuccess', label: i18nService.t('text_is_success'), minWidth: 100, format: (value: boolean) => value ? i18nService.t('text_yes') : i18nService.t('text_no') },
                { id: 'currentRole', label: i18nService.t('text_current_role'), minWidth: 100 },
                { id: 'printAddress', label: i18nService.t('text_printer'), minWidth: 100, format: (value: Array<any>) => {
                    if (Array.isArray(value)) return value.map((target: string, index: number) => (
                        <div key={index}>{target}</div>
                    ))
                    else return ''
                } }
            ];
        }
        let cacheLogs: any = {}
        let rows: any = [];
        try {
            // 表格显示的内容
            let cache: any;
            if(this.state.viewLogDialogVisible){
                cache = localStorage.getItem(CACHE_LOGGER);
            }
            else if(this.state.viewPrintLogDialogVisible){
                cache = localStorage.getItem(CACHE_PRINT_LOGGER);
            }
            cacheLogs = JSON.parse(cache)
            cacheLogs && Object.keys(cacheLogs).forEach(target => {
                // 过滤日期的条件
                if (this.state.logFilterDate === '' || this.state.logFilterDate === target) {
                    rows = rows.concat(this.createlogTableData(cacheLogs[target]))
                }
            })
        } catch (error) {
        }
        // 渲染视图
        return (<Paper style={{ width: '100%' }}>
            <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ marginRight: 10 }}>{i18nService.t('text_date')}: </span>
                    <FormControl variant="outlined" >
                        <Select
                            value={this.state.logFilterDate}
                            onChange={(event: any) => this.updateLocalState({
                                logFilterDate: event.target.value,
                                page: 0
                            })}
                        >
                            <MenuItem value={""}>{""}</MenuItem>
                            {cacheLogs && Object.keys(cacheLogs).map(target => <MenuItem key={target} value={target}>{target}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ marginRight: 10 }}>{i18nService.t('text_is_success')}: </span>
                    <FormControl variant="outlined" >
                        <Select
                            value={this.state.logFilterIsSuccess}
                            onChange={(event: any) => this.updateLocalState({
                                logFilterIsSuccess: event.target.value,
                                page: 0
                            })}
                        >
                            <MenuItem value={""}>{""}</MenuItem>
                            <MenuItem value={"yes"}>{i18nService.t('text_yes')}</MenuItem>
                            <MenuItem value={"no"}>{i18nService.t('text_no')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ marginRight: 10 }}>{i18nService.t('text_order_head_id')}: </span>
                    <TextField
                        style={{ height: 50 }}
                        value={this.state.logFilterOrderHeadId}
                        onChange={(event: any) => this.updateLocalState({
                            logFilterOrderHeadId: event.target.value,
                            page: 0
                        })}
                        variant="outlined"
                        type="text"
                    />
                </div>
                {/* <Fab
                    variant="extended"
                    aria-label="delete"
                    style={{ background: "#660033", color: "#eee", fontSize: 10, display: "flex", minWidth: 80, marginTop: 10 }}
                    onClick={async () => {
                    }}
                >
                    {i18nService.t('button_search')}
                </Fab> */}
                <Fab
                    variant="extended"
                    aria-label="delete"
                    color="secondary"
                    style={{ fontSize: 10, display: "flex", minWidth: 80, marginTop: 10 }}
                    onClick={async () => {
                        this.updateLocalState({
                            logFilterDate: '',
                            logFilterOrderHeadId: '',
                            logFilterIsSuccess: ''
                        })
                    }}
                >
                    {i18nService.t('button_display_all')}
                </Fab>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ marginRight: 10 }}>{i18nService.t('text_log_name')}: </span>
                    <TextField
                        style={{ height: 50 }}
                        value={this.state.logFileName}
                        onChange={(event: any) => this.updateLocalState({
                            logFileName: event.target.value
                        })}
                        variant="outlined"
                        type="text"
                    />
                </div>
                <Fab
                    variant="extended"
                    aria-label="delete"
                    color="secondary"
                    style={{ fontSize: 10, display: "flex", minWidth: 80, marginTop: 10 }}
                    onClick={async () => {
                        try {
                            // 表格显示的内容
                            if (this.state.logFileName) {
                                let cache: any;
                                if(this.state.viewLogDialogVisible) {
                                    cache = localStorage.getItem(CACHE_LOGGER);
                                } else if (this.state.viewPrintLogDialogVisible) {
                                    cache = localStorage.getItem(CACHE_PRINT_LOGGER);
                                }
                                await Request.exportLog(StateManager.get("serveUrl"), {
                                    log: cache,
                                    name: this.state.logFileName
                                });
                                this.messengerModule.say({
                                    message: "text_export_success",
                                    level: "Info",
                                });
                            } else {
                                this.messengerModule.say({
                                    message: "text_please_input_log_name",
                                    level: "Error",
                                });
                            }
                        } catch (error) {
                            this.messengerModule.say({
                                message: "text_export_faile",
                                level: "Error",
                            });
                        }
                    }}
                >
                    {i18nService.t('text_export_log')}
                </Fab>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row: any) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.updateTime + row.orderDetailId.join(',')}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={this.state.rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={(event: unknown, newPage: number) => {
                    this.updateLocalState({
                        page: newPage
                    })
                }}
                onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.updateLocalState({
                        rowsPerPage: +event.target.value,
                        page: 0
                    })
                }}
            />
        </Paper>)
    }
    // *********************
    // View
    // *********************

    render(this: any) {
        let turnEnable = this.state.settingInfo.find((target: any) => target.id === 16);
        return (
            <div
                key={"" + this.state.refreshTime}
                style={{
                    height: "100vh",
                    width: "100%",
                    // backgroundColor: '#170503',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    overflow: 'hidden',
                }}
                className="settingPage"
            >
                {/* left */}
                <div style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 24,
                    marginRight: 24,
                    textAlign: "center"
                }}>

                    {/* Dialog */}

                    <DialogComponent
                        key={"exit-dialog-" + this.state.exitDialogVisible}
                        visible={this.state.exitDialogVisible}
                        title={i18nService.t("text_confirmation_box")}
                        content={i18nService.t("text_is_the_current_account_cancelled")}
                        cancelButtonText={i18nService.t("text_no")}
                        confirmButtonText={i18nService.t("text_yes")}
                        onCancelFn={() => {
                            this.setState({
                                exitDialogVisible: false
                            });
                        }}
                        onConfirmFn={() => {
                            try {
                                this.setState({ exitDialogVisible: false, exit_button_lock: true });
                                GlobalState.dispatch({
                                    type: "SET_BY_PATH",
                                    path: "account",
                                    value: "",
                                });
                                createHashHistory().push('./');
                                this.setState({ exit_button_lock: false });
                                // show message
                                this.messengerModule.say({
                                    message: "text_unable_to_get_global_setting_info",
                                    level: "Error",
                                });
                                window.location.reload();
                            } catch (error) {
                                this.messengerModule.say({
                                    ...error,
                                    details: {
                                        caller: "setting.page.tsx => DialogComponent 0 => onConfirmFn",
                                    }
                                });
                            }
                        }}
                    />
                    <DialogComponent
                        key={"reset-dialog-" + this.state.resetDialogVisible}
                        visible={this.state.resetDialogVisible}
                        title={i18nService.t("text_confirmation_box")}
                        content={i18nService.t("text_do_you_clear_and_reset_all_settings_and_cache")}
                        cancelButtonText={i18nService.t("text_no")}
                        confirmButtonText={i18nService.t("text_yes")}
                        onCancelFn={() => {
                            this.setState({
                                resetDialogVisible: false
                            });
                        }}
                        onConfirmFn={async () => {
                            try {
                                // start loading
                                this.setState({ loadingVisible: true, reset_button_lock: true });
                                // clear $ reset all cache and state
                                GlobalState.dispatch({
                                    type: "SET",
                                    value: INIT_STATE[getSelectConfig()]
                                });
                                // init
                                await this.init();
                                // forced refresh
                                this.updateLocalState({ resetDialogVisible: false, refreshTime: new Date(), reset_button_lock: false });
                                // info
                                // show message
                                this.messengerModule.say({
                                    message: "text_all_settings_and_caches_have_been_reset",
                                    level: "Info",
                                });

                                // $ go sign in page
                                createHashHistory().push('./');
                            } catch (error) {
                                this.messengerModule.say({
                                    ...error,
                                    details: {
                                        caller: "setting.page.tsx => DialogComponent 1 => onConfirmFn",
                                    }
                                });
                            } finally {
                                // end loading
                                this.setState({
                                    loadingVisible: false
                                });
                            }
                        }}
                    />

                    <DialogComponent
                        key={"reset-change-config-dialog-" + this.state.resetChangeConfigDialogVisible}
                        visible={this.state.resetChangeConfigDialogVisible}
                        title={i18nService.t("text_confirmation_box")}
                        content={i18nService.t("text_change_default_config")}
                        cancelButtonText={i18nService.t("text_no")}
                        confirmButtonText={i18nService.t("text_yes")}
                        onCancelFn={() => {
                            this.setState({
                                resetChangeConfigDialogVisible: false
                            });
                        }}
                        onConfirmFn={async () => {
                            try {
                                // start loading
                                this.setState({ loadingVisible: true, selectConfig: this.willChangeConfig });

                                // clear $ reset all cache and state
                                localStorage.setItem(CACHE_SELECT_CONFIG, this.willChangeConfig);
                                this.updateGlobalState({
                                    type: 'INIT'
                                })

                                // init
                                await this.init();
                                // forced refresh
                                this.updateLocalState({ resetChangeConfigDialogVisible: false });
                                // info
                                // show message
                                this.messengerModule.say({
                                    message: "text_all_settings_and_caches_have_been_reset",
                                    level: "Info",
                                });

                                // $ go sign in page
                                createHashHistory().push('./');
                            } catch (error) {
                                this.messengerModule.say({
                                    ...error,
                                    details: {
                                        caller: "setting.page.tsx => DialogComponent 1 => onConfirmFn",
                                    }
                                });
                            } finally {
                                // end loading
                                this.setState({
                                    loadingVisible: false
                                });
                            }
                        }}
                    />

                    <DialogComponent
                        key={"clearLog-dialog-" + this.state.clearLogDialogVisible}
                        visible={this.state.clearLogDialogVisible}
                        title={i18nService.t("text_confirmation_box")}
                        content={i18nService.t("text_whether_to_clear_all_logs")}
                        cancelButtonText={i18nService.t("text_no")}
                        confirmButtonText={i18nService.t("text_yes")}
                        onCancelFn={() => {
                            this.setState({
                                clearLogDialogVisible: false
                            });
                        }}
                        onConfirmFn={() => {
                            try {
                                this.setState({ clearLogDialogVisible: false });

                                localStorage.removeItem(CACHE_LOGGER);
                                localStorage.removeItem(CACHE_PRINT_LOGGER);
                                // show message
                                this.messengerModule.say({
                                    message: "text_all_logs_have_been_emptied",
                                    level: "Info",
                                });
                            } catch (error) {
                                this.messengerModule.say({
                                    ...error,
                                    details: {
                                        caller: "setting.page.tsx => DialogComponent 2 => onConfirmFn",
                                    }
                                });
                            }
                        }}
                    />

                    <Dialog
                        key={"viewLog-dialog-" + (this.state.viewLogDialogVisible || this.state.viewPrintLogDialogVisible)}
                        open={(this.state.viewLogDialogVisible || this.state.viewPrintLogDialogVisible) && this.state.loggingFunction}
                        onClose={() => {
                            this.updateLocalState({
                                viewLogDialogVisible: false,
                                viewPrintLogDialogVisible: false
                            })
                        }}

                        maxWidth={false}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        onClick={(e) => { e.stopPropagation(); }}
                    >
                        <DialogTitle id="scroll-dialog-title">
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start' }}>
                                <div>{i18nService.t("button_view_logging")}</div>
                                <CloseIcon onClick={() => {
                                    this.updateLocalState({
                                        viewLogDialogVisible: false,
                                        viewPrintLogDialogVisible: false,
                                    })
                                }} />
                            </div>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            {/* <DialogContentText
                                id="scroll-dialog-description"
                                tabIndex={-1}
                            > */}
                            {this.renderLogsContent()}
                            {/* </DialogContentText> */}
                        </DialogContent>
                    </Dialog>

                    {/* Buttons */}

                    <Fab
                        // color="primary"
                        color="secondary"
                        onClick={() => {    
                            let flag: boolean = StateManager.isSignedIn();
                            if (flag === false) {
                                createHashHistory().push('./');
                            } else {
                                createHashHistory().go(-2);
                            }

                        }}
                        style={{ marginTop: 20, }}
                    >
                        <ArrowBackIcon />
                    </Fab>
                    <Fab
                        // color="primary"
                        color="secondary"
                        style={{ marginTop: 20, }}
                        disabled={this.state.refreshButtonLock}
                        onClick={async () => {
                            try {
                                // start loading
                                this.setState({ loadingVisible: true, refreshButtonLock: true });
                                // init
                                await this.init();
                                // init setting info
                                await this.refreshSettingInfo();
                                // forced refresh
                                this.updateLocalState({ resetDialogVisible: false, refreshTime: new Date(), refreshButtonLock: false });
                                // info
                                // show message
                                this.messengerModule.say({
                                    message: "text_refreshed",
                                    level: "Info",
                                });
                            } catch (error) {
                                this.messengerModule.say({
                                    ...error,
                                    details: {
                                        caller: "setting.page.tsx => Buttons Area => Fab 1 => onClick",
                                    }
                                });
                            } finally {
                                // end loading
                                this.setState({
                                    loadingVisible: false
                                });
                            }
                        }}
                    >
                        <RefreshIcon />
                    </Fab>
                    <Fab
                        // color="primary"
                        color="secondary"
                        style={{ marginTop: 20, }}
                        disabled={this.state.reset_button_lock}
                        onClick={() => this.setState({ resetDialogVisible: true })}
                    >
                        <RestoreIcon />
                    </Fab>
                    <Fab
                        // color="primary"
                        color="secondary"
                        style={{ marginTop: 20, }}
                        disabled={this.state.exit_button_lock}
                        onClick={() => this.setState({ exitDialogVisible: true })}
                    >
                        <ExitToAppIcon />
                    </Fab>
                </div>
                {/* center */}
                <div
                    className="setting-content"
                    style={{
                        width: "90%",
                        overflowY: 'scroll',
                        overflowX: 'visible',
                        paddingTop: 120
                    }}
                    onScroll={(e) => {
                        let scrollTop = e.currentTarget.scrollTop;
                        let topArray: any = [];
                        // 获取右侧的菜单选项
                        this.state.rightSelectItem.forEach((target: any) => {
                            if (target.target) {
                                let ele: any = document.getElementById(target.target)
                                // 减2是为了增大些容错
                                if (ele) topArray.push({ top: ele.offsetTop - 2, index: target.index })
                            }
                        })
                        // 循环判断当前滚动的高度在哪个区间里面
                        for (const item of topArray) {
                            if (item.top <= scrollTop) {
                                this.updateLocalState({
                                    rightSelectIndex: item.index
                                })
                            } else break
                        }
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                width: "100%",
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                // backgroundColor: '#170503'
                            }}
                        >
                            {/* 基本设置 */}
                            <div id="base-setting" style={{ display: 'flex', flexDirection: 'column', marginBottom: 120 }}>
                                <span className="setting-type-title" style={{ fontSize: 25, margin: '0 0 24px 24px' }}>{i18nService.t('text_base_setting')}</span>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* 岗位信息 */}
                                    <div className="panelWrapper" style={{
                                        // background: "#38363E",
                                        borderRadius: 20,
                                        padding: 20,
                                        marginTop: 10,
                                        // height: "100%",
                                        flex: 1
                                    }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_job_position_info')}</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24 }}>
                                            <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_post')}</span>
                                            <FormGroup row>
                                                {
                                                    this.state.chefPositions.map((element: any, index: any) =>
                                                        <FormControlLabel key={"chef-positions-" + index}
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(element.checked)}
                                                                    onChange={(event: any, newValue: any) => this.onCheckerChange("chefPositions", "print_class_id", element, event.target.checked)}
                                                                    // style={{ color: "#eee" }} 
                                                                    value={element.print_class_name}
                                                                />
                                                            }
                                                            // style={{ color: "#eee" }}
                                                            label={element.print_class_name}
                                                        ></FormControlLabel>
                                                    )
                                                }
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24, }}>
                                            <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_the_waite_did_not_make_a_job_selection')}</span>
                                            <FormGroup row>
                                                {
                                                    this.state.waiterPositions.map((element: any, index: any) =>
                                                        <FormControlLabel key={"waiter-positions-" + index}
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(element.checked)}
                                                                    onChange={(event: any, newValue: any) => this.onCheckerChange("waiterPositions", "print_class_id", element, event.target.checked)}
                                                                    // style={{ color: "#eee" }} 
                                                                    value={element.print_class_name}
                                                                />
                                                            }
                                                            // style={{ color: "#eee" }}
                                                            label={element.print_class_name}
                                                        ></FormControlLabel>
                                                    )
                                                }
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24 }}>
                                            <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('prepare_dish_post')}</span>
                                            <FormGroup row>
                                                {
                                                    this.state.prepareDishPost.map((element: any, index: any) =>
                                                        <FormControlLabel disabled={!StateManager.get('prepareDishFunction')} key={"prepar-positions-" + index}
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(element.checked)}
                                                                    onChange={(event: any, newValue: any) => this.onCheckerChange("prepareDishPost", "print_class_id", element, event.target.checked)}
                                                                    // style={{ color: "#eee" }} 
                                                                    value={element.print_class_name}
                                                                />
                                                            }
                                                            // style={{ color: "#eee" }}
                                                            label={element.print_class_name}
                                                        ></FormControlLabel>
                                                    )
                                                }
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24, }}>
                                            <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_printer')}</span>
                                            <FormGroup row>
                                                {
                                                    (this.state.printers || []).map((element: any, index: any) =>
                                                        <FormControlLabel key={"printers-" + index}
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(element.checked)}
                                                                    onChange={(event: any, newValue: any) => this.onCheckerChange("printers", "print_device_id", element, event.target.checked)}
                                                                    // style={{ color: "#eee" }}
                                                                    value={element.print_device_name}
                                                                />
                                                            }
                                                            // style={{ color: "#eee" }}
                                                            label={element.print_device_name}
                                                        ></FormControlLabel>
                                                    )
                                                }
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24 }}>
                                            <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_food_combination')}</span>
                                            <FormGroup row>
                                                {
                                                    (this.state.dishMergeTypes || []).map((element: any, index: any) =>
                                                        <FormControlLabel key={"dish-merge-types-" + index}
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(element.checked)}
                                                                    onChange={(event: any, newValue: any) => this.onCheckerChange("dishMergeTypes", "family_group_id", element, event.target.checked)}
                                                                    // style={{ color: "#eee" }}
                                                                    value={element.family_group_name}
                                                                />
                                                            }
                                                            // style={{ color: "#eee" }}
                                                            label={element.family_group_name}
                                                        ></FormControlLabel>
                                                    )
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                                    {/* 卡片 */}
                                    <div style={{ flex: 2 }}>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            padding: 20,
                                            marginTop: 10,
                                            marginRight: 20,
                                            marginLeft: 24,
                                            flex: 2
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_card')}  </p>
                                            </div>

                                            <SwitchsComponent settingValue="displayName1" switchTitle={i18nService.t("text_display_name1")}></SwitchsComponent>

                                            <PrettoSlidersComponent max={30} min={5} settingValue="name1FontSize" settingText={i18nService.t("text_name1_font_size")} ></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={30} min={5} settingValue="cardheaderInformationBarFontASize" settingText={i18nService.t("text_card_header_information_batext_r_font_size")} ></PrettoSlidersComponent>

                                            <SwitchsComponent settingValue="displayName2" switchTitle={i18nService.t("text_display_name2")}></SwitchsComponent>

                                            <PrettoSlidersComponent max={30} min={5} settingValue="name2FontSize" settingText={i18nService.t("text_display_name2_font_size")}></PrettoSlidersComponent>


                                            <PrettoSlidersComponent max={30} min={5} settingValue="cardRemarksFontSize" settingText={i18nService.t("text_card_memo_font_size")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={150} min={20} settingValue="cardPerPageNum" settingText={i18nService.t("text_card_limit_per_page")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={150} min={20} settingValue="waiterUnMakePerPageNum" settingText={i18nService.t("text_the_waiter_did_not_create_a_maximum_number_of_cards")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={50} min={2} settingValue="cardCapacity" settingText={i18nService.t("text_card_capacity_limit")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={500} min={180} settingValue="cardWidth" settingText={i18nService.t("text_card_width")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={500} min={200} settingValue="cardMaxHeight" settingText={i18nService.t("text_card_max_height")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={705} min={180} settingValue="smartMergeCardMaxHeight" settingText={i18nService.t("text_smart_merge_card_height_maximum")}></PrettoSlidersComponent>

                                            <PrettoSlidersComponent max={1000} min={180} settingValue="orderModeCardMaxHeight" settingText={i18nService.t("text_order_mode_card_height_max")}></PrettoSlidersComponent>
                                            
                                            <PrettoSlidersComponent max={50} min={10} settingValue="totalOrderModeNumber" settingText={i18nService.t("total_order_mode_number")}></PrettoSlidersComponent>
                                            <PrettoSlidersComponent max={50} min={10} settingValue="takeawayModeNumber" settingText={i18nService.t("total_takeaway_mode_number")}></PrettoSlidersComponent>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* 功能 */}
                                    <div className="panelWrapper" style={{
                                        // background: "#38363E",
                                        borderRadius: 20,
                                        marginTop: 10,
                                        padding: 20,
                                        height: "100%",
                                        flex: 1
                                    }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_function')}</p>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="chefControlFunction" switchTitle={i18nService.t('text_chef_control')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="prepareDishFunction" switchTitle={i18nService.t('text_prepare_dish_mode')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="everyTableFoodQuantityCount" switchTitle={i18nService.t("text_statistics_on_the_number_of_dishes_per_table")} ></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="displayOrderId" switchTitle={i18nService.t("text_display_order_number")}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="displayDishInCombination" switchTitle={i18nService.t('text_show_dishes_from_the_promotional_package')}></SwitchsComponent>
                                        </div>
                                        {/* <div>
                                            <SwitchsComponent settingValue="cardAutoHeight" switchTitle={i18nService.t('text_card_adaptive_height_function')}></SwitchsComponent>
                                        </div> */}
                                        <div>
                                            <SwitchsComponent settingValue="addReasonInCancelFunction" switchTitle={i18nService.t('text_add_reason_in_cancel_production')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="orderInfoShowCustomerName" switchTitle={i18nService.t('text_order_info_show_customer_name')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="isHighlightDishesOfTheSameName" switchTitle={i18nService.t('text_highlight_dishes_of_the_same_name')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="displayDishesSidebar" switchTitle={i18nService.t('display_a_summary_of_dishes_sidebar')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="totalOrderMode" switchTitle={i18nService.t('total_order_mode')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="displayTakeawayStatisticsSidebar" switchTitle={i18nService.t('text_display_takeaway_statistics_sidebar')}></SwitchsComponent>
                                        </div>
                                        <div>
                                            <SwitchsComponent settingValue="isHideLeftSidebarAutomatically" switchTitle={i18nService.t('text_auto_hide_left_sidebar')}></SwitchsComponent>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginBottom: 24, }}>
                                            <span style={{ flex: 1, fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_delivery_order_display_range')}</span>
                                            <div style={{ display: "flex", justifyContent: 'flex-start', marginTop: 15 }}>
                                                <TextField
                                                    style={{ height: 50 }}
                                                    value={this.state.deliveryDisplayRange}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: 'deliveryDisplayRange',
                                                        value: event.target.value
                                                    })}
                                                    variant="outlined"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_basis_for_round_advance')}</p>
                                            </div>
                                            <FormControl variant="outlined" fullWidth={true}>
                                                <Select
                                                    value={this.state.basisForRoundAdvance}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `basisForRoundAdvance`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={'served'}>{i18nService.t('text_served_for_round_advance')}</MenuItem>
                                                    <MenuItem value={'made'}>{i18nService.t('text_made_for_round_advance')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* <div>
                                        <SwitchsComponent settingValue="confirmWindow" switchTitle={i18nService.t('text_menu_update_confirmation_box')}></SwitchsComponent>
                                    </div> */}
                                        {/* <div>
                                        <SwitchsComponent settingValue="keyboardModeFunction" switchTitle={i18nService.t('text_keyboard_mode')}></SwitchsComponent>
                                    </div> */}
                                        {/* <div>
                                        <SwitchsComponent settingValue="orderRestaurantMode" switchTitle={i18nService.t('text_order_restaurant_mode')}></SwitchsComponent>
                                    </div> */}
                                    </div>
                                    {/* 智能合并 */}
                                    <div style={{
                                        marginLeft: 24,
                                        marginRight: 20,
                                        paddingTop: 10,
                                        // paddingRight: 40,
                                        flex: 1
                                    }}>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            // minWidth: '50%'
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_smart_merger')}  </p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.smartMergerAutoStart}
                                                    onChange={(event: any) => {
                                                        this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: `smartMergerAutoStart`,
                                                            value: event.target.value === "true"
                                                        })
                                                        let globalMergeStatus = StateManager.get('globalMergeStatus') || {};
                                                        Object.keys(globalMergeStatus).forEach(role => {
                                                            Object.keys(globalMergeStatus[role]).forEach(status => {
                                                                globalMergeStatus[role][status] = {
                                                                    intelligentMode: event.target.value === "true",
                                                                    isSimpleMode: false
                                                                }
                                                            })
                                                        })
                                                        this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: 'globalMergeStatus',
                                                            value: globalMergeStatus
                                                        })
                                                    }}

                                                >
                                                    <MenuItem value={"true"}>{i18nService.t('text_automatic')}</MenuItem>
                                                    <MenuItem value={"false"}>{i18nService.t('text_manual')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ marginTop: 24 }} >
                                                <SwitchsComponent settingValue="onlyMergeDineIn" switchTitle={i18nService.t('text_only_merge_dine_in')}></SwitchsComponent>
                                            </div>
                                            <div style={{}} >
                                                <SwitchsComponent settingValue="mergeIgnoreCustomerInfo" switchTitle={i18nService.t('text_merge_ignore_customer_information')}></SwitchsComponent>
                                            </div>
                                            <div style={{}} >
                                                <SwitchsComponent settingValue="mergeCheckDishID" switchTitle={i18nService.t('text_smart_merge_check_dish_id')}></SwitchsComponent>
                                            </div>
                                            <div style={{}} >
                                                <SwitchsComponent settingValue="isInheritMergeState" switchTitle={i18nService.t('text_page_inheritance_merge_status')}></SwitchsComponent>
                                            </div>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div>
                                                <SwitchsComponent settingValue="confirmWindow" switchTitle={i18nService.t('text_menu_update_confirmation_box')}></SwitchsComponent>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="rushDishesconfirmWindow" switchTitle={i18nService.t('text_rush_dish_confirmation_box')}></SwitchsComponent>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_update_confirmation_box_show_name')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.confirmWindowShowName}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `confirmWindowShowName`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={1}>{i18nService.t('text_display_name1')}</MenuItem>
                                                    <MenuItem value={2}>{i18nService.t('text_display_name2')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 40 }}>
                                                <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('dishes_summary_sidebar')}</span>
                                            </div>
                                            <FormControl variant="outlined" fullWidth={true}>
                                                <Select
                                                    value={this.state.dishesSummarySidebar}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `dishesSummarySidebar`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={'1'}>{i18nService.t('text_display_name1')}</MenuItem>
                                                    <MenuItem value={'2'}>{i18nService.t('text_display_name2')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    {/* 右边设置 */}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1,
                                        borderRadius: 20,
                                        marginRight: 20,
                                        // marginLeft: 24,
                                    }}>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_right_sidebar_align')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.rightSidebarAlign}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `rightSidebarAlign`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"flex-start"}>{i18nService.t('text_top_align')}</MenuItem>
                                                    <MenuItem value={"center"}>{i18nService.t('text_center_align')}</MenuItem>
                                                    <MenuItem value={"flex-end"}>{i18nService.t('text_bottom_align')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_sort_table')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.sortTableFunction}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `sortTableFunction`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"ascending"}>{i18nService.t('text_ascending')}</MenuItem>
                                                    <MenuItem value={"descending"}>{i18nService.t('text_descending')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_ready_to_serve_sort')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.readyToServeSort}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `readyToServeSort`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"endTimeSort"}>{i18nService.t('text_end_time')}</MenuItem>
                                                    <MenuItem value={"orderTimeSort"}>{i18nService.t('text_order_time')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_ordered_sort')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.orderedSort}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `orderedSort`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"kdsTimeJointOrderTimeSort"}>{i18nService.t('text_take_out_dine_in_joint_sorting')}</MenuItem>
                                                    <MenuItem value={"orderTimeSort"}>{i18nService.t('text_order_time')}</MenuItem>
                                                    <MenuItem value={"kdsTimeSort"}>{i18nService.t('text_dining_time')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_sort_the_dishes_in_the_card')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.sortTheDishesInTheCard}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `sortTheDishesInTheCard`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"0"}>{i18nService.t('text_order_detail_id')}</MenuItem>
                                                    <MenuItem value={"1"}>{i18nService.t('text_display_name1')}</MenuItem>
                                                    <MenuItem value={"2"}>{i18nService.t('text_display_name2')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_sort_the_order_mode_dinein_in_the_card')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.sortTheOrderModeDineIncard}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `sortTheOrderModeDineIncard`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"0"}>{i18nService.t('text_order_time')}</MenuItem>
                                                    <MenuItem value={"1"}>{i18nService.t('text_display_name1')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_rowing_print')}</p>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="crossFoodPrint" switchTitle={i18nService.t('text_rowing_print')}></SwitchsComponent>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="isPrintEatIn" switchTitle={i18nService.t('text_eat_in_order')}></SwitchsComponent>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="isPrintTakeAway" switchTitle={i18nService.t('text_take_away_order')}></SwitchsComponent>
                                            </div>
                                            <div style={{ marginBottom: 24 }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 40 }}>
                                                    <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_dish_name_print_method')}</span>
                                                </div>
                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <Select
                                                        value={this.state.dishNamePrintMethod}
                                                        onChange={(event: any) => this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: `dishNamePrintMethod`,
                                                            value: event.target.value
                                                        })}
                                                    >
                                                        <MenuItem value={'0'}>{i18nService.t('text_display_name1') + ' + ' + i18nService.t('text_display_name2')}</MenuItem>
                                                        <MenuItem value={'1'}>{i18nService.t('text_display_name1')}</MenuItem>
                                                        <MenuItem value={'2'}>{i18nService.t('text_display_name2')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div style={{ marginBottom: 24 }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 40 }}>
                                                    <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_printing_method')}</span>
                                                </div>
                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <Select
                                                        value={this.state.printingMethod}
                                                        onChange={(event: any) => this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: `printingMethod`,
                                                            value: event.target.value
                                                        })}
                                                    >
                                                        <MenuItem value={'SPLIT_MODE'}>{i18nService.t('text_split_printing_mode')}</MenuItem>
                                                        <MenuItem value={'FULL_MODE'}>{i18nService.t('text_full_order_printing_mode')}</MenuItem>
                                                        <MenuItem value={'SEPARATE_BY_DISH'}>{i18nService.t('text_one_receipt_per_dish_mode')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div style={{ marginBottom: 24 }}>
                                                <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 40 }}>
                                                    <span style={{ fontSize: 18, fontWeight: "bold" }}>{i18nService.t('text_printing_operator_type')}</span>
                                                </div>
                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <Select
                                                        value={this.state.printOperatorType}
                                                        onChange={(event: any) => this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: `printOperatorType`,
                                                            value: event.target.value
                                                        })}
                                                    >
                                                        <MenuItem value={'ORDER'}>{i18nService.t('text_printing_order_account')}</MenuItem>
                                                        <MenuItem value={'CHEF'}>{i18nService.t('text_printing_chef_account')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 其他设置 */}
                            <div id="other-setting" style={{ display: 'flex', flexDirection: 'column', marginBottom: 120 }}>
                                <span className="setting-type-title" style={{ fontSize: 25, margin: '0 0 24px 24px' }}>{i18nService.t('text_other_setting')}</span>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* 取餐屏幕区域 */}
                                    <div className="panelWrapper" style={{
                                        // background: "#38363E",
                                        borderRadius: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: 20,
                                        marginTop: 10,
                                        flex: 2
                                    }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_meal_screen_area')}</p>
                                        </div>
                                        <SwitchsComponent settingValue="mealScreenCustomerName" switchTitle={i18nService.t('text_whether_to_display_the_customer_name')}></SwitchsComponent>
                                        <PrettoSlidersComponent max={200} min={50} settingValue="mealScreenFontSize" settingText={i18nService.t('text_meal_screen_font_size')}></PrettoSlidersComponent>
                                        {/* <PrettoSlidersComponent max={20} min={1} settingValue="takeMealWaitTime" settingSubText={i18nService.t("text_change_to_wait_for_meal")} settingText={i18nService.t("text_take_meal_wait_time")}></PrettoSlidersComponent> */}
                                        <PrettoSlidersComponent max={25} min={5} settingValue="takeMealFormfeedTime" settingText={i18nService.t("text_take_meal_from_feed_time")}></PrettoSlidersComponent>
                                    </div>
                                    {/* 右边设置 */}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1,
                                        borderRadius: 20,
                                        marginRight: 20,
                                        marginLeft: 24,
                                    }}>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_language')}  </p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.language}
                                                    onChange={(event: any) => {
                                                        this.updateGlobalState({
                                                            type: "SET_BY_PATH",
                                                            path: `language`,
                                                            value: event.target.value
                                                        })
                                                        this.getTablesInfo()
                                                        this.initTablesInformation()
                                                    }}
                                                >
                                                    <MenuItem value={"en"}>en</MenuItem>
                                                    <MenuItem value={"it"}>it</MenuItem>
                                                    <MenuItem value={"zh"}>zh</MenuItem>
                                                    <MenuItem value={"es"}>es</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 70 }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_theme')}</p>
                                            </div>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    value={this.state.theme}
                                                    onChange={(event: any) => this.updateGlobalState({
                                                        type: "SET_BY_PATH",
                                                        path: `theme`,
                                                        value: event.target.value
                                                    })}
                                                >
                                                    <MenuItem value={"standard"}>{i18nService.t('text_theme_standard')}</MenuItem>
                                                    <MenuItem value={"summer"}>{i18nService.t('text_theme_summer')}</MenuItem>
                                                    <MenuItem value={"atlanti"}>{i18nService.t('text_theme_atlantis')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            padding: 20,
                                            marginTop: 10,
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_sound')}</p>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="soundReminder" switchTitle={i18nService.t('text_ringtone_reminder')}></SwitchsComponent>
                                            </div>
                                            <div>
                                                <span style={{ height: 50, fontSize: 18, fontWeight: "bold", display: "flex", textAlign: "center" }}>{i18nService.t('text_ring')}</span>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <FormControl variant="outlined" style={{ flex: 1 }} >
                                                        <Select
                                                            disabled={!StateManager.get('soundReminder')}
                                                            value={this.state.ringtoneFile}
                                                            onChange={(event: any) => {
                                                                let ringtoneFile = event.target.value;
                                                                if (ringtoneFile) this.audio = new Audio(require(`../assets/audio/${ringtoneFile}`));
                                                                this.updateGlobalState({
                                                                    type: "SET_BY_PATH",
                                                                    path: `ringtoneFile`,
                                                                    value: event.target.value
                                                                })
                                                            }}
                                                        >
                                                            <MenuItem value={"radar.mp3"}>{i18nService.t('text_sound_name_radar')}</MenuItem>
                                                            <MenuItem value={"starlight.mp3"}>{i18nService.t('text_sound_name_starlight')}</MenuItem>
                                                            <MenuItem value={"bird.mp3"}>{i18nService.t('text_sound_name_bird')}</MenuItem>
                                                            <MenuItem value={"bamboo.mp3"}>{i18nService.t('text_sound_name_bamboo')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", paddingTop: 20, paddingBottom: 20 }}>
                                                        <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, marginRight: 10, display: "flex", width: 80 }} disabled={!StateManager.get('soundReminder')} onClick={this.audition} >
                                                            <VolumeUpIcon></VolumeUpIcon>
                                                        </Fab>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 高级设置 */}
                            <div id="advanced-setting" style={{ display: 'flex', flexDirection: 'column', marginBottom: 120 }}>
                                <span className="setting-type-title" style={{ fontSize: 25, margin: '0 0 24px 24px' }}>{i18nService.t('text_advanced_settings')}</span>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* 左边设置 */}
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >
                                            <div style={{ display: "flex", justifyContent: "flex-start", minHeight: 50 }}>
                                                <span style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_default_config')}</span>
                                            </div>
                                            <FormControl variant="outlined" fullWidth={true}>
                                                <Select
                                                    value={this.state.selectConfig}
                                                    onChange={(event: any) => {
                                                        if (event.target.value !== this.state.selectConfig) {
                                                            this.willChangeConfig = event.target.value;
                                                            this.updateLocalState({
                                                                resetChangeConfigDialogVisible: true
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {Object.keys(INIT_STATE).map(target => {
                                                        if (target === 'selectConfig') return undefined
                                                        else return <MenuItem value={target} key={target}>{target}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            marginTop: 10,
                                            padding: 20,
                                            // height: "100%"
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_logging_function')}</p>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="loggingFunction" switchTitle={i18nService.t('text_is_enable_logging_function')}></SwitchsComponent>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, minHeight: 50, width: 120 }} disabled={!this.state.loggingFunction} onClick={this.clearAllLogging}>
                                                    {i18nService.t('button_clear_all_logging')}
                                                </Fab>
                                                <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, minHeight: 50, width: 120 }} disabled={!this.state.loggingFunction} onClick={this.readPrintLogs}>
                                                    {i18nService.t('button_view_print_logging')}
                                                </Fab>
                                                <Fab color="secondary" variant="extended" aria-label="delete" style={{ fontSize: 10, minHeight: 50, width: 120 }} disabled={!this.state.loggingFunction} onClick={this.readLogs}>
                                                    {i18nService.t('button_view_logging')}
                                                </Fab>
                                            </div>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            padding: 20,
                                            marginTop: 10
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span>{i18nService.t('text_version')} :</span>
                                                <span style={{ marginLeft: 10 }}>{packageJson && packageJson.version ? packageJson.version : '1.0.9'}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 24 }}>
                                                <span>{i18nService.t('text_round_propulsion_mode')} :</span>
                                                <span style={{ marginLeft: 10 }}>{turnEnable && turnEnable.value && turnEnable.value.status ? i18nService.t('text_chef_automatically_pushes') : i18nService.t('text_waiter_manually_pushes')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 右边设置 */}
                                    <div style={{
                                        flex: 2, display: 'flex', flexDirection: 'column',
                                        marginRight: 20,
                                        marginLeft: 24
                                    }}>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >

                                            <ServesComponent></ServesComponent>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >

                                            <PrinterComponent></PrinterComponent>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >

                                            <PrettoSlidersComponent max={20} min={3} settingValue="dataFreshTime" settingText={i18nService.t('text_data_refresh_cycle')}></PrettoSlidersComponent>
                                        </div>
                                        <div className="panelWrapper" style={{
                                            minHeight: 150,
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            marginTop: 10,
                                        }} >

                                            <PrettoSlidersComponent max={20} min={3} settingValue="receiptRetryTimes" settingText={i18nService.t('text_retry_printing_the_number_of_small_votes')}></PrettoSlidersComponent>
                                        </div>
                                        <div className="panelWrapper tableArea" style={{
                                            // background: "#38363E",
                                            borderRadius: 20,
                                            padding: 20,
                                            marginTop: 10
                                        }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <p style={{ fontSize: 25, fontWeight: "bold" }}>{i18nService.t('text_dish_area')}</p>
                                            </div>
                                            <div>
                                                <SwitchsComponent settingValue="regionalMenuFunction" switchTitle={i18nService.t("text_whether_to_open_the_regional_menu_function")}></SwitchsComponent>
                                            </div>
                                            {
                                                StateManager.get('regionalMenuFunction') ?
                                                    <div>
                                                        <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap", }}>
                                                            {(this.state.getTablesArray).map((item: any, getTablesArrayIndex: number) => {
                                                                return (
                                                                    <div style={{ display: "flex", flexDirection: 'column', width: 110, marginBottom: 24 }} key={getTablesArrayIndex}>
                                                                        <Badge style={{ marginRight: 24 }} badgeContent={item.selectedQuantity} color="primary">
                                                                            <Fab className={this.state.selectedTableAreaName === item.rvc_center_name ? 'selectArea' : ''} color="secondary"
                                                                                style={{ width: 100, borderRadius: 20 }}
                                                                                aria-label="add"
                                                                                onClick={this.getTablesInformation.bind(this, item)}>
                                                                                {item.rvc_center_name}
                                                                            </Fab>
                                                                        </Badge>
                                                                    </div>

                                                                )
                                                            })}

                                                        </div>
                                                        <div>
                                                            <Fab color="secondary"
                                                                style={{ minWidth: 100, background: 'rgba(255,255,255,0.2)', height: 'auto', borderRadius: 20, marginRight: 24 }}
                                                                aria-label="add"
                                                                onClick={this.changeNegate.bind(this, `${i18nService.t('text_select_all')}`, this.state.selectedTableNumberArray)}
                                                            >
                                                                {`${i18nService.t('text_select_all')}`}
                                                            </Fab>
                                                            <Fab color="secondary"
                                                                style={{ minWidth: 100, background: 'rgba(255,255,255,0.2)', height: 'auto', borderRadius: 20 }}
                                                                aria-label="add"
                                                                onClick={this.changeNegate.bind(this, `${i18nService.t('text_all_inversion')}`, this.state.selectedTableNumberArray)}
                                                            >
                                                                {`${i18nService.t('text_all_inversion')}`}
                                                            </Fab>
                                                        </div>
                                                        <div style={{ marginTop: 24 }}>
                                                            {this.state.tableNameArray.length > 0 ? <FormGroup row>
                                                                {
                                                                    (this.state.tableNameArray || []).map((element: any, index: any) =>
                                                                        <FormControlLabel key={"table-name-array-" + index}
                                                                            style={{ marginRight: 24 }}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={Boolean(element.checked)}
                                                                                    onChange={(event: any,) => this.onCheckerChange("tableName", "table_id", element, event.target.checked)}
                                                                                    // style={{ color: "#eee" }}
                                                                                    value={element.table_name}
                                                                                />
                                                                            }
                                                                            // style={{ color: "#eee" }}
                                                                            label={element.table_name}
                                                                        ></FormControlLabel>
                                                                    )
                                                                }
                                                            </FormGroup> :
                                                                <div key={'table-name-array-' + this.state.tableNameArray.length} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                                    <p style={{ color: 'white' }}>{i18nService.t("text_no_data")}</p>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div> : null
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* right */}
                <div className="setting-type" style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 24,
                    marginRight: 24,
                    textAlign: "center",
                    paddingTop: 120
                }}>
                    {this.state.rightSelectItem.map((target: any, index: any) => {
                        return (
                            <Button className={this.state.rightSelectIndex === target.index ? 'select' : ''} key={index} variant="outlined" style={{ border: 'none', padding: 0 }} onClick={() => {
                                if (target.target) {
                                    let anchorElement = document.getElementById(target.target);
                                    if (anchorElement) { anchorElement.scrollIntoView(); }
                                }
                                this.updateLocalState({
                                    rightSelectIndex: target.index
                                })
                            }}>{i18nService.t(target.name)}
                            </Button>
                        )
                    })}
                </div>
                {/* Loading */}
                <LoadingComponent key={"" + this.state.loadingVisible} style={{ position: "absolute", right: 20, bottom: 20, width: 60, height: 60 }} visible={this.state.loadingVisible} />

            </div >
        );
    }
}

export default withSnackbar(SetPage as any);

// *********************
// Types
// *********************

type Column = {
    id: 'updateTime' | 'orderHeadId' | 'orderDetailId' | 'updateType' | 'user' | 'updateDishesNumber' | 'isSuccess' | 'activePushTurn' | 'currentMode' | 'currentRole' | 'restaurantMode' | 'turns' | 'dishOrderTime' | 'chefTurns' | 'printAddress';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string | JSX.Element | JSX.Element[];
}

type logData = {
    updateTime: string;
    // 订单id
    orderHeadId: Array<string>;
    // 菜品详细ID
    orderDetailId: Array<string>;
    // 更新类型（从什么状态，更新为什么状态）
    updateType: string;
    // 操作者账户
    user: string;
    // 更新菜品数量
    updateDishesNumber: number;
    // 是否成功
    isSuccess: boolean;
    // 状态快照
    stateSnapshot: {
        // 当前模式
        currentMode: string;
        // 当前页面
        currentRole: string;
        // 当前选择按钮  已下单，已备菜，待上菜，已上菜，已暂停，取消制作，已制作
        currentFilterButton: string;
        // 点餐餐厅模式
        restaurantMode: boolean;
        // 推进模式
        activePushTurn: boolean;
    }
    // 菜品所在轮次
    turns: string[],
    // 菜品下单时间
    dishOrderTime: string[]
    // 厨师当前制作轮次
    chefTurns: string[]
    // 打印地址
    printAddress: string[]
}

type Props = {
} & WithSnackbarProps;

type State = {
    refreshTime: Date;
    printers: Array<any>;
    chefPositions: Array<any>;
    dishMergeTypes: Array<any>;
    waiterPositions: Array<any>;
    prepareDishPost: Array<any>;
    serveUrl: string;

    exitDialogVisible: boolean;
    resetDialogVisible: boolean;

    loadingVisible: boolean;

    refreshButtonLock: boolean;
    getTablesArray: Array<any>
    tableNameArray: Array<any>
    selectedTableAreaName: string
    selectedTableNumberArray: Array<any>
    negateSelectedArray: Array<any>
    negateSelectedArrayValue: string,
    settingInfo: Array<any>;

    sortTableFunction: 'ascending' | 'descending';
    rightSidebarAlign: 'Top' | 'Center' | 'Bottom';
    loggingFunction: boolean;
    clearLogDialogVisible: boolean;
    viewLogDialogVisible: boolean;
    viewPrintLogDialogVisible: boolean;
    rowsPerPage: number;
    page: number;
    rowsPerPageOptions: Array<number>;
    logFilterDate: string;
    logFilterIsSuccess: string;
    logFilterOrderHeadId: string;
    ringtoneFile: string;
    readyToServeSort: string;
    orderedSort: string;
    sortTheDishesInTheCard: '0' | '1' | '2';
    sortTheOrderModeDineIncard: '0' | '1'
    theme: string;
    confirmWindowShowName: number;
    selectConfig: string;
    resetChangeConfigDialogVisible: boolean;
    rightSelectItem: Array<{
        name: string;
        target: string;
    }>;
    rightSelectIndex: number;
    logFileName: string;
    dishNamePrintMethod: string;
    dishesSummarySidebar: string;
    printingMethod: 'SPLIT_MODE' | 'FULL_MODE' | 'SEPARATE_BY_DISH';
    // 划菜打印操作者方式
    printOperatorType: 'ORDER' | 'CHEF';
}
