import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { StateManager } from '../modules/state';

export class TakeDishesComponent extends Component<Props, State> {
    // eslint-disable-next-line
    constructor(props: Props) {
        super(props);
        this.state = {
            mealData: []
        }
    };
    private swiperEle: any;
    // *********************
    // Default Function
    // *********************

    async componentDidMount() {
    }
    /**
       * remove subscribe and timer
       */
    componentWillUnmount() {
    }
    componentWillReceiveProps = (nextProps: Props) => {
        this.setState({
            mealData: nextProps.mealData
        })
    }
    componentDidUpdate() {
        console.log(this.swiperEle)
        if(this.swiperEle){
            // this.swiperEle.update(true);
            // this.swiperEle.updateSlides();
            // 触发一下自动切换
            if(this.swiperEle.autoplay.running){
                this.swiperEle.autoplay.stop();
                this.swiperEle.autoplay.start();
            }
        }
    }
    /**
     * update local state by object
     * @param val 
     */
    public updateLocalState(val: Partial<State>) {
        this.setState(val as any);
    };

    public align() {
        let align = 'left';
        if (this.props.align) align = this.props.align;
        if (align === 'right') {
            return 'end';
        } else if (align === 'center') {
            return 'center';
        } else if (align === 'left') {
            return 'start';
        }
    }

    public flexAlign() {
        let align = 'left';
        if (this.props.align) align = this.props.align;
        if (align === 'right') {
            return 'flex-end';
        } else if (align === 'center') {
            return 'center';
        } else if (align === 'left') {
            return 'flex-start';
        }
    }
    // *********************
    // View
    // *********************

    render() {
        const params = {
            direction: 'vertical',
            // loop: true,
            // effect: 'fade',
            slidesPerView: 'auto',
            // slidesPerView: 3,
            // spaceBetween: 30,
            // slidesPerView: 6,
            // spaceBetween: 30,
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: "column",
                // overflow: 'hidden'
                height: "100vh",
                boxSizing: 'border-box',
                padding: '0 15px',
                ...this.props.styles
            }} className={this.props.takeMealClass}>
                <div style={{ textAlign: this.align() }}>
                    <div className="take-dishes-title" style={{ display: 'inline-block', height: '70px', lineHeight: '70px', textAlign: 'center', padding: '0px 35px', minWidth: 150, borderRadius: 8, fontSize: 30 }}>
                        {this.props.title}
                    </div>
                </div>
                <div style={{ height: '90%', boxSizing: 'border-box', paddingTop: 10 }}>
                    <Swiper
                        shouldSwiperUpdate={true}
                        // rebuildOnUpdate={true}
                        {...params}
                        {...this.props.swiperParams}
                        getSwiper={(ele) => {
                            this.swiperEle = ele;
                        }}
                    >
                        {this.state.mealData.map((target: any, index: number) => {
                            return (
                                <div className={this.flexAlign()} key={target.order_head_id}>
                                    <div className="takeDishNumber" style={{
                                        display: 'inline-block',
                                        // flex:1,
                                        padding: '10px 15px',
                                        margin: '10px 0',
                                        textAlign: 'center',
                                        borderRadius: 8,
                                        fontSize: StateManager.get('mealScreenFontSize') + 'px'
                                    }}>{this.props.format instanceof Function ? this.props.format(target) : target.check_number}</div>
                                </div>
                            )
                        })}
                        {/* <div>Slide #1</div>
                        <div>Slide #2</div>
                        <div>Slide #3</div>
                        <div>Slide #4</div>
                        <div>Slide #5</div> */}
                    </Swiper>
                </div>
            </div>
        );
    }

}

export default withSnackbar(TakeDishesComponent as any);

// *********************
// Types
// *********************

type State = {
    mealData: any[];
}

type Props = {
    takeMealClass?: string;
    swiperParams?: object;
    mealData: any[];
    title: string;
    format?: Function;
    styles?: React.CSSProperties;
    align?: 'left' | 'center' | 'right';
}

