import { OptionsObject } from 'notistack';
import { DetailMessageObject } from './type';
import i18nService from '../../i18n';
import SnackMessageComponent from '../../components/snack-message.component';
import React from 'react';
import { ERROR_000 } from '../../constants/error';
import _ from 'lodash';

export class MessengerModule {

    private sk: (message: string | React.ReactNode, options?: OptionsObject) => OptionsObject['key'] | null;

    constructor(
        sk: (message: string | React.ReactNode, options?: OptionsObject) => OptionsObject['key'] | null
    ) {
        this.sk = sk;
    }

    private isDetailMessageObject(val: any): val is DetailMessageObject {
        return val && val.hasOwnProperty("message") && val.hasOwnProperty("level");
    }

    private stringifySafe(val: any): string | null {
        try {
            if (val === undefined || val === null) return null;
            else if (typeof val === "string") return val;
            else return JSON.stringify(val);
        } catch (error) {
            return null;
        }
    }

    /**
     * show snack bar with `DetailMessageObject`, if is not the correct type, the raw error will be showed
     * @param dmo 
     */
    public say(dmo: DetailMessageObject, options: { vertical?: string; horizontal?: string } = {}) {
        try {
            // init
            if (!this.isDetailMessageObject(dmo)) dmo = {
                message: ERROR_000.message,
                level: "Error",
                details: dmo || {}
            };
            // build details
            if (dmo.details) {
                let detailsArray: any[] = [];
                _.forIn(dmo.details, (value: any, key: any) => {
                    detailsArray.push(this.stringifySafe({ [key]: value }));
                });
                dmo.details = detailsArray;
            }
            // show snack
            this.sk('', {
                persist: false,
                anchorOrigin: {
                    vertical: options.vertical || 'bottom',
                    horizontal: options.horizontal || 'left',
                },
                content: (key: any) => (
                    <SnackMessageComponent
                        id={key}
                        level={dmo.level as any}
                        message={`${i18nService.t(dmo.message)}`}
                        details={dmo.details}
                    />
                ),
            } as any);
        } catch (error) {

        }
    }

}