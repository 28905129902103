import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { dish } from '../modules/cardProcessor/type';

export default class CardDialogComponent extends Component<DialogComponentProps, DialogComponentState> {

    constructor(props: DialogComponentProps) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);
        this.updateLocalState = this.updateLocalState.bind(this)

    };


    // *********************
    // Functions
    // *********************

    /**
     * update local state by object
     * @param val 
     */
    private updateLocalState(val: Partial<DialogComponentState>) {
        this.setState(val as any);
    };

    // *********************
    // View
    // *********************
    render(this: any) {
        return (
            <Dialog
                onClose={this.props.onClose}
                open={this.props.visible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="cardDialog"
            >
                <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        className="dialogButton"
                        onClick={() => this.props.onSelectedAll()}
                    >
                        Select All
                    </Button>
                    <Button
                        className="dialogButton"
                        onClick={() => this.props.onUnselectedAll()}
                    >
                        Unselect All
                    </Button>
                </DialogActions>
                <DialogContent style={{maxHeight:'50vh', marginBottom: 10}}>
                    <FormGroup>
                        {
                            this.props.dataArr.map((ele: dish & { isCardDialogSelected?: boolean }, index: any) =>
                                <div className="dialogLine" key={index} style={{ borderBottomWidth:'thin',borderBottomStyle:'dashed'}}>
                                    <div className="tableName" style={{
                                        display: 'inline-block',
                                        // border: 'thin solid #660033',
                                        margin: '0 10px 2px 0',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        // color: 'white',
                                        // backgroundColor: '#660033',
                                    }}>
                                        {ele.table_name}
                                    </div>
                                    <FormControlLabel
                                        label={ele.item_name1}
                                        control={
                                            <Checkbox
                                                checked={!!ele.isCardDialogSelected}
                                                color="default"
                                                onChange={() => this.props.onSelected(ele)}
                                            />
                                        }
                                    />
                                </div>
                            )
                        }
                    </FormGroup>
                </DialogContent>
            </Dialog>
        )
    }
}

// *********************
// States
// *********************

type DialogComponentProps = {
    visible: boolean;
    dataArr: Array<dish & { isCardDialogSelected?: boolean }>;
    onClose: Function;
    onSelected: Function;
    onSelectedAll: Function;
    onUnselectedAll: Function;
}

type DialogComponentState = {}
