import React, { Component } from 'react';
import "../css/tablesClassify.css"
import Badge from '@material-ui/core/Badge';
import { StateManager } from '../modules/state';

export default class TablesClassifyComponent extends Component<TablesClassifyComponentProps, State> {

    constructor(props: TablesClassifyComponentProps) {
        super(props);
        this.state = {
            horizontal: 'left'
        }
        // 给外部组件绑定自身
        if (this.props.self) this.props.self(this)
    };
    // 选中的索引
    private selectIndex = 0;
    public resetSelectIndex() {
        this.selectIndex = 0;
    }
    // *********************
    // View
    // *********************

    render() {

        return (
            <div
                className="root"
                style={{
                    paddingTop: 10,
                    display: StateManager.get('everyTableFoodQuantityCount') ? 'block' : 'none'
                }}>
                <div
                    className="rootdiv"
                    style={{
                        width: "90%",
                        height: 42,
                        overflow: 'hidden',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        paddingTop: 10,
                        paddingBottom: 5
                    }}>
                    {this.props.TablesList.map((element, index) =>
                        <Badge key={`chef-desk-` + index} id="Badge" color="primary" badgeContent={this.props.TablesBubble[index]} style={{marginRight: 13}}>
                            <span 
                                style={{
                                    // paddingRight: 15,
                                    // paddingLeft: index === 0 ? 0 : 15,
                                    display: "inline-block"
                                }}
                                onClick={(e) => {
                                    this.selectIndex = index;
                                    this.props.onClick.bind(this, element)();
                                }}
                            >
                                <button
                                    className={this.selectIndex === index ? 'selectedDeskButton' : 'deskButton'}
                                    style={{ height: 42, minWidth: 55, border: 'none', outline: 'none', borderRadius: 5, fontWeight: "bold" }}>
                                    {element}
                                </button>
                            </span>
                        </Badge>
                    )}
                </div>
            </div >
        );
    }
}

// *********************
// Type
// *********************

type TablesClassifyComponentProps = {
    TablesList: string[];
    TablesBubble: number[];
    onClick: Function;
    self?: any,
}
type State = {
    horizontal: any;

}
