import '../css/card.scss'
import React from 'react';
import { CardBox } from '../modules/cardProcessor/module'
import { CardInfo, dish } from '../modules/cardProcessor/type'
// import i18nService from '../i18n';
import { StateManager } from '../modules/state';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PeopleIcon from '@material-ui/icons/People';
import WeekendIcon from '@material-ui/icons/Weekend';
import { Fab, LinearProgress } from '@material-ui/core';
import { getStateDiffs } from '../utils/tools';
import _ from 'lodash';
import PersonIcon from '@material-ui/icons/Person';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { SummaryDishNames } from '../pages/common.page';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import LinkIcon from '@material-ui/icons/Link';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import CardTravelIcon from '@material-ui/icons/CardTravel';

let moment = require('moment-timezone');
let playList = require('../assets/baseline_playlist_play.png')
let ballot = require('../assets/baseline_ballot.png')
export class CardComponent extends React.Component<CardComponentProps, CardComponentState>  {

    constructor(props: CardComponentProps) {
        super(props);
        this.dbclickCard = this.dbclickCard.bind(this);
        this.clickCard = this.clickCard.bind(this);
        this.getClickHandler2 = this.getClickHandler2.bind(this);
        this.longTouch = this.longTouch.bind(this)
        this.endLongTouch = this.endLongTouch.bind(this)
        this.longTouchStart = this.longTouchStart.bind(this)
        this.quantityButton = this.quantityButton.bind(this)
        this.waiterToServeShowEndTime = this.waiterToServeShowEndTime.bind(this)
        this.waiterToServeShowTimeGones = this.waiterToServeShowTimeGones.bind(this)
        this.takeoutClassShowTime = this.takeoutClassShowTime.bind(this)
    }
    // 记录当前卡片的点击次数
    private clickCount = 0;

    // *********************
    // States
    // *********************

    public state: CardComponentState = {
        operationButtonVisibility: false,
        cardInfo: this.props.cardBox.showBoxContent(),
        select: false,
        cardContainerHeight: 0,
        cardDetailHeight: 0,
        selectDetailCard: [],
        selectData: this.props.selectData,
        isShowOrderRemarkDialog: false,
        OrderRemarkDialogData: {
            remark: '',
            kdsTime: '',
            deliveryInfo: ''
        },
        isHideCardMask: this.props.isHideCardMask,
        selectSummaryDishNames: this.props.selectSummaryDishNames,
        isHideCarFooterdMask: true
    }

    // *********************
    // Functions
    // *********************

    /**
     * update local state by object
     * @param val 
     */
    private updateLocalState(val: Partial<CardComponentState>) {
        this.setState(val as any);
    };

    // 单击方案2：也是设置一个timeout，在规定的延迟时间内再次点击会执行双击事件，不同的是每次点击都会马上执行单击事件。优点：单击不会有延迟的问题。缺点：执行双击事件前会执行两次单击事件。
    private timeoutID: any = null;
    private longTouchTimeoutID: any = null;
    private triggerClickTimeoutID: any = null;
    /**
     * onClick: 单击方法
     * onDblClick: 双击方法
     * delay: 执行双击方法的延迟时间 
     */
    getClickHandler2 = (onClick: any, onDblClick: any, onLongTouch: any, delay?: any) => {
        delay = delay || 500;
        return (event: any) => {
            event.stopPropagation();
            // event.preventDefault();
            // 每点击一次加1
            this.clickCount++;
            if (!this.timeoutID) {
                this.timeoutID = setTimeout(() => {
                    this.timeoutID = null;
                    // 一段时候没有操作后，点击次数重置为0
                    this.clickCount = 0;
                }, delay);
                onClick(event);
                
                // 这是为了防止在滚动的时候碰一下就选中了卡片
                // this.triggerClickTimeoutID = setTimeout(() => {
                //     this.triggerClickTimeoutID = null
                //     onClick(event);
                // }, 30);
            } else {
                this.timeoutID = clearTimeout(this.timeoutID);
                // debugger
                // if (this.props.section === "kitchen") { onDblClick(event); }
                // 防止连续点击的情况下，连续触发几次双击事件。只会触发次数小于3的那次双击。
                console.log('db, 222')
                if(this.clickCount < 3) onDblClick(event);
            }
            // console.log('this.longTouchTimeoutID',this.longTouchTimeoutID)
            // if (!this.longTouchTimeoutID && this.state.cardInfo.cardType !== 'normal') {
            //     this.longTouchTimeoutID = setTimeout(() => {
            //         this.longTouchTimeoutID = null
            //         onLongTouch(event);
            //     }, 700)
            // }
        };
    }
    /**
     * 判断是否需要显示菜品的信息，例如调味品，菜品备注
     */
    isShowDishesInfo = (dish: dish, isPackageDish?: boolean) => {
        return dish.is_make === 5 && !!_.get(dish, 'return_reason') ||
            !!_.get(dish, 'description') ||
            !!_.get(dish, 'condimentsDescription') ||
            !!_.get(dish, 'unit') ||
            !isPackageDish && !!_.get(dish, 'combo_meal_name') || 
            _.get(dish, 'condiments', []).length > 0
    }

    touchMove = () => {
        // 卡片里的内容滚动浏览的时候，清空长按的timeout id,不需要触发长按
        clearTimeout(this.longTouchTimeoutID);
        this.longTouchTimeoutID = null;
        // 滚动的时候不执行单击
        // clearTimeout(this.triggerClickTimeoutID);
        // this.triggerClickTimeoutID = null;
    }
    clickCard = () => {
        this.updateLocalState({
            select: !this.state.select
        })
        this.props.clickCard(this.state.cardInfo.boxData)
    }

    dbclickCard = async () => {
        await this.props.doubleClickCard(this.state.cardInfo.boxData)
    }

    /**
     * 单击套餐
     * @param dish 单击的套餐 | 套餐的菜品
     * @param isPackageDish 是否单击套餐的菜品，belongedPackage: 所属的套餐
     */
    onClickPackage = (
        event: any, 
        dish: dish,
        isPackageDish?: {
            belongedPackage: dish;
        }, 
    ) => {
        return () => {
            typeof this.props.clickQuantity === 'function' && this.props.clickQuantity(event, dish, isPackageDish);
        }
    }

    /**
     * 双击套餐
     * @param dish 套餐 | 套餐的菜品
     * @param isPackageDish 是否双击的是套餐的菜品, belongedPackage 所属的套餐
     */
    onDbClickPackage = (
        dish: dish,
        isPackageDish?: {
            belongedPackage: dish;
        }
    ) => {
        const { boxData } = this.state.cardInfo;
        let activedDish: dish[] = [];
        // 如果双击的是套餐中的某个菜品
        if (isPackageDish) {
            activedDish = boxData.reduce<dish[]>((pre, cur) => {
                if (cur.order_detail_id === isPackageDish.belongedPackage.order_detail_id) {
                    cur.course_groupArray = [dish];
                    pre.push(cur);
                }
                return pre;
            }, []);
            // 如果双击的是整个套餐
        } else {
            activedDish = boxData.filter(item => item.order_detail_id === dish.order_detail_id);
        }
        return async () => {
           await this.props.doubleClickCard(activedDish);
        }
    }

    longTouchStart = async (e: any) => {
        if (!this.longTouchTimeoutID && this.state.cardInfo.cardType !== 'normal') {
            this.longTouchTimeoutID = setTimeout(() => {
                this.longTouchTimeoutID = null
                this.longTouch(e);
            }, 700)
        }
    }

    longTouch = async (e: any) => {
        await this.props.longTouchCard();
    }

    endLongTouch = async () => {
        await this.props.longTouchEnd()
        window.clearTimeout(this.longTouchTimeoutID);
        this.longTouchTimeoutID = null
    }

    hideCardButton = () => {
        this.updateLocalState({
            operationButtonVisibility: false
        })
    }

    /**
     * 切换当前按钮的显示状态
     */
    showOrHideCardButton = () => {
        this.updateLocalState({
            operationButtonVisibility: !this.state.operationButtonVisibility
        })
    }
    /**
     * 根据传入的菜品对象，选择需要的底色
     */
    cardBackgroundColor = (
        ele: dish,
        cardType: string, 
        finishedPackageIdGroup?: number[],
        makeDishNumber?: number,
        isIncludedPackage?: boolean
    ) => {
        const { boxData } = this.state.cardInfo;
        const finishedPackageId = finishedPackageIdGroup || [];
        const madeDishNum = makeDishNumber || 0
        // 正常卡片不需要渐变的底色, 套餐的催菜背景不需要占满
        if (ele.rush > 0 && cardType !== 'normal' && ele.item_type !== 3) {
            return 'cardDetailRush'
            // return 'linear-gradient(145deg, #ff091c 20%, #3794f6 80%)';
        }
        // 开启订单模式后，并且是未制作的菜品，显示未制作的背景颜色
        if(this.props.isShowTotalOrder && ele.is_make !== 1 && ele.eat_type === 0 && ele.item_type !== 3 && !isIncludedPackage) return 'cardDetailNotMakeEatIn';
        else if (
            this.props.isShowTotalOrder && finishedPackageId.includes(ele.order_detail_id) && ele.eat_type === 0 && madeDishNum !== this.state.cardInfo.boxData.length && isIncludedPackage
        ) {
            return 'cardDetailFinishedEatIn';
        }
        else if (
            this.props.isShowTotalOrder && finishedPackageId.includes(ele.order_detail_id) &&
            [1, 2, 3].includes(ele.eat_type) && madeDishNum !== this.state.cardInfo.boxData.length && isIncludedPackage
         ) {
            return 'cardDetailFinishedTakeaway';
        }
        else if (this.props.isShowTotalOrder && isIncludedPackage && ele.is_make !== 1 && ele.eat_type === 0 && finishedPackageId.length !== boxData.length) return 'cardPackageNotMakeEatIn'
        else if (this.props.isShowTotalOrder && isIncludedPackage && ele.is_make !== 1 && [1, 2, 3].includes(ele.eat_type) && finishedPackageId.length !== boxData.length) return 'cardPackageNotMakeTakeaway'
        else if(this.props.isShowTotalOrder && ele.is_make !== 1 && ele.item_type !== 3) return 'cardDetailNotMakeTakeout'
       
        else if (ele.eat_type === 1 || ele.eat_type === 2 || ele.eat_type === 3) {
            return 'cardDetailTakeout'
            // return '#E4542C'
        }
        // else if (`${ele.is_make}` === '11'){
        //     return '#97AB2E'
        // }
        // else return 'transparent'
        // else return '#660033'
        else return 'cardDetailBase'
    }

    /**
     * 根据传入的菜品对象，选择需要的底色
     */
    cardQuantityBackgroundColor = (ele: any, cardType: string) => {
        // 正常卡片外菜需要的底色
        if (ele.rush > 0 && cardType !== 'normal') {
            // return '#660033';
            return 'otherCardQuantity';
        }
        else if (ele.eat_type === 1 || ele.eat_type === 2 || ele.eat_type === 3) {
            // return '#FFD4B7'
            return 'takeoutCardQuantity'
        }
        // else return '#81355B'
        else return 'baseCardQuantity'
    }

    /**
     * 根据传入的菜品对象，选择需要的字体颜色
     */
    cardQuantityColor = (ele: any, cardType: string) => {
        // 正常卡片外菜需要渐变的字体颜色
        if (ele.rush > 0 && cardType !== 'normal') {
            return '#fff';
        }
        else if (ele.eat_type === 1 || ele.eat_type === 2 || ele.eat_type === 3) {
            return '#FF6600'
        }
        // else return '#DBC6D1'
        else return '#fff'
    }
    /**
     * 判断当前卡片需不需要选中
     */
    getCardSelectStatus = () => {
        let allData = true;
        for (const item of this.state.cardInfo.boxData) {
            let index = this.state.selectData.findIndex(target => {
                if (item.item_type === 3) {
                    return target.order_detail_id === item.order_detail_id && (target.course_groupArray || []).length === (item.course_groupArray || []).length
                }
                return target.order_detail_id === item.order_detail_id
            })
            if (index === -1) {
                allData = false;
                break;
            }
        }
        return allData
    }
    /**
     * 获取顾客名称
     */
    getCustomerName = (dish: any) => {
        try {
            let deliveryInfo = _.get(dish, 'delivery_info') || ''
            let customerArr = deliveryInfo.split(',')
            if (customerArr.length > 0) {
                // let customerName = customerArr[0].split(' ')
                return _.get(customerArr, ['0'], '') || ''
            } else return ''
        } catch (error) {
            console.log('error', error)
            return ''
        }
    }
    /**
     * 返回数量按钮
     */
    quantityButton = (
        ele: dish,
        cardType?: string, 
        extraStyle?: CSSProperties,
        isPackageDish?: {
            belongedPackage: dish;
        }
    ) => {
        return (
            <div className="quantity-btn-wrapper">
                <Fab className={["quantity_circle", this.cardQuantityBackgroundColor(ele, this.state.cardInfo.cardType), ele.rush > 0 && this.props.isSimpleMode && cardType === 'Magnet' ? 'quantityRush' : ''].join(' ')}
                    style={{
                        // backgroundColor: this.cardQuantityBackgroundColor(ele, this.state.cardInfo.cardType), 
                        // color: this.cardQuantityColor(ele, this.state.cardInfo.cardType), 
                        fontSize: 28,
                        ...extraStyle
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        // 修改选中的状态
                        // let index = this.state.selectDetailCard.indexOf(ele.order_detail_id);
                        // let selectDetailCard = this.state.selectDetailCard;
                        // if(index !== -1) selectDetailCard.splice(index,1)
                        // else  selectDetailCard.push(ele.order_detail_id)
                        // this.setState({
                        //     selectDetailCard:selectDetailCard
                        // })

                        /**
                         * 判断点击的是套餐或者套餐的菜品还是普通的菜品
                         * 套餐 | 套餐的菜品需要判断是双击还是点击
                         */
                        if (ele.item_type === 3 || isPackageDish) {
                            this.getClickHandler2(this.onClickPackage(event, ele, isPackageDish), isPackageDish ? this.onDbClickPackage(ele, isPackageDish) : this.onDbClickPackage(ele), this.longTouch)(event);
                        } else {
                            if (typeof this.props.clickQuantity === 'function') this.props.clickQuantity(event, ele, isPackageDish);
                        }
                    }}
                >{Math.round(ele.quantity * 100) / 100}
                </Fab>
                {
                    // 普通模式或订单模式的按钮下显示菜品轮次
                    ele.turn && ele.turn !== '0' && (this.props.modeSelected === 'normal_mode' || this.props.modeSelected === 'order_mode') ?
                    <span className='roundNum'><DoubleArrowIcon style={{ verticalAlign: -2, marginRight: 3 }} fontSize="inherit" /><span>{ele.turn}</span></span>
                    : null
                }
            </div>
        )
    }

    /**
     * 显示调味品
     * @param condiment 调味品
     */
    displayCondiment(
        description: string,
        condimentsDescription: string,
        ele: dish,
        cardType: string,
        finishedPackageId?: number[],
        makeDishNumber?: number,
        belongedPackage?: dish
    ) {
        try {
            const { boxData } = this.state.cardInfo;
            let className = '';
            let condiment = `${description && description.toLowerCase() !== 'null' ? description : ''} ${condimentsDescription && condimentsDescription.toLowerCase() !== 'null' ? condimentsDescription : ''}`;
            if ((ele.rush > 0  || (belongedPackage && belongedPackage.rush || 0) > 0) && cardType !== 'normal') {
                className = belongedPackage && belongedPackage.item_type === 3 ? 'rushPackage' : 'other';
            }
            else if (ele.eat_type === 1 || ele.eat_type === 2 || ele.eat_type === 3) {
                className = belongedPackage && belongedPackage.item_type === 3 ? 'takeawayPackage' : 'takeout';
            }
            else className = belongedPackage && belongedPackage.item_type === 3 ? 'package' : 'base';
            // 总单模式下，未制作的时候加上未制作标签
            if(ele.is_make !== 1 && this.props.isShowTotalOrder) className = `${className} notmake`
            // 总单模式下，已制作的套餐位于已完成区域
            if ((finishedPackageId || []).includes(ele.order_detail_id) && this.props.isShowTotalOrder && (makeDishNumber || 0) !== boxData.length && ele.eat_type === 0) {
                className = `${className} eatInFinished`;
            }
            if ((finishedPackageId || []).includes(ele.order_detail_id) && this.props.isShowTotalOrder && (makeDishNumber || 0) !== boxData.length && [1, 2, 3].includes(ele.eat_type)) {
                className = `${className} takeawayFinished`;
            }
            if (!condiment.trim()) return null;
            return (
                <div key={'card-condiment'} className={['card-condiment', className].join(' ')}>
                    {condiment}
                </div>
            )
        } catch (error) {
            return null
        }
    }

    renderLinkHeaderBg = (dish: dish, finishedPackageIdGroup: number[], isIncludedPackage: boolean): string => {
        let packageLinkHeadBg = '';
        if (this.props.isShowTotalOrder && finishedPackageIdGroup.includes(dish.order_detail_id) && (dish.item_type === 3 || isIncludedPackage) && finishedPackageIdGroup.length !== this.state.cardInfo.boxData.length) {
            if (dish.eat_type === 0) {
                packageLinkHeadBg = 'packageLinkHeadEatInBg';
            } else {
                packageLinkHeadBg = 'packageLinkHeadTakeawayBg'
            }
        }
        return packageLinkHeadBg;
    }

    /**
     * 显示整单备注和就餐时间，用户名称
     */
    orderRemark = (ele: any, index: number) => {
        let check_name = _.get(ele, ['check_name']) || ''
        let orderRemark = _.get(ele, ['remark']) || ''
        // 整单备注
        let remark = `${check_name && check_name.toLowerCase() !== 'null' ? check_name : ''}${orderRemark && orderRemark.toLowerCase() !== 'null' ? orderRemark : ''}`;
        // 就餐时间
        let kdsTime = ''
        // 外卖信息
        let deliveryInfo = ''
        // 为订单模式服务，只有第一个菜品显示就餐时间和用户信息，其他的菜品不显示
        // if (this.state.cardInfo.cardType === 'Magnet') {
        //     if (index === 0) {
        //         if (StateManager.get('orderInfoShowCustomerName')) deliveryInfo = this.getCustomerName(ele)
        //         kdsTime = `${ele.kds_time ? moment(ele.kds_time).format("kk:mm") : ''}`
        //     } else {
        //         kdsTime = ''
        //         deliveryInfo = ''
        //     }
        // } else {
        //     kdsTime = `${ele.kds_time ? moment(ele.kds_time).format("kk:mm") : ''}`
        //     if (StateManager.get('orderInfoShowCustomerName')) deliveryInfo = this.getCustomerName(ele)
        // }
        // 3个备注都没有，则可以不渲染
        // if (!remark && !kdsTime && !deliveryInfo) return null
        if (!remark.trim()) return null

        return (
            <div className="order-remark"
            // onClick={(e) => {
            //     e.stopPropagation();
            //     this.setState({
            //         isShowOrderRemarkDialog: true,
            //         OrderRemarkDialogData: {
            //             remark,
            //             kdsTime,
            //             deliveryInfo
            //         }
            //     })
            // }}
            >
                <div
                    style={{
                        position: 'relative', 
                        padding: '16px 15px',
                        opacity: this.state.isHideCardMask ? 1 : 0.5 // 遮罩层出现的时候，透明度调低
                    }}>
                    {/* <div style={{ fontSize: StateManager.get('cardRemarksFontSize') + 'px', color: '#fff', display: !!kdsTime ? 'flex' : 'none', alignItems: 'center' }}>
                        <AccessTimeIcon style={{ display: !!kdsTime ? 'block' : 'none', fontSize: 24, marginRight: 8, marginBottom: 16 }}></AccessTimeIcon>
                        <div style={{ display: !!kdsTime ? 'block' : 'none', marginBottom: 16 }}>
                            {ele.kds_time && ele.kds_time !== 'null' && ele.kds_time !== undefined ? <div className="kdsTime">{kdsTime}</div> : ''}
                        </div>
                    </div>
                    <div style={{ fontSize: StateManager.get('cardRemarksFontSize') + 'px', color: '#fff', display: !!deliveryInfo ? 'flex' : 'none', alignItems: 'center' }}>
                        <PersonIcon style={{ display: !!deliveryInfo ? 'block' : 'none', fontSize: 24, marginRight: 8, marginBottom: 16 }}></PersonIcon>
                        <div style={{ display: !!deliveryInfo ? 'block' : 'none', marginBottom: 16 }}>
                            {ele.delivery_info && ele.delivery_info !== 'null' && ele.delivery_info !== undefined ? <div className="deliveryInfo">{deliveryInfo}</div> : ''}
                        </div>
                    </div> */}
                    <div style={{ display: !!remark ? 'flex' : 'none', flex: 1, justifyContent: 'flex-start', fontSize: StateManager.get('cardRemarksFontSize') + 'px', color: '#fff', alignItems: 'center', opacity: this.state.isHideCardMask ? 1 : 0.5 }}>
                        <img src={ballot} style={{ display: 'inline-block', height: 24, width: 24, marginRight: 8 }} alt="" />
                        <div className="order-remark-text">{remark}</div>
                    </div>
                </div>
            </div>
        )
    }
    /**
     * 查找菜品在菜品汇总中的位置
     * @param dish 菜品
     */
    findDishNameInSummaryDishNames(dish: any) {
        let index: number
        let dishesSummarySidebar = StateManager.get('dishesSummarySidebar');
        const { selectSummaryDishNames } = this.state
        if (dishesSummarySidebar === '0') {
            // 菜品名称1+菜品名称2
            index = selectSummaryDishNames.findIndex((dishName: any) => dish.item_name1 === dishName.item_name1 && dish.item_name2 === dishName.item_name2)
        } else if (dishesSummarySidebar === '1') {
            // 菜品名称1
            index = selectSummaryDishNames.findIndex((dishName: any) => dish.item_name1 === dishName.item_name1)
        } else {
            // 菜品名称2
            index = selectSummaryDishNames.findIndex((dishName: any) => dish.item_name2 === dishName.item_name2)
        }
        return index;
    }
    /**
     * 根据选中的菜品汇总栏数据，判断同名菜品卡片是否需要隐藏遮罩层
     * @param dish 菜品
     */
    getAllDishNamesHidesTheMaskStatus(dish: any) {
        // 用来判断是否全部菜品都不存在已选择的菜品汇总中，默认都不存在
        let allDataNotExist = true;
        const { selectSummaryDishNames } = this.state
        // 没有选择数据，则直接隐藏遮罩
        if (selectSummaryDishNames.length === 0) {
            this.setState({
                isHideCarFooterdMask: true
            })
            return true;
        }

        for (const item of this.state.cardInfo.boxData) {
            let index = this.findDishNameInSummaryDishNames(item)
            if (index > -1) {
                allDataNotExist = false;
                break;
            }
        }
        if (allDataNotExist) {
            // 所有的菜品都不存在，直接直接开启遮罩层,卡片底部可以关闭遮罩层
            this.setState({
                isHideCardMask: false,
                isHideCarFooterdMask: true
            })
            return true;
        } else {
            // 有部分菜品存在，大的遮罩层直接关闭，菜品的遮罩层按需要显示，卡片底部遮罩层需要开启
            this.setState({
                isHideCardMask: true,
                isHideCarFooterdMask: false
            })
            let index = this.findDishNameInSummaryDishNames(dish)
            return index > -1
        }
    }
    /**
     * 检查当前菜品上下有没有选择菜品，从而选择要不要显示上下边框
     * @param dish 
     */
    checkSelectBorderTopAndBottom(dish: dish) {
        const {selectData, cardInfo } = this.state;
        // 当前菜品在卡片数据内的索引
        let index = cardInfo.boxData.findIndex(boxDish => boxDish.order_detail_id === dish.order_detail_id);
        // 当前菜品的上一个菜品
        let topDish = _.get(cardInfo.boxData, [index - 1]);
        // 当前菜品的下一个菜品
        let bottomDish = _.get(cardInfo.boxData, [index + 1])
        // 边框样式
        let borderStyle: CSSProperties = {}
        // 如果当前菜品存在上一个菜品, 检查是否勾选了上个菜品, 是就隐藏上边框
        if (topDish) {
            let topIndex = -1;
            // 当前菜品是套餐，判断上个菜品是普通菜还是套餐
            if (dish.item_type === 3) {
                if (topDish.item_type === 3) {
                    topIndex = selectData.findIndex(item => {
                        return item.order_detail_id === topDish.order_detail_id && (item.course_groupArray || []).length === (topDish.course_groupArray || []).length
                    });
                } else {
                    topIndex = selectData.findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id);
                }
            } 
            // 当前菜品是普通菜,判断上个菜品是普通菜还是套餐
            else {
                if (topDish.item_type === 3) {
                    topIndex = selectData.findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id && (selectDish.course_groupArray || []).length === (topDish.course_groupArray || []).length)
                } else {
                    topIndex = selectData.findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id);
                }
            }
            if (topIndex > -1) {
                borderStyle.borderTopWidth = 0;
                borderStyle.borderTopLeftRadius = 0;
                borderStyle.borderTopRightRadius = 0;
            }
            else {
                borderStyle.borderTopWidth = 6
                borderStyle.borderTopLeftRadius = 5;
                borderStyle.borderTopRightRadius = 5;
            }
        }
        // 如果当前菜品存在下个菜品, 判断是否勾选了下个菜品, 是就隐藏当前菜品的下边框
        if (bottomDish) {
            let bottomIndex = -1;
            if (dish.item_type === 3) {
                bottomIndex = selectData.findIndex(selectDish => selectDish.order_detail_id === bottomDish.order_detail_id && (selectDish.course_groupArray || []).length === (bottomDish.course_groupArray || []).length)
            } else {
                // 下个菜品是否是套餐
                if (bottomDish.item_type === 3) {
                    bottomIndex = selectData.findIndex(item => item.order_detail_id === bottomDish.order_detail_id && (bottomDish.course_groupArray || []).length === (item.course_groupArray || []).length);
                } else {
                    bottomIndex = selectData.findIndex(selectDish => selectDish.order_detail_id === bottomDish.order_detail_id);
                }
            }
            if (bottomIndex > -1) {
                borderStyle.borderBottomWidth = 0;
                borderStyle.borderBottomLeftRadius = 0;
                borderStyle.borderBottomRightRadius = 0;
            }
            else {
                borderStyle.borderBottomWidth = 6;
                borderStyle.borderBottomLeftRadius = 5;
                borderStyle.borderBottomRightRadius = 5;
            }
        }
        return borderStyle
    }

    /**
     * 检查当前菜品下有没有相同磁力链菜品，从而选择要不要显示下边框
     * @param dish 
     */
    checkMagneticLinkBorderBottom(dish: dish) {
        let index = this.state.cardInfo.boxData.findIndex(boxDish => boxDish.order_detail_id === dish.order_detail_id);
        // 当前菜品的下一个菜品
        let bottomDish = _.get(this.state.cardInfo.boxData, [(index + 1) === this.state.cardInfo.boxData.length ? index : index + 1])
        // 边框样式
        let borderStyle: CSSProperties = {}
        // 检查要不要隐藏下边框
        if (bottomDish) {
            // 最后一个菜品或者下一个菜品不是磁力链，需要下边距
            if((index + 1) === this.state.cardInfo.boxData.length || !bottomDish.magnetic_link || (bottomDish.is_make !== 1 && bottomDish.is_make !==2)) {
                borderStyle.marginBottom = dish.item_type === 3 ? 5 : 10;
            }
            // 一样的磁力链码，并且不是最后一个菜品，则不需要显示下划线
            if (bottomDish.magnetic_link === dish.magnetic_link && (index + 1) !== this.state.cardInfo.boxData.length &&(bottomDish.is_make === 1 || bottomDish.is_make === 2)) {
                borderStyle.borderBottomWidth = 0;
                borderStyle.borderBottomLeftRadius = 0;
                borderStyle.borderBottomRightRadius = 0;
            }
            else {
                let nextSelectIndex = this.state.selectData.findIndex(item => item.order_detail_id === bottomDish.order_detail_id)
                // let nextSelectDish = this.state.selectData.find(item => item.order_detail_id === bottomDish.order_detail_id)
                let currentSelectIndex = this.state.selectData.findIndex(item => item.order_detail_id === dish.order_detail_id)
                // let currentSelectedDish = this.state.selectData.reduce((pre, cur, index) => {
                //     cur.order_detail_id === dish.order_detail_id && (pre = {...cur, currentDishIndex: index})
                //     return pre;
                // }, {} as dish);
                // 下个菜品不是同一个磁力链，并且当前菜品和下个菜品都选中了，则不需要左右边框。或者没有选中整个卡片
                if(nextSelectIndex !== -1 && currentSelectIndex !== -1 && !this.getCardSelectStatus()) {
                    // if (
                    //     (dish.item_type === 3 && (dish.course_groupArray || []).length === (currentSelectedDish.course_groupArray || []).length)
                    // ) {
                        borderStyle.borderLeftWidth = 0;
                        borderStyle.borderRightWidth = 0;
                    // }
                }
                borderStyle.borderBottomWidth = 2;
                borderStyle.borderBottomLeftRadius = 10;
                borderStyle.borderBottomRightRadius = 10;
            }
        }
        return borderStyle
    }

    /**
     * 检查套餐当前菜品上下有没有选择菜品，从而选择要不要显示上下边框
     * @param dish 套餐内菜品
     * @param packageDishes 套餐所有菜品,
     * @param belongedPackage 所属的套餐
     */
    checkPackageSelectBorderTopAndBottom(dish: dish, packageDishes: dish[], belongedPackage: dish) {
        let index = packageDishes.findIndex(boxDish => boxDish.order_detail_id === dish.order_detail_id);
        // 当前菜品的上一个菜品
        let topDish = _.get(packageDishes, [index - 1])
        // 当前菜品的下一个菜品
        let bottomDish = _.get(packageDishes, [index + 1])
        // 边框样式
        let borderStyle: CSSProperties = {}
        // 查找所属的套餐
        const selectedPackage = this.state.selectData.find(item => item.order_detail_id === belongedPackage.order_detail_id);
        // 检查要不要隐藏上边框
        if (topDish) {
            let topIndex = -1;
            if (selectedPackage) {
                topIndex = (selectedPackage.course_groupArray || []).findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id);
            }
            if (topIndex > -1) {
                borderStyle.borderTopWidth = 0;
                borderStyle.borderTopLeftRadius = 0;
                borderStyle.borderTopRightRadius = 0;
            }
            else {
                borderStyle.borderTopWidth = 6
                borderStyle.borderTopLeftRadius = 5;
                borderStyle.borderTopRightRadius = 5;
            }
        }
        // 检查要不要隐藏下边框
        if (bottomDish) {
            let bottomIndex = -1;
            if (selectedPackage) {
                bottomIndex = (selectedPackage.course_groupArray || []).findIndex(selectDish => selectDish.order_detail_id === bottomDish.order_detail_id);
            }
            if (bottomIndex > -1) {
                borderStyle.borderBottomWidth = 0;
                borderStyle.borderBottomLeftRadius = 0;
                borderStyle.borderBottomRightRadius = 0;
            }
            else {
                borderStyle.borderBottomWidth = 6;
                borderStyle.borderBottomLeftRadius = 5;
                borderStyle.borderBottomRightRadius = 5;
            }
        }
        return borderStyle
    }

    componentWillUnmount = () => {
        window.clearTimeout(this.longTouchTimeoutID)
        window.clearTimeout(this.timeoutID)
        if (this.props.cardUnmount) this.props.cardUnmount();
    }
    componentWillReceiveProps = (nextProps: CardComponentProps) => {
        this.setState({
            selectData: nextProps.selectData,
            cardInfo: nextProps.cardBox.showBoxContent(),
            isHideCardMask: nextProps.selectSummaryDishNames.length > 0 ? this.state.isHideCardMask : nextProps.isHideCardMask,
            selectSummaryDishNames: nextProps.selectSummaryDishNames
        })
    }
    componentDidMount() {
        try {
            this.updateLocalState({
                cardDetailHeight: (this.refs.cardDetail as any).clientHeight,
                cardContainerHeight: (this.refs.cardContainer as any).clientHeight,
            });
        } catch (error) {
        }
    }

    shouldComponentUpdate(nextProps: CardComponentProps, nextState: CardComponentState) {
        try {
            // get diffs report
            let report = getStateDiffs(this.state, nextState);
            // $ do not refresh
            if (
                (report.flag === true && report.diffs.length === 0 && this.props.cardWidth === nextProps.cardWidth)
            ) {
                // console.log(report, "[X]");
                return false;
            }
            // $ do refresh
            else {
                // console.log(report);
                return true;
            }
        } catch (error) {
        }
        return true;
    }

    /**
     * 套餐背景色
     * @param dish 菜品源数据
     * @param cardType 卡片类型
     * @param finishedPackageIdGroup 已完成的套餐ID集合
     * @param makeDishNumber 已完成的菜品总数量
     */
    renderPackageBgColor = (
        dish: dish, 
        cardType: string, 
        finishedPackageIdGroup: number[],
        makeDishNumber: number
    ): string => {
        const { boxData } = this.state.cardInfo;
        let packageBgCssName = '';
        // 参与合并的套餐催菜
        if (dish.rush > 0 && cardType !== 'normal') {
            packageBgCssName = 'rushedBg';
        } 
        // 总单模式下存在已制作外卖套餐并且完成的数量不等于总菜品数量
        else if (this.props.isShowTotalOrder && [1, 2, 3].includes(dish.eat_type) && finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageBgCssName = 'packageTakeawayFinishedBg';
        } 
        else if (this.props.isShowTotalOrder && dish.eat_type === 0 && finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageBgCssName = 'packageEatInFinishedBg';
        }
        // 总单模式下外卖未制作套餐
        else if (this.props.isShowTotalOrder && dish.is_make !== 1 && [1, 2, 3].includes(dish.eat_type) && !finishedPackageIdGroup.includes(dish.order_detail_id)) {
            packageBgCssName =  'packageTakeawayNotMakeBg';
        }
        // 总单模式下堂吃未制作的套餐
        else if (this.props.isShowTotalOrder && dish.is_make !== 1 && dish.eat_type === 0 && !finishedPackageIdGroup.includes(dish.order_detail_id)) {
            packageBgCssName = 'packageEatInNotMakeBg';
        } 
        // 不是总单模式的外卖套餐 或者总单模式下包含套餐并且已制作数量和总数相等
        else if ((!this.props.isShowTotalOrder || (finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber === boxData.length)) && [1, 2, 3].includes(dish.eat_type)) {
            packageBgCssName = 'takeawayPackageBg';
        } 
        // 不是总单模式啊的堂吃
        else {
            packageBgCssName = 'packageBg';
        }
        return packageBgCssName;
    }

    /**
     * 套餐内菜品背景色
     * 
     * @param dish 套餐内的菜品
     * @param belongedPackage 该菜品所属的套餐
     * @param cardType 卡片类型
     * @param finishedPackageIdGroup 已完成的套餐ID集合
     * @param makeDishNumber 已完成的菜品总数量
     */
    renderPackageDishesBgColor = (
        dish: dish,
        belongedPackage: dish,
        cardType: string, 
        finishedPackageIdGroup: number[], 
        makeDishNumber: number
    ): string => {
        const { boxData } = this.state.cardInfo;
        let packageDishesBgCssName = '';
        if ((dish.rush > 0 || belongedPackage.rush > 0) && cardType !== 'normal') {
            packageDishesBgCssName = 'packageDishesRushedBg';
        } 
        // 总单模式下已完成的堂吃套餐且位于已完成区域
        else if (this.props.isShowTotalOrder && dish.eat_type === 0 && (finishedPackageIdGroup.includes(belongedPackage.order_detail_id) || dish.is_make === 1) && dish.is_make === 1 && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageDishesBgCssName = 'packageFinishedEatInDishesBg';
        }
        // 总单模式下已完成的外卖菜品且位于已完成区域
        else if (this.props.isShowTotalOrder && [1, 2, 3].includes(belongedPackage.eat_type) && (finishedPackageIdGroup.includes(belongedPackage.order_detail_id) || dish.is_make === 1) && dish.is_make === 1 && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageDishesBgCssName = 'packageFinishedTakeawayDishesBg';
        }
        else if ((!this.props.isShowTotalOrder || (finishedPackageIdGroup.includes(belongedPackage.order_detail_id) && makeDishNumber === boxData.length && dish.is_make === 1)) && [1, 2, 3].includes(belongedPackage.eat_type)) {
            packageDishesBgCssName = 'takeawayPackageDishesBg';
        }
        // 总单模式未制作的外卖套餐
        else if (this.props.isShowTotalOrder && (belongedPackage.is_make !== 1 || [0, 11].includes(dish.is_make)) && [1, 2, 3].includes(belongedPackage.eat_type)) {
            packageDishesBgCssName = 'packageTakeawayDishesNotMakeBg';
        } 
        // 总单模式未制作堂吃套餐
        else if (this.props.isShowTotalOrder && (belongedPackage.is_make !== 1 || [0, 11].includes(dish.is_make)) && belongedPackage.eat_type === 0) {
            packageDishesBgCssName = 'packageEatInDishesNotMakeBg';
        } 
        // 非总单模式堂吃套餐
        else {
            packageDishesBgCssName = 'packageDishesBg';
        }
        return packageDishesBgCssName;
    }
    // 套餐头底部边框背景色
    renderPackageHeadBorderBottomColor = (
        dish: dish,
        finishedPackageIdGroup: number[], 
        makeDishNumber: number,
        cardType: string
    ): string => {
        const { boxData } = this.state.cardInfo;
        let packageHeadBorderBottomCssName = '';
        if (dish.rush > 0 && cardType !== 'normal') {
            packageHeadBorderBottomCssName = 'packageRushBorderBottom';
            return packageHeadBorderBottomCssName; 
        }
        if (this.props.isShowTotalOrder && dish.is_make !== 1 && dish.eat_type === 0 && !dish.rush) {
            packageHeadBorderBottomCssName = 'packageEatInNotMakeBorderBottom';
        }
        else if (this.props.isShowTotalOrder && dish.eat_type === 0 && finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageHeadBorderBottomCssName = 'packageEatInFinishedBorderBottom';
        }
        else if (this.props.isShowTotalOrder && [1, 2, 3].includes(dish.eat_type) && finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber !== this.state.cardInfo.boxData.length) {
            packageHeadBorderBottomCssName = 'packageTakeawayFinishedBorderBottom';
        }
        else if (this.props.isShowTotalOrder && dish.is_make !== 1 && [1, 2, 3].includes(dish.eat_type)) {
            packageHeadBorderBottomCssName = 'packageTakeawayNotMakeBorderBottom';
        }
        else if ((!this.props.isShowTotalOrder || (finishedPackageIdGroup.includes(dish.order_detail_id) && makeDishNumber === boxData.length)) && [1, 2, 3].includes(dish.eat_type)) {
            packageHeadBorderBottomCssName = 'packageTakeAwayBorderBottom';
        } 
        // else if (dish.rush > 0 && cardType !== 'normal') {
        //     packageHeadBorderBottomCssName = 'packageRushBorderBottom';
        // }
        else {
            packageHeadBorderBottomCssName = 'packageEatInBorderBottom';
        }
        
        return packageHeadBorderBottomCssName;
    }

    /**
     * 渲染套餐的菜品
     * @param belongedPackage 所属的套餐
     * @param finishedPackageIdGroup 已制作的菜品id集合
     * @param makeDishNumber 已制作的菜品总数量
     * @param isHideMask 是否隐藏卡片遮罩层
     * @param isShowRemarkAndCondiments 是否显示调味品
     */
    renderPackageDishes = (
        belongedPackage: dish,
        finishedPackageIdGroup: number[],
        makeDishNumber: number,
        isHideMask: boolean,
        isShowRemarkAndCondiments: boolean
    ): JSX.Element => {
        return ( 
            <div style={{
                ...belongedPackage.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? {
                    background: 'linear-gradient(#A95578 12%, #7573B4 90%)',
                } : {},
                borderBottomLeftRadius: 9,
                borderBottomRightRadius: 9 
            }}>
            {
                (belongedPackage.course_groupArray || []).map((packageDish, index, originalData: dish[]) => {
                    let borderStyle = this.checkPackageSelectBorderTopAndBottom(packageDish, originalData, belongedPackage);
                    let selectedPackage = this.state.selectData.find(item => item.order_detail_id === belongedPackage.order_detail_id);
                    let selectedIndex = -1;
                    if (selectedPackage) {
                        selectedIndex = (selectedPackage.course_groupArray || []).findIndex((item, index, arr) => item.order_detail_id === packageDish.order_detail_id && (belongedPackage.course_groupArray || []).length !== arr.length);
                    }
                    const { length } = originalData;
                    return (
                        <div 
                            key={packageDish.key}
                            className={
                                this.renderPackageDishesBgColor(
                                    packageDish,
                                    belongedPackage, 
                                    this.state.cardInfo.cardType,
                                    finishedPackageIdGroup,
                                    makeDishNumber
                                )
                            }
                            style={{ 
                                borderBottomLeftRadius: index === length - 1 ? 8 : 0,
                                borderBottomRightRadius: index === length - 1  ? 8 : 0,
                                borderBottom: index === length - 1 ? 'none' : '',
                                // display: 'grid',
                                minHeight: 80,
                                position: 'relative',
                                background: belongedPackage.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? 'none' : '',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                        {/* 选择菜品的边框 */}
                        <div 
                            className={['cardDetailBorder', selectedIndex !== -1 && !this.getCardSelectStatus() ? 'selectCardDetailBorder' : ''].join(' ')} 
                            style={{
                                position: "absolute", 
                                top: index === 0 ? 0 : -3,
                                left: 0, right: 0, 
                                bottom: 0, 
                                borderRadius: 5,
                                borderWidth: 6, 
                                borderStyle: 'solid', 
                                zIndex: 0, 
                                inset: -2,
                                ...borderStyle 
                            }} 
                        />

                        {/* 套餐菜名 */}
                            <div 
                                className='Row' 
                                style={{ 
                                    display: 'flex',
                                    fontSize: StateManager.get('name1FontSize') + 'px', 
                                    padding: '5px 13px 5px 7px',
                                    opacity: isHideMask ? 1 : 0.5,
                                    alignItems: 'center',
                                    height: '100%',
                                    flex: 1
                                }} 
                            >
                                <span style={{ width: 16 , height: 17 }}>-</span>
                                <div 
                                    style={{opacity: isHideMask ? 1 : 0.5, flex: 2 }}
                                >
                                    {`${packageDish.menu_item_name}`}
                                </div>
                                <div className='Quantity' style={{ flex: 1 }}>
                                    {
                                        this.quantityButton(
                                            packageDish, 
                                            undefined, 
                                            { width: 36, height: 36, borderRadius: 18, marginRight: 0, fontSize: 20 },
                                            {
                                                belongedPackage
                                            }
                                        )
                                    }
                                </div>
                            </div>
                            {/* 备注、调味品 */}
                            {
                                isShowRemarkAndCondiments ? 
                                <div 
                                    style={{ 
                                        padding: '5px 13px 5px 7px', 
                                        fontSize: StateManager.get('cardRemarksFontSize') + 'px', 
                                        color: 'inherit', 
                                        display: this.isShowDishesInfo(packageDish, true) ? 'block' : 'none',
                                        opacity: isHideMask ? 1 : 0.5,
                                        wordBreak: 'break-all'
                                    }}
                                >
                                    <div style={{opacity: isHideMask ? 1 : 0.5}}>
                                        {
                                            packageDish.is_make === 5 && packageDish.return_reason && packageDish.return_reason !== 'null' && packageDish.return_reason !== undefined ? 
                                            <div className="description">{`${belongedPackage.return_reason}`}</div> 
                                            : 
                                            ''
                                        }
                                        <div 
                                            style={{ 
                                                display: !packageDish.description && !packageDish.condiments.length ? 'none' : 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'start'
                                            }}
                                        >
                                            {
                                                this.displayCondiment(
                                                    packageDish.description,
                                                    (packageDish.condiments || []).reduce((pre, cur) => `${pre}${cur.item_name1}`, ''), 
                                                    packageDish, 
                                                    this.state.cardInfo.cardType,
                                                    finishedPackageIdGroup,
                                                    makeDishNumber,
                                                    belongedPackage
                                                )
                                            }
                                        </div>
                                        {
                                            packageDish.unit && packageDish.unit !== 'null' && packageDish.unit !== undefined ?
                                            <div className="unit">{`${packageDish.unit}`}</div>
                                            :
                                            ''
                                        }
                                    </div>
                                </div> : null
                            }
                        </div>        
                    )
                })
            }
            </div>
        );
    }

    /**
     * 如果是套餐,判断套餐的菜品是否有催过菜
     */
    isRushed = (): boolean => {
        return this.state.cardInfo.boxData.reduce<dish[]>((pre, cur) => (
            [
                ...pre,
                ...cur.item_type === 3 ? (cur.course_groupArray || []) : []
            ]
        ), []).some(item => item.rush > 0);
    }

    /**
     * 外卖订单卡片头部时间显示
     * 1. 如果是已下单页面,非等取类型,直接显示最顶部菜品的kds_time
     * 2. 如果是已下单页面,等取类型,直接显示最顶部菜品的order_time
     * 3. 其他情况非等取类型显示cardInfo.kds_time, 等取类型显示cardInfo.order_time
     * @param dishes
     * @param waitForOrder 是否为等取类型
     * @returns 
     */
    takeoutClassShowTime(dishes: dish[], waitForOrder: boolean) {
        if(!dishes) {
            return
        }
        let showTime = '0:00';
        if((this.props.role === 'waiter' && this.props.section === 'chefkitchen') || (this.props.role === 'chef' && this.props.section === 'kitchen')) {
            let show_time = waitForOrder ? this.state.cardInfo.boxData[0].order_time : this.state.cardInfo.boxData[0].kds_time
            let showTimeHours = (new Date(show_time)).getHours();
            let showTimeMinutes = (new Date(show_time)).getMinutes();
            showTime = `${showTimeHours < 10 ? '0' + showTimeHours : showTimeHours}:${showTimeMinutes < 10 ? '0' + showTimeMinutes : showTimeMinutes}`;
        } else {
            showTime = waitForOrder ? this.state.cardInfo.orderTime : this.state.cardInfo.kdsTime
        }

        return showTime
    }

    /**
     * 堂吃订单卡片头部时间显示
     * 1. 如果是服务员待上菜页面，设置了最后状态更改时间排序且开启了总单模式，直接显示最顶部菜品的end_time
     * 2. 如果是服务员待上菜页面，设置了最后状态更改时间排序，直接显示cardInfo.endTime
     * 3. 如果是已下单页面，直接显示最顶部菜品的时间order_time
     * 4. 其他情况显示cardInfo.orderTime
     * @param dishes
     * @returns 
     */
     waiterToServeShowEndTime(dishes: dish[]) {
        if(!dishes) {
            return
        }
        let showTime = '0:00';
        if(StateManager.get('totalOrderMode') && this.props.role === 'waiter' && (this.props.section === 'history' || (this.props.section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort'))) {
            let end_time = this.state.cardInfo.boxData[0].end_time || null;
            let endTimeHours = (new Date(end_time)).getHours();
            let endTimeMinutes = (new Date(end_time)).getMinutes();
            showTime = `${endTimeHours < 10 ? '0' + endTimeHours : endTimeHours}:${endTimeMinutes < 10 ? '0' + endTimeMinutes : endTimeMinutes}`;
        } else if ( this.props.role === 'waiter' && (this.props.section === 'history' || (this.props.section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort'))) {
            showTime = this.state.cardInfo.endTime;
        } else if((this.props.role === 'waiter' && this.props.section === 'chefkitchen') || (this.props.role === 'chef' && this.props.section === 'kitchen')) {
            let show_time = this.state.cardInfo.boxData[0].order_time
            let showTimeHours = (new Date(show_time)).getHours();
            let showTimeMinutes = (new Date(show_time)).getMinutes();
            showTime = `${showTimeHours < 10 ? '0' + showTimeHours : showTimeHours}:${showTimeMinutes < 10 ? '0' + showTimeMinutes : showTimeMinutes}`;
        } else {
            showTime = this.state.cardInfo.orderTime
        }
        return showTime
     }

     /**
      * 卡片头部过去时间timeGones显示
      * 1. 如果是服务员待上菜页面，设置了最后状态更改时间排序且开启了总单模式，根据最顶部菜品的end_time计算timeGones
      * 2. 其他情况直接显示cardInfo.timesGone
      * @returns 
      */
     waiterToServeShowTimeGones() {
         let timeGonesShow = `0min`;
         if(StateManager.get('totalOrderMode') && this.props.role === 'waiter' && (this.props.section === 'history' || (this.props.section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort'))) {
            let time = new Date(this.state.cardInfo.boxData[0].end_time).getTime();
            let timesGone: number | string = parseInt(((new Date().getTime() - time) / 1000 / 60).toFixed(0))
            if (timesGone > 99) timesGone = `99+min`;
            else timesGone = `${timesGone}min`;
            timeGonesShow = timesGone
         } else {
            timeGonesShow = this.state.cardInfo.timesGone
         }
         return timeGonesShow;
     }

    // *********************
    // View
    // *********************

    render() {
        console.log(this.state.cardInfo,'box')
        let height = '';
        if (this.props.modeSelected === 'order_mode' || this.props.modeSelected === 'takeaway_mode') {
            height = StateManager.get('orderModeCardMaxHeight') + 'px';
        } else {
            height = this.state.cardInfo.cardType === 'normal' ? StateManager.get('cardMaxHeight') + 'px' : StateManager.get('smartMergeCardMaxHeight') + 'px'
        }
        let rushClass = this.state.cardInfo.rush > 0 || this.isRushed() ? 'rush' : '';
        let takeoutClass = '';
        // let prepareDishClass = ''
        // 需判断第一笔数据是否外卖
        if (this.state.cardInfo.boxData.length > 0 && (this.state.cardInfo.boxData[0].eat_type === 1 || this.state.cardInfo.boxData[0].eat_type === 2 || this.state.cardInfo.boxData[0].eat_type === 3)) takeoutClass = 'takeout' //外卖
        let notShowOrderIdClass = ''
        // 等取类型订单
        let waitForOrder = this.state.cardInfo.boxData[0].eat_type === 1 ? true : false;
        let dishTurn = _.get(this.state.cardInfo, ['boxData', '0', 'turn']);
        let firstDish = _.get(this.state.cardInfo, ['boxData', '0']);
        // 不需要显示订单号
        if (!StateManager.get('displayOrderId')) notShowOrderIdClass = "notShowOrderId"
        // 显示总单模式的时候，计算进度条中的已完成数量
        let makeDishNumber = 0;
        let isIncludedPackage: boolean = false;
        // 已完成菜品ID集合
        let finishedPackageIdGroup: number[] = [];
        if (this.props.isShowTotalOrder) {
            (_.get(this.state,'cardInfo.boxData') || []).forEach((target:dish) =>{
                if (target.is_make === 1) {
                    makeDishNumber++;
                    finishedPackageIdGroup.push(target.order_detail_id);
                }
                if (target.item_type === 3) {
                    isIncludedPackage = true
                }
            });
        }
        return (
            <div
                ref="card"
                className={[
                    this.props.modeSelected === 'normal_mode' ? this.state.cardInfo.cardType : 'Merge', takeoutClass, this.getCardSelectStatus() 
                    ? 'cardSelect' : ''
                ].join(' ')}
                style={{
                    maxHeight: height,
                    height: StateManager.get('cardAutoHeight') ? 'auto' : height,
                    width: this.props.cardWidth ? this.props.cardWidth + 'px' : StateManager.get('cardWidth') + 'px',
                    borderWidth: 6,
                    borderStyle: 'solid',
                    // border: this.getCardSelectStatus() ? '6px solid #FF0066' : '6px solid transparent',
                    borderRadius: '19px',
                    position: 'relative',
                    alignSelf: 'flex-start'
                }}
                // onTouchStart={this.getClickHandler2(this.clickCard, this.dbclickCard, this.longTouch)}
                onTouchStart={this.longTouchStart}
                onMouseDown={this.longTouchStart}
                onTouchMove={this.touchMove}
                onClick={(e) => {
                    e.stopPropagation();
                    this.getClickHandler2(this.clickCard, this.dbclickCard, this.longTouch)(e)
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                }}
                onMouseUp={this.endLongTouch}
                onMouseMove={(e) => { }}
                onTouchEnd={this.endLongTouch}>
                {/* 卡片蒙版 */}
                {
                    this.state.isHideCardMask ?
                    null :
                    <div style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0,0,0,0.5)',
                        borderRadius: 12,
                        borderBottomLeftRadius: 10, 
                        borderBottomRightRadius: 10 
                    }}
                    ></div>
                }
                {/* 催菜提示 */}
                <div className="rushTips" style={{
                    display: rushClass ? 'flex' : 'none',
                    borderRadius: '50%',
                    position: 'absolute',
                    right: '-17px',
                    top: '-17px',
                    width: '35px',
                    height: '35px',
                    // backgroundColor: '#D1016C',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // color: '#fff'
                }}>
                    <NotificationsIcon />
                </div>

                {/* 卡片头部 */}
                <div
                    onClick={(e) => {
                        // e.stopPropagation() 
                    }}
                >
                    <div 
                        className={['Header', notShowOrderIdClass].join(' ')}
                        style={{
                            borderTopLeftRadius: "12px",
                            borderTopRightRadius: "12px",
                            fontSize: StateManager.get('cardheaderInformationBarFontASize') + 'px'
                        }}
                    >
                        <div 
                            className={['Numbers', takeoutClass].join(' ')} 
                            style={
                                (this.props.modeSelected === 'order_mode' || this.props.modeSelected === 'takeaway_mode') && takeoutClass === 'takeout' ?
                                { display: 'flex', visibility: 'visible' } 
                                : {}
                            }
                        >
                            <div className='Nums' style={{ display: takeoutClass !== 'takeout' ? 'block' : 'none' }}>
                                <span className='DeskNum'>
                                    <WeekendIcon style={{ verticalAlign: -2, marginRight: 3 }} fontSize="inherit" />
                                    <span>{this.state.cardInfo.tableName}</span>
                                </span>
                                <span className='PeopleNum'>
                                    <PeopleIcon 
                                        style={{ verticalAlign: -2, marginRight: 3 }}
                                        fontSize="inherit" 
                                    />
                                    <span>{this.state.cardInfo.personNum}</span>
                                </span>
                            </div>
                            {
                                takeoutClass ?
                                    <div style={{ display: 'flex' }}>
                                        {
                                            (this.state.cardInfo.cardType !== 'Magnet' || (this.props.modeSelected === 'order_mode' || this.props.modeSelected === 'takeaway_mode')) ?
                                            <span 
                                                className='OrderNum' 
                                                style={{ display: StateManager.get('displayOrderId') ? 'flex' : 'none', alignItems: 'center' }}
                                            >
                                                <AssignmentIcon style={{ verticalAlign: -2, marginRight: 3 }} fontSize="inherit" />
                                                {firstDish.check_number}
                                            </span> : null
                                        }
                                        <div className={['Time', takeoutClass].join(' ')}>
                                            <span className='OverTime' style={{display:'flex',justifyContent: 'flex-end',alignItems: 'center'}}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        _.get(this.state.cardInfo, ['boxData','0', 'eat_type']) === 2 ? 
                                                        <AirportShuttleIcon fontSize="inherit"/>:
                                                        <CardTravelIcon fontSize="inherit"/>
                                                    }
                                                </div>
                                                <div style={{display : 'flex', marginLeft: 7 , alignItems: 'center'}}>
                                                    <CardGiftcardIcon style={{ marginRight: 3, display: waitForOrder ? 'none' : 'block'}} fontSize="inherit" />
                                                    <span>
                                                        {
                                                         this.takeoutClassShowTime(this.state.cardInfo.boxData, waitForOrder)
                                                        }
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <span className='OrderNum' style={{ display: StateManager.get('displayOrderId') ? 'block' : 'none' }}>
                                        <AssignmentIcon style={{ verticalAlign: -2, marginRight: 3 }} fontSize="inherit" />
                                        {firstDish.check_number}
                                    </span>
                            }
                            {
                                // 只有普通卡片的外卖 并且设置显示用户名的时候才显示名称
                                StateManager.get('orderInfoShowCustomerName') && takeoutClass
                                && (this.state.cardInfo.cardType !== 'Magnet' || (this.props.modeSelected === 'order_mode' || this.props.modeSelected === 'takeaway_mode')) ?
                                <span className='OrderNum'>
                                    <PersonIcon style={{ verticalAlign: -2, marginRight: 3 }} fontSize="inherit"></PersonIcon>
                                    {this.getCustomerName(_.get(this.state.cardInfo, ['boxData', '0']))}
                                </span> : null
                            }
                        </div>
                        {
                            // 外卖不需要以下的时间展示了
                            takeoutClass ? null :
                                <div className={['Time', takeoutClass].join(' ')}>
                                    <span className='OverTime'>{
                                        // this.state.cardInfo.timesGone
                                        this.waiterToServeShowTimeGones()
                                    }</span>
                                    <span className='OrderTime' style={{ display: StateManager.get('displayOrderId') ? 'inline' : 'none' }}>
                                        {
                                            // this.props.section === 'history' || (this.props.section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort') ? this.state.cardInfo.endTime : this.state.cardInfo.orderTime
                                            this.waiterToServeShowEndTime(this.state.cardInfo.boxData)
                                        }
                                    </span>
                                </div>
                        }
                    </div>
                    {/* 进度条 */}
                    {
                        this.props.isShowTotalOrder && makeDishNumber > 0 ? 
                        <div style={{position:'relative'}}>
                            <LinearProgress variant="determinate" value={makeDishNumber / (_.get(this.state,'cardInfo.boxData') || []).length * 100} />
                            <div style ={{position:'absolute',left:0,right:0,textAlign:'center',top:0,bottom:0,lineHeight:'20px',color:'#fff'}}>
                                {`${makeDishNumber}/${(_.get(this.state,'cardInfo.boxData') || []).length}`}
                            </div>
                        </div>
                        : null
                    }
                </div>
                {/* 卡片中间 */}
                <div 
                    className={['Container'].join(' ')}
                    ref={"cardContainer"}
                    style={StateManager.get('cardAutoHeight') ? {
                        borderBottomRightRadius: this.state.cardContainerHeight < this.state.cardDetailHeight ? 0 : 10,
                        borderBottomLeftRadius: this.state.cardContainerHeight < this.state.cardDetailHeight ? 0 : 10
                    } : {
                        flex: 1,
                        borderBottomRightRadius: this.state.cardContainerHeight < this.state.cardDetailHeight ? 0 : 10,
                        borderBottomLeftRadius: this.state.cardContainerHeight < this.state.cardDetailHeight ? 0 : 10
                    }}
                >
                    <div 
                        ref={"cardDetail"}
                        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
                    >
                        {/* 普通模式 聚集 头部显示 菜名 */}
                        <div 
                            style={{ 
                                display: this.state.cardInfo.cardType === 'Magnet' && this.props.modeSelected === 'normal_mode' ? ' block ' : 'none', 
                                marginBottom: '10px',
                                padding: '10px 15px 0 15px',
                                opacity: this.state.isHideCardMask ? 1 : 0.5 
                            }}
                        >
                            <div 
                                style={{ 
                                    display: StateManager.get('displayName1') ? 'grid' : 'none', 
                                    fontSize: this.props.name1FontSize + 'px',
                                    opacity: this.state.isHideCardMask ? 1 : 0.5 
                                }}
                            >
                                {`${firstDish.item_name1}`}
                            </div>
                            <div 
                                style={{ 
                                    display: StateManager.get('displayName2') ? 'grid' : 'none',
                                    fontSize: this.props.name2FontSize + 'px',
                                    opacity: this.state.isHideCardMask ? 1 : 0.5  
                                }}
                            >
                                {`${firstDish.item_name2 ? firstDish.item_name2 : ''}`}
                            </div>
                        </div>
                        {/* 普通模式的聚集卡片 -显示备注、调味品、取消制作原因 */}
                        {
                            this.state.cardInfo.cardType === 'Magnet' && this.props.modeSelected === 'normal_mode' ?
                                <div 
                                    style={{ 
                                        padding: '5px 5px 5px 15px', 
                                        fontSize: StateManager.get('cardRemarksFontSize') + 'px',
                                        color: '#fff',
                                        display: this.isShowDishesInfo(firstDish) ? 'flex' : 'none',
                                        opacity: this.state.isHideCardMask ? 1 : 0.5 
                                    }}
                                >
                                    <div style={{ opacity: this.state.isHideCardMask ? 1 : 0.5 }}>
                                        {/* 菜品类型是不可制作 */}
                                        {
                                            firstDish.is_make === 5 && firstDish.return_reason && firstDish.return_reason !== 'null' && firstDish.return_reason !== undefined ? 
                                            <div className="description">
                                                {`${firstDish.return_reason}`}
                                            </div> 
                                            : ''
                                        }
                                        {/* 调味品 */}
                                        <div 
                                            style={{ 
                                                display: !firstDish.description && !firstDish.condimentsDescription ? 'none' : 'flex', 
                                                flexWrap: 'wrap', justifyContent: 'start',
                                                marginBottom: -10, 
                                                marginRight: -13 
                                            }}
                                        >
                                            {
                                                this.displayCondiment(firstDish.description, firstDish.condimentsDescription, firstDish, this.state.cardInfo.cardType)
                                            }
                                        </div>
                                        {/* {firstDish.description && firstDish.description !== 'null' && firstDish.description !== undefined ? <div className="description">{`${firstDish.description}`}</div> : ''}
                                                            {firstDish.condimentsDescription && firstDish.condimentsDescription !== 'null' && firstDish.condimentsDescription !== undefined ? <div className="condimentsDescription">{`${firstDish.condimentsDescription}`}</div> : ''} */}
                                        {/* {firstDish.check_name && firstDish.check_name !== 'null' && firstDish.check_name !== undefined ? <div className="checkName">{`${firstDish.check_name}`}</div> : ''} */}
                                        {/* {firstDish.remark && firstDish.remark !== 'null' && firstDish.remark !== undefined ? <div className="checkName">{`${firstDish.remark}`}</div> : ''} */}
                                        {/* 菜品规格 */}
                                        {
                                            firstDish.unit && firstDish.unit !== 'null' && firstDish.unit !== undefined ?
                                            <div className="unit">
                                                {`${firstDish.unit}`}
                                            </div> 
                                            : ''
                                        }
                                        {/* 套餐名 */}
                                        {
                                            firstDish.combo_meal_name && firstDish.combo_meal_name !== 'null' && firstDish.combo_meal_name !== undefined ?
                                            <div className="comboMealMame">
                                                {`${firstDish.combo_meal_name}`}
                                            </div> 
                                            : ''
                                        }
                                    </div>
                                </div> : null
                        }
                        {/* 普通模式聚集-缩略模式 -- 显示数量 */}
                        {
                            this.state.cardInfo.cardType === 'Magnet' && this.props.modeSelected === 'normal_mode' ?
                            // 缩略模式 直接显示数量
                                this.props.isSimpleMode ?
                                    <div
                                        key={`card-magnet-simple`}
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                            position: 'relative'
                                        }}
                                    >
                                        <div
                                            // className={this.cardBackgroundColor(firstDish, this.state.cardInfo.cardType)} 
                                            style={{
                                                // marginBottom: '10px',
                                                // background: this.cardBackgroundColor(ele, this.state.cardInfo.cardType),
                                                // marginTop: 10,
                                                padding: '0 10px 0 10px',
                                                opacity: this.state.isHideCardMask ? 1 : 0.5 // 遮罩层出现的时候，透明度调低
                                            }}
                                        >
                                            <div 
                                                className='simple-row'
                                                style={{ 
                                                    padding: 5,
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    marginRight: -3,
                                                    opacity: this.state.isHideCardMask ? 1 : 0.5 
                                                }}
                                            >
                                                {
                                                    this.state.cardInfo.boxData.map((ele: dish, index: number) => {
                                                        return (
                                                            <div 
                                                                className={
                                                                    [
                                                                        'Quantity',
                                                                        'quantityButtonBorder',
                                                                        this.state.selectData.findIndex(item => item.order_detail_id === ele.order_detail_id) !== -1 && !this.getCardSelectStatus() ? 'selectQuantityButtonBorder' : ''
                                                                    ].join(' ')
                                                                }
                                                                key={'simple' + index} 
                                                                style={{ borderRadius: "50%", borderWidth: 6, borderStyle: 'solid', marginRight: 3 }}
                                                            >
                                                                {this.quantityButton(ele, this.state.cardInfo.cardType)}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                    :
                                    // 非缩略模式
                                    this.state.cardInfo.boxData.map((ele: dish, index: number) => {
                                        // 菜品遮罩层状态
                                        let dishNamesHidesTheMaskStatus =this.getAllDishNamesHidesTheMaskStatus(ele);
                                        // 边框样式
                                        let borderStyle = this.checkSelectBorderTopAndBottom(ele);
                                        // 选择数据中的索引
                                        let selectIndex = this.state.selectData.findIndex(item => {                                   
                                            // 存在选择的套餐并且选择的套餐的菜品等于套餐的总菜品数量
                                            if (item.item_type === 3 && ele.order_detail_id === item.order_detail_id) {
                                                return (ele.course_groupArray || []).length === (item.course_groupArray || []).length
                                            }
                                            return item.order_detail_id === ele.order_detail_id
                                        });
                                        return (
                                            <div
                                                key={`card-${ele.order_detail_id}-${index}`}
                                                className={this.cardBackgroundColor(ele, this.state.cardInfo.cardType)}
                                                style={{
                                                    // minHeight: ele.item_type === 3 ? 70 : '',
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    position: 'relative',
                                                    opacity: this.state.isHideCardMask ? 1 : 0.5 // 遮罩层出现的时候，透明度调低
                                                }}
                                            >
                                                {/* 选择菜品的边框 */}
                                                <div 
                                                    className={
                                                        [
                                                            'cardDetailBorder',
                                                            selectIndex !== -1 && !this.getCardSelectStatus() ? 'selectCardDetailBorder' : ''
                                                        ].join(' ')
                                                    } 
                                                    style={{ 
                                                        position: "absolute",
                                                        top: index === 0 ? 0 : -3,
                                                        left: 0, 
                                                        right: 0, 
                                                        bottom: 0, 
                                                        borderRadius: 5,
                                                        borderWidth: 6,
                                                        borderStyle: 'solid', 
                                                        zIndex: 0,
                                                        ...borderStyle 
                                                        }}
                                                />
                                                <div 
                                                    style={{
                                                        padding: '0 10px 0 10px',
                                                        opacity: this.state.isHideCardMask ? 1 : 0.5 // 遮罩层出现的时候，透明度调低
                                                    }}
                                                >
                                                    {
                                                        ele.item_type === 3 ? (
                                                            <div
                                                                className={this.renderPackageBgColor(ele, this.state.cardInfo.cardType, finishedPackageIdGroup, makeDishNumber)}
                                                                style={{
                                                                    opacity: this.state.isHideCardMask ? 1 : 0.5, // 遮罩层出现的时候，透明度调低,
                                                                    borderRadius: 9,
                                                                    boxSizing: 'border-box',
                                                                    borderBottomLeftRadius: !(ele.course_groupArray || []).length ? 9 : 9,
                                                                    borderBottomRightRadius: !(ele.course_groupArray || []).length ? 9 : 9,
                                                                }}
                                                            >
                                                                {/* 套餐头 */}
                                                                <div
                                                                    className='Row'
                                                                    key={index}
                                                                    style={{ 
                                                                        minHeight: 68,
                                                                        padding: '5px 10px',
                                                                        ...ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? {
                                                                            background: 'linear-gradient(to right bottom, #BD4A62 12%, #617ECA 90%)',
                                                                            borderTopLeftRadius: 9,
                                                                            borderTopRightRadius: 9,
                                                                            borderBottomLeftRadius: !(ele.course_groupArray || []).length ? 9 : 0,
                                                                            borderBottomRightRadius: !(ele.course_groupArray || []).length ? 9 : 0,
                                                                        } : {}
                                                                    }}
                                                                >
                                                                    <div className='Name' style={{ fontSize: 24 }}>
                                                                        {
                                                                            ![1, 2, 3].includes(ele.eat_type) ?
                                                                            <div>
                                                                                <WeekendIcon style={{ verticalAlign: -4, marginRight: 10 }} fontSize="inherit" />
                                                                                <span>{ele.table_name}</span>
                                                                            </div> 
                                                                            : 
                                                                            ele.check_number
                                                                        }
                                                                    </div>
                                                                    <div className='Quantity'>
                                                                        {this.quantityButton(ele)}
                                                                    </div>
                                                                </div>
                                                                {/* 套餐菜品 */}
                                                                {
                                                                    this.renderPackageDishes(
                                                                        ele,
                                                                        finishedPackageIdGroup,
                                                                        makeDishNumber,
                                                                        dishNamesHidesTheMaskStatus,
                                                                        false
                                                                    )
                                                                }
                                                                {/* <div style={{
                                                                    ...ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? {
                                                                        background: 'linear-gradient(#A95578 12%, #7573B4 90%)',
                                                                    } : {},
                                                                    borderBottomLeftRadius: 9,
                                                                    borderBottomRightRadius: 9 
                                                                }}>
                                                                {
                                                                    (ele.course_groupArray || []).map((packageDish, index, originalData: dish[]) => {
                                                                        let borderStyle = this.checkPackageSelectBorderTopAndBottom(packageDish, originalData, ele);
                                                                        let selectedPackage = this.state.selectData.find(item => item.order_detail_id === ele.order_detail_id);
                                                                        let selectedIndex = -1;
                                                                        if (selectedPackage) {
                                                                            selectedIndex = selectedPackage.course_groupArray.findIndex((item, index, arr) => item.order_detail_id === packageDish.order_detail_id && ele.course_groupArray.length !== arr.length);
                                                                        }
                                                                        const { length } = originalData;
                                                                        return (
                                                                            <div 
                                                                                key={packageDish.key}
                                                                                className={
                                                                                    this.renderPackageDishesBgColor(
                                                                                        packageDish,
                                                                                        ele, 
                                                                                        this.state.cardInfo.cardType,
                                                                                        finishedPackageIdGroup,
                                                                                        makeDishNumber
                                                                                    )
                                                                                }
                                                                                style={{ 
                                                                                    borderBottomLeftRadius: index === length - 1 ? 8 : 0,
                                                                                    borderBottomRightRadius: index === length - 1  ? 8 : 0,
                                                                                    borderBottom: index === length - 1 ? 'none' : '',
                                                                                    minHeight: 80,
                                                                                    position: 'relative',
                                                                                    background: ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? 'none' : '',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column'
                                                                                }}
                                                                            >
                                                                            <div 
                                                                                className={['cardDetailBorder', selectedIndex !== -1 && !this.getCardSelectStatus() ? 'selectCardDetailBorder' : ''].join(' ')} 
                                                                                style={{
                                                                                    position: "absolute", 
                                                                                    top: index === 0 ? 0 : -3,
                                                                                    left: 0, right: 0, 
                                                                                    bottom: 0, 
                                                                                    borderRadius: 5,
                                                                                    borderWidth: 6, 
                                                                                    borderStyle: 'solid', 
                                                                                    zIndex: 0, 
                                                                                    inset: -2,
                                                                                    ...borderStyle 
                                                                                }} 
                                                                            />

                                                                                <div 
                                                                                    className='Row' 
                                                                                    style={{ 
                                                                                        display: 'flex',
                                                                                        fontSize: StateManager.get('name1FontSize') + 'px', 
                                                                                        padding: '5px 13px 5px 7px',
                                                                                        opacity: this.state.isHideCardMask ? 1 : 0.5,
                                                                                        alignItems: 'center',
                                                                                        height: '100%',
                                                                                        flex: 1
                                                                                    }} 
                                                                                >
                                                                                    <span style={{ width: 16 , height: 17 }}>-</span>
                                                                                    <div 
                                                                                        style={{opacity: this.state.isHideCardMask ? 1 : 0.5, flex: 2 }}
                                                                                    >
                                                                                        {`${packageDish.menu_item_name}`}
                                                                                    </div>
                                                                                    <div className='Quantity' style={{ flex: 1 }}>
                                                                                        {
                                                                                            this.quantityButton(
                                                                                                packageDish, 
                                                                                                undefined, 
                                                                                                { width: 36, height: 36, borderRadius: 18, marginRight: 0, fontSize: 20 },
                                                                                                {
                                                                                                    belongedPackage: ele
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>        
                                                                        )
                                                                    })
                                                                }
                                                                </div> */}
                                                            </div>
                                                        ) 
                                                        : 
                                                        (
                                                            <div className='Row' key={index} style={{ padding: 5 }}>
                                                                <div className='Name' style={{ fontSize: 24 }}>
                                                                    {
                                                                        // eslint-disable-next-line
                                                                        ele.eat_type !== 1 && ele.eat_type !== 2 && ele.eat_type !== 3 ?
                                                                        <div>
                                                                            <WeekendIcon style={{ verticalAlign: -4, marginRight: 10 }} fontSize="inherit" />
                                                                            <span>{ele.table_name}</span>
                                                                        </div> 
                                                                        : 
                                                                        ele.check_number
                                                                    }
                                                                </div>
                                                                <div className='Quantity'>
                                                                    {this.quantityButton(ele)}
                                                                </div>
                                                            </div> 
                                                        )
                                                    }
                                                    {/* 分割线 */}
                                                    {
                                                        _.get(borderStyle, 'borderBottomWidth') === 6 && selectIndex !== -1 ? 
                                                        null 
                                                        :
                                                        <div 
                                                            className="cardUnderline" 
                                                            style={
                                                                this.state.cardInfo.cardType !== 'normal' && index + 1 !== this.state.cardInfo.boxData.length ?
                                                                { marginTop: '10px', borderBottomWidth: 'thin', borderBottomStyle: 'solid' } 
                                                                :
                                                                { marginTop: '10px', borderBottom: 'thin solid transparent' }
                                                            }
                                                        >
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                            :
                            // 订单模式或外卖模式的聚集 | 普通模式的普通卡片
                            this.state.cardInfo.boxData.map((ele: dish, index: number) => {
                                // 套餐头部渐变色
                                let packageHeadRushBg = ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? {
                                    background: 'linear-gradient(to right bottom, #BD4A62 12%, #617ECA 90%)',
                                    borderTopLeftRadius: 9,
                                    borderTopRightRadius: 9,
                                    borderBottomLeftRadius: !(ele.course_groupArray || []).length ? 9 : 0,
                                    borderBottomRightRadius: !(ele.course_groupArray || []).length ? 9 : 0
                                } : {};
                                // 整个套餐催菜后的菜品的渐变背景色
                                let packageDishesRushBg = ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? {
                                    background: 'linear-gradient(#A95578 12%, #7573B4 90%)',
                                } : {}
                                // 边框样式
                                let borderStyle = this.checkSelectBorderTopAndBottom(ele);
                                // 磁力链边框样式
                                let magneticLinkBorderStyle = this.checkMagneticLinkBorderBottom(ele);
                                // 选择数据中的索引
                                let selectIndex = this.state.selectData.findIndex(item => {
                                    // 存在选择的套餐并且选择的套餐的菜品等于套餐的总菜品数量
                                    if (item.item_type === 3 && ele.order_detail_id === item.order_detail_id) {
                                        return (ele.course_groupArray || []).length === (item.course_groupArray || []).length
                                    }
                                    return item.order_detail_id === ele.order_detail_id
                                });
                                // 菜品遮罩层状态
                                let dishNamesHidesTheMaskStatus =this.getAllDishNamesHidesTheMaskStatus(ele);
                                // 是否最后一个磁力链菜品
                                let isLastMagneticLink = this.props.magneticLinkMode && ele.magnetic_link && (ele.is_make === 1 || ele.is_make === 2) && (index === (this.state.cardInfo.boxData.length - 1) || (index + 1 < (this.state.cardInfo.boxData.length -1) && this.state.cardInfo.boxData[index + 1].magnetic_link !== ele.magnetic_link))
                                let topIndex = -1;
                                let topDish = _.get(this.state.cardInfo.boxData, [index - 1]);
                                if (topDish) {
                                    if (topDish.item_type === 3) {
                                            topIndex = this.state.selectData.findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id && (selectDish.course_groupArray || []).length === (topDish.course_groupArray || []).length)
                                    } else {
                                        topIndex = this.state.selectData.findIndex(selectDish => selectDish.order_detail_id === topDish.order_detail_id)
                                    }
                                }
                                // 判断下一个菜品是否有选中
                                let nextSelectIndex = -1;
                                let nextDish = _.get(this.state.cardInfo.boxData, [index + 1]);
                                if (nextDish) {
                                    if (nextDish.item_type === 3) {
                                        nextSelectIndex = this.state.selectData.findIndex(item => item.order_detail_id === nextDish.order_detail_id && (item.course_groupArray || []).length === (nextDish.course_groupArray || []).length)
                                    } else {
                                        nextSelectIndex = this.state.selectData.findIndex(item => item.order_detail_id === nextDish.order_detail_id)
                                    }
                                }
                                return (
                                    <React.Fragment key={`card-${ele.order_detail_id}-${index}`}>
                                    {
                                        // 磁力链模式的头部
                                        this.props.magneticLinkMode && ele.magnetic_link && (ele.is_make === 1 || ele.is_make === 2) &&(index === 0 || (index -1 > -1 && this.state.cardInfo.boxData[index - 1].magnetic_link !== ele.magnetic_link)) ?
                                        <div 
                                            className={[
                                                "magneticLinkHeaderContainer", 
                                                topIndex !== -1 && selectIndex !== -1 && !this.getCardSelectStatus() ? 'magneticLinkHeaderShowBorder' : '',
                                                this.renderLinkHeaderBg(ele, finishedPackageIdGroup, isIncludedPackage)
                                            ].join(' ')}
                                            // style={{ background: this.props.isShowTotalOrder && }}
                                        >
                                            <div className="magneticLinkHeader">
                                                <LinkIcon />
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div
                                        className={this.cardBackgroundColor(ele, this.state.cardInfo.cardType, finishedPackageIdGroup, makeDishNumber, isIncludedPackage)}
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            position: 'relative',
                                            opacity: this.state.isHideCardMask ? 1 : 0.5, // 遮罩层出现的时候，透明度调低
                                            // marginBottom: isLastMagneticLink ? 10 : 0
                                            // marginLeft: -6,
                                            // marginRight: -6
                                        }}
                                    >
                                        {/* 卡片蒙版 */}
                                        {
                                            dishNamesHidesTheMaskStatus ?
                                            null :
                                            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.5)' }}></div>
                                        }
                                        {/* 选择菜品的边框 */}
                                        <div 
                                            className={['cardDetailBorder', selectIndex !== -1 && !this.getCardSelectStatus() ? 'selectCardDetailBorder' : ''].join(' ')} 
                                            style={{
                                                position: "absolute", 
                                                top: index === 0 ? 0 : -3,
                                                left: 0, right: 0, 
                                                bottom: 0, 
                                                borderRadius: 5,
                                                borderWidth: 6, 
                                                borderStyle: 'solid', 
                                                zIndex: 0, 
                                                ...borderStyle 
                                            }} 
                                        />
                                        {
                                            // (没有选中菜品，并且是磁力链模式下)或者(当前是最后一个磁力链菜品，并且下一个菜品也选中了)，才显示的磁力链边框 
                                            (
                                                this.props.magneticLinkMode && ele.magnetic_link &&
                                                (ele.is_make === 1 || ele.is_make === 2) &&
                                                !(selectIndex !== -1 && !this.getCardSelectStatus()) ||
                                                (isLastMagneticLink && nextSelectIndex !== -1)
                                            ) ?
                                            <div
                                                className={'magneticLinkBorder'}
                                                style={{
                                                    position: "absolute",
                                                    top: index === 0 ? 0 : -3, 
                                                    left: 6,
                                                    right: 6,
                                                    bottom: 0,
                                                    borderWidth: 2, 
                                                    borderTopWidth:0,
                                                    borderStyle: 'solid',
                                                    zIndex: 0, 
                                                    ...magneticLinkBorderStyle,
                                                }} 
                                            />
                                            : null
                                        }
                                        {/* 是否是套餐 */}
                                        {
                                            ele.item_type === 3 ? (
                                                <div 
                                                    key={`card-${ele.order_detail_id}-${index}`} 
                                                    // className={ele.rush > 0  ? 'rushedBg' : takeoutClass ? 'takeawayPackageBg' : 'packageBg'}
                                                    className={this.renderPackageBgColor(ele, this.state.cardInfo.cardType, finishedPackageIdGroup, makeDishNumber)}
                                                    style={{
                                                        opacity: this.state.isHideCardMask ? 1 : 0.5, // 遮罩层出现的时候，透明度调低,
                                                        // border: takeoutClass ? '1px solid #88482d' : '1px solid #9b7272',
                                                        // border: selectIndex !== -1 && !this.getCardSelectStatus() ? '4px solid #b93072' : '',
                                                        borderRadius: selectIndex !== -1 && !this.getCardSelectStatus() ? '9px' : '9px',
                                                        margin:'0 10px',
                                                        // background: takeoutClass ? '#bb5217' : '#4f102e',
                                                        boxSizing: 'border-box',
                                                        borderBottomLeftRadius: !(ele.course_groupArray || []).length ? 9 : 9,
                                                        borderBottomRightRadius: !(ele.course_groupArray || []).length ? 9 : 9,
                                                    }}
                                                >
                                                    {/* 套餐头 */}
                                                    <div 
                                                        // className={['Row', takeoutClass ? 'packageTakeAwayBorderBottom' : 'packageEatInBorderBottom'].join(' ')}
                                                        className={
                                                            [
                                                                'Row',
                                                                (ele.course_groupArray || []).length && this.renderPackageHeadBorderBottomColor(ele, finishedPackageIdGroup, makeDishNumber, this.state.cardInfo.cardType)
                                                            ].join(' ')
                                                        }
                                                        style={{ 
                                                            fontSize: StateManager.get('name1FontSize') + 'px', 
                                                            padding: '15px 10px',
                                                            opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5,
                                                            ...packageHeadRushBg,
                                                            // display: 'flex',
                                                            // justifyContent: 'space-between'
                                                            // borderBottom: takeoutClass ? 'packageTakeAwayBorderBottom' : 'packageEatInBorderBottom',
                                                        }} 
                                                    >
                                                        <div 
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: StateManager.get('displayName1') && StateManager.get('displayName2') ? 'space-between' : 'center'
                                                            }}
                                                        >
                                                        <div 
                                                            className='Name' 
                                                            style={{
                                                                opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5,
                                                                display: StateManager.get('displayName1') ? 'flex' : 'none',
                                                            }}
                                                            
                                                        >
                                                            {`${ele.item_name1}`}
                                                        </div>
                                                        <div 
                                                            className='Name' 
                                                            style={{
                                                                opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5,
                                                                display: StateManager.get('displayName2') ? 'flex' : 'none',
                                                            }}
                                                        >
                                                            {`${ele.item_name2}`}
                                                        </div>
                                                        </div>
                                                        <div className='Quantity'>
                                                            {this.quantityButton(ele, undefined, undefined, undefined)}
                                                        </div>
                                                    </div>
                                                    {/* 套餐菜品 */}
                                                    {
                                                        this.renderPackageDishes(
                                                            ele,
                                                            finishedPackageIdGroup,
                                                            makeDishNumber,
                                                            dishNamesHidesTheMaskStatus,
                                                            true
                                                        )
                                                    }
                                                    {/* <div style={{...packageDishesRushBg, borderBottomLeftRadius: 9, borderBottomRightRadius: 9 }}>
                                                    {
                                                        (ele.course_groupArray || []).map((packageDish, index, originalData: dish[]) => {
                                                            let borderStyle = this.checkPackageSelectBorderTopAndBottom(packageDish, originalData, ele);
                                                            let selectedPackage = this.state.selectData.find(item => item.order_detail_id === ele.order_detail_id);
                                                            let selectedIndex = -1;
                                                            if (selectedPackage) {
                                                                selectedIndex = selectedPackage.course_groupArray.findIndex((item, index, arr) => item.order_detail_id === packageDish.order_detail_id && ele.course_groupArray.length !== arr.length);
                                                            }
                                                            const { length } = originalData;
                                                            return (
                                                                <div 
                                                                    key={packageDish.key}
                                                                    className={
                                                                        this.renderPackageDishesBgColor(
                                                                            packageDish,
                                                                            ele, 
                                                                            this.state.cardInfo.cardType,
                                                                            finishedPackageIdGroup,
                                                                            makeDishNumber
                                                                        )
                                                                    }
                                                                    style={{ 
                                                                        borderBottomLeftRadius: index === length - 1 ? 8 : 0,
                                                                        borderBottomRightRadius: index === length - 1  ? 8 : 0,
                                                                        borderBottom: index === length - 1 ? 'none' : '',
                                                                        // display: 'grid',
                                                                        minHeight: 80,
                                                                        position: 'relative',
                                                                        background: ele.rush > 0 && this.state.cardInfo.cardType !== 'normal' ? 'none' : '',
                                                                        display: 'flex',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                >
                                                                <div 
                                                                    className={['cardDetailBorder', selectedIndex !== -1 && !this.getCardSelectStatus() ? 'selectCardDetailBorder' : ''].join(' ')} 
                                                                    style={{
                                                                        position: "absolute", 
                                                                        top: index === 0 ? 0 : -3,
                                                                        left: 0, right: 0, 
                                                                        bottom: 0, 
                                                                        borderRadius: 5,
                                                                        borderWidth: 6, 
                                                                        borderStyle: 'solid', 
                                                                        zIndex: 0, 
                                                                        inset: -2,
                                                                        ...borderStyle 
                                                                    }} 
                                                                />

                                                                    <div 
                                                                        className='Row' 
                                                                        style={{ 
                                                                            display: 'flex',
                                                                            fontSize: StateManager.get('name1FontSize') + 'px', 
                                                                            padding: '5px 13px 5px 7px',
                                                                            opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5,
                                                                            alignItems: 'center',
                                                                            height: '100%',
                                                                            flex: 1
                                                                        }} 
                                                                    >
                                                                        <span style={{ width: 16 , height: 17 }}>-</span>
                                                                        <div 
                                                                            style={{opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5, flex: 2 }}
                                                                        >
                                                                            {`${packageDish.menu_item_name}`}
                                                                        </div>
                                                                        <div className='Quantity' style={{ flex: 1 }}>
                                                                            {
                                                                                this.quantityButton(
                                                                                    packageDish, 
                                                                                    undefined, 
                                                                                    { width: 36, height: 36, borderRadius: 18, marginRight: 0, fontSize: 20 },
                                                                                    {
                                                                                        belongedPackage: ele
                                                                                    }
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div 
                                                                        style={{ 
                                                                            padding: '5px 13px 5px 7px', 
                                                                            fontSize: StateManager.get('cardRemarksFontSize') + 'px', 
                                                                            color: 'inherit', 
                                                                            display: this.isShowDishesInfo(packageDish, true) ? 'block' : 'none',
                                                                            opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5,
                                                                            wordBreak: 'break-all'
                                                                        }}
                                                                    >
                                                                        <div style={{opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5}}>
                                                                            {
                                                                                packageDish.is_make === 5 && packageDish.return_reason && packageDish.return_reason !== 'null' && packageDish.return_reason !== undefined ? 
                                                                                <div className="description">{`${ele.return_reason}`}</div> 
                                                                                : 
                                                                                ''
                                                                            }
                                                                            <div 
                                                                                style={{ 
                                                                                    display: !packageDish.description && !packageDish.condiments.length ? 'none' : 'flex',
                                                                                    flexWrap: 'wrap',
                                                                                    justifyContent: 'start', 
                                                                                    // marginBottom: -10,
                                                                                    // marginRight: -13, 
                                                                                }}
                                                                            >
                                                                                {
                                                                                    this.displayCondiment(
                                                                                        packageDish.description,
                                                                                        (packageDish.condiments || []).reduce((pre, cur) => `${pre}${cur.item_name1}`, ''), 
                                                                                        packageDish, 
                                                                                        this.state.cardInfo.cardType,
                                                                                        finishedPackageIdGroup,
                                                                                        makeDishNumber,
                                                                                        ele
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            {
                                                                                packageDish.unit && packageDish.unit !== 'null' && packageDish.unit !== undefined ?
                                                                                <div className="unit">{`${packageDish.unit}`}</div>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>        
                                                            )
                                                        })
                                                    }
                                                    </div> */}

                                                    {
                                                        // 如果是最后一个磁力链，或者没有选中菜品则不需要显示东西,否则显示下划线
                                                        ((_.get(borderStyle, 'borderBottomWidth') === 6 && selectIndex !== -1) || isLastMagneticLink) 
                                                        ? null
                                                        :
                                                        <div 
                                                            className="cardUnderline"
                                                            style={
                                                                this.state.cardInfo.cardType !== 'normal' && index + 1 !== this.state.cardInfo.boxData.length ?
                                                                { marginTop: '10px', borderBottomWidth: 'thin', borderBottomStyle: 'solid',opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 } 
                                                                :
                                                                { marginTop: '10px', borderBottom: 'thin solid transparent',opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 
                                                            }}
                                                        ></div>  
                                                    }
                                                </div>
                                            )
                                            : 
                                            // 普通菜品
                                            (
                                                <div 
                                                    key={`card-${ele.order_detail_id}-${index}`} 
                                                    style={{
                                                        padding: '0 10px 0 10px',
                                                        opacity: this.state.isHideCardMask ? 1 : 0.5, // 遮罩层出现的时候，透明度调低,
                                                    }}
                                                >
                                                {/* 菜名1 */}
                                                <div 
                                                    className='Row' 
                                                    style={{ 
                                                        display: StateManager.get('displayName1') ? 'grid' : 'none',
                                                        fontSize: StateManager.get('name1FontSize') + 'px', 
                                                        padding: 5,
                                                        opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5
                                                    }} 
                                                >
                                                    <div 
                                                        className='Name' 
                                                        style={{opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5}}
                                                    >
                                                        {`${ele.item_name1}`}
                                                    </div>
                                                    <div className='Quantity'>
                                                        {this.quantityButton(ele)}
                                                    </div>
                                                </div>
                                                {/* 菜名2 */}
                                                <div 
                                                    className='Row' 
                                                    style={{
                                                        display: StateManager.get('displayName2') ? 'grid' : 'none', 
                                                        fontSize: StateManager.get('name2FontSize') + 'px', 
                                                        padding: 5, 
                                                        opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 
                                                    }}
                                                >
                                                    <div 
                                                        className='Name'
                                                        style={{ opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 }}
                                                    >
                                                        {`${ele.item_name2}`}
                                                    </div>
                                                    <div className='Quantity' style={{ display: StateManager.get('displayName1') ? 'none' : 'flex' }}>
                                                        {this.quantityButton(ele)}
                                                    </div>
                                                </div>
                                                {/* 退菜原因/订单备注/调味品/套餐名 */}
                                                <div 
                                                    style={{ 
                                                        padding: 5, 
                                                        fontSize: StateManager.get('cardRemarksFontSize') + 'px', 
                                                        color: 'inherit', 
                                                        display: this.isShowDishesInfo(ele) ? 'flex' : 'none',
                                                        opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 
                                                    }}
                                                >
                                                    <div style={{opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5}}>
                                                        {ele.is_make === 5 && ele.return_reason && ele.return_reason !== 'null' && ele.return_reason !== undefined ? <div className="description">{`${ele.return_reason}`}</div> : ''}
                                                        <div style={{ display: !ele.description && !ele.condimentsDescription ? 'none' : 'flex', flexWrap: 'wrap', justifyContent: 'start', marginBottom: -10, marginRight: -13 }}>
                                                            {this.displayCondiment(ele.description, ele.condimentsDescription, ele, this.state.cardInfo.cardType)}
                                                        </div>
                                                        {ele.unit && ele.unit !== 'null' && ele.unit !== undefined ? <div className="unit">{`${ele.unit}`}</div> : ''}
                                                        {ele.combo_meal_name && ele.combo_meal_name !== 'null' && ele.combo_meal_name !== undefined ? <div className="comboMealMame">{`${ele.combo_meal_name}`}</div> : ''}
                                                    </div>
                                                </div>
                                                {
                                                    // 如果是最后一个磁力链，或者没有选中菜品则不需要显示东西,否则显示下划线
                                                    ((_.get(borderStyle, 'borderBottomWidth') === 6 && selectIndex !== -1) || isLastMagneticLink) 
                                                    ? null
                                                    :
                                                    <div 
                                                        className="cardUnderline"
                                                        style={
                                                            this.state.cardInfo.cardType !== 'normal' && index + 1 !== this.state.cardInfo.boxData.length ?
                                                            { marginTop: '10px', borderBottomWidth: 'thin', borderBottomStyle: 'solid',opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 } 
                                                            :
                                                            { marginTop: '10px', borderBottom: 'thin solid transparent',opacity: dishNamesHidesTheMaskStatus ? 1 : 0.5 
                                                        }}
                                                    ></div>  
                                                }
                                            </div>
                                            )
                                        }

                                    </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        {/* 整单备注 */}
                        {
                            this.orderRemark(_.get(this.state.cardInfo, ['boxData', '0']), 0)
                        }
                    </div>
                </div>
                {/* 卡片脚部 */}
                <div
                    className={['Footer'].join(' ')}
                    onClick={(e) => { e.stopPropagation() }}
                    style={{ height: 10, minHeight: 10, display: this.state.cardContainerHeight < this.state.cardDetailHeight ? 'block' : 'none', position: 'relative' }}
                >
                    {/* 页脚蒙版 */}
                    {
                        // this.state.isHideCarFooterdMask && this.state.isHideCardMask ?
                        this.state.isHideCardMask ?
                            null :
                            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.5)', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}></div>
                    }
                    <div
                        className='CardButton'
                        onClick={() => {
                            this.props.clickButton()
                            this.showOrHideCardButton()
                        }}
                        style={{ alignItems: 'flex-end', display: this.state.cardContainerHeight < this.state.cardDetailHeight ? 'flex' : 'none' }}
                    >
                        ...
                    </div>
                    {/* <div
                        className='ButtonGroup'
                        style={{
                            display: this.state.operationButtonVisibility ? undefined : 'none',
                            position: 'relative',
                            zIndex: 1
                        }}
                    >
                        <div
                            onClick={() => {
                                this.props.clickUnableToMake(this.state.cardInfo.boxData)
                            }}
                        >
                            {this.props.footerLeftText ?i18nService.t(this.props.footerLeftText) :i18nService.t('text_unable_to_make')}
                        </div>
                        <div
                            onClick={() => {
                                this.props.clickSuspended(this.state.cardInfo.boxData)
                            }}
                        >
                            {this.props.footerRightText ?i18nService.t(this.props.footerRightText): i18nService.t('text_pause_production')}
                        </div>
                    </div> */}
                </div>
            </div >
        );
    }
}

// *********************
// Types
// *********************

type CardComponentProps = {
    cardBox: CardBox;
    doubleClickCard: Function;
    clickButton: Function;
    clickCard: Function;
    clickQuantity?: Function;
    clickUnableToMake: Function;
    longTouchCard: Function;
    longTouchEnd: Function;
    clickSuspended: Function;
    cardWidth: number;
    section: string;
    modeSelected: string;
    cardUnmount?: Function;
    footerLeftText?: string;
    footerRightText?: string;
    name1FontSize: any;
    name2FontSize: any;
    selectData: Array<dish>;
    // 是否简单模式
    isSimpleMode: boolean;
    // 是否需要隐藏卡片蒙版
    isHideCardMask: boolean;
    // 选则的菜品汇总
    selectSummaryDishNames: Array<SummaryDishNames>
    // 是否显示总单模式
    isShowTotalOrder: boolean;
    // 磁力链模式
    magneticLinkMode: boolean;
    // 角色
    role: string;
}

type CardComponentState = {
    operationButtonVisibility: boolean;
    cardInfo: CardInfo;
    select: boolean;
    cardDetailHeight: number;
    cardContainerHeight: number;
    selectDetailCard: Array<number>;
    selectData: Array<dish>;
    // 是否显示整单备注详情
    isShowOrderRemarkDialog: boolean;
    // 显示的弹框文本
    OrderRemarkDialogData: {
        remark: string;
        kdsTime: string;
        deliveryInfo: string;
    };
    // 是否需要隐藏卡片蒙版
    isHideCardMask: boolean
    // 选则的菜品汇总
    selectSummaryDishNames: Array<SummaryDishNames>
    // 是否隐藏卡片底部的遮罩层
    isHideCarFooterdMask: boolean;
}