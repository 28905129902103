import React from "react";
import { withSnackbar } from "notistack";
import CommonPage from './common.page'
import { StateManager } from "../modules/state";
import i18nService from '../i18n';

class ChefPage extends React.Component<any> {
    constructor(props: any) {
        super(props);
        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));
    }
    render() {
        return (
            <CommonPage
                enqueueSnackbar={this.props.enqueueSnackbar}
                filterButtonSelected={'kitchen'}
                role={'chef'}
            ></CommonPage>
        )
    }
}

export default withSnackbar(ChefPage as any);