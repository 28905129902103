import React, { Component, SetStateAction } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "../css/paging.scss"
import { Unsubscribe } from 'redux';
import { StateManager, GlobalState } from '../modules/state';



export default class PagingComponent extends Component<PagingComponentProps, PagingComponentState> {
  constructor(props: any) {
    super(props);

    this.create = this.create.bind(this)

    // 设置当前页码，默认为第一页
    this.state = {
      pageCurrent: StateManager.get("pageCurrent")
    }
    // $ global state => local state
    // $ do one-way binding here
    this.unsubscribe = StateManager.subscribe("paging-component-update", () => {
      // update local state
      this.setState({
        pageCurrent: StateManager.get("pageCurrent")
      } as SetStateAction<PagingComponentState>);
    });
  };
  private unsubscribe: Unsubscribe;

  /**
     * update local state by object
     * @param val 
     */

  // *********************
  // Functions
  // *********************

  create() {
    let pages = [];
    pages.push(
      <li
        onClick={this.state.pageCurrent === 1 ? () => { } : this.goPrev.bind(this)}
        className={this.state.pageCurrent === 1 ? "" : "nomore"}
        key={0}>
        <ArrowBackIosIcon />
      </li>)

    for (let i = 1; i <= this.props.totalPage; i++) {
      pages.push(
        <li
          onClick={() => {
            GlobalState.dispatch({
              type: "SET_BY_PATH",
              path: "pageCurrent",
              value: i,
            });
            this.props.onChange(StateManager.get("pageCurrent"));
          }}
          className={this.state.pageCurrent === i ? "active" : ""}
          key={i}>
          {i}
        </li>
      )

    }
    pages.push(
      <li
        onClick={this.state.pageCurrent === this.props.totalPage ? () => { } : this.goNext.bind(this)}
        className={this.state.pageCurrent === this.props.totalPage ? "" : "nomore"}
        key={this.props.totalPage + 1}>
        <ArrowForwardIosIcon />
      </li>)

    return pages;
  }

  // 页面向前
  goPrev() {
    let pageCurrentValue = this.state.pageCurrent
    --pageCurrentValue
    GlobalState.dispatch({
      type: "SET_BY_PATH",
      path: "pageCurrent",
      value: pageCurrentValue,
    });
    this.props.onChange(StateManager.get("pageCurrent"));
  }

  // 页面向后
  goNext() {
    let pageCurrentValue = this.state.pageCurrent
    ++pageCurrentValue
    GlobalState.dispatch({
      type: "SET_BY_PATH",
      path: "pageCurrent",
      value: pageCurrentValue,
    });
    this.props.onChange(StateManager.get("pageCurrent"));
  }

  // *********************
  // View
  // *********************

  render() {
    const Pages = this.create.bind(this)();
    return (
      <div style={{ display: !this.props.totalPage ? 'none' : 'flex', justifyContent: "center", alignItems: "center", paddingTop: 5 }}>
        <ul className="page">
          {Pages}
        </ul>
      </div>
    );
  }
}

// *********************
// States
// *********************

type PagingComponentProps = {
  totalPage: any;
  onChange: Function;
  config?: Object;
}
type PagingComponentState = {
  pageCurrent: number
}