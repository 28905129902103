import React, { Component, SetStateAction } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { StateManager, GlobalState } from '../modules/state';
import { Unsubscribe } from 'redux';

export default class SwitchsComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        // binding `this`
        this.handleChange = this.handleChange.bind(this)
        this.updateLocalState = this.updateLocalState.bind(this)
        // State
        this.state = {
            checkedB: StateManager.get(this.props.settingValue),
        }
        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("setting-switch", () => {
            this.setState({
                checkedB: StateManager.get(this.props.settingValue),
            } as SetStateAction<State>);
        });

    };

    private unsubscribe: Unsubscribe;

    // *********************
    //  Life Cycle Function
    // *********************

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    // *********************
    // Functions
    // *********************

    /**
    * update local state by object
    * @param val 
    */
    public updateLocalState(val: Partial<State>) {
        this.setState(val as any);
    };

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        try {
            // 当开启“划菜打印”开关时，堂食和外卖开关必须有一个是开启状态
            // 修改堂食订单打印的时候，如果是关闭的操做，则判断是否开启了划菜打印，如果关闭，则需要保证外卖订单打印开启。
            if(this.props.settingValue === 'isPrintEatIn' && !event.target.checked) {
                if(StateManager.get('crossFoodPrint') && !StateManager.get('isPrintTakeAway')) return;
            }
            // 修改外卖订单打印的时候，如果是关闭的操做，则判断是否开启了划菜打印，如果关闭，则需要保证堂食订单打印开启
            if(this.props.settingValue === 'isPrintTakeAway' && !event.target.checked) {
                if(StateManager.get('crossFoodPrint') && !StateManager.get('isPrintEatIn')) return;
            }
            // 修改划菜打印的时候，如果是开启的操做，则判断堂食打印或外卖打印是否开启，如果两个都没开启，默认开启堂食打印
            if(this.props.settingValue === 'crossFoodPrint' && event.target.checked) {
                if(!StateManager.get('isPrintEatIn') && !StateManager.get('isPrintTakeAway')) {
                    GlobalState.dispatch({
                        type: "SET_BY_PATH",
                        path: 'isPrintEatIn',
                        value: true
                    })
                }
            }
            this.updateLocalState({
                checkedB: event.target.checked
            })
            GlobalState.dispatch({
                type: "SET_BY_PATH",
                path: this.props.settingValue,
                value: event.target.checked
            })
        } catch (error) {
            console.log("handleChange the error", error)
        }
    };

    // *********************
    // View
    // *********************

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 24, }}>
                <span style={{flex:1, fontSize: 18, fontWeight: "bold" }}>{this.props.switchTitle}</span>
                <div style={{  display: "flex", justifyContent: 'flex-end ' }}>
                    <SwitchComponent
                        checked={this.state.checkedB}
                        onChange={this.handleChange}
                        value="checkedB"
                    />
                </div>
            </div>
        );
    }
}

// *********************
//  Component Function Types
// *********************

type State = {
    checkedB: boolean;
}
type Props = {
    settingValue: string;
    switchTitle: string
}

// *********************
// Type
// *********************

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface ComponentProps extends SwitchProps {
    classes: Styles;
}


// *********************
// Component Function
// *********************

export const SwitchComponent = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
            marginTop: 0,
            marginLeft: 24
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#E4542C',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#E4542C',
                border: '6px solid #E4542C',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            backgroundColor: "#660033",
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: ComponentProps) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
