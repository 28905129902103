import { dish, operation, CardInfo } from './type'
import i18nService from '../../i18n';
import { StateManager } from '../state';
const uuidv4 = require('uuid/v4');

/**
 * 单张卡片的信息
 * @param cardIndex 卡片的序号
 * @param cardType 卡片的类型 
 * @param boxData  卡片里存放的菜品数据
 * @param tableName  桌名,只有同类同桌合并 及 默认模式 时需要处理
 * @param personNum  人数 只有同类同桌合并 及 默认模式 时需要处理
 * @param orderHeadId   订单orderHeadID
 * @param orderTime 下单时间
 * @param timesGone 下单了多久
 * @param uuid uuid
 * @param rush 催菜
 * @param eat_type 就餐方式
 * @function showBoxContent 返回一个对象,包含所有的属性
 */
export class CardBox {

    constructor(
        type: string,
        dishesData: Array<dish>,
        orderHeadId: number,
        orderTime: string,
        timesGone: string,
        endTime: string,
        kdsTime: string,
        cardIndex: number,
        rush: number,
        eat_type: number,
        personNum?: number | string,
        tableName?: string,
        isSelected?: boolean,
    ) {
        this.cardInfo = {
            //卡片的类型 
            cardType: type,
            //卡片里存放的菜品数据
            boxData: dishesData,
            //订单orderHeadID
            orderHeadId: orderHeadId,
            //下单时间
            orderTime: orderTime,
            //下单了多久
            timesGone: timesGone,
            //修改时间,只有在已制作状态时需要显示
            endTime: endTime,
            // 就餐时间
            kdsTime: kdsTime,
            //卡片的序号,用于发生操作时定位到卡片
            cardIndex: cardIndex,
            // 催菜
            rush: rush,
            // 就餐方式: 0:堂吃，1:堂吃打包，2:外送，3:来店自取
            eat_type: eat_type,
            //人数 只有同类同桌合并 及 默认模式 时需要处理
            personNum: personNum ? personNum : null,
            //桌名,只有同类同桌合并 及 默认模式 时需要处理
            tableName: tableName ? tableName : null,
            //当前是否被选中
            isSelected: isSelected ? isSelected : false,
            // id
            uuid: uuidv4()
        }
    }

    public cardInfo: CardInfo;
    /**
     * 返回卡片信息
     */
    public showBoxContent(): CardInfo {
        return this.cardInfo
    }

    /**
     * @useage 当单个菜品状态发生改变时,将从BOX移出,同时要重置orderTime及timesGone
     *         不知道快捷的查找方式时,可通过order_detail_id在数据中搜索菜品,order_detail_id是永不重复的
     * @param dishIndex 菜品在box的序号
     * @param order_detail_id 菜品的独一无二的ID
     */
    public removeDataFromBox(dishIndex: number, order_detail_id: number) {
        if (this.cardInfo.boxData[dishIndex].order_detail_id === order_detail_id) {
            this.cardInfo.boxData.splice(dishIndex, 1);
        }
    }
}

/**
 * 卡片处理器
 * @param dishesData  菜品原数据
 * @param BoxGroup 卡包
 * @function update 格式化传入的dishesData
 * @function removeDishFromCardBox 从卡片中删除菜品
 */
export class CardProcessor {
    constructor(dishesData: Array<dish>) {
        this.dishesData = dishesData;
        this.update()
    }
    private dishesData: Array<dish> = [];
    private operation: operation = "normal";
    public BoxGroup: Array<CardBox> = [];
    public deskNum: number | string = "All";
    public eatType: number | string = "All";

    /**
     * 计算菜品权重值
     * @param dishesData 菜品源数据
     * @param time 排序时间 order_time | end_time | kds_time | joint_time
     * @param role 角色 waiter | chef
     * @param section 菜品状态
     * @returns 
     */
     public setDishesWeights(dishes: Array<dish>, time: string, role?: string, section?: string) {
         console.log(time,'排序时间类型')
        // 每分钟差值（用于权重值计算）
        const weightPerMins:number = 1.667
        // 轮次膨胀值(RBP中设置), 换算成毫秒跟时间戳单位一致
        const weightForTurn:number = Number(StateManager.get('turnWeightValue')) * 60000 || 0
        if(dishes) {
            // 记录排序时间类型
            const timeSort: string = time;
            // ！时间统一转换为时间戳格式(精确到秒)，方便计算
            // 对菜品根据排序时间 time 进行排序
            const sortArr =  dishes.sort((a:dish , b:dish) => {
                let pre = Date.parse(a[timeSort] || 0)
                let next = Date.parse(b[timeSort] || 0)
                return next - pre
            })
            // 记录当前最大时间（最新下单时间或最新更改时间）
            const maxOrderTimeWeights: number = sortArr && sortArr.length > 0 ? ( Date.parse(sortArr[0][timeSort]) || 0 ) : 0 
            // 设置菜品权重值，字段 dishWeights
            dishes.forEach((dish: dish, index: number)=>{
                if(dish[timeSort] || timeSort === 'joint_time') {
                    // 计算时间差值
                    // 外卖堂食联合排序时，当joint_time为null时，取order_time
                    let timeDifference =  maxOrderTimeWeights - Date.parse(dish[timeSort] || dish['order_time'])
                    // 根据时间差，计算菜品权重值
                    let dishWeights = timeDifference * weightPerMins || weightPerMins
                    // 计算催菜权重值
                    if(dish.rush) {
                        // 计算催菜值权重点  （催菜次数 * 当前最大时间）
                        let rushWeights = dish.rush * maxOrderTimeWeights 
                        // 菜品权重值加上催菜权重值
                        dishWeights += rushWeights
                    }
                    // 计算轮次权重值(已下单页面才进行计算)
                    if((role === 'waiter' && section === 'chefkitchen') || (role === 'chef' && section === 'kitchen')) {
                        let turnWeights: number = 0;
                        if(!dish.turn) {
                            // $ 如果轮次为null或为空，则默认按照轮次1进行计算
                            // 计算轮次权重值  (轮次膨胀值 * 当前轮次 * 每分钟差值)
                            turnWeights = weightForTurn * 1 * weightPerMins
                            // 菜品权重值减去轮次权重值
                            dishWeights -= turnWeights
                        } else {
                            // 计算轮次权重值  (轮次膨胀值 * 当前轮次 * 每分钟差值)
                            turnWeights = weightForTurn * Number(dish.turn) * weightPerMins || 0
                            // 菜品权重值减去轮次权重值
                            dishWeights -= turnWeights
                        }
                    }
                    // 记录菜品权重值
                    dish['dishWeights'] = dishWeights
                } else if(timeSort === 'kds_time' && dish[timeSort] === null ) {
                    /**
                     * 如果交付时间kds_time为null，则取下单时间order_time计算权重值
                     * 1. 根据order_time计算时间差
                     * 2. 计算菜品权重值
                     * 3. 加上催菜权重值
                     * 4. 加上轮次权重值
                     */
                    // 计算时间差
                    let timeDifferenceForNull = maxOrderTimeWeights - Date.parse(dish['order_time'])
                    // 计算菜品权重值
                    let dishWeightsForNull = timeDifferenceForNull * weightPerMins || weightPerMins
                    // 计算催菜权重值
                    if(dish.rush) {
                        // 计算催菜值权重点  （催菜次数 * 当前最大时间）
                        let rushWeights = dish.rush * maxOrderTimeWeights 
                        // 菜品权重值加上催菜权重值
                        dishWeightsForNull += rushWeights
                    }
                    // 计算轮次权重值(已下单页面才进行计算)
                    if((role === 'waiter' && section === 'chefkitchen') || (role === 'chef' && section === 'kitchen')) {
                        // 计算轮次权重值
                        let turnWeights: number = 0;
                        if(!dish.turn) {
                            // $ 如果轮次为null或为空，则默认按照轮次1进行计算
                            // 计算轮次权重值  (轮次膨胀值 * 当前轮次 * 每分钟差值)
                            turnWeights = weightForTurn * 1 * weightPerMins
                            // 菜品权重值减去轮次权重值
                            dishWeightsForNull -= turnWeights
                        } else {
                            // 计算轮次权重值  (轮次膨胀值 * 当前轮次 * 每分钟差值)
                            turnWeights = weightForTurn * Number(dish.turn) * weightPerMins || 0
                            // 菜品权重值减去轮次权重值
                            dishWeightsForNull -= turnWeights
                        }
                    }
                    // 记录菜品权重值
                    dish['dishWeights'] = dishWeightsForNull
                }
            })
            return dishes
        }
    }

    /**
     * 更新boxgroup
     * @param section 菜品状态(已下单,待上菜等)
     * @param role 菜品状态
     * @param operation 合并类型
     * @param dishesData 菜品源数据
     * @param deskNum 当前桌台号
     * @param eatType 当前就餐方式
     */
    public update(
        section?: string,
        role?: 'chef' | 'waiter', 
        operation?: operation,
        dishesData?: Array<dish>, 
        deskNum?: number | string,
        eatType?: number | string
    ) {
        if (dishesData) {
            // 默认按照下单时间排序
            let time = 'order_time'
            // 已制作或者已上菜，或者服务员的待上菜使用end_time排序，则使用end_time，其他使用order_time
            if((section && section === 'history') || (role === 'waiter' && section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort')) {
                time = 'end_time'
            }
            /**
             *  厨师及服务员的已下单
             *  1. 按照下单时间排序
             *  2. 按照就餐时间排序
             *  3. 按照外卖堂食联合排序
             */
            if((role === 'waiter' && section === 'chefkitchen' && StateManager.get('orderedSort') === 'orderTimeSort') ||
               (role === 'chef' && section === 'kitchen' && StateManager.get('orderedSort') === 'orderTimeSort')
            ) {
                time = 'order_time'
            }
            if((role === 'waiter' && section === 'chefkitchen' && StateManager.get('orderedSort') === 'kdsTimeSort') ||
               (role === 'chef' && section === 'kitchen' && StateManager.get('orderedSort') === 'kdsTimeSort')
            ) {
                time = 'kds_time'
            }
            if((role === 'waiter' && section === 'chefkitchen' && StateManager.get('orderedSort') === 'kdsTimeJointOrderTimeSort') ||
               (role === 'chef' && section === 'kitchen' && StateManager.get('orderedSort') === 'kdsTimeJointOrderTimeSort')
            ) {
                time = 'joint_time'
            }
            /**
             * 厨师及服务员的已备菜
             * 1. 堂食订单按照下单时间order_time排序
             * 2. 外送订单按照交付时间kds_time排序
             */
            if((role === 'waiter' && section === 'prepareDish') || (role === 'chef' && section === 'prepareDish')) {
                time = 'kds_time'
            }
            /**
             *  外卖模式
             *  1. 服务员待上菜页面若开启了按照最后状态更改时间排序，则按照end_time排序
             *  2. 其余情况按照kds_time排序
             */
            if(StateManager.get('modeSelected') === 'takeaway_mode' && (role === 'waiter' && section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort')) {
                time = 'end_time'
            } else if(StateManager.get('modeSelected') === 'takeaway_mode') {
                time = 'kds_time'
            }
      
            this.dishesData = this.setDishesWeights(dishesData, time, role, section) || []
        }
        if (operation) {
            this.operation = operation
        }
        if (deskNum) {
            this.deskNum = deskNum
        }
        if (eatType) {
            this.eatType = eatType
        }
        this.BoxGroup = []
        for (let index = 0; index < this.dishesData.length; index++) {
            let dish = this.dishesData[index];
            // 已制作或者已上菜，或者服务员的待上菜使用end_time排序，则使用end_time，其他使用order_time
            let time = (section && section === 'history') || (role === 'waiter' && section === 'kitchen' && StateManager.get('readyToServeSort') === 'endTimeSort') ? new Date(dish.end_time).getTime() : new Date(dish.order_time).getTime()
            let timesGone: number | string = parseInt(((new Date().getTime() - time) / 1000 / 60).toFixed(0))
            if (timesGone > 99) {
                timesGone = `99+min`
            } else {
                timesGone = `${timesGone}min`
            }
            let orderTimeHours = (new Date(dish.order_time)).getHours();
            let orderTimeMinutes = (new Date(dish.order_time)).getMinutes()
            let orderTime = `${orderTimeHours < 10 ? '0' + orderTimeHours : orderTimeHours}:${orderTimeMinutes < 10 ? '0' + orderTimeMinutes : orderTimeMinutes}`;
            let personNum = `${dish.customer_num}`
            let endTimeHours = (new Date(dish.end_time)).getHours();
            let endTimeMinutes = (new Date(dish.end_time)).getMinutes();
            let endTime = `${endTimeHours < 10 ? '0' + endTimeHours : endTimeHours}:${endTimeMinutes < 10 ? '0' + endTimeMinutes : endTimeMinutes}`;
            let kdsTimeHours = (new Date(dish.kds_time)).getHours();
            let kdsTimeMinutes = (new Date(dish.kds_time)).getMinutes();
            let kdsTime = `${kdsTimeHours < 10 ? '0' + kdsTimeHours : kdsTimeHours}:${kdsTimeMinutes < 10 ? '0' + kdsTimeMinutes : kdsTimeMinutes}`;
            let card = new CardBox(
                this.operation,
                [dish], 
                dish.order_head_id, 
                orderTime, 
                timesGone, 
                endTime,
                kdsTime, 
                index, 
                dish.rush, 
                dish.eat_type,
                personNum, 
                dish.table_name
            )
            this.BoxGroup.push(card)
        }
        this.filterDishByDeskNumFromCardBox(this.deskNum)
        // 根据就餐方式过滤卡片
        if (eatType !== undefined && eatType !== null) {
            this.filterDishByEatTypeFromCardBox(this.eatType)
        }
        return this
    }

    /**
     * 根据桌号筛选卡片
     * @param deskNum 桌号
     */
    public filterDishByDeskNumFromCardBox(deskNum: any) {
        if (deskNum !== 'All') {
            this.BoxGroup = this.BoxGroup.filter(CardBox => CardBox.showBoxContent().tableName === deskNum)
        }
        return this
    }

    /**
     * 根据就餐方式筛选卡片
     * @param eatType 就餐方式
     */
    public filterDishByEatTypeFromCardBox(eatType: any) {
        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));
        let eatTypeValue: any = {
            0: `${i18nService.t('text_dinein')}`,
            1: `${i18nService.t('text_hall_for_packaging')}`,
            2: `${i18nService.t('text_outside_order')}`,
            3: `${i18nService.t('text_to_store_comeUndone')}`
        }
        if (eatType !== 'All') {
            this.BoxGroup = this.BoxGroup.filter(CardBox => eatTypeValue[CardBox.showBoxContent().eat_type] === eatType)
        }
        return this
    }

    /**
     * 把单个菜品数据从原数据中删除,然后在cardBox中也删除对应数据
     * 通过order_detail_id在原数据中搜索菜品,order_detail_id是永不重复的
     * @param cardIndex 卡片序号
     * @param dishIndex 菜品在卡片中的序号
     * @param order_detail_id 菜品的独一无二的ID
     */
    public removeDishFromCardBox(cardIndex: number, dishIndex: number, order_detail_id: number) {
        let index = this.dishesData.findIndex((dish) => {
            return dish.order_detail_id === order_detail_id;
        })
        if (index !== -1) {
            this.dishesData.splice(index, 1);
            this.BoxGroup[cardIndex].removeDataFromBox(dishIndex, order_detail_id);
        } else {
            throw new Error('no relate data');
        }
        return this;
    }
    /**
     * 获取菜品数据源
     */
    public getOriginDishes() {
        return this.dishesData;
    }

    /**
     * 根据boxData中的数据，把菜品数据从原数据中删除,然后在cardBox中也删除对应数据
     * @param boxData 
     */
    public removeCardFromBoxGroup(boxData: Array<dish>): this {
        // for (let dish of boxData) {
        //     let index = this.dishesData.findIndex((ele: dish) => {
        //         return ele.order_detail_id === dish.order_detail_id
        //     })
        //     if (index !== -1) {
        //         // this.dishesData.splice(index, 1)
        //     }
        // }
        this.dishesData = this.dishesData.filter((dish: dish) => {
            // 判断当前dishesData是否在需要更改状态得菜品中
            let index = boxData.findIndex((ele: dish) => {
                return ele.order_detail_id === dish.order_detail_id
            })
            // 不在则不需要变化
            if (index === -1) return true
            else {
                // 存在则需要把对应Boxgroup中的菜品也移除
                this.BoxGroup = this.BoxGroup.filter((cardBox: CardBox) => {
                    let findIndex = cardBox.cardInfo.boxData.findIndex((cardBoxdish: dish) => {
                        return cardBoxdish.order_detail_id === dish.order_detail_id
                    })
                    if (findIndex !== -1) cardBox.cardInfo.boxData.splice(findIndex, 1)
                    if (cardBox.cardInfo.boxData.length === 0) return false
                    else return true
                })
                return false
            }
        })
        // this.update()
        return this
    }
}