import React, { Component, SetStateAction } from 'react';
import { createHashHistory } from 'history';
import { Fab, Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { StateManager } from '../modules/state';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PanToolIcon from '@material-ui/icons/PanTool';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import i18nService from '../i18n';
import { MessengerModule } from "../modules/messenger/module";
import { ERROR_004, ERROR_0026 } from "../constants/error";
import { Unsubscribe } from 'redux';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';

class Jobselection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // binding `this`
        this.render = this.render.bind(this);
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);

        // state
        this.state = {
            serveUrl: StateManager.get("serveUrl"),
        }
        // update i18n
        i18nService.changeLanguage(StateManager.get("language"));

        // $ global state => local state
        // $ do one-way binding here
        this.unsubscribe = StateManager.subscribe("job-selection-page-update", () => {
            this.setState({
                serveUrl: StateManager.get("serveUrl")
            } as SetStateAction<State>);
        });
    };

    private messengerModule: MessengerModule;

    private unsubscribe: Unsubscribe;

    // *********************
    // Function
    // *********************

    componentDidMount() {
        // we check if already has a account
        let flag: boolean = StateManager.isSignedIn();
        if (!flag) {
            createHashHistory().push('./');
            return;
        }
    }

    async componentWillUnmount() {
        // $ alwyas unsubscribe in un mount function
        if (this.unsubscribe) this.unsubscribe();
    }

    /**
     * select job position and change page
     * @param url 
     */
    clickJobButton(url: "/Chef" | "/Waiter" | "/MealDisplay") {
        // we check if already has a account
        let flag: boolean = StateManager.isSignedIn();
        if (!flag) {
            createHashHistory().push('./');
            return;
        }
        // check if any position has been selected
        const positions = StateManager.get("chefPositionsSelected");
        try {
            if (url === '/MealDisplay') createHashHistory().push(url);
            if (positions && positions.length > 0) {
                // 服务员页面需要多判断未制作页面是否有选择岗位
                if (url === '/Waiter') {
                    let waiterPositionsSelected = StateManager.get("waiterPositionsSelected");
                    if (waiterPositionsSelected && waiterPositionsSelected.length > 0) createHashHistory().push(url);
                    else {
                        createHashHistory().push('/Setting');
                        throw ERROR_0026;
                    }
                }
                else createHashHistory().push(url);
            }
            else {
                createHashHistory().push('/Setting');
                throw ERROR_004;
            }

        } catch (error) {
            this.messengerModule.say(error);
        }

    }

    // *********************
    // View
    // *********************

    render(this: any) {
        return (
            <div
                className="jobSelectPage"
                style={{
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                    // backgroundColor: '#170503',
                    overflow: "hidden",
                }}
            >

                <div
                    style={{
                        minHeight: "100vh",
                        width: "100%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: '#170503',
                    }}
                >

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                            flexWrap: "nowrap",
                            position:'relative'
                        }}
                    >
                        <div
                            className="jobButton"
                            style={{ width: 300, height: 300,borderRadius:'50%'  }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<RestaurantMenuIcon style={{ width: 50, height: 50 }} />}
                                style={{ width: 240, height: 240, fontSize: 24,borderRadius:'50%' }}
                                onClick={() => this.clickJobButton("/Chef")}
                            >
                                {i18nService.t('text_chef')}
                            </Button>

                        </div>
                        <div
                            className="jobButton"
                            style={{ width: 300, height: 300,borderRadius:'50%'  }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<PanToolIcon style={{ width: 50, height: 50, left: 20 }} />}
                                style={{ width: 240, height: 240, fontSize: 24,borderRadius:'50%' }}
                                onClick={() => this.clickJobButton("/Waiter")}
                            >
                                {i18nService.t('text_waiter')}
                            </Button>

                        </div>
                        <div
                            className="jobButton"
                            style={{ width: 300, height: 300,borderRadius:'50%'  }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<VideoLabelIcon style={{ width: 50, height: 50, left: 20 }} />}
                                style={{ width: 240, height: 240, fontSize: 24,borderRadius:'50%' }}
                                onClick={() => this.clickJobButton("/MealDisplay")}
                            >
                                {i18nService.t('text_meal_screen')}
                            </Button>
                        </div>

                    </div>

                    <div
                        style={{
                            fontSize: 20,
                            // color: "#fff",
                            marginTop: 40
                        }}
                    >
                        {i18nService.t('text_please_select_your_job_position')}
                    </div>

                    <Fab
                        color="secondary"
                        onClick={() => createHashHistory().push('/SettingVertify')}
                        style={{ position: "absolute", right: 10, bottom: 10 }}
                    >
                        <SettingsIcon />
                    </Fab>
                    <div style={{ fontSize: 16, position: "absolute", textAlign: 'center', right: 0, left: 0, bottom: 10 }}>2020 MAIN TANK SRL. ALL RIGHTS RESERVED</div>
                </div>
            </div >
        );
    }
}

export default withSnackbar(Jobselection as any);

// *********************
// Types
// *********************

type State = {
    serveUrl: string;
}

type Props = {
} & WithSnackbarProps;