import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import it from "./locales/it";
import zh from "./locales/zh";
import es from "./locales/es";

// import all i18n resources
const resources = {
    'en': { translation: en },
    'it': { translation: it },
    'zh': { translation: zh },
    'es': { translation: es }
};

i18n
    // passes i18n down to react-i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        keySeparator: false,

        interpolation: {
            // react already safes from xss
            escapeValue: false
        }
    });

export default i18n;